.like_big,
.likes_count {
  color: gray;
  font-size: 14px;
  padding-right: initial;
}

.like_big:hover {
  color: Violet;
}

.blog_new_main {
  background: #090909;
}

.blog_new_main .blog_new_banner {
  padding-top: 50px;
  display: flex;
  align-items: center;
  background-image: url("../../../public/images/blog-banner-image1.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.blog_new_main .blog_new_banner .VideoWrper {
  position: relative;
}

.blog_new_main .blog_new_banner .VideoWrper .video_sec {
  height: 650px;
}

.blog_new_main .blog_new_banner .VideoWrper video {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
}

.blog_new_main .blog_new_banner .VideoWrper .content_sec {
  position: absolute;
  top: 17%;
  left: 0%;
  z-index: 2;
  width: 100%;
  padding: 0 30px 0 0;
}

.blog_new_main .blog_new_banner .VideoWrper .content_sec .title_sec {
  position: relative;
  z-index: 1;
}

.blog_new_main .blog_new_banner .VideoWrper .content_sec .title {
  font-size: 45px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  /* margin-left: 50px; */
}

/* .blog_new_main .blog_new_banner .VideoWrper .content_sec .title_sec::after {
    content: "";
    position: absolute;
    bottom: 0%;
    right: 39%;
    background: linear-gradient(92.04deg, #CA46FF 4.22%, #415AFF 114.98%);
    width: 125px;
    height: 11px;
    clip-path: polygon(4% 0%, 100% 0%, 96% 100%, 0% 100%);
    z-index: -1;
} */

.blog_new_main .blog_new_banner .VideoWrper .content_sec .contents {
  font-size: 16px;
  padding-top: 10px;
  color: #fff;
  font-weight: 300;
  line-height: 1.7;
  /* margin-left: 50px; */
  /* width: 40%; */
  text-align: center;
}

.blog_new_main .blog_new_banner .VideoWrper .content_sec .search_sec {
  margin-top: 30px;
}

.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input:focus,
.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  .search_btn:focus {
  box-shadow: unset;
}

.blog_new_main .blog_new_banner .VideoWrper .content_sec .search_sec input {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #ca46ff;
  backdrop-filter: blur(40px);
  border-radius: 9px;
  position: relative;
  color: white !important;
  font-size: 14px;
  font-weight: 300;
}

.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input:-webkit-autofill,
.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input:-webkit-autofill:focus,
.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #041220 inset !important;
  background-image: none;
  -webkit-text-fill-color: #fff !important;
}

.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.52);
  font-weight: 300;
  font-size: 14px;
  opacity: 1;
  /* Firefox */
}

.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.52);
  font-weight: 300;
  font-size: 14px;
}

.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.52);
  font-weight: 300;
  font-size: 14px;
}

.blog_new_main
  .blog_new_banner
  .VideoWrper
  .content_sec
  .search_sec
  .search_btn {
  padding: 0px;
  position: absolute;
  right: 2%;
  top: 17%;
  font-weight: 200;
  z-index: 10;
  color: rgba(255, 255, 255, 0.52);
}

.blog_new_main .blog_new_trending_sec .title {
  font-size: 35px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  position: absolute;
  top: 12%;
  left: 40%;
  z-index: 1;
}

.blog_new_main .blog_new_trending_sec .carousel_sec {
  position: relative;
}

.blog_new_main .blog_new_trending_sec .items {
  padding: 10px 100px;
  background-repeat: no-repeat;
  position: relative;
}

.blog_new_main .blog_new_trending_sec .items:after {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  background: rgb(0 0 0 / 85%);
  width: 100%;
  height: 100%;
  z-index: 0;
}

.blog_new_main .blog_new_trending_sec .trending_box {
  padding: 10px 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  /* height: 50vh; */
  height: auto;
  display: flex;
  align-items: center;
  z-index: 10;
  position: relative;
}

.blog_new_main .blog_new_trending_sec .trending_box .sub_title {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

.blog_new_main .blog_new_trending_sec .trending_box .sub_contents {
  font-size: 16px;
  padding-top: 10px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 1.7;
}

.blog_new_main .blog_new_trending_sec .trending_box .readmore_btn_sec {
  margin-top: 30px;
}

.blog_new_main .blog_new_trending_sec .trending_box .readmore_btn {
  padding: 8px 20px;
  font-size: 17px !important;
  font-weight: 500 !important;
  border: none;
  letter-spacing: 1.5px;
  color: #fff;
  width: 160px;
}

.blog_new_main .blog_new_trending_sec .slick-prev {
  left: 65%;
  top: 80%;
  z-index: 1;
}

.blog_new_main .blog_new_trending_sec .slick-prev:before {
  content: "\01F860";
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  opacity: 1;
}

.blog_new_main .blog_new_trending_sec .slick-prev:hover:before,
.blog_new_main .blog_new_trending_sec .slick-next:hover:before {
  color: #bd3aff;
}

.blog_new_main .blog_new_trending_sec .slick-next {
  top: 80% !important;
  right: 30%;
  z-index: 1;
}

.blog_new_main .blog_new_trending_sec .slick-next:before {
  content: "\01F862";
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  opacity: 1;
}

.blog_new_main .blog_new_trending_sec .slick-dots {
  position: absolute;
  bottom: 15%;
  left: 12%;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}

.blog_new_main
  .blog_new_trending_sec
  .slick-dots
  li.slick-active
  button:before {
  background: linear-gradient(90.17deg, #ca46ff 5.42%, #415aff 104.33%);
  opacity: 1;
}

.blog_new_main .blog_new_trending_sec .slick-dots li {
  margin: 0 18px;
}

.blog_new_main .blog_new_trending_sec .slick-dots li button:before {
  color: #fff;
  opacity: 1;
  content: "";
  width: 40px;
  height: 2px;
  background: #fff;
}

.blog_new_main .blog_new_trending_sec .trending_box .img_sec {
  overflow: hidden;
}

.blog_new_main .blog_new_trending_sec .trending_box img {
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.blog_new_main .blog_new_trending_sec .trending_box img:hover {
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.blog_new_main .blog_new_popular_sec {
  padding: 50px 0px;
}

.blog_new_main .blog_new_popular_sec #popular_tab_sec {
  flex-direction: row !important;
  justify-content: center;
}

.blog_new_main .blog_new_popular_sec #popular_tab_sec .nav-link {
  color: #fff;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  margin: 5px 10px;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  border: none;
}

.blog_new_main .blog_new_popular_sec #popular_tab_sec .nav-link.active,
.blog_new_main .blog_new_popular_sec #popular_tab_sec .show > .nav-link {
  border: 1px solid #fff !important;
  background: unset !important;
  color: #c746ff !important;
}

.blog_new_main .blog_new_popular_sec #popular_tab_content_sec {
  margin-top: 30px;
}

.blog_new_main .blog_new_popular_sec #popular_tab_content_sec .popular_tab_box {
  background: rgba(255, 255, 255, 0.03);
  margin-top: 30px;
  min-height: 500px;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .content_sec {
  padding: 0px 15px 15px;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .img_sec {
  overflow: hidden;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  img {
  position: relative;
  width: 100%;
  height: 283px;
  transition: all 1s;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box:hover
  img {
  scale: 1.05;
  rotate: 3deg;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .category_btn {
  position: absolute;
  top: 10%;
  left: 7%;
  padding: 3px 8px;
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(
    180deg,
    rgba(202, 70, 255, 0.65) 0%,
    rgba(65, 90, 255, 0.65) 100%
  );
  border: 1px solid #ca46ff;
  backdrop-filter: blur(0.5px);
  z-index: 1;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .bottom_sec {
  display: flex;
  padding-left: 15px;
  padding-bottom: 10px;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .content_sec1 {
  min-height: 168px;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .time_sec {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 300;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .like_sec {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  padding-left: 10px;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .like_sec
  .like_sec_btn {
  padding: 0px;
  line-height: 0px;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .like_sec
  .like_sec_btn:focus {
  box-shadow: unset;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .like_sec
  .like_sec_btn
  i {
  color: #777;
}

.blog_new_main
  .blog_new_popular_sec
  #popular_tab_content_sec
  .popular_tab_box
  .like_sec:hover
  i {
  color: #cc45ff;
}

.blog_new_main .blog_new_popular_sec .popular_box {
  padding: 10px;
}

.blog_new_main .blog_new_popular_sec .popular_box .img_sec {
  overflow: hidden;
}

.blog_new_main .blog_new_popular_sec .popular_box img {
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.blog_new_main .blog_new_popular_sec .popular_box img:hover {
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.blog_new_main .blog_new_popular_sec .sub_title {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  padding-top: 15px;
  line-height: 1.6;
}

.blog_new_main .blog_new_popular_sec .sub_contents {
  font-size: 16px;
  color: #ffffffc2;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 1.6;
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .title_sec {
  border-bottom: 2px dashed #994dff;
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .title {
  font-size: 23px;
  font-weight: 300;
  text-transform: capitalize;
  padding-bottom: 15px;
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .popular_list {
  padding: 10px;
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .num_sec {
  font-size: 30px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.45);
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .bottom_sec {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .read_head {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
}

.blog_new_main .blog_new_popular_sec .popular_right_sec .date {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
}

.blog_new_main .blog_new_category_sec {
  padding: 50px 0px;
}

.blog_new_main .blog_new_category_sec .title {
  font-size: 35px;
  color: #fff;
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
}

.blog_new_main .blog_new_category_sec .contents {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}

.blog_new_main .blog_new_category_sec a {
  text-decoration: none;
  color: #fff;
}

.blog_new_main .blog_new_category_sec .category_box {
  margin-top: 30px;
  padding: 25px 20px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  position: relative;
  transition: all 0.2s;
}

.blog_new_main .blog_new_category_sec .category_box:hover:before {
  background: transparent;
  transition: all 0.2s;
}

.blog_new_main .blog_new_category_sec .category_box::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 5%;
  background: linear-gradient(
    180deg,
    rgba(202, 70, 255, 0.45) 0%,
    rgba(65, 90, 255, 0.45) 100%
  );
  width: 85px;
  height: 100%;
  transition: all 0.2s;
  clip-path: polygon(0 0, 70% 0, 100% 100%, 30% 100%);
}

.blog_new_main .blog_new_category_sec .sub_title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}

.blog_new_main .blog_new_card_sec {
  padding: 50px 0px;
}

.blog_new_main .blog_new_card_sec .title {
  font-weight: 600;
  font-size: 35px;
  text-transform: capitalize;
}

.blog_new_main .blog_new_card_sec .cus_title {
  font-size: 60px;
  font-weight: 700;
}

@media (min-width: 991px) {
  .blog_new_main .blog_new_card_sec #blog_new_card {
    padding: 30px;
    position: relative;
    /* background: url('../../asset/inner-pages-new/blog/blog-card-mbl-bg.png'); */
    height: 520px;
  }
}

.blog_new_main .blog_new_card_sec #blog_new_card .blog_new_card_btn_sec {
  margin-top: 30px;
}

.blog_new_main .blog_new_card_sec #blog_new_card .blog_new_card_btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  background: #fff;
  color: #000;
}

.blog_new_main .blog_new_podcast_sec {
  padding: 50px 0px;
}

.blog_new_main .blog_new_podcast_sec .title {
  font-weight: 600;
  font-size: 35px;
  text-transform: capitalize;
  text-align: center;
}

.blog_new_main .blog_new_podcast_sec .podcast_box {
  background: rgba(255, 255, 255, 0.03);
  margin-top: 30px;
}

.blog_new_main .blog_new_podcast_sec .podcast_box .content_sec {
  padding: 20px 20px 10px 20px;
}

.blog_new_main .blog_new_podcast_sec .podcast_box .pod_contents {
  font-weight: 500;
  font-size: 25px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.blog_new_main .blog_new_podcast_sec .podcast_box .social_icon_sec {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.blog_new_main .blog_new_podcast_sec .podcast_box .social_icon {
  color: #ffffffab;
  font-weight: 300;
  font-size: 14px;
  padding: 0px;
  margin-right: 20px;
}

.blog_new_main .blog_new_podcast_sec .podcast_box .social_icon:focus {
  box-shadow: unset;
}

.blog_new_main .blog_new_podcast_sec .podcast_list_sec .podcast_list {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 53px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.blog_new_main .blog_new_podcast_sec .podcast_list_sec .sub_title {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 0px;
}

.blog_new_main .blog_new_podcast_sec .podcast_list_sec .icon_sec {
  background: linear-gradient(180deg, #ca46ff 0%, #415aff 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.blog_new_main .blog_new_podcast_sec .podcast_list_sec .podcast_list i {
  color: #fff;
  font-size: 20px;
}

.blog_new_main .blog_new_video_sec {
  padding: 50px 0px;
}

.blog_new_main .blog_new_video_sec .video_card {
  padding: 30px;
  background: rgba(255, 255, 255, 0.05);
}

.blog_new_main .blog_new_video_sec .title {
  font-weight: 500;
  font-size: 30px;
  text-transform: capitalize;
}

.blog_new_main .blog_new_video_sec .video_sec_main img {
  margin-top: 20px;
  width: 100%;
}

.blog_new_main .blog_new_video_sec .video_sec_sub {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .blog_new_main
    .blog_new_popular_sec
    #popular_tab_content_sec
    .popular_tab_box {
    min-height: 0px;
  }

  .blog_new_main .blog_new_card_sec #blog_new_card {
    padding: 30px;
    background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  }

  .blog_new_main .blog_new_card_sec .cus_title {
    font-size: 50px;
    font-weight: 700;
  }

  .blog_new_main .blog_new_trending_sec .trending_box {
    padding: 10px 0px;
    margin-top: 30px;
  }

  .blog_new_main .blog_new_trending_sec .title {
    top: 3%;
  }

  .blog_new_main .blog_new_trending_sec .trending_box img {
    margin-top: 30px;
  }

  .blog_new_main .blog_new_trending_sec .slick-dots {
    bottom: 1%;
  }

  .blog_new_main .blog_new_trending_sec .slick-prev,
  .blog_new_main .blog_new_trending_sec .slick-next {
    top: 95% !important;
  }

  .blog_new_main .blog_new_trending_sec .slick-prev {
    left: 62%;
  }

  .blog_new_main .blog_new_trending_sec .title {
    left: 38%;
  }
}

@media (max-width: 767px) {
  .blog_new_main .blog_new_banner .VideoWrper .content_sec .title {
    font-size: 28px;
  }

  .blog_new_main .blog_new_banner .VideoWrper .content_sec .title_sec::after {
    display: none;
  }

  .blog_new_main .blog_new_banner .VideoWrper .content_sec {
    padding-right: inherit;
    left: unset;
    top: 18%;
  }

  .blog_new_main .blog_new_trending_sec .title {
    font-size: 25px;
  }

  .blog_new_main .blog_new_trending_sec .trending_box .sub_title,
  .blog_new_main .blog_new_popular_sec .popular_right_sec .title {
    font-size: 20px;
  }

  .blog_new_main .blog_new_trending_sec .items {
    padding: 40px 20px !important;
  }

  .blog_new_main .blog_new_popular_sec #popular_tab_sec .nav-link {
    width: 100%;
  }

  .blog_new_main .blog_new_category_sec .title,
  .blog_new_main .blog_new_card_sec .title {
    font-size: 25px;
  }

  .blog_new_main .blog_new_card_sec .cus_title {
    font-size: 30px;
  }

  .blog_new_main .blog_new_trending_sec .trending_box .readmore_btn,
  .blog_new_main .blog_new_card_sec #blog_new_card .blog_new_card_btn {
    font-size: 14px !important;
  }

  .blog_new_main .blog_new_trending_sec .trending_box {
    background: unset;
    height: auto;
    text-align: center;
  }

  .blog_new_main .blog_new_trending_sec .slick-prev,
  .blog_new_main .blog_new_trending_sec .slick-next {
    display: none !important;
  }

  .blog_new_main .blog_new_popular_sec .popular_right_sec {
    margin-top: 20px;
  }

  .blog_new_main .blog_new_trending_sec .trending_box .readmore_btn {
    margin: 0 auto;
  }

  .blog_new_main .blog_new_trending_sec .title {
    left: 36%;
  }
}

@media (max-width: 600px) {
  .blog_new_main .blog_new_trending_sec .title {
    left: 33%;
  }
}

@media (max-width: 400px) {
  .blog_new_main .blog_new_trending_sec .trending_box .sub_title {
    font-size: 18px !important;
  }

  .blog_new_main .blog_new_category_sec,
  .blog_new_main .blog_new_card_sec {
    padding-top: 0px;
  }

  .blog_new_main .blog_new_banner .VideoWrper .video_sec {
    height: 480px;
  }

  .blog_new_main .blog_new_trending_sec .title {
    left: 25%;
  }

  .blog_new_main .blog_new_banner .VideoWrper .content_sec .search_sec {
    margin-top: 20px;
  }
}

@media (min-width: 767px) {
  .video_sec_main iframe {
    height: 380px;
  }
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
}

.avatar-img,
.avatar-initials,
.avatar-placeholder {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.avatar-img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-initials {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  line-height: 0;
  background-color: #a0aec0;
}

.avatar-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  background: #a0aec0
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='%23fff' d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'/%3e%3c/svg%3e")
    no-repeat center/1.75rem;
}

.avatar-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20%;
  height: 20%;
  display: block;
  background-color: #a0aec0;
  border-radius: 50%;
}

.avatar-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

.avatar-sm,
.avatar-group-sm > .avatar {
  width: 2.125rem;
  height: 2.125rem;
  font-size: 1rem;
}

.avatar-sm .avatar-placeholder,
.avatar-group-sm > .avatar .avatar-placeholder {
  background-size: 1.25rem;
}

.avatar-group-sm > .avatar + .avatar {
  margin-left: -0.53125rem;
}

.avatar-lg,
.avatar-group-lg > .avatar {
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
}

.avatar-lg .avatar-placeholder,
.avatar-group-lg > .avatar .avatar-placeholder {
  background-size: 2.25rem;
}

.avatar-group-lg > .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-light .avatar-indicator {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
}

.avatar-group-light > .avatar {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
}

.avatar-dark .avatar-indicator {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25);
}

.avatar-group-dark > .avatar {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25);
}

/* Font not working in <textarea> for this version of bs */

textarea {
  font-family: inherit;
  color: white;
}

.blog-place:placeholder-shown {
  color: white !important;
  width: 100%;
  height: 8vh;
}


.aside-block {
    margin-bottom: 30px;
}


.aside-title, .category-title {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 30px;
    border-bottom: 2px solid var(--color-black);
}

.list-unstyled li{
    display: inline-block;
    color: rgba(var(--color-black-rgb), 0.7);
    padding: 7px 10px;
    border: 1px solid white;
    margin-bottom: 8px;
    transition: 0.3s all ease;
    margin-right: 5px;

}



.aside-title{
border-bottom: 1px solid white;
}



.bottom_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.like_sec {
  display: flex;
  align-items: center;
}

.read_more_sec {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Center-align the Read More button */
}

.read_more_sec button {
  padding: 3px 8px;
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(
    180deg,
    rgba(202, 70, 255, 0.65) 0%,
    rgba(65, 90, 255, 0.65) 100%
  );
  border: 1px solid #ca46ff;
  backdrop-filter: blur(0.5px);
  z-index: 1;
  margin-right: 40px;
}


.titlebar {
  /* background: linear-gradient(90deg, #1e3c72, #2a5298); */
  background-image: url("https://media.istockphoto.com/id/1406988004/vector/brigh-blue-geometric-shapes-and-stripes-on-dark-blue-background.jpg?s=612x612&w=0&k=20&c=BdWWyQbuHk4xr5faMN82Suc6Vt5Merpf1EgBcMQmWII=");
  background-size: cover;
  background-position: center;
background-repeat: no-repeat;
height: 50vh;
object-fit: contain;
text-align: center;
width: 100%;
}
.titlebar-container .ourtopheadingblog{
  margin-top: 150px !important;
}
@media(max-width:768px){
  .titlebar {
    /* background: linear-gradient(90deg, #1e3c72, #2a5298); */
    background-image: url("https://media.istockphoto.com/id/1406988004/vector/brigh-blue-geometric-shapes-and-stripes-on-dark-blue-background.jpg?s=612x612&w=0&k=20&c=BdWWyQbuHk4xr5faMN82Suc6Vt5Merpf1EgBcMQmWII=");
    background-size: cover;
    background-position: center;
  background-repeat: no-repeat;
  height: 30vh;
  object-fit: contain;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  }
  .titlebar-container .ourtopheadingblog{
    margin-top: 50px !important;
  }
}

.titlebar h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.2;
}

.titlebar .entry-meta li {
  font-size: 18px;
  font-weight: 500;
  color: #e6e6e6;
}

.titlebar a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.titlebar a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.single-post img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin: 0 auto 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.single-post .entry-content {
  font-size: 18px;
  line-height: 1.6;
  font-family: 'Georgia', serif;
  color: #444;
  text-align: justify;
}

.single-post .entry-content p {
  margin-bottom: 20px;
  font-weight: bold;
}

.aside-block {
  background: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.aside-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #1e3c72;
}

.aside-tags li {
  font-size: 16px;
  padding: 8px 10px;
  margin-bottom: 8px;
  background: #f0f0f0;
  display: inline-block;
  border-radius: 4px;
  font-weight: bold;
  color: black;
}

.breadcrumb {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
}

.breadcrumb li {
  margin-left: 10px;
}

.breadcrumb li a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}

.breadcrumb li a:hover {
  text-decoration: underline;
}