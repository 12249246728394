.dashboard-main-box {
    margin-top: 40px !important;
}

.dashboard-container-box {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid white;
    /* transition: 0.6s; */
    width: 100%;
    max-width: 290px; /* Adjust this to allow flexibility */
    height: auto;
}

/* Hover effect */
/* .dashboard-container-box:hover {
    transform: scale(1.06);
} */

/* Key feature heading styles */
.dashboard-main-box .Key-feature-heading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0px 20px 0px;
}

.Key-feature-heading {
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

.dashboard-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 44px;
    margin-bottom: 30px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .dashboard-container {
        gap: 20px !important;
        justify-content: center; /* Ensure the cards align well */
    }

    .dashboard-container-box {
        width: 100%; /* Make the cards take full width on smaller screens */
        max-width: 100%; /* Ensure they don't exceed container width */
    }
}

/* Tablet and smaller screen responsiveness */
@media (max-width: 600px) {
    .dashboard-container {
        gap: 15px !important;
    }

    .dashboard-container-box {
        width: 100%; /* Ensure one column layout on very small screens */
    }
}

/* Large screens */
@media (min-width: 1200px) {
    .dashboard-container {
        gap: 30px !important; /* Adjust the gap on larger screens */
    }

    .dashboard-container-box {
        width: calc(33.33% - 30px); /* 3 columns layout */
    }
}

/* Extra Large screens */
@media (min-width: 1500px) {
    .dashboard-container-box {
        width: calc(25% - 30px); /* 4 columns layout on very large screens */
    }
}

.key-feature-heading h4 {
    text-align: center;
}

/* Center the image */
.key-feature-heading img {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.key-feature-box:hover {
    border: 1px solid rgb(184, 32, 211);
}
