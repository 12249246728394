.Item img{
    width: 160px;
    cursor: pointer;
    }
    
    .product-img-div{
        /* border: 1px solid #F4F4F4; */
        border-radius: 5px;
        /* width: 162px; */
        margin-left: 95px;
        /* cursor: pointer; */
    }
    
    
    .star-div{
        /* line-height: 20px; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    
    .Item .star-div p{
    color: #6C757D;
  text-align: center;
    width: 200px;
    
    }
    
    .Item .star-div span{
        font-weight: bold;
    }
    
    
    .Item .star-div .rate{
       color: #608CC7;
    }

    .Item {
        border: 2px solid;
        margin: 30px;
        height: 350px;
        padding: 20px 0px;
        border-radius: 10px;
    }
    
    .Learn-more-button{
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);
        border-radius: 4px;
        border: none;
        box-shadow: unset;
        margin-top: 10px;
        padding: 5px;
        width: 140px !important;
        text-align: center;
        transition: 2s;
        animation: glowing 2s ease infinite alternate; 
    }
    