/* .contect-form {
  position: absolute;
  right: 100px;
  top: 70px;
  margin-top: 40px;
  opacity: 1;
  background: #313240;
  border-radius: 10px;
 
}


.form-div {
  border-radius: 5px;

  margin: auto;

  padding: 20px 40px;
  width: auto;
  height: auto;
 
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.form-heading-1 {
  font-size: 23px;

  background: linear-gradient(90deg, #CC46FB 0%, #4DFFF4 38.02%, #FFB44A 70.31%, #FF00E5 100%);
  -webkit-background-clip: text;
 
  color: transparent;
  
  font-size: 24px;
 
  font-weight: bold;
 
  display: inline-block;
 
  font-weight: 800;
}

.form-div input {
  background-color: transparent;
  padding-left: 10px;
  width: 250px;
  height: 7vh;
  border: 1px solid #575555;
  color: white !important;
}

.form-div textarea {
  background-color: transparent;
  border: 1px solid #575555;
  padding-left: 10px;

}

.form-div input:hover {
  background-color: rgba(106, 105, 105, 0.164);
}


.submit {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  box-shadow: unset;
  margin-top: 10px;
  width: auto !important;
  text-align: center;
  transition: 2s;
  animation: glowing 2s ease infinite alternate;
}




@media (max-width:992px) {
  .contect-form {
    display: none;
  }
} */


.contact-form-container {
  background: #313240;
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
  max-width: 400px; /* Prevent the form from being too wide on larger screens */
}

.form-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 15px;
}

.form-heading-1 {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(90deg, #CC46FB 0%, #4DFFF4 38.02%, #FFB44A 70.31%, #FF00E5 100%);
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.contact-form-iframe {
  border-radius: 8px;
  width: 100%; /* Make the iframe responsive */
  height: 437px;
  max-width: 100%; /* Ensure the iframe doesn't overflow */
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .contact-form-container {
    display: none; /* Hide the form on small screens */
  }

  .form-div input,
  .form-div textarea {
    width: 100%; /* Ensure inputs and textarea take full width on smaller screens */
  }

  .contact-form-container {
    padding: 15px; /* Slightly reduce padding on smaller screens */
  }

  .form-heading-1 {
    font-size: 20px; /* Adjust heading size for smaller screens */
  }

  .contact-form-iframe {
    height: 300px; /* Reduce iframe height on smaller screens */
  }
}
