.dashboard-main-box {
    margin-top: 40px !important;
}

.dashboard-container-box {
    padding: 20px 20px;
    cursor: pointer;
    width: 290px !important;
    height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    border-radius: 15px;
    border: 1px solid white;
    transition: 0.6s;
}

.dashboard-container-box:hover {
    transform: scale(1.06);
}

.dashboard-main-box .Key-feature-heading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0px 20px 0px;
}

.Key-feature-heading {
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

.dashboard-container {
    margin: 0px;
    flex-wrap: wrap !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 44px !important;
}

.software-feature-headings {
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

.fs-4 {
    font-size: calc(1.275rem + .3vw) !important;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

@media (max-width:768px) {
    .dashboard-container {
        width: 100%;
        gap: 20px !important;
    }
}
