@import url('https://fonts.googleapis.com/css?family=Amatic+SC');



.button_container {
  position: absolute;
}

.btn-hover {
  /* border: 1px solid;
  border-color: #e35cbd80 #e35cbd80 #817ce78f #817ce78f !important;
  border-image-slice: 1;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 12px !important;
  background-color: #222;
  padding: 5px 10px;
  margin: 0 auto;
  box-shadow: 0 5px 5px rgb(63, 185, 241); */
  width: 250px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 12px !important;
  outline: none;
  position: relative;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  display: block;
  padding: 10px 2px;
  border-radius: 10px !important;
  box-shadow: 0 5px 5px rgb(63, 185, 241);
  background-color: #000 !important;
  color: #fff !important;
  overflow: hidden;
  border: 1px solid;
  border-color: #e35cbd80 #e35cbd80 #817ce78f #817ce78f !important;
  border-image-slice: 1;
}

.btn-hover span {
  position: relative;
  z-index: 1;
}

.btn-hover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #78c7d2;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.btn-hover:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.link {
  font-size: 20px;
  margin-top: 30px;
}

.link a {
  color: #000;
  font-size: 25px;
}