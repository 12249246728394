.our-team {
    padding: 30px 0 40px;
    margin-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.056);
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: 1px solid #00FFFF;
}

.our-team .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
}

.our-team .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #50727B;
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
    height: 100%;
}

.our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* background-color: #50727B; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.7);
}

.our-team .title-ourteam {
    display: block;
    font-size: 15px;
    color: #4e5052;
    text-transform: capitalize;
}

.our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #50727B;
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
}

.social p {
    /* padding-top: 20px; */
}

.our-team:hover .social {
    bottom: 0px;
}

.our-team .social p {
    display: inline-block;
}

.our-team .social p {
    display: block;
    /* padding: 10px; */
    font-size: 17px;
    color: white;
    transition: all 0.3s ease 0s;
    text-decoration: none;
}


.container-ourteam {
    padding: 30px;
}




.name {
    text-align: center;
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

.xle-ourteam {
    text-align: center;
    font-size: 15px;
}

.container h1 {
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

.main-navigation {
    margin: 0 auto;
    top: 35px;
    left: -1px;
    position: fixed;
    width: 101%;
    height: 100px;
    z-index: 100;
    background-color: #3498DB;
    -webkit-box-shadow: 0px 17px 5px -13px rgba(158, 158, 158, 0.68);
    -moz-box-shadow: 0px 17px 5px -13px rgba(158, 158, 158, 0.68);
    box-shadow: 0px 17px 5px -13px rgba(158, 158, 158, 0.68);
}

.main-menu {
    color: #fff;
    padding-top: 40px;
    padding-right: 50px;
    float: right;
}

.social-bar {
    position: fixed;
    background-color: white;
    height: 35px;
    width: 100%;
    z-index: 99;
}

.social-icons {
    margin-top: -3px;
    color: #3498DB;
    float: right;
}

.social-icons li {
    display: inline-block;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 18px;
    transition: all 1s;
}

.social-icons li:last-child {
    margin-right: 100px;
}

.social-icons li:hover {
    color: #888;
    cursor: pointer;
}

.main-menu li {
    display: inline-block;
    margin-left: 20px;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 0px solid #eee;
    padding-bottom: 3px;
    transition: border-width 0.2s;
}

.main-menu li a,
a:hover,
a:active,
a:visited {
    color: white;
    text-decoration: none;
}

.main-menu li a {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.active {
    border-bottom: 2px solid white !important;
    padding-button: 3px;
}

.main-menu li:hover {
    cursor: pointer;
    border-width: 2px;
}

.main-menu li:first-child {
    margin-left: 0;
}

.logo h1 {
    font-family: 'Pacifico', cursive;
    padding-top: 10px;
    padding-left: 50px;
    color: #fff;
}

.nav-color {
    background-color: #3498DB;
}

.about-row {
    /* background-color: #ECF0F1; */
    width: 80%;
    margin: 0 auto 0 auto;
}

.about-section {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    /* background-color: #ECF0F1; */
    /* border: 1px solid white; */
}

.about-text {
    font-size: 23px;
    text-align: justify;
}

.about-pic {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 300px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(158, 158, 158, 0.68);
    -moz-box-shadow: 0px 0px 5px 1px rgba(158, 158, 158, 0.68);
    box-shadow: 0px 0px 5px 1px rgba(158, 158, 158, 0.68);
}

.projects-section {
    background-color: #2C3E50;
    width: 100%;
}

.projects-section h2,
.skills-section h2,
.contact-section h2 {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    color: white;
}

.skills-section {
    background-color: #ECF0F1;
    width: 100%;
    padding-bottom: 50px;
}

.contact-section h2 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-section {
    background-color: #2980B9;
    position: relative;
    padding-bottom: 150px;
    color: white;
    text-align: center;
    z-index: 1;
}

.contact-section-row {
    background-color: #2980B9;
}

.contact-section input {
    margin: 0 auto;
    border-radius: 0;
    height: 50px;
    border: 0;
    width: 80%;
}

.contact-section textarea {
    width: 80%;
    margin: 0 auto;
    border-radius: 0;
    height: 150px;
    border: 0;
}

.contact-section .btn {
    border-radius: 0;
    padding: 10px 25px 10px 25px;
    text-transform: uppercase;
    margin: 0 auto;
    background-color: white;
    border: 0;
    margin-top: 45px;
}

.strong-text {
    font-weight: 700;
    font-size: 25px;
    display: block;
}

.project-img {
    margin: 0 auto;
    margin-bottom: 30px;
    -webkit-transition: filter 1s;
    transition: filter 1s;
}

.project-img:hover {
    cursor: pointer;
    -webkit-filter: blur(4px);
    filter: blur(4px);


}

.title-border {
    border: 0px solid white;
    padding: 20px 15px 20px 15px;
    width: 200px;
    margin: 0 auto;
    background-color: #E74C3C;
    border-radius: 5px;
}

.footer {
    background-color: #f8f8f8;
    padding: 10px;
    color: #777;
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin-bottom: -1000px;
}

.footer p {
    padding-top: 8px;
}

.encourage-contact {
    font-size: 23px;
    margin: 50px;
    text-align: justify;
}

.footer-social {
    color: white;
    font-size: 23px;
    text-align: center;
}

.footer-social ul {
    text-decoration: none;
    display: inline-block;
    margin: 10px 25px 10px 25px;
    -webkit-transition: color 1s;
}

.footer-social ul li:hover {
    color: #ccc;
    cursor: pointer;
}

.mobile-menu-icon {
    display: none;
    color: white;
    font-size: 23px;
    float: right;
    margin-top: 25px;
    margin-right: 50px;
}

.mobile-menu-icon:hover {
    cursor: pointer;
}

.responsive-menu {
    overflow: hidden;
    z-index: 10;
    top: 20px;
    left: 0;
    height: 0px;
    width: 100%;
    background-color: #3498DB;
    text-transform: uppercase;
}

.mobile-menu {
    margin-top: 20px;
    list-style-type: none;
    text-align: center;
    color: white;
    font-size: 18px;
}

.mobile-menu a,
a:visited,
a:active,
a:hover {
    color: white;
}

.mobile-menu li {
    margin-bottom: 10px;
    text-decoration: none;
}

.coming-soon {
    position: relative;
    clip: rect(0px, 500px, 400px, 0px);
    height: 400px;
    width: 400px;
}

.pen-center {
    text-align: center;
}

.Enterprise-left-dive h1{
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.Enterprise-left-dive .about-text-span {
    font-size: 20px;
    font-weight: bold;
}

.Enterprise-left-dive .lalit-sir-about {
    font-size: 18px;
    line-height: 1.9rem;
    padding-top: 10px;
}


@media only screen and (max-width: 991px) {
    .about-text {
        margin-top: 30px;
        text-align: center;
        font-size: 150%;
    }

    .about-pic {
        margin: 10px auto;
    }

    .footer {
        bottom: 250px;
    }

    .pen-frame {
        margin-bottom: 30px;
    }
    .Enterprise-right-dive h1 {
        text-align: center;
    }
    .Enterprise-left-dive h1{
        text-align: center;
    }
}

@media only screen and (min-width: 715px) {
    .responsive-menu {
        display: none;
    }
}

@media only screen and (max-width: 715px) {
    .main-menu {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }

    .mobile-menu-icon {
        margin-top: 32px;
    }
}

/* @media only screen and (max-width: 520px) {
    .social-icons {
        float: none;
        display: inline-block;
    }

    .social-icons li:last-child {
        margin-right: 0;
    }

    .social-bar {
        text-align: center;
    }

    .footer {
        height: 70px;
        bottom: 0px;
    }
.Enterprise-container .Enterprise-boxes{
        flex-direction: column;
        flex: 1;
    }
} */




@media only screen and (max-width: 520px) {
    .Enterprise-container .Enterprise-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Enterprise-right-dive {
        order: 1; /* Move the image section to the first position */
    }

    .Enterprise-left-dive {
        order: 2; /* Move the text section to the second position */
          text-align: center; /*Optional: Center-align text for smaller screens  */
          width: 100%;

    }
    .Enterprise-left-dive  p{
        text-align: center;
        width: 90%; /* Set width to a percentage for responsiveness */
        max-width: 400px; /* Ensure the text block doesn’t grow too wide */
        font-size: 0.95rem; /* Use a responsive font size */
        line-height: 1.8; /* Improve readability with increased line height */
        margin: 10px auto; 

    }
    .Enterprise-left-dive h1 {
        text-align: center;
    }
    

    .Enterprise-right-dive img {
        width: 100%; /* Make the image responsive */
        max-width: auto ; /* Prevent it from becoming too large */
        height: auto; /* Maintain aspect ratio */
       
    }
    

    
}


.img-circle {
    border-radius: 50%;
}

.Enterprise-right-dive h1 {
    text-align: center !important;
}

.team-section {
    /* background: #f1f2f6; */
    text-align: center;
}

.inner-width {
    max-width: 1200px;
    margin: auto;
    padding: 40px;
    color: #333;
    overflow: hidden;
}


.pe {
    float: left;
    width: calc(100% / 3);
    overflow: hidden;
    padding: 40px 0;
    transition: 0.4s;
    border: 1px solid #01D1D1;
    border-radius: 10px;
}

.pe:hover {
    background: #dfe4ea;
    color: black;
}

.pe:hover .p-name {
    color: black;
    font-size: 25px;
}

.pe:hover .p-sm {
    color: black;

}




.pe img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.p-name {
    font-size: 18px;
    margin: 5px;
    text-transform: uppercase;
    margin-top: 6px;
}

.p-des {
    font-style: italic;
    color: #3498db;
}

.p-sm {
    margin-top: 6px;
}

.p-sm a {
    margin: 0 4px;
    display: inline-block;
    width: 30px;
    height: 30px;
    transition: 0.4s;
}

.p-sm a:hover {
    transform: scale(1.3);
}

.p-sm a i {
    color: #333;
    line-height: 30px;
}

@media screen and (max-width: 960px) {
    .pe {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    .pe {
        width: 50%;
    }

    .pers {
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 600px) {
    .pe {
        width: 100%;
    }
}


.pers {
    display: flex;
    justify-content: space-around;
}







.team-boxe {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-box__thumb {
    position: relative;
    z-index: 2;
    padding: 0px 12px;
}

.team-box__thumb img {
    width: 100%;
}

.team-box__content {
    padding: 40px 25px;
    padding-top: 25px;
    text-align: center;
    border-top: 0px;
    position: relative;
    padding-bottom: 30px;
    position: relative;
    z-index: 1;
}

.team-box__content::after,
.team-box__content::before {
    top: -210px;
    min-height: 210px;
    width: 100%;
    border: 2px solid #2A4877;
    border-bottom: 0px;
    content: "";
    position: absolute;
    left: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: -1;
}

.team-box__content::after {
    top: auto;
    bottom: 0;
    border-top: 0px;
    border-bottom: 2px solid #2A4877;
}

.team-box__content .name {
    font-size: 22px;
    color: #fff;
    margin-bottom: 7px;
}

.team-box__content .designation {
    color: #fff;
    opacity: 0.7;
}

.team-box__content--2::after,
.team-box__content--2::before {
    border-color: #00FDFD;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.team-box__content--2 .names {
    color: #146af5;
    text-align: center;
}

.team-box__content--2 .designationc {
    color: white;
}

.team-boxe .social-links {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.team-boxe .social-links a {
    width: 38px;
    height: 38px;
    text-align: center;
    border: 1px solid #fff;
    font-size: 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}

.team-boxe .social-links a:hover {
    color: #fff;
    background: #FD4A36;
    border-color: #FD4A36;
}

.team-boxe .social-links a:not(:last-child) {
    margin-right: 15px;
}

.team-boxe .social-links__2 a {
    border-color: #00FDFD;
    background: transparent;
    color: #0C2957;
}

.team-boxe:hover .team-box__content::after,
.team-boxe:hover .team-box__content::before {
    border-color: #FD4A36;
}

.team-box__2:hover .team-box__content--2::after,
.team-box__2:hover .team-box__content--2::before {
    border-color: transparent;
    box-shadow: 0px 20px 35px 0px rgba(0, 15, 105, 0.08);
}

.rowt {
    display: flex;
    justify-content: center;
    align-items: center;
}


.containere {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}

@media (min-width:576px) {
    .containere {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .containere {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .containere {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .containere {
        max-width: 1200px
    }
}

.rowt {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.All-icons-ul {
    display: flex;
    gap: 10px;
    text-align: left;
}

.All-icons-ul li {
    border: 1px solid #00FDFD;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.All-icons-ul li:hover {
    background-color: #e74d3cb4;
}

.image-founder {
    border: 1px solid #00FDFD;
    height: 50vh;
    border-radius: 10px;
    box-shadow: 0px 1px 20px -6px #00FDFD;
}

.image-founder img {
    margin-top: 8vh;
}

.image-founder img:hover {
    transform: scale(1.3);
    transition: 600ms;
}

.about-text span {
    color: #3cd6e7;
}

@media(max-width:500px) {
    .team-texts-div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .team-texts-div h2 {
        text-align: center;
        font-size: 30px;
    }

    .image-founder {
        border: 1px solid #00FDFD;
        height: 45vh;
        border-radius: 10px;
        box-shadow: 0px 1px 20px -6px #00FDFD;
    }

    .image-founder img {
        margin-top: 10vh;
    }

    .image-founder img:hover {
        transform: scale(1.1);
        transition: 600ms;
    }
}

.lalit-sir-about span {
    color: #146af5;
}

#ajay img{
    width: 100%;
    height: 10% !important;
}


.whats-icon-web{
    color: red !important;
}


/* *
{
	background: #f1f2f6;
	margin: 0;
	padding: 0;
} */

/* .image-area
{
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
} */

.img-wrapper
{
	width: 300px;
	height: 400px;
	position: relative;
	overflow: hidden;
}

.img-wrapper:before
{
	content: '';
	position: absolute;
	top: 0;
	left: 180%;
	height: 100%;
	width: 100%;
	background: rgba(255,255,255,.3);
	z-index: 1;
	transform: skew(45deg); 
	 transition: .5s; 
}

.img-wrapper:hover:before
{
	left: -180%;
}

.img-wrapper img
{
	height: 400px;
	width: 300px;
	/* filter: grayscale(100%); */
	transition: 2s;
}
.img-wrapper:hover img
{
	filter: grayscale(0%);
	transform: scale(1.1);
}
.img-card{
    /* background-color: linear-gradient(to right, darkblue, darkorchid) 1; */
    border: 5px solid ; /* Set border width */
    /* border-image: linear-gradient(to right, darkblue, darkorchid) 1; */
  
}

.img-wrapper h2
{
	background: #856cf5;
	font-family: Poppins;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	margin: 0;
	padding: 6px 0;
	position: absolute;
	bottom: 0;
	width: 100%;
	transform: perspective(400px) rotateY(90deg); 
	transform-origin: right;
	transition: 1s;
}

.img-wrapper:hover h2
{
	transform: perspective(400px) rotateY(0deg);
}

.img-wrapper ul
{
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	background: rgba(255,255,255,0);
}

.img-wrapper ul li
{
	background: #333;
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	transform: perspective(800px) rotateY(90deg);
	transition: .5s;
	transform-origin: left;
}

.img-wrapper:hover ul li
{
	transform: perspective(800px) rotateY(0deg);
}

.img-wrapper:hover ul li:nth-child(1)
{
	transition-delay: .2s;
}

.img-wrapper:hover ul li:nth-child(2)
{
	transition-delay: .6s;
}

.img-wrapper:hover ul li:nth-child(3)
{
	transition-delay: .8s;
}

.img-wrapper:hover ul li:nth-child(4)
{
	transition-delay: 1s;
}

.img-wrapper ul li a
{
	color: tomato;
	background: rgba(255,255,255,0);
}

.img-wrapper ul li i
{
	color: tomato;
	background: rgba(255,255,255,0);
}

.img-wrapper ul li i:hover
{
	color: #fff;
	background: rgba(255,255,255,0);
}