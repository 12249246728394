.HddrBg {
  box-shadow: 2px 2px 5px 1px #9c9a9a93;
}

.website-list span.nav-link:hover {
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  /* text-fill-color: transparent !important; */
  -webkit-text-fill-color: transparent !important;

  transition: 0.6s !important;
}

.contact-list a.nav-link:hover {
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  /* text-fill-color: transparent !important; */
  -webkit-text-fill-color: transparent !important;

  transition: 0.6s !important;
}


@media (max-width: 1400px) {
  .bnrVideoTop {
    margin-top: 50px;
  }
}

.Responsive-icon {
  display: none !important;
}

@media all and (max-width:992px) {
  .Responsive-icon {
    display: block !important;
  }

}

.Main-section #homebanner .banner_btn_sec {
  margin-top: 30px;
  margin-bottom: 30px;
}

.Main-section #homebanner .BnrCnt .title {
  margin-bottom: 20px;
  color: #ffffffb8;
  line-height: 1.2;
}

.Main-section #homebanner .BnrCnt .cus_title {
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.Main-section #homebanner .vdoCntLst {
  margin-top: 10px;
}

.Main-section .BnnerCntSec .cus_title {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  font-family: "Urbanist", sans-serif;
  text-transform: uppercase;
}

.Main-section .BnnerCntSec .cus_title_we {
  font-weight: 200;
}

@media (max-width: 767px) {
  .Main-section #homebanner .BnrCnt .title {
    line-height: 1.4;
  }

  .Main-section #homebanner .BnrCnt .cus_title {
    font-size: 38px;
  }

  .Main-section #homebanner .banner_btn_sec {
    margin-top: 0px;
    margin-bottom: 45px;
  }

  .Main-section .BnnerCntSec .cus_title {
    font-size: 25px;
  }

  .Main-section .BnnerCntSec .BnrCnt h1 {
    line-height: 1.5;
    font-size: 25px;
  }

  .Main-section .why_metablock_blockchain_sec {
    padding-top: 0px !important;
    text-align: center;
  }

  .Main-section .why_metablock_blockchain_sec .HddrBtns {
    display: flex;
    justify-content: center;
  }

  .Main-section .blockchain_solution_sec .blck_sol_box,
  .Main-section .blockchain_solution_sec .blck_sol_box1 {
    min-height: 0px !important;
  }

  .Main-section .blockchain_solution_sec .sub_title {
    margin-top: 20px !important;
  }

  .Main-section .blockchain_solution_sec .BnrCnt h2 {
    text-align: center;
  }

  .Main-section .blockchain_solution_sec .img_sec {
    margin-top: -10% !important;
  }

  .Main-section .blockchain_dev_sec .blockchain_dev_btnsec .BtnPrimry {
    margin: 0 auto;
  }

  .Main-section .blockchain_dev_sec .mnyExpoScImg img {
    margin-top: 30px;
  }
}

.Main-section .why_metablock_blockchain_sec {
  padding: 50px 0px;
}

@media (min-width: 991px) {
  .Main-section .why_metablock_blockchain_sec .why_metablock_blockchain_card {

    background-repeat: no-repeat;
    padding: 50px;
    min-height: 562px;
  }
}

.Main-section .why_metablock_blockchain_sec .contents {
  font-size: 16px !important;
  line-height: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 300;
}

@media (min-width: 991px) {
  .Main-section .blockchain_solution_sec {
    padding: 50px 0px;

    background-repeat: no-repeat;
    min-height: 1289px;
  }
}

.Main-section .blockchain_solution_sec .blck_sol_box {
  border-radius: 10px;
  background: linear-gradient(180deg,
      #ffffff24 0%,
      rgba(6, 6, 6, 0) 100%);
  padding: 20px;
  margin-top: 60px;
  min-height: 350px;
  position: relative;
  backdrop-filter: blur(3px);
  text-align: center;
}

.Main-section .blockchain_solution_sec .blck_sol_box1 {
  border-radius: 10px;
  background: linear-gradient(0deg, #ffffff24 0%, rgba(6, 6, 6, 0) 100%);
  padding: 20px;
  margin-top: 80px;
  min-height: 354px;
  position: relative;
  backdrop-filter: blur(3px);
  text-align: center;
}

.Main-section .blockchain_solution_sec .sub_title {
  color: #e64dff;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Urbanist", sans-serif;
  margin-top: 15px;
}

.Main-section .blockchain_solution_sec .sub_contents {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 30px;
}

.Main-section .blockchain_solution_sec .img_sec {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -20%;
}

.Main-section .blockchain_solution_sec .img_sec:after {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(125deg, #e442ff -13.43%, #3c5bff 100.49%);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 42%;
  left: 50%;
  z-index: -1;
  position: absolute;
  content: "";
  animation: rotatebg 2s infinite alternate-reverse;
}

.Main-section .blockchain_solution_sec .sprite_icons {
  height: 50px;
  width: 50px;
  object-fit: none;
}

.Main-section .blockchain_dev_sec {
  overflow: unset;
  padding-top: 0px;
}

.Main-section .blockchain_dev_sec p {
  line-height: 30px;
  font-weight: 300;
  font-size: 16px;
}

@keyframes rotatebg {
  from {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(0);
  }
}

.OurBlgBg .OurSrvcHdd .blog_title {
  background: linear-gradient(92.12deg, #e442ff 2.22%, #3c5bff 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.Main-section .MtaverseSec .title,
.Main-section .metaSlnSc .title,
.Main-section .OurPrjctBg .title {
  background: linear-gradient(92.12deg, #e442ff 2.22%, #3c5bff 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.Main-section .metablock_testimonial_sec {
  padding: 50px 0px;
}

.Main-section .metablock_testimonial_sec .title {
  font-weight: 600;
  font-size: 60px;
  line-height: 1.4;
  font-family: "Urbanist", sans-serif;
  text-transform: capitalize;
}

.Main-section .metablock_testimonial_sec .title .cus_title {
  color: #828282;
}

.Main-section .metablock_testimonial_sec .testimonials {
  margin-top: 30px;
}

.Main-section .metablock_testimonial_sec .item {
  margin: 20px 15px;
  min-height: 330px;
  border: 1px solid;
  border-color: #e35cbd80 #e35cbd80 #817ce78f #817ce78f;
  border-radius: 20px;
  position: relative;
  padding: 40px 25px;
  display: flex;
}

.Main-section .metablock_testimonial_sec .item::before {
  content: "";
  position: absolute;
  top: -28px;
  left: 50px;
  width: 52px;
  height: 52px;
  background: url("https://www.metablocktechnologies.com/asset/home-page-new/images/blueQuotes.webp") center/52px no-repeat;
}

.Main-section .metablock_testimonial_sec .item .testMoLst {
  margin-left: 15px;
}

.Main-section .metablock_testimonial_sec .item .testMoImgBg {
  display: flex;
  justify-content: space-between;
}

.Main-section .metablock_testimonial_sec .user_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.Main-section .metablock_testimonial_sec .sub_title {
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(105.71deg, #bd3aff -4.33%, #5a69ff 117.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.Main-section .metablock_testimonial_sec .sub_title1 {
  font-size: 14px;
  font-style: italic;
  color: #fff;
  text-transform: capitalize;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 0px;
}

.Main-section .metablock_testimonial_sec .sub_contents {
  font-size: 16px;
  padding-top: 15px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 1.8;
}

.Main-section .metablock_testimonial_sec .testimonials .slick-prev {
  left: 0%;
  z-index: 1;
}

.Main-section .metablock_testimonial_sec .testimonials .slick-prev:before {
  content: "\276E";
  font-size: 18px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #373737;
}

.Main-section .metablock_testimonial_sec .testimonials .slick-next {
  right: 1%;
  z-index: 1;
}

.Main-section .metablock_testimonial_sec .testimonials .slick-next:before {
  content: "\276F";
  font-size: 18px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #373737;
}

@media (max-width: 991px) {
  .Main-section .metablock_testimonial_sec .title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .Main-section .metablock_testimonial_sec .title {
    font-size: 25px;
    text-align: center;
  }
}

@media (max-width: 467px) {
  .Main-section .metablock_testimonial_sec .item {
    display: initial;
    padding: 30px;
  }

  .Main-section .metablock_testimonial_sec .item .testMoLst {
    margin-left: 0px;
    margin-top: 10px;
  }

  .Main-section .metablock_testimonial_sec .item .testMoImgBg {
    display: initial;
  }

  .Main-section .metablock_testimonial_sec .item .testMoImgBg .head_sec {
    margin-bottom: 10px;
  }
}


@media (min-width: 991px) {
  .Main-section .scroll-reveal-section .item {
    position: relative;
  }

  .Main-section .scroll-reveal-section .item:after {
    content: "";
    display: block;
    height: 120vh;
    margin-bottom: -120vh;
  }

  .Main-section .scroll-reveal-section .item:last-of-type {
    margin-bottom: 0;
    background-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0.05),
        transparent 50px),
      linear-gradient(to top, rgba(0, 0, 0, 0.05), transparent 50px);
  }

  .Main-section .scroll-reveal-section .item:last-of-type:after {
    display: none;
  }

  .Main-section .scroll-reveal-section .item .image-holder {
    position: sticky;
    top: 5%;
  }
}

.Main-section .scroll-reveal-section .service_head_sec .service_title {
  margin-top: 30px !important;
}

.Main-section .scroll-reveal-section .service_head_sec {
  position: relative;
  z-index: 1;
  padding-top: 50px;
}

.Main-section .scroll-reveal-section .service_head_sec .title {
  background: linear-gradient(92.12deg, #e442ff 2.22%, #3c5bff 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.Main-section .scroll-reveal-section .exchg_bg.DapsMainBg {
  min-height: 810px;
}

/*Scroll Service Style End*/

/*Mobile Service Style Start*/

.Main-section .service_mbl_sec {
  padding: 50px 0px;
}

.Main-section .service_mbl_sec .service_title {
  margin-top: 30px !important;
}

.Main-section .service_mbl_sec .title {
  background: linear-gradient(92.12deg, #e442ff 2.22%, #3c5bff 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.Main-section .service_mbl_sec .service_tabcont .tab-pane {
  border: 1px solid #ffffff42;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
}

.Main-section .service_mbl_sec .service_tabcont .MtavCntHdd .h3_title {
  font-size: 25px;
}

.Main-section .service_mbl_sec .service_tabcont .MtavCnt {
  padding-left: 20px;
  margin-bottom: 20px;
}

.Main-section .service_mbl_sec .service_tabcont .MtavCnt:after {
  left: 0px;
  top: 10px;
}

.Main-section .service_mbl_sec .service_tabcont .MtaverseSec {
  padding: 0px;
}

.Main-section .service_mbl_sec .service_tabcont .MtavCntHdd {
  margin-top: 0 !important;
}

.Main-section .service_mbl_sec ul.nav-tabs {
  border-bottom: none;
  margin-top: 20px;
}

.Main-section .service_mbl_sec .nav-tabs a.nav-link {
  color: #fff !important;
}

.Main-section .service_mbl_sec .nav-tabs a.nav-link.active {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  border: none;
}



.Main-section .blockchain_process_sec {
  padding: 50px 0px;
  position: relative;
  overflow: unset;
}

.Main-section .blockchain_process_sec .title {
  font-weight: 600;
  font-size: 60px;
  line-height: 1.3;
  font-family: "Urbanist", sans-serif;
  text-transform: capitalize;
}

.Main-section .blockchain_process_sec .cusclr_title {
  color: #828282;
}

.Main-section .blockchain_process_sec .contents {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.9;
}

.Main-section .blockchain_process_sec .pros_head_Sec {
  position: sticky;
  top: 10%;
}

.Main-section .blockchain_process_sec .process_list {
  padding: 10px;
}

.Main-section .blockchain_process_sec .sub_title {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 10px;
}

.Main-section .blockchain_process_sec .sub_contents {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.7;
  padding-top: 10px;
}

.Main-section .blockchain_process_sec .sprite_icons {
  height: 50px;
  width: 50px;
  object-fit: none;
}

.Main-section .blockchain_process_sec .process_list .img_sec {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .Main-section .blockchain_process_sec .title {
    font-size: 25px;
    line-height: 1.5;
  }

  .Main-section .blockchain_process_sec .process_list {
    padding: 10px 0px;
  }

  .Main-section .blockchain_process_sec {
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 500px;
    margin: 0px 10px;
    background: #101010;
    border: 1px solid #ffffff17;
    border-radius: 5px;
  }

  .Main-section .blockchain_process_sec .pros_head_Sec {
    top: 0%;
  }

  .Main-section .blockchain_process_sec {
    scrollbar-width: thin;
    scrollbar-color: #00f0ff transparent;
    overflow-y: auto;
  }

  .Main-section .blockchain_process_sec::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    border-radius: 3px;
  }

  .Main-section .blockchain_process_sec::-webkit-scrollbar-track {
    border-radius: 3px;
    background: transparent;
  }

  .Main-section .blockchain_process_sec::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: linear-gradient(208deg, #e442ff 20.57%, #3c5bff 80.49%);
    height: 4px;
  }

  @-moz-document url-prefix() {
    .Main-section .blockchain_process_sec {
      scrollbar-width: thin;
      scrollbar-color: #00f0ff transparent;
      overflow-y: auto;
    }

    .Main-section .blockchain_process_sec::scrollbar {
      width: 5px;
      height: 4px;
      border-radius: 3px;
    }

    .Main-section .blockchain_process_sec::-webkit-scrollbar-track {
      border-radius: 3px;
      background: transparent;
    }

    .Main-section .blockchain_process_sec::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: linear-gradient(208deg, #e442ff 20.57%, #3c5bff 80.49%);
      height: 4px;
    }
  }

}

*/ .custom-drp.show .dropdown-menu {
  display: flex;
}

.navMnuMn li:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
  border-radius: 5px;
  box-shadow: 0 12px 28px 1px rgb(0 0 0 / 47%),
    0 2px 4px 0 rgb(8 8 8 / 37%);
}

.navMnuMn .nav-item {
  position: relative;
}

.navMnuMn .sub-menu {
  visibility: hidden;
  color: white;
  opacity: 0;
  margin: 0;
  padding: 10px 10px !important;
  position: absolute;
  /*left: -100px;*/
  background: #090909;
  backdrop-filter: blur(23px);
  z-index: 99;
  transform: translate(0, 20px);
  transition: all 0.2s ease-out;
  width: max-content;
  top: 60px;
  border: 1px solid #ffffff12;
  padding: 20px 30px !important;
  text-align: left;
}



.navMnuMn .sub-menu ul li {
  margin-top: 10px;
}

.navMnuMn .sub-menu li a {
  text-decoration: none !important;
  font-weight: 400;
  font-size: 14px;
}

.navMnuMn .sub-menu li a:before {
  display: none;
}

.navMnuMn .sub-menu li a:after {
  display: none;
}

.tbsMenu .nav-tabs {
  display: none;
}

.cmpy_Overflow {
  background: #090909;
}

.card-body {
  background: #090909;
}

/*Social Icons Animation Style Start*/

.HddrLnks li.hasNewMenu a::before,
.HddrLnks li.hasNewMenu a::after {
  display: none !important;
}

.HddrLnks .hasNewMenu {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  background: #090909;
  place-items: center;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  position: relative;
  border: 1px solid #fff3;
  z-index: 0;
  cursor: pointer;
  margin-top: 5px;
}

.HddrLnks .hasNewMenu:hover {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  border-color: transparent;
  border: none;
}

.HddrLnks .hasNewMenu::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  border-radius: 50%;
  will-change: box-shadow;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  z-index: -1;
  animation: 1s ease-out infinite ripple;
}

.HddrLnks .hasNewMenu .nav-link .contactIconTop {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 6px;
  top: 0px;
}

.mr-40 {
  margin-right: 40px;
}

@keyframes ripple {

  100%,
  25% {
    box-shadow: 0 0 0 0 rgb(165 115 201 / 30%),
      0 0 0 0.2em rgb(165 115 201 / 10%);
  }

  50% {
    box-shadow: 0 0 0 0.5em rgb(165 115 201 / 30%),
      0 0 0 1em rgb(165 115 201 / 30%);
  }

  75% {
    box-shadow: 0 0 0 2em rgb(165 115 201 / 30%),
      0 0 0 2.5em rgb(165 115 201 / 10%);
  }

  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(2);
  }
}

.HddrLnks .hasNewMenu .social_icon_sub_menu {
  position: absolute;
  border: 1px solid #ffffff17 !important;
  border-radius: 6px;
  top: 55px !important;
  min-width: 280px !important;
  width: 280px !important;
  transition: 0.1s ease-in-out !important;
  background: #1c1f21 !important;
  right: 0px !important;
  left: auto !important;
  padding: 0px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10px solid #8650ff;
  border-left: 10px solid transparent;
  top: -10px;
  right: 12px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact {
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: flex-start;
  border-radius: 6px 6px 0 0;
  background: #8650ff;
  padding: 5px 10px;
  border-bottom: 1px solid #efefef21;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu>p {
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #ffffff38;
  margin-bottom: 0px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li {
  margin-top: 0px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li a {
  padding-left: 5px;
  margin-right: 0px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li a {
  font-size: 14px;
  margin-bottom: 0px;
  padding-left: 10px;
  margin-right: 0px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li a:hover {
  background: unset;
  color: #fff;
  -webkit-text-fill-color: unset;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li {
  display: flex;
  margin-top: 0px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li.lastWhatsapp {
  display: flex;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li.lastWhatsapp a {
  line-height: 15px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li .middlePartMenu_img {
  width: 18px;
}

.HddrLnks .HddrBtns .menu_btn {
  padding: 10px 20px;
  /*width: 160px;*/
  font-size: 15px;
  min-height: 40px;
}

.HddrLnks .HddrBtns .menu_btn:focus {
  box-shadow: unset;
}

.HddrLnks .HddrBtns .menu_btn.button_christ .raindeer_img {
  width: 25px;
  padding-left: 3px;
}

.HddrLnks .HddrBtns .menu_btn.button_christ {
  position: relative;
  display: flex;
  align-items: center;
  background: #8650ff;
  padding: 10px 15px;
  line-height: 26px;
  text-decoration: none;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  border-radius: 15px;
  border: 1px solid #8650ff;
  box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
    inset 0 0 6px #be48ff, inset 0 80px 80px -40px #a54cff,
    1px 1px 3px rgba(0, 0, 0, 0.75);
}

.HddrLnks .HddrBtns .menu_btn.button_christ::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: -3px;
  right: 0;
  height: 23px;
  background: url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/snow1.webp) no-repeat 0 0,
    url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/snow2.webp) no-repeat 50% 0,
    url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/snow3.webp) no-repeat 100% 0;
}

.HddrLnks li a.cus_title {
  color: #00fff5;
  animation: zoom-in-zoom-out1 3s ease-out infinite;
}

.HddrLnks li a.cus_title:before,
.HddrLnks li a.cus_title:after {
  background: unset;
}

.HddrLnks li a.cus_title:hover {
  background: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
  text-fill-color: unset;
  color: #00fff5;
}

@keyframes zoom-in-zoom-out1 {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

/*Social Icons Animation Style Start*/

@media (min-width: 991px) {
  .tbsMenu .nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }

  .tbsMenu .nav-tabs {
    border-bottom: none;
    border-right: 1px solid #ddd;
    display: flex;
  }

  .tbsMenu .nav-tabs {
    margin: 0 15px;
  }

  .tbsMenu .nav-tabs .nav-item+.nav-item {
    margin-top: 0.25rem;
  }

  .tbsMenu .nav-tabs .nav-link {
    transition: border-color 0.125s ease-in;
    white-space: nowrap;
    color: white !important;
  }

  .tbsMenu .nav-tabs .nav-link:hover {
    background-color: #f7f7f7;
    border-color: transparent;
  }

  .tbsMenu .nav-tabs .nav-link.active {
    background: linear-gradient(93.79deg,
        #e442ff -13.43%,
        #3c5bff 100.49%);
    border-radius: 5px;
    border: none;
    color: #fff !important;
  }

  .tbsMenu .nav-tabs .nav-link.active:hover {
    background: linear-gradient(93.79deg,
        #e442ff -13.43%,
        #3c5bff 100.49%);
    color: #fff !important;
    -webkit-text-fill-color: unset;
  }

  .tbsMenu .card {
    border: none;
  }

  .tbsMenu .card .card-header {
    display: none;
  }

  .tbsMenu .card .collapse {
    display: block;
  }
}

@media (max-width: 991px) {
  .tbsMenu .tab-pane {
    display: block !important;
    opacity: 1;
  }

  .HddrLnks .HddrBtns .menu_btn {
    margin: 12px 0px;
  }
}

.cmpy_Overflow {
  height: 350px;
  overflow-x: hidden;
}

.tbsMenu ::-webkit-scrollbar {
  width: 3px;
}

/* Track */

.tbsMenu ::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 50px;
}

/* Handle */

.tbsMenu ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */

.tbsMenu ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
  border-radius: 50px;
}

.serSubMnu {
  left: -300px !important;
}

.serSubMnu .tab-content {
  width: 700px !important;
}

.serTabsOver {
  height: 450px;
  overflow-x: hidden;
}

.sub-menu ul li {
  position: relative;
}

.sub-menu ul li:after {
  content: "\25B6";
  position: absolute;
  top: 7px;
  left: -16px;
  color: #000000;
  font-size: 1px;
}

.cmpyAfter:after {
  display: none;
}

@media (min-width: 992px) {
  .menu_res {
    display: none;
  }
}

@media (max-width: 991px) {
  .menu_desk {
    display: none;
  }

  .menu_res .dropdown-menu {
    padding: 15px;
    background-color: #090909;
    border: 1px solid rgb(122 122 122 / 15%);
    color: #fff;
    height: 260px;
    overflow-y: scroll;
  }

  .menu_res .dropdown-toggle:after {
    right: -16px;
    top: 22px;
    background: #090909;
    width: 0px !important;
  }

  .menu_res .dropdown-toggle:before {
    display: none;
  }

  .compyMenuResHight {
    height: 400px;
    overflow-x: hidden;
  }

  .serSubMnu {
    position: initial !important;
    height: 350px;
    overflow-x: hidden;
    visibility: initial !important;
    opacity: 1 !important;
    width: initial !important;
  }

  .cmpy_Overflow {
    height: 200px !important;
  }

  .card {
    margin-top: 20px;
  }

  .navMnuMn .sub-menu {
    margin-bottom: 30px;
  }
}

#navbarNavAltMarkup .dropdown_sub_menu {
  margin-top: 10px;
}

#navbarNavAltMarkup .dropdown_sub_menu .drop_right_menu {
  width: max-content;
  margin-left: 2.1rem;
  padding: 20px 30px;
  background: #090909;
  backdrop-filter: blur(23px);
  border: 1px solid #ffffff12;
  border-radius: 5px;
  box-shadow: 0 12px 28px 1px rgb(0 0 0 / 47%),
    0 2px 4px 0 rgb(8 8 8 / 37%);
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  margin-right: 5px;
  position: relative;
  display: inline-block;
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link::before {
  transition: unset;
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link:hover::before,
#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link:hover::after {
  background: unset;
  transition: unset;
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link::after {
  right: auto;
  background: unset;
  margin-left: 10px;
}

@media (max-width: 991px) {
  .HddrBg .navbar #navbarNavAltMarkup .HdrSroll {
    height: 703px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

/*Side Conatct Form Style Start*/

.HddrBg .web_menu_btn_sec {
  position: relative;
  z-index: 999;
  cursor: pointer;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec {
  position: absolute;
  border: 1px solid #ffffff17 !important;
  border-radius: 6px;
  top: 55px !important;
  min-width: 300px !important;
  width: 300px !important;
  transition: 0.1s ease-in-out !important;
  background: #1c1f21 !important;
  right: 0% !important;
  left: auto !important;
  display: none;
  padding: 0px !important;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10px solid #8650ff;
  border-left: 10px solid transparent;
  top: -10px;
  right: 20px;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec .topNewContact {
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: flex-start;
  border-radius: 6px 6px 0 0;
  background: #8650ff;
  padding: 10px 10px;
  border-bottom: 1px solid #efefef21;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec .topNewContact .sub_title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.HddrBg .contact_form_mainsec .contact_form_sec {
  padding: 20px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .side_contact_submit_btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px;
  background: #8650ff !important;
  margin-top: 0px;
  border: none;
  padding: 8px 20px;
  padding-left: 20px !important;
  width: fit-content;
  color: #fff;
  text-align: center;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input {
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(2px);
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  height: 40px;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 200;
  width: 100%;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_country select {
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(2px);
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  height: 40px;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 200;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_country select option {
  color: #000 !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div {
  display: flex;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list {
  background: rgb(255 255 255 / 6%) !important;
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  height: 40px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 200;
  margin-right: 10px;
  width: 30%;
  align-items: center;
  display: grid;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .btn {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  color: #fff;
  padding: 5px;
  margin-top: 0px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .btn i {
  font-size: 16px;
  padding-left: 10px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg {
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(2px);
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  padding-left: 10px;
  margin-top: 0px;
  height: 90px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 200;
  padding-top: 15px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-ms-input-placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input::-ms-input-placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
  /* Microsoft Edge */
  color: #fff;
}

#popup_form_modal .fitoutform .form-group {
  margin-bottom: 1em !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select:-webkit-autofill,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form textarea:-webkit-autofill,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill:focus,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #292c2e inset !important;
  background-image: none;
  -webkit-text-fill-color: #fff !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .select2-selection__rendered {
  color: #929292;
  margin: 0px;
  padding: 6px;
  background: #fff;
  padding-left: 15px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_select {
  padding-left: 15px;
  font-size: 17px;
  color: #929292 !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: unset;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form label.error {
  border-radius: 5px;
  background: red;
  color: #fff !important;
  padding: 2px;
  position: absolute;
  top: -12px;
  right: 0;
  width: auto;
  font-size: 10px;
  font-weight: 400;
  z-index: 1;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form label.error:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: red transparent transparent transparent;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a {
  background: #8650ff;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
  text-decoration: none;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
  color: #000;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a::before,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a::after {
  background: unset !important;
  -webkit-text-fill-color: unset !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a:hover {
  -webkit-text-fill-color: unset !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a:hover {
  background: #8650ff !important;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
  text-decoration: none;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #side_contact_form_error_msg {
  font-size: 14px !important;
}

/*Side Conatct Form Style End*/

/*Metaverse Blink Button Style*/

.single_trending_btn>p>a:hover {
  -webkit-text-fill-color: unset !important;
  -webkit-background-clip: unset;
  background: #bd48ff;
}

.single_trending_btn>p>a:hover:before,
.single_trending_btn>p>a::before,
.single_trending_btn>p>a::after {
  background: unset;
}

.single_trending_btn {
  position: absolute;
  right: 5px;
  bottom: -34px;
}

.single_trending_btn>p {
  margin-bottom: 0;
}

.single_trending_btn>p>a {
  color: #fff;
  background: #bd48ff;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 25px;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-decoration: none;
  margin-right: 0px;
  line-height: unset;
}

.single_trending_btn>p>a img {
  filter: invert(1);
  width: 20px;
}

.single_trending_btn .text_blink_animate {
  display: block;
  animation: zoom-in-zoom-out1 3s ease-out infinite;
}

@keyframes zoom-in-zoom-out1 {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

/*Metaverse Blink Button Style*/

@media (max-width: 991px) {
  .HddrBg .web_menu_btn_sec .side_menu_btn.button_christ {
    position: relative;
    display: flex;
    align-items: center;
    background: #8650ff;
    padding: 10px 15px;
    line-height: 23px;
    text-decoration: none;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
    border-radius: 12px;
    border: 1px solid #8650ff;
    box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
      inset 0 0 6px #be48ff, inset 0 80px 80px -40px #a54cff,
      1px 1px 3px rgba(0, 0, 0, 0.75);
  }

  .HddrBg .web_menu_btn_sec .side_menu_btn.button_christ::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: -7px;
    left: -3px;
    right: 0;
    height: 23px;
    background: url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/snow1.webp) no-repeat 0 0,
      url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/snow3.webp) no-repeat 100% 0;
  }
}

@media (max-width: 767px) {
  .HddrBg .navbar .logo_header .mbl_res_logo {
    width: 210px;
  }

  .HddrBg .web_menu_btn_sec .side_menu_btn {
    padding: 8px 10px !important;
    font-size: 13px !important;
  }

  .HddrBg .web_menu_btn_sec .contact_form_mainsec::after {
    right: 50px !important;
  }

  .HddrBg .web_menu_btn_sec .contact_form_mainsec {
    right: -30% !important;
  }

  .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .side_contact_submit_btn {
    font-size: 14px !important;
  }
}

@media (max-width: 467px) {
  .HddrBg .web_menu_btn_sec .side_menu_btn {
    font-size: 12px !important;
    padding: 6px 8px !important;
    font-weight: 400 !important;
  }

  .HddrBg .logo_header {
    width: 55% !important;
  }

  .HddrBg .navbar {
    padding: 0.25rem 0.5rem;
  }
}

/*On Hover Hide Metaverse button*/

.HddrLnks .hasNewMenu:hover~.single_trending_sec {
  opacity: 0 !important;
}

.HddrBg .web_menu_btn_sec .side_menu_btn {
  padding: 8px 20px;
  font-size: 16px;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  color: #fff;
  font-weight: 500;
  border: none;
}

.HddrBg .web_menu_btn_sec .side_menu_btn:focus {
  box-shadow: unset;
}

/*Social Media Icons Style*/

.menu_social_icon_main {
  padding: 20px 0px;
}

.menu_social_icon_main .contents {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  font-style: italic;
}

.menu_social_icon_main .menu_social_listsec {
  width: fit-content;
}

.menu_social_icon_main .menu_social_listsec .menu_social_list {
  list-style: none;
  display: inline-block;
  position: relative;
  margin: 0 3px;
  transition: 0.3s all ease-in-out;
}

.menu_social_icon_main .menu_social_listsec .menu_social_list img {
  width: 35px;
}

.menu_social_icon_main .menu_social_listsec:hover>li {
  opacity: 0.4;
  transition: 0.3s all ease-in-out;
}

.menu_social_icon_main .menu_social_listsec>li:hover {
  opacity: 1;
  transition: 0.3s all ease-in-out;
  transform: scale(1.3);
}

/*Social Media Icons Style*/




.popup-form .modal-dialog {
  max-width: 1000px !important;
  margin-top: 100px;
  width: auto;
}

.popup-form .modal-dialog .modal-body {
  padding: 0px;
}

.popup-form .top_cls {
  color: #000000 !important;
  opacity: 1;
  font-size: 35px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  position: relative;
  right: 0px;
  top: -3px;
  z-index: 1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.OurPrjctBg .PrjctExmplsImg img {
  border-radius: 5px;
}

@media (max-width: 991px) {
  .popup-form {
    display: none;
  }
}

#portfolio_modal .model_full_width .HddrBtns {
  margin-top: 12px;
}

#portfolio_modal .model_full_width .img_sec {
  padding: 0px 20px 15px 20px;
  text-align: center;
}

#portfolio_modal .portfolio_btn {
  margin: 0 auto;
}

@media (max-width: 767px) {
  #portfolio_modal .model_full_width .BtnPrimry {
    font-size: 14px !important;
    min-height: 40px !important;
    line-height: 40px !important;
  }

  #portfolio_modal .model_full_width .TlkBtn {
    width: 155px !important;
  }

  #portfolio_modal .model_full_width .img_sec {
    padding: 0px 15px 10px 15px !important;
  }
}



































body {
  font-family: "Readex Pro", sans-serif;

}

section {
  overflow: hidden;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.BtnPrimry {
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 113.4%;
  color: #FFFFFF;
  min-width: 124px;
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Urbanist", sans-serif;
  z-index: 1;
  min-height: 44px;
  border: none;
  line-height: 42px;
  perspective: 230px;
}

.BtnPrimry.BtnTrnsp {
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
}

.BtnPrimry.Btn175-44 {
  min-width: 175px;
}

.BtnPrimry span {
  background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 0;
  text-transform: capitalize;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.3s;
}

.BtnPrimry span:nth-child(1) {
  transform: rotateX(90deg);
  transform-origin: 50% 50% -20px;
}

.BtnPrimry span:nth-child(2) {
  transform: rotateX(0deg);
  transform-origin: 50% 50% -20px;
}

.BtnPrimry:hover span:nth-child(1) {
  color: #FFFFFF;
  transform: rotateX(0deg);
}

.BtnPrimry:hover span:nth-child(2) {
  color: transparent;
  transform: rotateX(-90deg);
}

.TlkBtn {
  width: 192px;
}

.TlkBtn img {
  width: 20px;
  height: 20px;
}

.BtnTrnsp span {
  background: transparent;
}

.BtnTrnsp span:nth-child(1) {
  transform: rotateX(90deg);
  transform-origin: 100% 100% -25px;
}

@media (min-width: 1200px) {
  .container-1170 {
    max-width: 1170px;
  }
}

.pgLoad {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
}

.HddrBg {
  background: #090909;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999999999;
}

.HddrBg .navbar {
  padding: 0.25rem 1rem;
}

.HddrBg .navbar .socialIcns .icons_li {
  width: 35px;
  height: 35px;
  display: grid !important;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  text-decoration: none;
}

.HddrBg .navbar .socialIcns .icons_li:hover {
  -webkit-text-fill-color: unset;
}

.HddrBg .navbar .socialIcns .icons_li::before,
.HddrBg .navbar .socialIcns .icons_li::after {
  background: unset !important;
}

.HddrBg .navbar .socialIcns .icons_li i {
  font-size: 20px !important;
  font-weight: 500;
  color: #fff;
  margin-left: 0;
}

.HddrBg .navbar .socialIcns .icons_li.whatsapp {
  background: #34af23 !important;
}

.HddrBg .navbar .socialIcns .icons_li.telegram {
  background: #08c !important;
}

.HddrBg .navbar .socialIcns .icons_li.skype {
  background: #4ea9f4;
}

.MnuPaddTop {
  padding-top: 62px;
}

.line {
  fill: none;
  stroke: #fff;
  stroke-width: 3px;
  transition: 500ms ease-out;
}

.line_1 {
  stroke-dasharray: 21 100;
  stroke-dashoffset: 82;
  transition: 500ms ease-out;
}

.ui .active .line_1 {
  stroke-dashoffset: 26;
}

.line_2 {
  stroke-dasharray: 21 100;
  stroke-dashoffset: 82;
  transition: 800ms ease-out;
}

.ui .active .line_2 {
  stroke-dashoffset: 25;
}

.line_3 {
  stroke-dasharray: 21 100;
  stroke-dashoffset: 82;
  transition: 1100ms ease-out;
}

.ui .active .line_3 {
  stroke-dashoffset: 26;
}

.ui {
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  display: none;
}

@media (max-width: 991px) {
  .ui {
    display: block;
  }
}

.HddrLnks li a {
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #fff;
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.HddrLnks li a:hover {
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: 0.6s;
}

@media (min-width: 992px) and (max-width: 1300px) {
  .HddrLnks li a {
    margin-right: 5px;
    font-size: 13px;
  }
}

/* .website-list span.nav-link::after{
  content: "" !important;
  position: absolute !important;
  width: 0% !important;
  height: 1px !important;
  bottom: 10px !important;
  margin-top: -0.5px !important;
  background: #3C5BFF !important;
}

.website-list span.nav-link:hover::before {
  background: #3C5BFF !important;
  width: 100% !important;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) !important;
} */
.HddrLnks li a::before,
.HddrLnks li a::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 10px;
  margin-top: -0.5px;
  background: #3C5BFF;
}



.HddrLnks li a::before {
  left: 0;
}

.HddrLnks li a::after {
  right: 0;
  background: #3C5BFF;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.HddrLnks li a:hover::before {
  background: #3C5BFF;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.HddrLnks li a:hover::after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

.BnrCnt h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .BnrCnt h1 {
    font-size: 42px;
  }
}

@media (max-width: 768px) {
  .BnrCnt h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .MnuPaddTop #homebanner,
  .MnuPaddTop .BnnerCntSec,
  .MnuPaddTop .mnyExpoSc,
  .MnuPaddTop .metaSlnSc,
  .MnuPaddTop .MtaverseSec h2,
  .MnuPaddTop .MtaverseSec h4 {
    text-align: center !important;
  }

  .MnuPaddTop .MtaverseSec {
    padding: 20px 0px;
    background-position: 65%;
    position: relative;
    z-index: 1;
  }

  .MnuPaddTop .MtaverseSec .metaverse_img {
    display: block;
  }

  .MnuPaddTop .MtaverseSec:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #06060600;
  }

  .MnuPaddTop #homebanner .BnrCnt p {
    text-align: center;
  }

  .MnuPaddTop #homebanner .BtnPrimry {
    display: inline-table;
  }

  .mnyExpoSc .mnySub:after {
    display: none;
  }

  .MnuPaddTop .BnrCnt {
    margin-top: 50px;
  }

  .MnuPaddTop .BnnerCntSec .BtnPrimry.Btn175-44,
  .MnuPaddTop .BnnerCntSec .TlkBtn {
    min-width: 140px !important;
    width: 140px !important;
  }

  .MnuPaddTop .TlkBtn {
    width: 165px !important;
  }

  .MnuPaddTop .BnnerCntSec .GetBtnSec {
    text-align: center;
    justify-content: center;
  }

  .MnuPaddTop .BtnPrimry {
    font-size: 14px;
  }

  .MnuPaddTop .metaSlnSc .metaSlnScRelat ul {
    text-align: center;
    justify-content: center;
  }

  .MnuPaddTop .MtaverseSec.DapsMainBg.TechStk .OurSrvcHdd,
  .MnuPaddTop .MtaverseSec.DapsMainBg.TechStk .OurservHdd h2,
  .MnuPaddTop .MtaverseSec .OurSrvcHdd h6,
  .MnuPaddTop .MtaverseSec.DapsMainBg.OurPrjctBg .BnrCnt p {
    text-align: center !important;
  }

  .MnuPaddTop .MtaverseSec.DapsMainBg.TechStk .nav,
  .MnuPaddTop .MtaverseSec.DapsMainBg.OurPrjctBg .nav {
    display: -webkit-inline-box !important;
  }

  .MnuPaddTop .MtavCntHdd h4 {
    font-size: 25px !important;
  }
}

.MnuPaddTop .MtaverseSec.DapsMainBg.TechStk .OurSrvcHdd {
  text-align: right;
}

.BnrCnt h2 {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  font-family: "Urbanist", sans-serif;
  text-transform: uppercase;
}

.BnrCnt h2 .Fw-200 {
  font-weight: 200;
}

@media (max-width: 1300px) {
  .BnrCnt h2 {
    font-size: 42px;
    line-height: 52px;
  }
}

@media (max-width: 991px) {
  .BnrCnt h2 {
    font-size: 40px;
    line-height: 46px;
  }
}

@media (max-width: 768px) {
  .BnrCnt h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .BnrCnt h2.text {
    height: 40px;
  }
}

.BnrCnt p {
  font-size: 16px;
  line-height: 1.9;
}

span.TxtGrdnt {
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-size: 200% auto;
  position: relative;
}

span.TxtGrdnt::after {
  content: attr(data-shine) " ";
  position: absolute;
  top: 0;
  left: 1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200%;
  animation: shine 10s infinite;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.BnrCountSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.BnrCount h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 0;
  font-family: "Urbanist", sans-serif;
  min-width: 110px;
}

@media (max-width: 767px) {
  .BnrCount h4 {
    font-size: 28px;
    line-height: 34px;
  }
}

.BnrCount p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #BABABA;
}

.BnrTxtFlwr {
  position: relative;
}

.BnrTxtFlwr::after {
  content: "";
  position: absolute;
  top: -40px;
  right: 20px;
  /* background: url(../images/bnr-flwr.svg) no-repeat right; */
  width: 50px;
  height: 50px;
}

.BnnerCntSec {
  padding: 38px 0 25px;
}

.VideoWrper {
  position: relative;
}

.VideoWrper.VidoFltr::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #DF48FF 0%, #2D4FFF 100%);
  mix-blend-mode: color;
  position: absolute;
  top: 0;
  left: 0;
}

.VideoWrper video {
  width: 100%;
  height: calc(100vh - 91px);
  -o-object-fit: cover;
  object-fit: cover;
}

/*@media (max-width: 991px) {
      .VideoWrper video {
        height: 390px;
      }
    }*/

@media(max-width: 767px) {
  .desktop {
    display: none !important;
  }

  .VidoCntMn {
    margin-top: 50px !important
  }

  .vdoCntLstCount {
    font-size: 30px !important;
  }
}

.VidoCnt {
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 2;
  width: 100%;
  padding: 0 30px 0 0;
}

@media (max-width: 767px) {
  .VidoCnt {
    left: 10px;
  }
}

.GrdentBg {
  background: linear-gradient(129deg, #060606 0%, rgba(103, 58, 183, 0) 89%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.GetBtnSec {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.OurSrvcHdd h6 {
  background: linear-gradient(92.12deg, #E442FF 2.22%, #3C5BFF 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.OurservHdd .Txt8282 {
  color: #828282;
}

.MtavCnt {
  margin-bottom: 20px;
  position: relative;
  font-size: 16px;
  line-height: 30px;
}

.MtavCnt:after {
  content: "";
  position: absolute;
  top: 7px;
  left: -25px;
  /* background: url('../images/content-star-icon.svg'); */
  width: 10px;
  height: 10px;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .MtavCnt {
    margin-bottom: 0;
  }
}

.MtavCnt li {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 20px;
}

.MtavCnt:hover li span img {
  animation: pulsate-fwd 0.5s ease-in-out infinite alternate-reverse both;
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.MtavCntHdd h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.6;
  font-family: "Urbanist", sans-serif;
  text-transform: capitalize;
}

.MtavCntHdd .h3_title {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.6;
  font-family: "Urbanist", sans-serif;
  text-transform: capitalize;
}

.OurBlgBg .BlgHdd .cat_title {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.6;
  font-family: "Urbanist", sans-serif;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.Main-section .h5_title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.MtaverseSec {
  background: url(../../../public/images/metaverse-bg-new.webp) no-repeat top;
  background-size: 100% 100%;
  position: relative;
  min-height: 800px;
  padding: 40px 0;
}

.Main-section .OurPrjctBg .port_sub_title {
  font-size: 30px;
  font-weight: 500;
}

@media (max-width: 991px) {
  .MtaverseSec {
    padding: 0;
  }
}

.MtaverseSec:hover .MtrvrRtImg img {
  animation: blink-2 0.8s linear infinite both;
}

html {
  scroll-behavior: smooth;
}

.MxBlndLight {
  mix-blend-mode: lighten;
}

.MtrvrRtImg {
  position: absolute;
  right: -5%;
  top: 11.5rem;
}

.MtrvrRtImg_cus_img {
  position: absolute;
  right: -12%;
  top: 0%;
}

.CmmnSdHdd .text-line {
  stroke-dasharray: 290;
  stroke-dashoffset: 290;
  animation: dash 4s linear infinite both alternate-reverse;
  color: transparent;
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.3em;
  opacity: 0.5;
  font-family: "Urbanist", sans-serif;
  fill: transparent;
  stroke: #E442FF;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media(min-width: 991px) {
  .Main-section .blockchain_service_sec {
    /* background: url(../images/blockchain-bg.webp) no-repeat bottom right; */
    min-height: 700px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -5%;
  }

  .Main-section .blockchain_service_sec:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #06060682;
  }

  .Main-section .blockchain_service_sec .blockchain_imgsec {
    position: absolute;
    top: 18%;
    left: 0%;
  }
}

.NftMainSec {
  /* background: url(../images/nft-main-bg-new.webp?v1) no-repeat bottom right; */
  min-height: 800px;
  position: relative;
}

@media(min-width: 991px) {
  .NftMainSec:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #0506077d;
  }
}

.NftRtImg {
  left: 0%;
  top: 0;
}

.dapp_bg .NftRtImg {
  left: -5%;
  top: 0;
}

.DefiMainBg {
  /* background: url(../images/defi-main-bg-new.webp) no-repeat bottom right; */
  min-height: 750px;
  position: relative;
}

@media(min-width: 991px) {
  .DefiMainBg:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #050607c9;
  }
}

.DefiMainBg .CmmnSdHdd .text-line {
  letter-spacing: normal;
}

.DefiMainBg .MtrvrRtImg {
  top: 0;
}

.LtrSpcNorml.CmmnSdHdd .text-line {
  letter-spacing: normal;
}

@media(min-width: 992px) {
  .DapsMainBg {
    background: transparent;
    min-height: 570px;
  }

  .dapp_bg {
    /* background-image: url('../images/dapp-bg.webp'); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    position: relative;
  }

  .dapp_bg:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: black !important;
  }

  .crypto_bg {
    /* background-image: url('../images/crypto-bg-new.webp') !important; */
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: top left !important;
    position: relative;
  }

  .crypto_bg:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: black !important;
  }

  .exchg_bg {
    /* background-image: url('../images/exchange-bg-new.webp'); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
  }

  .exchg_bg:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #060606b3;
  }

  .ExchngeSec .CmmnSdCnt {
    margin-left: 20%;
  }
}

@media(min-width: 991px) {
  .CryptoMainBg {
    background: transparent;
    min-height: 721px;
  }
}

.CryptoMainBg .CmmnSdHdd .text-line {
  letter-spacing: 0.2rem;
}

.CryptoMainBg .MtrvrRtImg {
  position: unset;
}

.TechStkIconBox {
  background: #101010;
  display: inline-block;
  min-height: 106px;
  min-width: 106px;
  padding: 18px 0 10px;
}

.TechStkIconBox .TechStkIcnCnt p {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0;
}

.TechStkIconSec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.TechStkIcon img {
  width: 48px;
  height: 48px;
  -o-object-fit: contain;
  object-fit: contain;
}

.TchStkCnt p {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
}

.TchStkCnt h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  font-family: "Urbanist", sans-serif;
}

.VrtclTbb.nav-pills .nav-link {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #828282;
  text-align: end;
  padding-right: 0;
  position: relative;
  transition: 0.9s all ease-in-out;
}

.VrtclTbb.nav-pills .nav-link:hover {
  padding-right: 25px;
  transition: 0.5s all ease-in-out;
  background: transparent;
  background: linear-gradient(92.08deg, #E442FF 2.18%, #3C5BFF 113.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.VrtclTbb.nav-pills .nav-link::after {
  content: "";
  width: 0;
  background: #FFFFFF;
  top: 50%;
  right: 0;
  position: absolute;
  height: 1px;
  transform: translateY(-50%);
  transition: 0.5s all ease-in-out;
}

.VrtclTbb.nav-pills .nav-link.active {
  background: transparent;
  background: linear-gradient(92.08deg, #E442FF 2.18%, #3C5BFF 113.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-right: 25px;
}

.VrtclTbb.nav-pills .nav-link.active::after {
  content: "";
  width: 12px;
  background: #FFFFFF;
  top: 50%;
  right: 0;
  position: absolute;
  height: 1px;
  transform: translateY(-50%);
  transition: 0.5s all ease-in-out;
}

.VrtclTbb.nav-pills .nav-link:hover::after {
  content: "";
  width: 12px;
  background: #FFFFFF;
  top: 50%;
  right: 0;
  position: absolute;
  height: 1px;
  transform: translateY(-50%);
  transition: 0.5s all ease-in-out;
}

.TechStkIconBox {
  position: relative;
  overflow: hidden;
}

.TechStkIconBox::after {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transform: skew(30deg);
}

.TechStkIconSec .col:nth-child(odd) .TechStkIconBox::after {
  display: block;
  content: "";
  animation: shine1 8s linear infinite;
}

.TechStkIconSec .col:nth-child(even) .TechStkIconBox::after {
  display: block;
  content: "";
  animation: shine1 8s linear infinite;
  animation-delay: 4s;
}

@keyframes shine1 {
  0% {
    left: -50%;
    opacity: 0;
  }

  50% {
    left: 25%;
    opacity: 0.5;
  }

  100% {
    left: 100%;
    opacity: 0;
  }
}

.OurClintSldr .slide-content {
  margin-bottom: 15px;
}

.ClintSldr2 {
  margin-top: -60px;
}

.SldrBoxSadw {
  position: relative;
  padding-top: 61px;
}

.SldrBoxSadw::after {
  content: "";
  background: linear-gradient(179.95deg, #060606 -5.25%, rgba(6, 6, 6, 0.3) 17.46%, rgba(6, 6, 6, 0) 39.77%, rgba(6, 6, 6, 0.46) 77.57%, #060606 99.96%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ViwallLnk {
  display: inline-flex;
  justify-content: end;
  flex-flow: column;
  position: relative;
  overflow: hidden;
  position: relative;
  top: -20px;
}

.VwAllLnkHov {
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
}

.VwAllLnkHov1 {
  transform: translateX(-85px) translateY(29px);
  display: inline-block;
  transition: 0.8s cubic-bezier(0.3, 0, 0, 1.3);
}

.VwAllLnkHov2 {
  transform: translateX(0%) translateY(0px);
  transition: 0.8s cubic-bezier(0.3, 0, 0, 1.3);
  display: inline-block;
}

.ViwallLnk:hover .VwAllLnkHov1 {
  transform: translateX(0px) translateY(29px);
  transition: 0.8s cubic-bezier(0.3, 0, 0, 1.3);
  display: inline-block;
}

.ViwallLnk:hover .VwAllLnkHov2 {
  transform: translateX(100%) translateY(0px);
  transition: 0.8s cubic-bezier(0.3, 0, 0, 1.3);
  display: inline-block;
}

.ViwallLnk:after {
  content: "";
  width: 0;
  background: linear-gradient(92.12deg, #E442FF 2.22%, #3C5BFF 51.79%);
  height: 1px;
  bottom: 0;
  left: 0;
  transition: 0.8s cubic-bezier(0.3, 0, 0, 1.3);
}

.ViwallLnk:hover:after {
  content: "";
  width: 100%;
  transition: 0.8s cubic-bezier(0.3, 0, 0, 1.3);
}

.ColWdth {
  flex: 0 0 15%;
  max-width: 15%;
  transition: 2s cubic-bezier(0.3, 0, 0, 1.3);
  margin: 0 10px;
}

.ColWdth .BlgCntImg {
  border-radius: 6px;
}

.ColWdth .BlgCntImg img {
  width: 100%;
  height: 435px;
  -o-object-fit: cover;
  object-fit: cover;
}

.ColWdth.active {
  flex: 0 0 31%;
  max-width: 31%;
  transition: 1s all ease-in-out;
}

.ColWdth.active .BlgCntImg img {
  width: 100%;
  border-radius: 6px;
  height: 435px;
  -o-object-fit: cover;
  object-fit: cover;
}

.ColWdth.active .BlgCntSec {
  display: inline-block;
  transform: translateX(0%);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 2s;
}

.ColWdth.active .BlgHdd {
  display: none;
}

@media (max-width: 1200px) {
  .ColWdth {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.BlgCntSec h4,
.BlgHdd h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.BlgHdd {
  position: absolute;
  bottom: 40px;
  left: 20px;
}

.BlgCntSec {
  display: none;
  transform: translateX(-100%);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.BlgCntSec p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
}

.BlgCntBg {
  position: relative;
  overflow: hidden;
}

.BlgCntSec {
  position: absolute;
  bottom: 0;
  padding: 0 20px 20px;
  min-width: 365px;
  z-index: 1;
}

.NftSvgTxt .text-line {
  font-size: 41px;
}

@media (max-width: 767px) {

  .DefiMainBg,
  .MtaverseSec,
  .footer_details_form_new .footer_form_main {
    background: none !important;
  }

  .CmmnSdHdd .text-line {
    font-size: 25px;
  }
}

@media (max-width: 580px) {
  .CmmnSdHdd .text-line {
    font-size: 18px;
    height: 30px;
  }
}

.ColWdth.active .BlgCntBg::after {
  content: "";
  background: rgb(0 0 0 / 76%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.BlgCntBg:after {
  content: "";
  background: rgb(0 0 0 / 66%);
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 0;
}

.BlgHdd h4 {
  z-index: 1;
  position: relative;
}

.PrjctDtlsItm {
  background: linear-gradient(167.3deg, #EC1146 9.19%, #6E3CFF 141.84%);
  border-radius: 50px;
  padding: 20px 45px 15px;
  margin-bottom: 15px;
}

.RltdPrjHddSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PrjctDtlCnt h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  font-family: "Urbanist", sans-serif;
}

.PrjctDtlCnt p {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
}

.PrjctBtn {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  transition: 0.3s all ease-in-out;
}

.game_PrjctBtn .PrjctBtn_new {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  transition: 0.3s all ease-in-out;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PrjctBtn:hover {
  background: #FFFFFF;
  border: 1px solid #FFF;
  transition: 0.3s all ease-in-out;
  color: #101010;
}

.Btn211-42 {
  min-width: 211px;
  min-height: 42px;
}

.PrjctsBtnSec {
  display: flex;
  flex-wrap: wrap;
}

.OurPrjcts .slick-prev:before {
  content: "";
  /* background: url(../images/our-prjct-arrw2.svg) no-repeat 16px; */
  width: 48px;
  height: 48px;
  display: flex;
  border: 1px solid #fff;
  border-radius: 50px;
}

.OurPrjcts .slick-prev {
  left: auto;
  top: -55px;
  right: 6rem;
}

.OurPrjcts .slick-next:before {
  content: "";
  /* background: url(../images/our-prjct-arrw1.svg) no-repeat 18px; */
  width: 48px;
  height: 48px;
  display: flex;
  border: 1px solid #fff;
  border-radius: 50px;
}

.OurPrjcts .slick-next {
  top: -55px;
  right: 2rem;
}

.OurPrjcts .slick-slide img {
  display: inline-block;
}

.PrjctExmpls {
  padding: 0 15px 0 15px;
}

.PrjctExmpls .slick-prev {
  left: -15px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 999;
}

.PrjctExmpls .slick-prev:before {
  content: "";
  /* background: url(../images/our-prjct-arrw2.svg) no-repeat 16px; */
  background-size: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  border: unset;
  border-radius: 50px;
}

.PrjctExmpls .slick-next {
  top: 40%;
  transform: translateY(-50%);
  right: 6px;
}

.PrjctExmpls .slick-next:before {
  content: "";
  /* background: url(../images/our-prjct-arrw1.svg) no-repeat 16px; */
  background-size: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  border: unset;
  border-radius: 50px;
}

.VlublClintsBg {
  min-height: 420px;
  padding-bottom: 55px;
}

.TechStk {
  padding-bottom: 85px;
}

.PrjctExmplsImg {
  margin: 0 7px;
  text-align: center;
}

.OurBlgBg {
  padding-bottom: 90px;
}

.OurPrjctBg {
  padding-bottom: 100px;
}

@media (max-width: 1200px) {
  .ColWdth .BlgCntImg img {
    height: 175px;
    margin-bottom: 30px;
  }

  .ColWdth.active {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .FtrPhnIcon {
    margin-bottom: 30px;
  }

  .BlgCntSec {
    min-width: 100%;
  }

  .ColWdth {
    margin: 0;
    padding: 0 15px;
  }

  .PrjctDtlsItm {
    padding: 20px 20px;
  }
}

.OurPrjctHddSec {
  padding-bottom: 55px;
}

@media (max-width: 991px) {
  .OurPrjctHddSec {
    padding-bottom: 0px;
  }
}

.FtrTopSec {
  background: #181818;
  padding: 35px 0 20px;
}

.LogoSpprtSec {
  display: flex;
}

.LogoSpprtSec li {
  margin-right: 30px;
}

.FtrTopCntHdd h4 {
  background: linear-gradient(90.77deg, #E442FF 0.83%, #3C5BFF 132.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.FtrTopCnt li {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}

.FtrPhnIcon a {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
}

.FttrLnkHdd {
  margin-bottom: 20px;
}

.FttrLnkHdd .ft_menutitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.FtrLnkCnt li {
  margin-bottom: 10px;
}

.FtrLnkCnt li a {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  position: relative;
  display: inline-block;
}

.FtrLnkCnt li a:hover {
  -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  -webkit-mask-size: 200%;
  animation: shine2 3s infinite both;
}

.FtrMltplLnks .FtrLnkSec .connectus_sec a {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

.FtrMltplLnks .FtrLnkSec .connectus_sec a i {
  color: #fff;
  font-size: 16px;
  line-height: inherit;
}

.FtrMltplLnks .FtrLnkSec .connectus_sec a p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
  margin-left: 15px;
  word-break: break-all;
}

.FtrMltplLnks .FtrLnkSec .FttrLnkHdd .title_hrline {
  border: 1px solid #ae3cd4;
  width: 30%;
  margin: 10px 0;
}

@keyframes shine2 {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

.FtrLnkCnt p {
  font-weight: 300;
  font-size: 13px;
  line-height: 23px;
  color: #FFFFFF;
}

.SclIconSec {
  display: inline-flex;
}

.SclIconSec li {
  margin-right: 8px;
}

/*.FtrBrdBtm {
      border-bottom: 1px solid rgba(255, 255, 255, 0.27);
    }*/

.CpyRtSec {
  padding: 20px 0;
}

.CpyRtSec p {
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
}

.FtrMltplLnks {
  padding: 40px 0 1px;
}

.ExchngeSec {
  padding-bottom: 50px;
}

.SclIconSec:hover>li {
  opacity: 0.4;
  transition: 0.3s all ease-in-out;
}

.SclIconSec>li:hover {
  opacity: 1;
  transition: 0.3s all ease-in-out;
  transform: scale(1.3);
}

.SclIconSec li {
  transition: 0.3s all ease-in-out;
}

.FtrTopCnt {
  min-height: 75px;
  margin-bottom: 10px;
}

.MailCnt {
  display: flex;
  flex-wrap: wrap;
}

.MailCnt li {
  margin-right: 25px;
}

.MailCnt li a {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
  position: relative;
  display: inline-block;
}

.MailCnt li a:hover {
  background: linear-gradient(90.77deg, #E442FF 0.83%, #3C5BFF 132.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: 0.6s;
}

.MailCnt li a::before,
.MailCnt li a::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 1px;
  margin-top: -0.5px;
  background: #3C5BFF;
}

.MailCnt li a::before {
  left: 0;
}

.MailCnt li a::after {
  right: 0;
  background: #3C5BFF;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.MailCnt li a:hover::before {
  background: #3C5BFF;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.MailCnt li a:hover::after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

.FttrMailBox {
  background: #0C0C0C;
  padding: 10px 22px 10px;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
}

.MailIcon {
  padding: 0 12px 0 0;
  margin-right: 15px;
}

.MailBrdRt {
  border-right: 1px solid rgba(255, 255, 255, 0.37);
}

.FtrFrm #footer_subscripe .form-group {
  position: relative;
}

.FtrFrm .form-control {
  background: #262626;
  border-radius: 2px;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
  min-height: 44px;
  border: unset;
  box-shadow: unset;
  min-width: 271px;
}

.FtrFrm #footer_subscripe label.error {
  border-radius: 5px;
  background: red;
  color: #fff !important;
  padding: 4px;
  position: absolute;
  top: -12px;
  right: 0;
  width: auto;
  font-size: 13px;
  font-weight: 400;
  z-index: 1;
}

.FtrFrm #footer_subscripe label.error:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: red transparent transparent transparent;
}

@media (max-width: 1119px) {
  .FtrFrm .form-control {
    min-width: 200px;
  }
}

.SendBtn {
  background: #1B1B1B;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: unset;
  min-height: 44px;
  min-width: 81px;
  transition: 0.5s all ease-in-out;
}

.SendBtn:hover {
  background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
  transition: 0.5s all ease-in-out;
}

@media (max-width: 768px) {
  .MtavCnt li {
    line-height: 20px;
  }

  .MtaverseSec {
    background-size: cover;
  }

  .PrjctsBtnSec {
    justify-content: center !important;
  }

  .PrjctDtlsItm {
    text-align: center !important;
  }
}

@media (max-width: 1199px) {
  .MtrvrRtImg {
    display: none;
  }

  .DefiMainBg,
  .DapsMainBg,
  .CryptoMainBg,
  .MtaverseSec {
    min-height: auto;
  }

  .ExchngeSec,
  .OurBlgB,
  .OurPrjctBg,
  .TechStk,
  .OurBlgBg {
    padding-bottom: 20px;
  }

  .MtavCnt {
    margin-bottom: 30px;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .MtrvrRtImg img {
    transform: scale(0.7);
    margin-left: 24rem !important;
  }

  .NftRtImg {
    right: auto;
    margin-left: -31rem;
  }

  .CryptoMainBg .MtrvrRtImg img {
    margin-left: 0;
  }
}

#BnrHdAnim {
  position: absolute;
  margin: auto;
  width: 100vw;
  height: 115pt;
  top: 0;
  bottom: 0;
  filter: url(#threshold) blur(0.5px);
}

@media (max-width: 767px) {
  #BnrHdAnim {
    height: auto;
    top: 7rem;
  }
}

#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  font-family: "Urbanist", sans-serif;
}

@media (max-width: 767px) {

  #text1,
  #text2 {
    font-size: 32px;
    line-height: 36px;
  }
}

.PrjctTb.nav-pills .nav-link {
  text-align: start;
  padding-right: 1rem;
  padding-left: 0px;
  outline: none;
}

.PrjctTb.nav-pills .nav-link:hover {
  padding-right: 1rem;
  padding-left: 20px;
}

.PrjctTb.nav-pills .nav-link::after {
  right: auto;
  left: 0;
}

.PrjctTb.nav-pills .nav-link.active,
.PrjctTb.nav-pills .nav-link:hover {
  padding-left: 20px;
}

.PrjctTb.nav-pills .nav-link.active::after,
.PrjctTb.nav-pills .nav-link:hover::after {
  right: auto;
  left: 0;
}

/*# sourceMappingURL=style.css.map */

.BnrCnt {
  margin-top: 50px;
}

.text {
  height: 65px;
}

.typed-cursor,
.typed-cursor--blink {
  display: none;
}

.menu_foot_sec ul {
  padding-left: 0px;
  display: flex;
}

.menu_foot_sec ul li a {
  color: #fff;
}

.menu_foot_sec ul li:hover a {
  background: linear-gradient(92.12deg, #E548FF 2.22%, #2E4FFF 51.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: 0.6s;
}

@media(min-width: 992px) {
  .bnrVideoTop {
    margin-top: 100px;
  }
}

.mnyExpoSc {
  padding: 60px 0px;
}

.mnyExpoSc .mnySubTlt {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  leading-trim: both !important;
  text-edge: cap !important;
  color: #FFFFFF !important;
}

.mnyExpoSc .mnySub {
  position: relative;
  z-index: 1;
}

.mnyExpoSc .mnySub:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0px;
  background: linear-gradient(92.12deg, #E442FF 2.22%, #3C5BFF 51.79%);
  width: 170px;
  height: 10px;
  z-index: -1;
}

@media(max-width: 767px) {
  .mnyExpoSc .mnySubTlt {
    font-size: 15px !important;
  }
}

@media(max-width: 372px) {
  .mnyExpoSc .mnySubTlt {
    font-size: 13px !important;
  }
}

.metaSlnSc {
  padding: 60px 0px;
}

.metaSlnTabCnt {
  padding: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
}

.metaSlnTabCnt .sml_tlt {
  font-size: 18px !important;
  color: #fff !important;
  margin-bottom: 0px;
}

.metaSlnTabCnt .Bg_tlt {
  font-size: 35px !important;
  color: #fff !important;
}

.metaSlnTabCnt .Cnt {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF
}

.metaSlnSc .nav-pills .nav-link.active,
.metaSlnSc .nav-pills .show>.nav-link {
  background: linear-gradient(81.04deg, #E442FF 5.15%, #3C5BFF 89.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  font-size: 16px;
}

.metaSlnSc .nav-pills .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  opacity: 0.8;
}

.metaSlnScRelat {
  margin-top: 100px;
}

.metaSlnScRelat {
  position: relative;
}

.gameLi {
  position: absolute;
  left: 0px;
  top: 44%;
  z-index: 10;
}

.mallLi {
  position: absolute;
  left: 10px;
  top: 19%;
  z-index: 10;
}

.theatreLi {
  position: absolute;
  left: 63px;
  top: 0%;
  z-index: 10;
}

.casinoLi {
  position: absolute;
  right: 90px;
  top: 19%;
  z-index: 10;
}

.storeLi {
  position: absolute;
  right: 120px;
  top: 0%;
  z-index: 10;
}

.eventLi {
  position: absolute;
  top: -50px;
  left: -95px;
  right: 0px;
  margin: 0 auto;
  display: grid;
}

.theatreLi .nav-link {
  color: #dcd9d9 !important;
}

.eventLi .nav-link {
  color: #999595 !important;
}

.storeLi .nav-link {
  color: #696868 !important;
}

.casinoLi .nav-link {
  color: #5f5f5f !important;
}

.metaSlnScTabBnImg {
  margin-top: -260px;
  text-align: end;
  margin-right: -38px;
  position: relative;
}

.tbSlnLst {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  padding: 5px;
  text-align: center;
  margin-top: 20px;
}

.tbSlnLst .tbSlnLstTlt {
  font-weight: 300;
  font-size: 14px;
  leading-trim: both;
  text-edge: cap;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.tbSlnLstPosi {
  position: absolute;
  bottom: 48px;
  right: 127px;
}

.slnImgIndex {
  z-index: 1;
  position: relative;
}

.metaSlnBtn {
  background: linear-gradient(92.12deg, #E442FF 2.22%, #3C5BFF 51.79%);
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  leading-trim: both;
  text-edge: cap;
  border: none;
  padding: 10px 20px;
  color: #fff !important;
  border-radius: 0px;
}

.metaSlnScRelati {
  position: relative;
}

@media(max-width: 1199px) {
  .metaSlnTabCnt .Cnt {
    z-index: 1;
    position: relative;
  }

  .gameLi {
    top: 33%;
  }
}

@media(max-width: 991px) {

  .gameLi,
  .mallLi,
  .theatreLi,
  .eventLi,
  .casinoLi,
  .storeLi {
    position: initial;
    margin: 0;
  }

  .metaSlnTabCnt {
    position: initial;
    width: 100%;
  }

  .metaSlnScTabBnImg {
    margin-top: 0px;
    margin-right: 0px;
  }

  .tbSlnLstPosi {
    position: initial;
  }

  .metaSlnBtn {
    margin: 30px auto;
    display: table;
  }

  .metaSlnScRelat {
    margin-top: 20px;
  }

  .slnImgIndex {
    display: none;
  }
}

@media(max-width: 767px) {
  .metaSlnTabCnt .Bg_tlt {
    font-size: 25px !important;
  }
}

@media(max-width: 394px) {
  .logo_header {
    width: 80%;
  }
}

.mnyExpoScImg {
  position: relative;
  z-index: 1;
}

.mnyExpoScImg:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 50px;
  background: #3C5BFF;
  width: 94.46px;
  height: 230px;
  transform: rotate(-51.35deg);
  border-radius: 50%;
  z-index: -1;
  filter: blur(52px);
}

.mnyExpoScImg:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 70px;
  background: #E442FF;
  width: 94.46px;
  height: 230px;
  border-radius: 50%;
  z-index: -1;
  filter: blur(52px);
}

.VidoCntMn {
  background: #00000069;
  margin: 20px;
  padding: 10px;
  border-radius: 20px;
  margin-top: 150px;
}

.vdoCntLst {
  border: 1px solid #1f1e1e;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  background: #0000008a;
}

.vdoCntLstCount {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 0px;
  font-family: 'Urbanist';
}

.vdoCntLstTlt {
  margin-bottom: 0px;
  font-size: 13px;
}

.game-1 {
  /* background-image: url('../images/game-bg.webp?v1'); */
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

@media(max-width: 991px) {
  .game-1 {
    background-color: #a128f9;
    background-image: initial !important;
  }

  .nft-1 {
    background-color: #fe992e;
    background-image: initial !important;
  }

  .play-1 img,
  .move-1 img,
  .meta-1 img,
  .blkchain-1 img,
  .defi-1 img,
  .dapp-1 img {
    display: none;
  }
}

.play-1 {
  background: #AE58F1;
}

.move-1 {
  background: #29AEC2;
}

.meta-1 {
  background: #6E3CFF;
}

.blkchain-1 {
  background: #F2654E;
}

.defi-1 {
  background: #FC2A5C;
}

.dapp-1 {
  background: #AE58F1;
}

.nft-1 {
  /* background-image: url('../images/nft-tab-1.webp'); */
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

/* Start Popup Form*/

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

#popup_form_modal.popup-form .modal-dialog {
  max-width: 840px;
  margin-top: 100px;
  width: auto;
}

#popup_form_modal.modal .modal-content {
  border-radius: 0;
}

#popup_form_modal.popup-form .modal-content {
  background: rgb(124, 13, 70);
  background: linear-gradient(135deg, rgba(124, 13, 70, 1) 0%, rgba(154, 13, 70, 1) 100%);
  /* background: url('../../modal-bg.png?v2'); */
  background-size: cover;
  background-repeat: no-repeat;
}

#popup_form_modal.popup-form .top_cls {
  color: #d53938 !important;
  opacity: 1;
  font-size: 40px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  position: relative;
  right: -16px;
  top: -11px;
  z-index: 1;
  background: #ffffff;
}

#popup_form_modal .error,
#popup_contact_form .error {
  color: #fff !important;
}

#popup_form_modal .popup-contact-form {
  padding-top: 0px;
  padding-bottom: 0em;
  color: #fff;
  margin-top: 0px;
  position: relative;
}

#popup_form_modal .popup-contact-form h2 {
  font-weight: bold;
  color: #fff;
  margin-top: 0px;
}

#popup_form_modal .popup-contact-form p {
  font-weight: 500;
  color: #fff;
  font-size: 16px;
}

#popup_form_modal .popup-contact-form .modal-disclaim {
  font-size: 14px;
}

.popup-contact-form .mt-3 {
  margin-top: 0rem !important;
}

#popup_form_modal .popup-form p {
  color: #fff;
  font-size: 15px;
  text-align: left;
  margin-bottom: 20px;
  line-height: 23px;
}

#popup_form_modal .fitoutform .form-group {
  margin-bottom: 0em !important;
}

#popup_form_modal .popup-contact-form input,
#popup_form_modal .popup-contact-form textarea {
  width: 100%;
  height: 45px;
  margin-bottom: 13px;
  padding-left: 15px;
  border-radius: 0px;
  border: none;
  /* box-shadow: 0px 1px 5px rgba(0,0,0,0.1); */
  color: #222;
  border-bottom: 1px solid #fff;
}

#popup_form_modal.popup-form input,
#popup_form_modal.popup-form select,
#popup_form_modal.popup-form textarea {
  margin-bottom: 5px;
  background: transparent !important;
  color: #fff;
  border: none;
  font-size: 14px;
  padding: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  outline: 0;
}

#popup_form_modal #popup_contact_form_home .selecpicker_input_group_div,
#request_quote_form .selecpicker_input_group_div,
#contactus_form .selecpicker_input_group_div,
#blog_right_side_form .selecpicker_input_group_div,
#blog_form_comment .selecpicker_input_group_div {
  display: flex;
}

#popup_form_modal #popup_contact_form_home .selecpicker_input_group_div .select_icon_list,
#request_quote_form .selecpicker_input_group_div .select_icon_list,
#contactus_form .selecpicker_input_group_div .select_icon_list {
  width: 30%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  align-items: center;
  display: grid;
}

#popup_form_modal #popup_contact_form_home #popup_error_msg_home .alert-danger p {
  color: inherit;
}

#blog_right_side_form .selecpicker_input_group_div .select_icon_list {
  width: 30%;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

#request_quote_form .selecpicker_input_group_div .select_icon_list {
  width: 20%;
}

#blog_form_comment .selecpicker_input_group_div .select_icon_list {
  width: 30%;
}

@media (max-width: 767px) {
  #request_quote_form .selecpicker_input_group_div .select_icon_list {
    width: 30%;
  }
}

#popup_form_modal #popup_contact_form_home .selecpicker_input_group_div .select_icon_list .btn {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  color: #fff;
  padding: 5px;
}

#request_quote_form .selecpicker_input_group_div .select_icon_list .btn,
#contactus_form .selecpicker_input_group_div .select_icon_list .btn {
  border-radius: 50px;
  padding: 5px 10px;
  background: transparent;
  box-shadow: none;
  outline: none !important;
}

#blog_form_comment .selecpicker_input_group_div .select_icon_list .btn {
  height: 45px;
  border: 1px solid #b0c9c9;
  background: #225a5d08;
  outline: none !important;
  box-shadow: none;
}

#blog_right_side_form .selecpicker_input_group_div .select_icon_list .btn {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  padding: 5px;
}

#popup_form_modal #popup_contact_form_home .selecpicker_input_group_div .select_icon_list .btn i,
#request_quote_form .selecpicker_input_group_div .select_icon_list .btn i,
#contactus_form .selecpicker_input_group_div .select_icon_list .btn i,
#blog_right_side_form .selecpicker_input_group_div .select_icon_list .btn i,
#blog_form_comment .selecpicker_input_group_div .select_icon_list .btn i {
  font-size: 25px;
}

#popup_form_modal #popup_contact_form_home .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a,
#request_quote_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a,
#contactus_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a,
#blog_right_side_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a,
#blog_form_comment .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a {
  font-size: 20px;
  font-weight: 600;
}

#popup_form_modal #popup_contact_form_home .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a,
#request_quote_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a,
#contactus_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a,
#blog_right_side_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a,
#blog_form_comment .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a {
  background: #b52152;
  color: #fff;
}

#popup_contact_form .form_control_select {
  color: #fff;
}

#popup_contact_form select.form-control:not([size]):not([multiple]) {
  height: 45px;
}

.fitoutform .form-control {
  padding: 0;
  border: none;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid #e5e3e3;
  border-radius: 0;
  box-shadow: none;
}

.popup-contact-form .btn {
  background-color: #40a58d;
  color: #fff;
  font-size: 19px;
  width: 152px;
  font-weight: 500;
  border-radius: 50px;
  background: linear-gradient(180deg, rgba(0, 188, 201, 1) 0%, rgba(0, 131, 143, 1) 100%);
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0px 4px 19px -2px #033a3e;
}

#popup_contact_form_home ::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

#popup_contact_form_home :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

#popup_contact_form_home ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

#popup_contact_form_home select option {
  background-color: rgb(124, 13, 70);
  color: #fff;
}

#popup_contact_form_home input:-webkit-autofill,
#popup_contact_form_home input:-webkit-autofill:hover,
#popup_contact_form_home input:-webkit-autofill:focus,
#popup_contact_form_home input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1f5359 inset !important;
  -webkit-text-fill-color: #fff;
}

#popup_contact_form_home input:-webkit-autofill,
#popup_contact_form_home textarea:-webkit-autofill,
#popup_contact_form_home select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #1f5359 inset !important;
  background-image: none;
  -webkit-text-fill-color: #fff;
}

#popup_form_modal {
  margin-top: 0px !important;
  top: 6px !important;
  z-index: 9999999999;
}

@media (max-width:767px) {
  #popup_form_modal .popup-contact-form h2 {
    font-size: 30px;
  }

  #popup_form_modal {
    margin-top: 0px !important;
    top: 25px !important;
  }

  #popup_form_modal.popup-form .modal-dialog {
    max-width: 386px;
    margin-top: 5rem;
    width: auto;
    position: relative;
  }

  #msg {
    width: 100% !important;
  }
}

body {
  margin: 0px;
}

@media(min-width: 767px) {
  .mobile {
    display: none !important;
  }
}

























.popup-form .modal-dialog {
  max-width: 1000px !important;
  margin-top: 100px;
  width: auto;
}

.popup-form .modal-dialog .modal-body {
  padding: 0px;
}

.popup-form .top_cls {
  color: #000000 !important;
  opacity: 1;
  font-size: 35px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  position: relative;
  right: 0px;
  top: -3px;
  z-index: 1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.OurPrjctBg .PrjctExmplsImg img {
  border-radius: 5px;
}

@media (max-width: 991px) {
  .popup-form {
    display: none;
  }
}

#portfolio_modal .model_full_width .HddrBtns {
  margin-top: 12px;
}

#portfolio_modal .model_full_width .img_sec {
  padding: 0px 20px 15px 20px;
  text-align: center;
}

#portfolio_modal .portfolio_btn {
  margin: 0 auto;
}

@media (max-width: 767px) {
  #portfolio_modal .model_full_width .BtnPrimry {
    font-size: 14px !important;
    min-height: 40px !important;
    line-height: 40px !important;
  }

  #portfolio_modal .model_full_width .TlkBtn {
    width: 155px !important;
  }

  #portfolio_modal .model_full_width .img_sec {
    padding: 0px 15px 10px 15px !important;
  }
}





















#popup_form_modal.popup-form .modal-dialog {
  max-width: 1000px !important;
}

#popup_form_modal.popup-form .modal-dialog .modal-body {
  padding: 0px;
}

#popup_form_modal.popup-form {
  backdrop-filter: blur(7px) !important;
}


/* .MuiBox-root{
display: none !important;
} */

.css-1o5pq7n {
  display: none !important;
}

@media (min-width: 991px) {
  .css-1o5pq7n {
    display: none !important;
    width: 0px !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .fixed_social_icons a.mobile {
    display: none !important;
  }

  #popup_form_modal.popup-form .modal-content .popup_form_left_sec {
    border-radius: 0 !important;
    background: url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/popup-form.webp);
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    padding: 10px 20px;
    position: relative;
  }
}

#popup_form_modal.popup-form .modal-content {
  background: #101010;
  backdrop-filter: blur(2px);
}

#popup_form_modal.popup-form .title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 0px;
  color: #fff;
}

#popup_form_modal.popup-form .contents {
  font-size: 16px !important;
  margin-bottom: 20px;
}

#popup_form_modal.popup-form .title1 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 0px;
  color: #fff;
}

#popup_form_modal.popup-form .contents1 {
  font-size: 16px !important;
  margin-bottom: 20px;
}

#popup_form_modal.popup-form .btm_contents {
  font-size: 14px !important;
  margin-bottom: 0px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

#popup_form_modal.popup-form .head_sec {
  position: absolute;
  bottom: 28%;
  transform: rotate(-90deg);
  left: -15%;
}

#popup_form_modal.popup-form #popup_contact_form_home_new {
  margin-top: 30px !important;
}

#popup_form_modal.popup-form .popup-contact-form {
  padding: 20px 20px;
}

#popup_form_modal.popup-form .top_cls {
  color: #ffffff !important;
  opacity: 1;
  font-size: 22px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  position: relative;
  right: -10px;
  top: -10px;
  z-index: 1;
  background: linear-gradient(93.79deg,
      #14f4ff -13.43%,
      #0ba0a2 100.49%) !important;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.popup-contact-form .btn {
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  border-radius: 4px;
  border: none;
  padding: 10px 35px;
  box-shadow: unset;
  margin-top: 10px;
  width: unset;
}

#popup_form_modal.popup-form .home_popup_submit {
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  border-radius: 4px;
  border: none;
  padding: 10px 35px;
  box-shadow: unset;
  margin-top: 10px;
  width: unset;
}

#popup_form_modal #popup_contact_form_home_new .christmas_btn {
  position: relative;
  display: flex;
  align-items: center;
  background: #00f7ff;
  padding: 10px 35px;
  line-height: 23px;
  text-decoration: none;
  color: #000;
  border-radius: 12px;
  border: 1px solid #14626b;
  box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
    inset 0 0 6px #23fff8, inset 0 80px 80px -40px #20f2ff,
    1px 1px 3px rgba(0, 0, 0, 0.75);
  text-transform: uppercase;
  margin: 0 auto;
}

#popup_form_modal #popup_contact_form_home_new .christmas_btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: -3px;
  right: 0;
  height: 23px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAXCAYAAACS5bYWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABFpJREFUeNrUV0tIo1cUvpkYjQ4xxSA6DxuNqG0dtaUKOgs3s6i0dFd3pSsXdjeIixakiGA34sZuXCkoONLFwJTK4GMYLYXg29gatTpiXurkbd7vv9/5ub+IxuhA7eiFQ5Kbc8/57ne/e87/ywRBYLdl3GG3aNwqsLJ0k0tLS+fmcnNzWUVFBVMoFGx2djarvLxcm5OTw+bm5iytra2xc4ExNjY27iqVyvvwK6CpeDzuCYVC1urq6qDA9UcfPp+PHR4esmAwKK6tr68/l5/8rgQ2Ozub1dbWyiYmJooaGxt/VqvV38jlchX9l0qlwoFA4DWS/RKLxRxFRUVf5+XlPcaaT2AP0sVPJBL2SCRiAPBpu93+vKamZo/Ae71eZjabWV1dXVqw7CKwp43ksrCw8Bhg7MJ/PLDZ5PHx8cz29vYT5JGD/bSYLgTrcDgYdk6siSc6NjZWDaAe4ZoHQL+cmZnRpZPnhWDpD8kw7uKo9ML/NMCsd2tr61vkzboMrEyv138M7TyLRqMWMBsX3sMgaZhMpp+AR5EJrCocDpuEGzKg4x8khs+CVWxubvZfR9JkMik4nU7BarUKLpeLmLsKuwIqTLynp4fqmIzASrqQT09Pf1VVVfX0KsWZ6uHBwQHTaDSsoKAgo6/H4xHLEcrVyRwuEisrKzs5XrrIVAVwiUVDKRRrL+YI32ewdVhMApuHWvcj6vids6J2u90MF4yBHUZNgKoEBaRBQalJqFSqtJfUYrGIlQX+ydXVVTN+u0tKSjQNDQ1axJVl2iTypebn55d7e3v/kqoDgZU1NTU9LCws/Py0M+2ekuGincxJ3yF+18jIyHJLS0slQJUWFxczrBeBE0vE5tHRkbixlZWVfSR8gTX/0P5gH7S1tX3Z3t7+BW8qAvwSfr8/jA0EIRM/qoFtampqbW9vTw+XA+ojUruVd3Z2tvb19T2TQFEim81GgVJoCvvj4+NLOJZgaWmpemdn5y3a6BbcnJDAw8HBwac6ne6eqCW5XDwB3qVSqM9/DAwMUNy/eVLabT7sI25qwgujThCBhWE+mAt2yNc4SQKSZrOQQE1HS22VJkmPAGTr7+//fX19fRk+Zgq0trbGeFAKEAQT98BSqKOj47vm5uaa/Px8JeIk4GcaHh6eWlxcfAU/A8xG67BxAX3fwdcbYUpSDJ06Z49Ak8ZC3OL8f3YiA4PBYKdLQ2AJ9OTk5GpXV9cQiCVh79M94QtlPLDUE/1gPNrd3f0W33W4cBoco48zQuy/IZYAMnGqlSc4c66L9JruQUaSARXeT8HGKzxAqFBekni6+h46+pMzGiJGMgTOJh1yU/KNEGDvZWvfBawkA9ppwGg0mrRa7SOI2g+gxOgbJIpdFpj72PnxSnPX8vqRxTURgBQWKisrH+GThOm+CtAzoK/9/Uiqq/6hoaHfdnd3jaOjo7/yY7yxbwqkWy3sQzpS2C6YirwvUJk0y7hurfyGRrnduPGvAAMASmo8wzeVwfsAAAAASUVORK5CYII=) no-repeat 0 0,
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAXCAYAAACFxybfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAodJREFUeNrsVb1rWlEUv2pN/GqspKRSKFYXWzEloIWif0Fn6dJChQ7OQil0qd3EzcEpg0OgdHDr4CQODk7VRlLMEIVqApX4We0zflR9/Z1Ui4T34ksaaAYP/Hzc673n/M6550PG8zz73yKjn0wm83fDYDAwo9HINBrNnwOQg4MDs0ql2lQqlfdAWont7ng8Pjw+Ps44nc4G1pI9EXWaSOzt7TGO42aH5Pv7+08ajUZ0MBiUeXEZd7vdL5VK5fX29rZ+5tQiEmdxKrlcjsEYczgcynK5/BKKv/IXFNz/XiqVXkHdjUuRIA9SqdRD8or/R8Ez9fr9fqHVakUR4c2z0REjIQuHw2ZcrPBXLCA0RHTezEdHjIQqkUhEr9I4HOILhQLf6/VoOUFEvDMiQiToDx1Cdz+bzZ6bUFarlel0OkkVUK/XWbvdPoVer5fh3ntsfwJ+CJ2XA4p0Op1bpBgJyxDehQQ6nQ5DZXHBYDBZq9V+EhFUndnr9drEqoc2bwJbwGPgtohuVSwWe2Gz2TZMJpNgRKi6qtUqg2EWj8dTgUDgo0KhWPN4PC70EvXOzs67fD6/S6kiRIKeZA1YJ2MiJNbdbvfTUCjkV6vVK2hcDF8GI2w0GrGTkxM2HA5PDxaLxSOfz/cWEfk81X0XIMMFgJJ/srBjCgk8IdcfuVyuZ36//7nFYtkQyAMumUzuRiKRD0jMFLa+AZOpYwqgB/ziBVqmVBKUO7eAB/R0WG/Z7XaTVqtdbTabHJL6EK2djBaBPHA0NSqpbUsiMUeEBgpF4Q5AbZrmSJ/yEWgBTaBNHl9kdkgmMUeG7qwAq9PqovceTA3zlxlgsuswyuXsGsiSxJLEkoSY/BZgAEjRodi+uBruAAAAAElFTkSuQmCC) no-repeat 100% 0;
}

#popup_form_modal.popup-form input {
  background: rgba(217, 217, 217, 0.08) !important;
  backdrop-filter: blur(2px);
  border: none !important;
  border-radius: 0px;
  color: #fff !important;
  height: 40px;
  padding-left: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 200;
}

#popup_form_modal.popup-form .popup_country select {
  background: rgba(217, 217, 217, 0.08) !important;
  backdrop-filter: blur(2px);
  border: none !important;
  border-radius: 0px;
  height: 40px;
  padding-left: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 200;
}

#popup_form_modal.popup-form .popup_country select option {
  color: #000 !important;
}

#popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div {
  display: flex;
}

#popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div .select_icon_list {
  background: rgba(217, 217, 217, 0.08) !important;
  border: none !important;
  border-radius: 0px;
  color: #fff !important;
  height: 40px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 200;
  margin-right: 10px;
  width: 30%;
  align-items: center;
  display: grid;
}

#popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div .select_icon_list .btn {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  color: #fff;
  padding: 5px;
  margin-top: 0px;
}

#popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div .select_icon_list .btn i {
  font-size: 16px;
  padding-left: 10px;
}

#popup_form_modal.popup-form #msg {
  background: rgba(217, 217, 217, 0.08) !important;
  backdrop-filter: blur(2px);
  border: none !important;
  border-radius: 0px;
  color: #fff !important;
  padding-left: 20px;
  margin-top: 0px;
  height: 90px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 200;
  padding-top: 15px;
}

#popup_form_modal.popup-form input::placeholder,
#popup_form_modal.popup-form select::placeholder,
#popup_form_modal.popup-form #msg::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.65);
  opacity: 1;
  /* Firefox */
}

#popup_form_modal.popup-form input:-ms-input-placeholder,
#popup_form_modal.popup-form select::placeholder,
#popup_form_modal.popup-form #msg::placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.65);
}

#popup_form_modal.popup-form input::-ms-input-placeholder,
#popup_form_modal.popup-form select::placeholder,
#popup_form_modal.popup-form #msg::placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.65);
}

#popup_form_modal .fitoutform .form-group {
  margin-bottom: 1em !important;
}

#popup_form_modal.popup-form input:-webkit-autofill,
#popup_form_modal.popup-form select:-webkit-autofill,
#popup_form_modal.popup-form textarea:-webkit-autofill,
#popup_form_modal.popup-form input:-webkit-autofill:focus,
#popup_form_modal.popup-form input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #202020 inset !important;
  background-image: none;
  -webkit-text-fill-color: #fff !important;
}

#popup_form_modal.popup-form .select2-selection__rendered {
  color: #929292;
  margin: 0px;
  padding: 6px;
  background: #fff;
  padding-left: 15px;
}

#popup_form_modal.popup-form #popup_contact_form_home_new .popup_select {
  padding-left: 15px;
  font-size: 17px;
  color: #929292 !important;
}

#popup_form_modal.popup-form #popup_contact_form_home_new .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: unset;
}

#popup_form_modal.popup-form .popup-contact-form .social_list_line {
  padding: 10px 22px;
}

#popup_form_modal.popup-form .popup-contact-form .social_list_sec {
  margin-top: 18px;
}

#popup_form_modal.popup-form .popup-contact-form .social_list_sec ul {
  display: flex;
}

#popup_form_modal.popup-form .popup-contact-form .social_list {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

#popup_form_modal.popup-form .popup-contact-form .social_list .sub_title {
  padding-left: 7px;
}

#popup_form_modal.popup-form .popup-contact-form .social_list .sprite_icons {
  width: 32px;
  height: 32px;
  object-fit: none;
}

#popup_form_modal.popup-form #popup_contact_form_home_new label.error {
  border-radius: 5px;
  background: red;
  color: #fff !important;
  padding: 2px;
  position: absolute;
  top: -12px;
  right: 0;
  width: auto;
  font-size: 10px;
  font-weight: 400;
  z-index: 1;
}

#popup_form_modal.popup-form #popup_contact_form_home_new label.error:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: red transparent transparent transparent;
}

@media (max-width: 991px) {
  #popup_form_modal.popup-form .modal-content {
    background: linear-gradient(186deg,
        #1e083d -13.43%,
        #000000 100.49%) !important;
    border: 1px solid #bf47ff61;
  }

  #popup_form_modal.popup-form .modal-dialog .modal-content .modal-body .popup_cus_row {
    display: unset !important;
  }

  .fixed_social_icons a.desktop {
    display: none !important;
  }

  #popup_form_modal.popup-form .head_sec {
    display: none;
  }

  #popup_form_modal.popup-form .popup-contact-form {
    padding: 20px 10px;
    text-align: center;
  }

  #popup_form_modal.popup-form .home_popup_submit {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    background: linear-gradient(93.79deg,
        #56f3f5 -13.43%,
        #ec25f8 100.49%);
    border-radius: 4px;
    border: none;
    padding: 8px 30px;
    box-shadow: unset;
    margin-top: 10px;
    width: unset;
    transition: 2s;
    -webkit-animation: glowing 2s ease infinite alternate;
    -moz-animation: glowing 2s ease infinite alternate;
    -o-animation: glowing 2s ease infinite alternate;
    animation: glowing 2s ease infinite alternate;
  }

  #popup_form_modal.popup-form .home_popup_submit:hover {
    animation: unset;
  }

  @-webkit-keyframes glowing {
    0% {
      /*background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);*/
      transform: scale(1);
    }

    50% {
      /*background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);*/
      transform: scale(0.9);
    }

    100% {
      background: linear-gradient(120deg,
          #6a58fb -25.43%,
          #3cfff6 100.49%);
      transform: scale(1);
    }
  }

  @-moz-keyframes glowing {
    0% {
      /*background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);*/
      transform: scale(1);
    }

    50% {
      /*background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);*/
      transform: scale(0.9);
    }

    100% {
      /*background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);*/
      transform: scale(1);
    }
  }

  @-o-keyframes glowing {
    0% {
      /*background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);*/
      transform: scale(1);
    }

    50% {
      /*background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);*/
      transform: scale(0.9);
    }

    100% {
      /*background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);*/
      transform: scale(1);
    }
  }

  @keyframes glowing {
    0% {
      /*background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);*/
      transform: scale(1);
    }

    50% {
      /*background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);*/
      transform: scale(0.9);
    }

    100% {
      /*background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);*/
      transform: scale(1);
    }
  }

  #popup_form_modal.popup-form input,
  #popup_form_modal.popup-form .popup_country select,
  #popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div .select_icon_list {
    height: 50px !important;
  }

  #popup_form_modal.popup-form #msg {
    height: 100px;
  }

  #popup_form_modal.popup-form .modal-dialog {
    margin-left: 15px;
    margin-right: 15px;
  }

  #popup_form_modal.popup-form .christ_tit {
    font-size: 55px;
    position: relative;
  }

  #popup_form_modal.popup-form .christ_tit::before {
    background: url(https://www.metablocktechnologies.com/asset/inner-pages-new/christmas/santa-hat.svg);
    position: absolute;
    content: "";
    left: -24%;
    top: -22.6%;
    width: 68px;
    transform: rotate(333deg);
    z-index: 1;
    height: 100px;
    background-repeat: no-repeat;
  }

  #popup_form_modal.popup-form .title1 {
    color: #00f7ff;
  }
}

@media (max-width: 767px) {
  #popup_form_modal .fitoutform .form-group {
    margin-bottom: 0.8em !important;
  }

  .popup-contact-form .btn {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px 35px;
  }

  #popup_form_modal.popup-form .title1 {
    font-size: 30px;
    /*background: linear-gradient(90deg, #CC46FB 0%, #4DFFF4 38.02%, #FFB44A 70.31%, #FF00E5 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;*/
    color: #00f7ff;
  }

  #popup_form_modal.popup-form .popup-contact-form .social_list_sec ul {
    justify-content: center;
  }

  #popup_form_modal.popup-form {
    top: 0px !important;
  }

  #popup_form_modal.popup-form .modal-dialog {
    margin-top: 3.5rem;
  }
}

#popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a {
  background: #101010;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
}

#popup_form_modal #popup_contact_form_home_new .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
}




















.footer_new_form {
  position: relative;
  z-index: 10;
  /* background: url(./asset/img/footer-form-bg-new.webp); */
  min-height: 530px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  display: flex;
  align-items: center;
  /*border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
    border-image-source: linear-gradient(to left, rgba(255, 45, 172, 1), rgba(33, 0, 164, 1), rgba(53, 255, 134, 1), rgba(255, 73, 237, 1), rgba(128, 255, 0, 1), rgba(25, 125, 217, 1), rgba(228, 155, 13, 1), rgba(0, 255, 240, 1), rgba(255, 151, 68, 1));*/
}

.footer_new_form .footer_left_bg {
  text-align: center;
}

.footer_new_form .footer_right_main {
  position: relative;
  background: #ffffff1f;
  padding: 20px;
  border-radius: 15px;
  margin: 50px 0px;
}

.footer_new_form .footer_right_main .title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 5px;
  text-align: center;
  background: linear-gradient(90deg,
      #cc46fb 0%,
      #4dfff4 38.02%,
      #ffb44a 70.31%,
      #ff00e5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
}

.footer_new_form .footer_right_main .contents {
  text-align: center;
}

.footer_new_form #footer_new_form {
  margin-top: 30px !important;
}

.footer_new_form #footer_new_form #footer_submit {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  background: linear-gradient(93.79deg, #56f3f5 -13.43%, #ec25f8 100.49%);
  border-radius: 4px;
  border: none;
  padding: 8px 30px;
  box-shadow: unset;
  margin-top: 10px;
  width: unset;
  transition: 2s;
  -webkit-animation: glowing 2s ease infinite alternate;
  -moz-animation: glowing 2s ease infinite alternate;
  -o-animation: glowing 2s ease infinite alternate;
  animation: glowing 2s ease infinite alternate;
}

.footer_new_form #footer_new_form #footer_submit:hover {
  animation: unset;
}

@-webkit-keyframes glowing {
  0% {
    background: linear-gradient(93.79deg,
        #56f3f5 -13.43%,
        #ec25f8 100.49%);
    transform: scale(1);
  }

  50% {
    background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);
    transform: scale(0.9);
  }

  100% {
    background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);
    transform: scale(1);
  }
}

@-moz-keyframes glowing {
  0% {
    background: linear-gradient(93.79deg,
        #56f3f5 -13.43%,
        #ec25f8 100.49%);
    transform: scale(1);
  }

  50% {
    background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);
    transform: scale(0.9);
  }

  100% {
    background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);
    transform: scale(1);
  }
}

@-o-keyframes glowing {
  0% {
    background: linear-gradient(93.79deg,
        #56f3f5 -13.43%,
        #ec25f8 100.49%);
    transform: scale(1);
  }

  50% {
    background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);
    transform: scale(0.9);
  }

  100% {
    background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);
    transform: scale(1);
  }
}

@keyframes glowing {
  0% {
    background: linear-gradient(93.79deg,
        #56f3f5 -13.43%,
        #ec25f8 100.49%);
    transform: scale(1);
  }

  50% {
    background: linear-gradient(55deg, #ffb516 -13.43%, #e40bf1 100.49%);
    transform: scale(0.9);
  }

  100% {
    background: linear-gradient(120deg, #6a58fb -25.43%, #3cfff6 100.49%);
    transform: scale(1);
  }
}

.footer_new_form #footer_new_form input {
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(2px);
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  height: 40px;
  padding-left: 10px !important;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  width: 100%;
  font-weight: 200;
}

.footer_new_form #footer_new_form .popup_country select {
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(2px);
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  height: 40px;
  padding-left: 10px !important;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  width: 100%;
  font-weight: 200;
}

.footer_new_form #footer_new_form .popup_country select option {
  color: #000 !important;
}

.footer_new_form #footer_new_form .selecpicker_input_group_div {
  display: flex;
}

.footer_new_form #footer_new_form .selecpicker_input_group_div .select_icon_list {
  background: rgb(255 255 255 / 6%) !important;
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  height: 40px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 200;
  margin-right: 10px;
  width: 30%;
  align-items: center;
  display: grid;
}

.footer_new_form #footer_new_form .selecpicker_input_group_div .select_icon_list .dropdown-toggle {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  color: #fff;
  padding: 5px;
  margin-top: 0px;
}

.footer_new_form #footer_new_form .selecpicker_input_group_div .select_icon_list .btn i {
  font-size: 16px;
  padding-left: 10px;
}

.footer_new_form #footer_new_form #footer_msg {
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(2px);
  border: 1px solid #ffffff36;
  border-radius: 5px;
  color: #fff !important;
  padding-left: 10px;
  margin-top: 0px;
  height: 90px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 200;
  padding-top: 15px;
}

.footer_new_form #footer_new_form input::placeholder,
.footer_new_form #footer_new_form select::placeholder,
.footer_new_form #footer_new_form #footer_msg::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.65);
  opacity: 1;
  /* Firefox */
}

.footer_new_form #footer_new_form input:-ms-input-placeholder,
.footer_new_form #footer_new_form select::placeholder,
.footer_new_form #footer_new_form #footer_msg::placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.65);
}

.footer_new_form #footer_new_form input::-ms-input-placeholder,
.footer_new_form #footer_new_form select::placeholder,
.footer_new_form #footer_new_form #footer_msg::placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.65);
}

.footer_new_form #footer_new_form .fitoutform .form-group {
  margin-bottom: 1em !important;
}

.footer_new_form #footer_new_form input:-webkit-autofill,
.footer_new_form #footer_new_form select:-webkit-autofill,
.footer_new_form #footer_new_form textarea:-webkit-autofill,
.footer_new_form #footer_new_form input:-webkit-autofill:focus,
.footer_new_form #footer_new_form input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #2a2a32 inset !important;
  background-image: none;
  -webkit-text-fill-color: #fff !important;
}

.footer_new_form #footer_new_form .select2-selection__rendered {
  color: #929292;
  margin: 0px;
  padding: 6px;
  background: #fff;
  padding-left: 15px;
}

.footer_new_form #footer_new_form .popup_select {
  padding-left: 15px;
  font-size: 17px;
  color: #929292 !important;
}

.footer_new_form #footer_new_form .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: unset;
}

.footer_new_form #footer_new_form label.error {
  border-radius: 5px;
  background: red;
  color: #fff !important;
  padding: 2px;
  position: absolute;
  top: -12px;
  right: 0;
  width: auto;
  font-size: 10px;
  font-weight: 400;
  z-index: 1;
}

.footer_new_form #footer_new_form label.error:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: red transparent transparent transparent;
}

@media (max-width: 991px) {
  .footer_new_form .popup-contact-form {
    padding: 30px 10px;
    text-align: center;
  }

  .footer_new_form {
    border: none;
  }
}

@media (max-width: 767px) {
  .footer_new_form .fitoutform .form-group {
    margin-bottom: 0.8em !important;
  }

  .footer_new_form .footer_right_main {
    padding: 20px;
  }

  .footer_new_form .footer_right_main .title {
    font-size: 25px;
  }

  .footer_new_form #footer_new_form #footer_submit {
    margin-top: 10px;
    font-size: 14px;
    padding: 8px 30px;
  }

  .footer_new_form .footer_right_main {
    margin: 0;
  }
}

.footer_new_form #footer_new_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a {
  background: #101010;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
}

.footer_new_form #footer_new_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
}








.calendly-inline-widget,
.calendly-inline-widget *,
.calendly-badge-widget,
.calendly-badge-widget *,
.calendly-overlay,
.calendly-overlay * {
  font-size: 16px;
  line-height: 1.2em
}

.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
  display: inline;
  width: 100%;
  height: 100%
}

.calendly-popup-content {
  position: relative
}

.calendly-popup-content.calendly-mobile {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto
}

.calendly-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  background-color: #a5a5a5;
  background-color: rgba(31, 31, 31, .4)
}

.calendly-overlay .calendly-close-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.calendly-overlay .calendly-popup {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 80%;
  min-width: 900px;
  max-width: 1000px;
  height: 90%;
  max-height: 680px
}

@media(max-width:975px) {
  .calendly-overlay .calendly-popup {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: auto;
    min-width: 0;
    max-height: none
  }
}

.calendly-overlay .calendly-popup .calendly-popup-content {
  height: 100%
}

.calendly-overlay .calendly-popup-close {
  position: absolute;
  top: 25px;
  right: 25px;
  color: #fff;
  width: 19px;
  height: 19px;
  cursor: pointer;
  /* background: url(/assets/external/close-icon.svg) no-repeat; */
  background-size: contain
}

@media(max-width:975px) {
  .calendly-overlay .calendly-popup-close {
    top: 15px;
    right: 15px
  }
}

.calendly-badge-widget {
  position: fixed;
  right: 20px;
  bottom: 15px;
  z-index: 9998
}

.calendly-badge-widget .calendly-badge-content {
  display: table-cell;
  width: auto;
  height: 45px;
  padding: 0 30px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, .25) 0 2px 5px;
  font-family: sans-serif;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  cursor: pointer
}

.calendly-badge-widget .calendly-badge-content.calendly-white {
  color: #666a73
}

.calendly-badge-widget .calendly-badge-content span {
  display: block;
  font-size: 12px
}

.calendly-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  z-index: -1
}

.calendly-spinner>div {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #e1e1e1;
  border-radius: 50%;
  vertical-align: middle;
  animation: calendly-bouncedelay 1.4s infinite ease-in-out;
  animation-fill-mode: both
}

.calendly-spinner .calendly-bounce1 {
  animation-delay: -.32s
}

.calendly-spinner .calendly-bounce2 {
  animation-delay: -.16s
}

@keyframes calendly-bouncedelay {

  0%,
  80%,
  100% {
    transform: scale(0)
  }

  40% {
    transform: scale(1)
  }
}

















/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b,
strong {
  font-weight: bolder
}

small {
  font-size: 80%
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent
}

a:hover {
  color: #0056b3;
  text-decoration: underline
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar
}

figure {
  margin: 0 0 1rem
}

img {
  vertical-align: middle;
  border-style: none
}

svg {
  overflow: hidden;
  vertical-align: middle
}

table {
  border-collapse: collapse
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent
}

label {
  display: inline-block;
  margin-bottom: .5rem
}

button {
  border-radius: 0
}

button:focus:not(:focus-visible) {
  outline: 0
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

textarea {
  overflow: auto;
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal
}

progress {
  vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

summary {
  display: list-item;
  cursor: pointer
}

template {
  display: none
}

[hidden] {
  display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}

.h1,
h1 {
  font-size: 2.5rem
}

.h2,
h2 {
  font-size: 2rem
}

.h3,
h3 {
  font-size: 1.75rem
}

.h4,
h4 {
  font-size: 1.5rem
}

.h5,
h5 {
  font-size: 1.25rem
}

.h6,
h6 {
  font-size: 1rem
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
  font-size: .875em;
  font-weight: 400
}

.mark,
mark {
  padding: .2em;
  background-color: #fcf8e3
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem
}

.initialism {
  font-size: 90%;
  text-transform: uppercase
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem
}

.blockquote-footer {
  display: block;
  font-size: .875em;
  color: #6c757d
}

.blockquote-footer::before {
  content: "\2014\00A0"
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.figure-caption {
  font-size: 90%;
  color: #6c757d
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word
}

a>code {
  color: inherit
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {

  .container,
  .container-sm {
    max-width: 540px
  }
}

@media (min-width:768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px
  }
}

@media (min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px
  }
}

@media (min-width:1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%
}

.row-cols-1>* {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.row-cols-2>* {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.row-cols-3>* {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%
}

.row-cols-4>* {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.row-cols-5>* {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%
}

.row-cols-6>* {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.order-first {
  -ms-flex-order: -1;
  order: -1
}

.order-last {
  -ms-flex-order: 13;
  order: 13
}

.order-0 {
  -ms-flex-order: 0;
  order: 0
}

.order-1 {
  -ms-flex-order: 1;
  order: 1
}

.order-2 {
  -ms-flex-order: 2;
  order: 2
}

.order-3 {
  -ms-flex-order: 3;
  order: 3
}

.order-4 {
  -ms-flex-order: 4;
  order: 4
}

.order-5 {
  -ms-flex-order: 5;
  order: 5
}

.order-6 {
  -ms-flex-order: 6;
  order: 6
}

.order-7 {
  -ms-flex-order: 7;
  order: 7
}

.order-8 {
  -ms-flex-order: 8;
  order: 8
}

.order-9 {
  -ms-flex-order: 9;
  order: 9
}

.order-10 {
  -ms-flex-order: 10;
  order: 10
}

.order-11 {
  -ms-flex-order: 11;
  order: 11
}

.order-12 {
  -ms-flex-order: 12;
  order: 12
}

.offset-1 {
  margin-left: 8.333333%
}

.offset-2 {
  margin-left: 16.666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.333333%
}

.offset-5 {
  margin-left: 41.666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.333333%
}

.offset-8 {
  margin-left: 66.666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.333333%
}

.offset-11 {
  margin-left: 91.666667%
}

@media (min-width:576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-sm-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-sm-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-sm-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .row-cols-sm-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-sm-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-sm-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.333333%
  }

  .offset-sm-2 {
    margin-left: 16.666667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.333333%
  }

  .offset-sm-5 {
    margin-left: 41.666667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.333333%
  }

  .offset-sm-8 {
    margin-left: 66.666667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.333333%
  }

  .offset-sm-11 {
    margin-left: 91.666667%
  }
}

@media (min-width:768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-md-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-md-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-md-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .row-cols-md-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-md-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-md-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.333333%
  }

  .offset-md-2 {
    margin-left: 16.666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.333333%
  }

  .offset-md-5 {
    margin-left: 41.666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.333333%
  }

  .offset-md-8 {
    margin-left: 66.666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.333333%
  }

  .offset-md-11 {
    margin-left: 91.666667%
  }
}

@media (min-width:992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-lg-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-lg-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-lg-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .row-cols-lg-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-lg-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-lg-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    /* max-width: 100% */
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.333333%
  }

  .offset-lg-2 {
    margin-left: 16.666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.333333%
  }

  .offset-lg-5 {
    margin-left: 41.666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.333333%
  }

  .offset-lg-8 {
    margin-left: 66.666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.333333%
  }

  .offset-lg-11 {
    margin-left: 91.666667%
  }
}

@media (min-width:1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-xl-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-xl-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-xl-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .row-cols-xl-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-xl-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-xl-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.333333%
  }

  .offset-xl-2 {
    margin-left: 16.666667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.333333%
  }

  .offset-xl-5 {
    margin-left: 41.666667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.333333%
  }

  .offset-xl-8 {
    margin-left: 66.666667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.333333%
  }

  .offset-xl-11 {
    margin-left: 91.666667%
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6
}

.table-sm td,
.table-sm th {
  padding: .3rem
}

.table-bordered {
  border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: #b8daff
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff
}

.table-hover .table-primary:hover {
  background-color: #9fcdff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: #d6d8db
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #c3e6cb
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e
}

.table-hover .table-success:hover {
  background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
  background-color: #bee5eb
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda
}

.table-hover .table-info:hover {
  background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: #ffeeba
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: #f5c6cb
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: #fdfdfe
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc
}

.table-hover .table-light:hover {
  background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: #c6c8ca
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th {
  background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.table-dark {
  color: #fff;
  background-color: #343a40
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55
}

.table-dark.table-bordered {
  border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-sm>.table-bordered {
    border: 0
  }
}

@media (max-width:767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-md>.table-bordered {
    border: 0
  }
}

@media (max-width:991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-lg>.table-bordered {
    border: 0
  }
}

@media (max-width:1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-xl>.table-bordered {
    border: 0
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
  border: 0
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-control {
    transition: none
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

select.form-control[multiple],
select.form-control[size] {
  height: auto
}

textarea.form-control {
  height: auto
}

.form-group {
  margin-bottom: 1rem
}

.form-text {
  display: block;
  margin-top: .25rem
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
  color: #6c757d
}

.form-check-label {
  margin-bottom: 0
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #28a745
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, .9);
  border-radius: .25rem
}

.form-row>.col>.valid-tooltip,
.form-row>[class*=col-]>.valid-tooltip {
  left: 5px
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
  display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #28a745
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #28a745
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
  border-color: #28a745
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #28a745
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
  border-color: #28a745
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, .9);
  border-radius: .25rem
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*=col-]>.invalid-tooltip {
  left: 5px
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
  display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #dc3545
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
  border-color: #dc3545
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #dc3545
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
  border-color: #dc3545
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center
}

.form-inline .form-check {
  width: 100%
}

@media (min-width:576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0
  }

  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .form-inline .form-control-plaintext {
    display: inline-block
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .form-inline .custom-control-label {
    margin-bottom: 0
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn.disabled,
.btn:disabled {
  opacity: .65
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5)
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5)
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
  .collapsing {
    transition: none
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
  .collapsing.width {
    transition: none
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

@media (min-width:576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto
  }
}

@media (min-width:768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto
  }
}

@media (min-width:992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto
  }
}

@media (min-width:1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-toggle::after {
  vertical-align: 0
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""
}

.dropleft .dropdown-toggle::after {
  display: none
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
  z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
  z-index: 1
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.btn-toolbar .input-group {
  width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
  margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group>.custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after,
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after,
.input-group.has-validation>.custom-select:nth-last-child(n+3),
.input-group.has-validation>.form-control:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
  margin-left: -1px
}

.input-group-prepend {
  margin-right: -1px
}

.input-group-append {
  margin-left: -1px
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
  height: calc(1.5em + .5rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem
}

.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #80bdff
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
  color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
  background-color: #e9ecef
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0, 123, 255, .5)
}

.custom-radio .custom-control-label::before {
  border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, .5)
}

.custom-switch {
  padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem
}

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .custom-switch .custom-control-label::after {
    transition: none
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(.75rem);
  transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, .5)
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef
}

.custom-select::-ms-expand {
  display: none
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0
}

.custom-file-input:focus~.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
  background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse)
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.custom-range:focus {
  outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range::-moz-focus-outer {
  border: 0
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
  cursor: default
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center !important;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto
}

@media (max-width:575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
    /* display: block; */
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media (max-width:767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media (max-width:991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media (max-width:1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
  padding-right: 0;
  padding-left: 0
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, .5);
  border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
  color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, .5);
  border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
  color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem
}

.card-title {
  margin-bottom: .75rem
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1.25rem
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, .03);
  border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck .card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px
  }

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px
  }
}

.card-group>.card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
  }

  .card-group>.card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-header,
  .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-footer,
  .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-header,
  .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-footer,
  .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0
  }
}

.card-columns .card {
  margin-bottom: .75rem
}

@media (min-width:576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1
  }

  .card-columns .card {
    display: inline-block;
    width: 100%
  }
}

.accordion {
  overflow-anchor: none
}

.accordion>.card {
  overflow: hidden
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none
}

.breadcrumb-item.active {
  color: #6c757d
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .badge {
    transition: none
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem
}

.badge-primary {
  color: #fff;
  background-color: #007bff
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.badge-success {
  color: #fff;
  background-color: #28a745
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.badge-info {
  color: #fff;
  background-color: #17a2b8
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.badge-warning {
  color: #212529;
  background-color: #ffc107
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.badge-danger {
  color: #fff;
  background-color: #dc3545
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.badge-dark {
  color: #fff;
  background-color: #343a40
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem
}

@media (min-width:576px) {
  .jumbotron {
    padding: 4rem 2rem
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 700
}

.alert-dismissible {
  padding-right: 4rem
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: .75rem 1.25rem;
  color: inherit
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff
}

.alert-primary hr {
  border-top-color: #9fcdff
}

.alert-primary .alert-link {
  color: #002752
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db
}

.alert-secondary hr {
  border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
  color: #202326
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb
}

.alert-success hr {
  border-top-color: #b1dfbb
}

.alert-success .alert-link {
  color: #0b2e13
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb
}

.alert-info hr {
  border-top-color: #abdde5
}

.alert-info .alert-link {
  color: #062c33
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba
}

.alert-warning hr {
  border-top-color: #ffe8a1
}

.alert-warning .alert-link {
  color: #533f03
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb
}

.alert-danger hr {
  border-top-color: #f1b0b7
}

.alert-danger .alert-link {
  color: #491217
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe
}

.alert-light hr {
  border-top-color: #ececf6
}

.alert-light .alert-link {
  color: #686868
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca
}

.alert-dark hr {
  border-top-color: #b9bbbe
}

.alert-dark .alert-link {
  color: #040505
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .progress-bar {
    transition: none
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start
}

.media-body {
  -ms-flex: 1;
  flex: 1
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.list-group-item+.list-group-item {
  border-top-width: 0
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px
}

@media (min-width:576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

.list-group-flush {
  border-radius: 0
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:hover {
  color: #000;
  text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0
}

a.close.disabled {
  pointer-events: none
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
  opacity: 0;
  border-radius: .25rem
}

.toast:not(:last-child) {
  margin-bottom: .75rem
}

.toast.showing {
  opacity: 1
}

.toast.show {
  display: block;
  opacity: 1
}

.toast.hide {
  display: none
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.toast-body {
  padding: .75rem
}

.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width:576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem)
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content
  }

  .modal-sm {
    max-width: 300px
  }
}

@media (min-width:992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px
  }
}

@media (min-width:1200px) {
  .modal-xl {
    max-width: 1140px
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: .9
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
  bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top]>.arrow::before,
.bs-popover-top>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,
.bs-popover-right>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,
.bs-popover-right>.arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
  top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,
.bs-popover-bottom>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,
.bs-popover-left>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
  display: none
}

.popover-body {
  padding: .5rem .75rem;
  color: #212529
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .carousel-indicators li {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

@media (prefers-reduced-motion:reduce) {

  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s
  }
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.bg-primary {
  background-color: #007bff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important
}

.bg-secondary {
  background-color: #6c757d !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important
}

.bg-success {
  background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important
}

.bg-info {
  background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important
}

.bg-warning {
  background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important
}

.bg-danger {
  background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important
}

.bg-light {
  background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important
}

.bg-dark {
  background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.border {
  border: 1px solid #dee2e6 !important
}

.border-top {
  border-top: 1px solid #dee2e6 !important
}

.border-right {
  border-right: 1px solid #dee2e6 !important
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important
}

.border-left {
  border-left: 1px solid #dee2e6 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #007bff !important
}

.border-secondary {
  border-color: #6c757d !important
}

.border-success {
  border-color: #28a745 !important
}

.border-info {
  border-color: #17a2b8 !important
}

.border-warning {
  border-color: #ffc107 !important
}

.border-danger {
  border-color: #dc3545 !important
}

.border-light {
  border-color: #f8f9fa !important
}

.border-dark {
  border-color: #343a40 !important
}

.border-white {
  border-color: #fff !important
}

.rounded-sm {
  border-radius: .2rem !important
}

.rounded {
  border-radius: .25rem !important
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-lg {
  border-radius: .3rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

@media (min-width:576px) {
  .d-sm-none {
    display: none !important
  }

  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width:768px) {
  .d-md-none {
    display: none !important
  }

  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width:992px) {
  .d-lg-none {
    display: none !important
  }

  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width:1200px) {
  .d-xl-none {
    display: none !important
  }

  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media print {
  .d-print-none {
    display: none !important
  }

  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden
}

.embed-responsive::before {
  display: block;
  content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%
}

.embed-responsive-16by9::before {
  padding-top: 56.25%
}

.embed-responsive-4by3::before {
  padding-top: 75%
}

.embed-responsive-1by1::before {
  padding-top: 100%
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important
}

@media (min-width:576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width:768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width:992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width:1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

@media (min-width:576px) {
  .float-sm-left {
    float: left !important
  }

  .float-sm-right {
    float: right !important
  }

  .float-sm-none {
    float: none !important
  }
}

@media (min-width:768px) {
  .float-md-left {
    float: left !important
  }

  .float-md-right {
    float: right !important
  }

  .float-md-none {
    float: none !important
  }
}

@media (min-width:992px) {
  .float-lg-left {
    float: left !important
  }

  .float-lg-right {
    float: right !important
  }

  .float-lg-none {
    float: none !important
  }
}

@media (min-width:1200px) {
  .float-xl-left {
    float: left !important
  }

  .float-xl-right {
    float: right !important
  }

  .float-xl-none {
    float: none !important
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
  box-shadow: none !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.mh-100 {
  max-height: 100% !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.vw-100 {
  width: 100vw !important
}

.vh-100 {
  height: 100vh !important
}

.m-0 {
  margin: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mr-0,
.mx-0 {
  margin-right: 0 !important
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
  margin-left: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.mt-1,
.my-1 {
  margin-top: .25rem !important
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.mt-2,
.my-2 {
  margin-top: .5rem !important
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.mt-3,
.my-3 {
  margin-top: 1rem !important
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.mt-5,
.my-5 {
  margin-top: 3rem !important
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pr-0,
.px-0 {
  padding-right: 0 !important
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important
}

.pl-0,
.px-0 {
  padding-left: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.pt-1,
.py-1 {
  padding-top: .25rem !important
}

.pr-1,
.px-1 {
  padding-right: .25rem !important
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
  padding-left: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.pt-2,
.py-2 {
  padding-top: .5rem !important
}

.pr-2,
.px-2 {
  padding-right: .5rem !important
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
  padding-left: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.pt-3,
.py-3 {
  padding-top: 1rem !important
}

.pr-3,
.px-3 {
  padding-right: 1rem !important
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
  padding-left: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.pt-5,
.py-5 {
  padding-top: 3rem !important
}

.pr-5,
.px-5 {
  padding-right: 3rem !important
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
  padding-left: 3rem !important
}

.m-n1 {
  margin: -.25rem !important
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important
}

.m-n2 {
  margin: -.5rem !important
}

.mt-n2,
.my-n2 {
  margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
  margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
  margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
  margin-left: -.5rem !important
}

.m-n3 {
  margin: -1rem !important
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important
}

.m-n4 {
  margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important
}

.m-n5 {
  margin: -3rem !important
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

@media (min-width:576px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important
  }

  .m-sm-1 {
    margin: .25rem !important
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important
  }

  .m-sm-2 {
    margin: .5rem !important
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important
  }

  .m-sm-3 {
    margin: 1rem !important
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important
  }

  .m-sm-4 {
    margin: 1.5rem !important
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important
  }

  .m-sm-5 {
    margin: 3rem !important
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important
  }

  .p-sm-1 {
    padding: .25rem !important
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important
  }

  .p-sm-2 {
    padding: .5rem !important
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important
  }

  .p-sm-3 {
    padding: 1rem !important
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important
  }

  .p-sm-4 {
    padding: 1.5rem !important
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important
  }

  .p-sm-5 {
    padding: 3rem !important
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important
  }

  .m-sm-n1 {
    margin: -.25rem !important
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important
  }

  .m-sm-n2 {
    margin: -.5rem !important
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.5rem !important
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.5rem !important
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.5rem !important
  }

  .m-sm-n3 {
    margin: -1rem !important
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important
  }

  .m-sm-n4 {
    margin: -1.5rem !important
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important
  }

  .m-sm-n5 {
    margin: -3rem !important
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important
  }
}

@media (min-width:768px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important
  }

  .m-md-1 {
    margin: .25rem !important
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important
  }

  .m-md-2 {
    margin: .5rem !important
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important
  }

  .m-md-3 {
    margin: 1rem !important
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important
  }

  .m-md-4 {
    margin: 1.5rem !important
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important
  }

  .m-md-5 {
    margin: 3rem !important
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important
  }

  .p-md-1 {
    padding: .25rem !important
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important
  }

  .p-md-2 {
    padding: .5rem !important
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important
  }

  .p-md-3 {
    padding: 1rem !important
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important
  }

  .p-md-4 {
    padding: 1.5rem !important
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important
  }

  .p-md-5 {
    padding: 3rem !important
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important
  }

  .m-md-n1 {
    margin: -.25rem !important
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important
  }

  .m-md-n2 {
    margin: -.5rem !important
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.5rem !important
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.5rem !important
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.5rem !important
  }

  .m-md-n3 {
    margin: -1rem !important
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important
  }

  .m-md-n4 {
    margin: -1.5rem !important
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important
  }

  .m-md-n5 {
    margin: -3rem !important
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important
  }
}

@media (min-width:992px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important
  }

  .m-lg-1 {
    margin: .25rem !important
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important
  }

  .m-lg-2 {
    margin: .5rem !important
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important
  }

  .m-lg-3 {
    margin: 1rem !important
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important
  }

  .m-lg-4 {
    margin: 1.5rem !important
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important
  }

  .m-lg-5 {
    margin: 3rem !important
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important
  }

  .p-lg-1 {
    padding: .25rem !important
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important
  }

  .p-lg-2 {
    padding: .5rem !important
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important
  }

  .p-lg-3 {
    padding: 1rem !important
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important
  }

  .p-lg-4 {
    padding: 1.5rem !important
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important
  }

  .p-lg-5 {
    padding: 3rem !important
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important
  }

  .m-lg-n1 {
    margin: -.25rem !important
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important
  }

  .m-lg-n2 {
    margin: -.5rem !important
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.5rem !important
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.5rem !important
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.5rem !important
  }

  .m-lg-n3 {
    margin: -1rem !important
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important
  }

  .m-lg-n4 {
    margin: -1.5rem !important
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important
  }

  .m-lg-n5 {
    margin: -3rem !important
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important
  }
}

@media (min-width:1200px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important
  }

  .m-xl-1 {
    margin: .25rem !important
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important
  }

  .m-xl-2 {
    margin: .5rem !important
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important
  }

  .m-xl-3 {
    margin: 1rem !important
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important
  }

  .m-xl-4 {
    margin: 1.5rem !important
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important
  }

  .m-xl-5 {
    margin: 3rem !important
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important
  }

  .p-xl-1 {
    padding: .25rem !important
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important
  }

  .p-xl-2 {
    padding: .5rem !important
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important
  }

  .p-xl-3 {
    padding: 1rem !important
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important
  }

  .p-xl-4 {
    padding: 1.5rem !important
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important
  }

  .p-xl-5 {
    padding: 3rem !important
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important
  }

  .m-xl-n1 {
    margin: -.25rem !important
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important
  }

  .m-xl-n2 {
    margin: -.5rem !important
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.5rem !important
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.5rem !important
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.5rem !important
  }

  .m-xl-n3 {
    margin: -1rem !important
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important
  }

  .m-xl-n4 {
    margin: -1.5rem !important
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important
  }

  .m-xl-n5 {
    margin: -3rem !important
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0)
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
  text-align: justify !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

@media (min-width:576px) {
  .text-sm-left {
    text-align: left !important
  }

  .text-sm-right {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width:768px) {
  .text-md-left {
    text-align: left !important
  }

  .text-md-right {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media (min-width:992px) {
  .text-lg-left {
    text-align: left !important
  }

  .text-lg-right {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width:1200px) {
  .text-xl-left {
    text-align: left !important
  }

  .text-xl-right {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-light {
  font-weight: 300 !important
}

.font-weight-lighter {
  font-weight: lighter !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-weight-bolder {
  font-weight: bolder !important
}

.font-italic {
  font-style: italic !important
}

.text-white {
  color: #fff !important
}

.text-primary {
  color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important
}

.text-secondary {
  color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important
}

.text-success {
  color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important
}

.text-info {
  color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important
}

.text-warning {
  color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important
}

.text-danger {
  color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important
}

.text-light {
  color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important
}

.text-dark {
  color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important
}

.text-body {
  color: #212529 !important
}

.text-muted {
  color: #6c757d !important
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.text-decoration-none {
  text-decoration: none !important
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important
}

.text-reset {
  color: inherit !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

@media print {

  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important
  }

  a:not(.btn) {
    text-decoration: underline
  }

  abbr[title]::after {
    content: " (" attr(title) ")"
  }

  pre {
    white-space: pre-wrap !important
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid
  }

  img,
  tr {
    page-break-inside: avoid
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }

  @page {
    size: a3
  }

  body {
    min-width: 992px !important
  }

  .container {
    min-width: 992px !important
  }

  .navbar {
    display: none
  }

  .badge {
    border: 1px solid #000
  }

  .table {
    border-collapse: collapse !important
  }

  .table td,
  .table th {
    background-color: #fff !important
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important
  }

  .table-dark {
    color: inherit
  }

  .table-dark tbody+tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6
  }
}

.navbarNavAltMarkup-inner{

  display: none;
}
@media (min-width: 1000px) {
  .navbarNavAltMarkup-inner {
    display: block;
  }
}