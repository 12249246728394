

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

:root {
    --color-primary: #059DFF;
    --color-primary-rgba: rgb(5, 157, 255);
    --color-primary-rgba-2: rgba(5, 157, 255, 0.15);
    --color-primary-alt: #059DFF;
    --color-primary-light: #059DFF;
    --color-primary-lightest: #059DFF;
    --color-secondary: #FB5343;
    --color-tertiary: #6549D5;
    --color-tertiary-alt: #6549D5;
    --color-pink: #E33FA1;
    --color-light-green: #36C6F0;
    --color-primary-darker: #011827;
    --color-tertiary-darker: #150b40;
    --color-secondary-darker: #190301;
    --color-light-green-darker: #001f27;
    --color-pink-darker: #2f011d;
    --color-heading: #ffffff;
    --color-body: #acacac;
    --color-dark: #060606;
    --color-darker: rgba(6, 6, 6, 0.7);
    --color-darkest: rgba(0, 0, 0, 0.3);
    --color-black: #000000;
    --color-black-off: rgba(0, 0, 0, 0.2);
    --color-blacker: rgba(15, 15, 17, 0);
    --color-blackest: #0f0f11;
    --color-border: hsla(0, 0%, 100%, 0.1);
    --color-lessdark: #1e1e1e;
    --color-gray: #65676B;
    --color-midgray: #878787;
    --color-light: #E4E6EA;
    --color-lighter: #CED0D4;
    --color-lightest: #F0F2F5;
    --color-white: #ffffff;
    --color-white-off: rgba(255, 255, 255, 0.05);
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;
    --color-extra01: #666666;
    --color-extra02: #606770;
    --color-extra03: #FBFBFD;
    --color-extra04: #1A1A1A;
    --color-extra05: #242424;
    --color-extra06: #111424;
    --border-width: 2px;
    --radius-small: 6px;
    --radius: 10px;
    --radius-big: 16px;
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;
    --shadow-primary: 0 0 20px 5px rgba(251, 83, 67, 0.2);
    --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
    --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);
    --transition: 0.3s;
    --transition-transform: transform .65s cubic-bezier(.23, 1, .32, 1);
    --font-primary: "Inter", sans-serif;
    --secondary-font: "Inter", sans-serif;
    --font-awesome: "Font Awesome 5 Pro";
    --font-size-b1: 18px;
    --font-size-b2: 16px;
    --font-size-b3: 14px;
    --font-size-b4: 12px;
    --line-height-b1: 1.67;
    --line-height-b2: 1.7;
    --line-height-b3: 1.6;
    --line-height-b4: 1.3;
    --h1: 56px;
    --h2: 44px;
    --h3: 36px;
    --h4: 24px;
    --h5: 18px;
    --h6: 16px;
}

/**
 * Reset Styels
 */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

a {
    color: var(--color-heading);
    text-decoration: none;
    outline: none;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: var(--color-primary);
}

a:focus {
    outline: none;
}


menu,
ol,
ul {
    margin: 16px 0;
    padding: 0 0 0 40px;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

li>ul,
li>ol {
    margin: 0;
}

ol ul {
    margin-bottom: 0;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 1px solid var(--color-border);
    margin: 0 2px;
    min-width: inherit;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type=checkbox],
input[type=radio] {
    padding: 0;
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    appearance: textfield;
    padding-right: 2px;
    width: 270px;
}

input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}



/**
 * Typography
 */

* {
    box-sizing: border-box;
}

html {
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    font-size: 10px;
}

body {
    overflow: auto;
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-primary);
    color: var(--color-body);
    font-weight: var(--p-regular);
    background-color: var(--color-dark);
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
    margin: 0;
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    word-break: break-word;
    font-family: var(--secondary-font);
    line-height: 1.4074;
    color: var(--color-heading);
}

h1,
.h1 {
    font-size: var(--h1);
    line-height: 1.19;
}

h2,
.h2 {
    font-size: var(--h2);
    line-height: 1.23;
}

h3,
.h3 {
    font-size: var(--h3);
    line-height: 1.4;
}

h4,
.h4 {
    font-size: var(--h4);
    line-height: 1.25;
}

h5,
.h5 {
    font-size: var(--h5);
    line-height: 1.24;
}

h6,
.h6 {
    font-size: var(--h6);
    line-height: 1.25;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
    color: inherit;
    transition: 0.5s;
}

a {
    color: var(--color-body);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    h1,
    .h1 {
        font-size: 38px;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    h3,
    .h3 {
        font-size: 28px;
    }

    h4,
    .h4 {
        font-size: 24px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) {

    h1,
    .h1 {
        font-size: 34px;
    }

    h2,
    .h2 {
        font-size: 28px;
    }

    h3,
    .h3 {
        font-size: 24px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 16px;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5 {
    font-weight: var(--p-bold);
}

h6,
.h6 {
    font-weight: var(--p-medium);
}

p {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    font-weight: var(--p-regular);
    color: var(--color-body);
    margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
    p {
        margin: 0 0 20px;
        font-size: var(--font-size-b2);
        line-height: var(--line-height-b2);
    }
}

p.has-large-font-size {
    line-height: 1.5;
    font-size: 36px;
}

p.has-medium-font-size {
    font-size: 24px;
    line-height: 36px;
}

p.has-small-font-size {
    font-size: 13px;
}

p.has-very-light-gray-color {
    color: var(--color-white);
}

p.has-background {
    padding: 20px 30px;
}

p.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
}

p.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
}

p.b3 {
    font-size: var(--font-size-b3);
    line-height: var(--line-height-b3);
}

p.b4 {
    font-size: var(--font-size-b4);
    line-height: var(--line-height-b4);
}

.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
}

.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
}

.b3 {
    font-size: var(--font-size-b3);
    line-height: var(--line-height-b3);
}

.b4 {
    font-size: var(--font-size-b4);
    line-height: var(--line-height-b4);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 40px;
    width: 100%;
}

table a,
table a:link,
table a:visited {
    text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
    color: var(--color-heading);
}

var {
    font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/

ul,
ol {
    padding-left: 18px;
}

ul {
    list-style: square;
    margin-bottom: 30px;
    padding-left: 20px;
}

ul.liststyle.bullet li {
    font-size: 18px;
    line-height: 30px;
    color: var(--color-body);
    position: relative;
    padding-left: 30px;
}

@media only screen and (max-width: 767px) {
    ul.liststyle.bullet li {
        padding-left: 19px;
    }
}

ul.liststyle.bullet li::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: var(--color-body);
    left: 0;
    top: 10px;
}

ul.liststyle.bullet li+li {
    margin-top: 8px;
}

ul li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--color-body);
    font-weight: 400;
}

ul li a {
    text-decoration: none;
    color: var(--color-body);
}

ul li a:hover {
    color: var(--color-primary);
}

ul ul {
    margin-bottom: 0;
}

ol {
    margin-bottom: 30px;
}

ol li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    color: var(--color-gray);
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}

ol li a {
    color: var(--heading-color);
    text-decoration: none;
}

ol li a:hover {
    color: var(--color-primary);
}

ol ul {
    padding-left: 30px;
}

hr {
    display: block;
    height: 0;
    margin: 15px 0;
    border-top: 1px solid var(--color-border);
    background: transparent;
}

kbd {
    background: var(--color-primary);
}

/*=====================
All Extend Here
=======================*/

.color-primary {
    color: var(--color-primary) !important;
}

.color-primary-light {
    color: var(--color-primary-light) !important;
}

.color-primary-alt {
    color: var(--color-primary-alt) !important;
}

.color-secondary {
    color: var(--color-secondary) !important;
}

.color-tertiary {
    color: var(--color-tertiary) !important;
}

.color-tertiary-alt {
    color: var(--color-tertiary-alt) !important;
}

.color-light-green {
    color: var(--color-light-green) !important;
}

.color-pink {
    color: var(--color-pink) !important;
}

.color-primary-darker {
    color: var(--color-primary-darker) !important;
}

.color-secondary-darker {
    color: var(--color-secondary-darker) !important;
}

.color-light-green-darker {
    color: var(--color-light-green-darker) !important;
}

.color-pink-darker {
    color: var(--color-pink-darker) !important;
}

.color-heading {
    color: var(--color-heading) !important;
}

.color-body {
    color: var(--color-body) !important;
}

.color-dark {
    color: var(--color-dark) !important;
}

.color-darker {
    color: var(--color-darker) !important;
}

.color-darkest {
    color: var(--color-darkest) !important;
}

.color-black {
    color: var(--color-black) !important;
}

.color-blacker {
    color: var(--color-blacker) !important;
}

.color-blackest {
    color: var(--color-blackest) !important;
}

.color-border {
    color: var(--color-border) !important;
}

.color-gray {
    color: var(--color-gray) !important;
}

.color-midgray {
    color: var(--color-midgray) !important;
}

.color-light {
    color: var(--color-light) !important;
}

.color-lighter {
    color: var(--color-lighter) !important;
}

.color-lightest {
    color: var(--color-lightest) !important;
}

.color-white {
    color: var(--color-white) !important;
}

[data-overlay]:before {
    background: var(--color-primary);
}

/* Radius */

.filter-button-default button,
.rainbow-card .inner .thumbnail .card-image img,
.rainbow-card .inner .thumbnail .card-image a,
.radius-small {
    border-radius: var(--radius-small);
}

.radius-small {
    border-radius: var(--radius-small);
}

.radius,
.post-scale {
    border-radius: var(--radius);
}

.radius-big {
    border-radius: var(--radius-big);
}

/* Font Weight */

.w-300 {
    font-weight: 300 !important;
}

.w-400 {
    font-weight: 400 !important;
}

.w-500 {
    font-weight: 500 !important;
}

.w-600 {
    font-weight: 600 !important;
}

.w-700 {
    font-weight: 700 !important;
}

.w-800 {
    font-weight: 800 !important;
}

.w-900 {
    font-weight: 900 !important;
}

/* Shadows */

.shadow-primary {
    box-shadow: var(--shadow-primary);
}

.shadow-light {
    box-shadow: var(--shadow-light);
}

.shadow-lighter {
    box-shadow: var(--shadow-lighter);
}

/*=============== Style Css =============*/

.rainbow-meta-list,
.advance-pricing .pricing-right .plan-offer-list,
.advance-brand,
.breadcrumb-inner ul.page-list {
    padding: 0;
    margin: 0;
    list-style: none;
}


.bg_image,
.bgImagePosition {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/*----------------------
    Animation Css  
-----------------------*/

.post-scale {
    overflow: hidden;
}

.post-scale img {
    transition: 0.5s;
}

.post-scale:hover img {
    transform: scale(1.1);
}

@keyframes customOne {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

@keyframes customTwo {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(100px, 0px);
    }

    100% {
        transform: translate(50px, 50px);
    }
}

.customOne {
    animation: customOne 2s infinite;
}





/*===========================
    Background Image 
=============================*/


.bg_image--23 {
    background-image: url("https://www.verdict.co.uk/wp-content/uploads/2021/04/shutterstock_1583248045.jpg");
    background-size: cover;
    width: 100%;
    width: auto;
    height: fit-content;
}



.fullscreen {
    min-height: 100vh;
    width: 100%;
}





/*=============================
	Overlay styles 
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
    position: relative;
}

[data-overlay]>div,
[data-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>* {
    position: relative;
    z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

[data-black-overlay]:before {
    background-color: #000000;
}

[data-white-overlay]:before {
    background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
    opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
    opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
    opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
    opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
    opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
    opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
    opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
    opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
    opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
    opacity: 1;
}





/*======================== 
    Common Style Css
==========================*/

/* Theme Gradient */

.theme-gradient,
.counter-style-1 .count-number {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-lessdark-gradient {
    background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
}

.bg-theme-gradient {
    background-color: transparent;
    background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary-alt) 90%);
}

.bg-primary-gradient {
    background-color: var(--color-primary);
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .theme-gradient,
    .counter-style-1 .count-number {
        color: var(--color-secondary);
        background: none !important;
    }
}

/* Heading Font  */

.h1 {
    font-size: var(--h1) !important;
}

.h2 {
    font-size: var(--h2) !important;
}

.h3 {
    font-size: var(--h3) !important;
}

.h4 {
    font-size: var(--h4) !important;
}

.h5 {
    font-size: var(--h5) !important;
}

.h6 {
    font-size: var(--h6) !important;
}

/* Text Color  */

.bg-primary-color {
    background: var(--color-primary);
}

.bg-secondary-color {
    background: var(--color-secondary);
}

.bg-tertiary-color {
    background-color: var(--color-tertiary);
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary-light {
    background-color: var(--color-primary-light) !important;
}

.bg-color-primary-alt {
    background-color: var(--color-primary-alt) !important;
}

.bg-color-secondary {
    background-color: var(--color-secondary) !important;
}

.bg-color-tertiary {
    background-color: var(--color-tertiary) !important;
}

.bg-color-tertiary-alt {
    background-color: var(--color-tertiary-alt) !important;
}

.bg-color-light-green {
    background-color: var(--color-light-green) !important;
}

.bg-color-pink {
    background-color: var(--color-pink) !important;
}

.bg-color-primary-darker {
    background-color: var(--color-primary-darker) !important;
}

.bg-color-secondary-darker {
    background-color: var(--color-secondary-darker) !important;
}

.bg-color-light-green-darker {
    background-color: var(--color-light-green-darker) !important;
}

.bg-color-pink-darker {
    background-color: var(--color-pink-darker) !important;
}

.bg-color-heading {
    background-color: var(--color-heading) !important;
}

.bg-color-body {
    background-color: var(--color-body) !important;
}

.bg-color-dark {
    background-color: var(--color-dark) !important;
}

.bg-color-darker {
    background-color: var(--color-darker) !important;
}

.bg-color-darkest {
    background-color: var(--color-darkest) !important;
}

.bg-color-black {
    background-color: var(--color-black) !important;
}

.bg-color-blacker {
    background-color: var(--color-blacker) !important;
}

.bg-color-blackest {
    background-color: var(--color-blackest) !important;
}

.bg-color-border {
    background-color: var(--color-border) !important;
}

.bg-color-gray {
    background-color: var(--color-gray) !important;
}

.bg-color-midgray {
    background-color: var(--color-midgray) !important;
}

.bg-color-light {
    background-color: var(--color-light) !important;
}

.bg-color-lighter {
    background-color: var(--color-lighter) !important;
}

.bg-color-lightest {
    background-color: var(--color-lightest) !important;
}

.bg-color-white {
    background-color: var(--color-white) !important;
}




/**
 * Settings
 */

/**
  * Easings
  */

/**
  * Core
  */

[data-sal] {
    transition-duration: 0.2s;
    transition-delay: 0s;
    transition-duration: var(--sal-duration, 0.2s);
    transition-delay: var(--sal-delay, 0s);
    transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
    transition-duration: 0.2s;
}

[data-sal][data-sal-duration="250"] {
    transition-duration: 0.25s;
}

[data-sal][data-sal-duration="300"] {
    transition-duration: 0.3s;
}

[data-sal][data-sal-duration="350"] {
    transition-duration: 0.35s;
}

[data-sal][data-sal-duration="400"] {
    transition-duration: 0.4s;
}

[data-sal][data-sal-duration="450"] {
    transition-duration: 0.45s;
}

[data-sal][data-sal-duration="500"] {
    transition-duration: 0.5s;
}

[data-sal][data-sal-duration="550"] {
    transition-duration: 0.55s;
}

[data-sal][data-sal-duration="600"] {
    transition-duration: 0.6s;
}

[data-sal][data-sal-duration="650"] {
    transition-duration: 0.65s;
}

[data-sal][data-sal-duration="700"] {
    transition-duration: 0.7s;
}

[data-sal][data-sal-duration="750"] {
    transition-duration: 0.75s;
}

[data-sal][data-sal-duration="800"] {
    transition-duration: 0.8s;
}

[data-sal][data-sal-duration="850"] {
    transition-duration: 0.85s;
}

[data-sal][data-sal-duration="900"] {
    transition-duration: 0.9s;
}

[data-sal][data-sal-duration="950"] {
    transition-duration: 0.95s;
}

[data-sal][data-sal-duration="1000"] {
    transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
    transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
    transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
    transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
    transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
    transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
    transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
    transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
    transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
    transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
    transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
    transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
    transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
    transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
    transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
    transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
    transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
    transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
    transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
    transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
    transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
    transition-delay: 0.05s;
}

[data-sal][data-sal-delay="100"] {
    transition-delay: 0.1s;
}

[data-sal][data-sal-delay="150"] {
    transition-delay: 0.15s;
}

[data-sal][data-sal-delay="200"] {
    transition-delay: 0.2s;
}

[data-sal][data-sal-delay="250"] {
    transition-delay: 0.25s;
}

[data-sal][data-sal-delay="300"] {
    transition-delay: 0.3s;
}

[data-sal][data-sal-delay="350"] {
    transition-delay: 0.35s;
}

[data-sal][data-sal-delay="400"] {
    transition-delay: 0.4s;
}

[data-sal][data-sal-delay="450"] {
    transition-delay: 0.45s;
}

[data-sal][data-sal-delay="500"] {
    transition-delay: 0.5s;
}

[data-sal][data-sal-delay="550"] {
    transition-delay: 0.55s;
}

[data-sal][data-sal-delay="600"] {
    transition-delay: 0.6s;
}

[data-sal][data-sal-delay="650"] {
    transition-delay: 0.65s;
}

[data-sal][data-sal-delay="700"] {
    transition-delay: 0.7s;
}

[data-sal][data-sal-delay="750"] {
    transition-delay: 0.75s;
}

[data-sal][data-sal-delay="800"] {
    transition-delay: 0.8s;
}

[data-sal][data-sal-delay="850"] {
    transition-delay: 0.85s;
}

[data-sal][data-sal-delay="900"] {
    transition-delay: 0.9s;
}

[data-sal][data-sal-delay="950"] {
    transition-delay: 0.95s;
}

[data-sal][data-sal-delay="1000"] {
    transition-delay: 1s;
}

[data-sal][data-sal-easing=linear] {
    transition-timing-function: linear;
}

[data-sal][data-sal-easing=ease] {
    transition-timing-function: ease;
}

[data-sal][data-sal-easing=ease-in] {
    transition-timing-function: ease-in;
}

[data-sal][data-sal-easing=ease-out] {
    transition-timing-function: ease-out;
}

[data-sal][data-sal-easing=ease-in-out] {
    transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing=ease-in-cubic] {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[data-sal][data-sal-easing=ease-out-cubic] {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-out-cubic] {
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-circ] {
    transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

[data-sal][data-sal-easing=ease-out-circ] {
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

[data-sal][data-sal-easing=ease-in-out-circ] {
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

[data-sal][data-sal-easing=ease-in-expo] {
    transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

[data-sal][data-sal-easing=ease-out-expo] {
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

[data-sal][data-sal-easing=ease-in-out-expo] {
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing=ease-in-quad] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-sal][data-sal-easing=ease-out-quad] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-sal][data-sal-easing=ease-in-out-quad] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-sal][data-sal-easing=ease-in-quart] {
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

[data-sal][data-sal-easing=ease-out-quart] {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

[data-sal][data-sal-easing=ease-in-out-quart] {
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-sal][data-sal-easing=ease-in-quint] {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

[data-sal][data-sal-easing=ease-out-quint] {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

[data-sal][data-sal-easing=ease-in-out-quint] {
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

[data-sal][data-sal-easing=ease-in-sine] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-sal][data-sal-easing=ease-out-sine] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-sal][data-sal-easing=ease-in-out-sine] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-sal][data-sal-easing=ease-in-back] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-sal][data-sal-easing=ease-out-back] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-sal][data-sal-easing=ease-in-out-back] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/**
  * Animations
  */

[data-sal|=fade] {
    opacity: 0;
    transition-property: opacity;
}

[data-sal|=fade].sal-animate,
body.sal-disabled [data-sal|=fade] {
    opacity: 1;
}

[data-sal|=slide] {
    opacity: 0;
    transition-property: opacity, transform;
}

[data-sal=slide-up] {
    transform: translateY(20%);
}

[data-sal=slide-down] {
    transform: translateY(-20%);
}

[data-sal=slide-left] {
    transform: translateX(20%);
}

[data-sal=slide-right] {
    transform: translateX(-20%);
}

[data-sal|=slide].sal-animate,
body.sal-disabled [data-sal|=slide] {
    opacity: 1;
    transform: none;
}

[data-sal|=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
}

[data-sal=zoom-in] {
    transform: scale(0.5);
}

[data-sal=zoom-out] {
    transform: scale(1.1);
}

[data-sal|=zoom].sal-animate,
body.sal-disabled [data-sal|=zoom] {
    opacity: 1;
    transform: none;
}

[data-sal|=flip] {
    backface-visibility: hidden;
    transition-property: transform;
}

[data-sal=flip-left] {
    transform: perspective(2000px) rotateY(-91deg);
}

[data-sal=flip-right] {
    transform: perspective(2000px) rotateY(91deg);
}

[data-sal=flip-up] {
    transform: perspective(2000px) rotateX(-91deg);
}

[data-sal=flip-down] {
    transform: perspective(2000px) rotateX(91deg);
}

[data-sal|=flip].sal-animate,
body.sal-disabled [data-sal|=flip] {
    transform: none;
}

.cd-intro {
    margin: 4em auto;
}

@media only screen and (min-width: 768px) {
    .cd-intro {
        margin: 5em auto;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-intro {
        margin: 6em auto;
    }
}

.cd-headline {
    font-size: 3rem;
    line-height: 1.2;
}

@media only screen and (min-width: 768px) {
    .cd-headline {
        font-size: 4.4rem;
        font-weight: 300;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-headline {
        font-size: 48px;
    }
}

@media only screen and (max-width: 768px) {
    .cd-headline {
        font-size: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .cd-headline {
        font-size: 26px;
    }
}

.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

/* -------------------------------- 

xclip 

-------------------------------- */

.cd-headline.clip span {
    display: inline-block;
    padding: 0;
}

.cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: middle;
}

.cd-headline.clip .cd-words-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 80%;
    background-color: var(--color-secondary);
    transform: translateY(-50%);
}

.cd-headline.clip b {
    opacity: 0;
}

.cd-headline.clip b.is-visible {
    opacity: 1;
}





/* Button Styles  */

.button-group {
    margin: -10px;
}

.button-group a,
.button-group button {
    margin: 10px;
}

a.btn-default,
button.btn-default,
div.btn-default {
    padding: 0 28px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 8px;
    background-color: transparent;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    transition-duration: 300ms;
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-shadow: var(--shadow-light);
    display: inline-block;
    height: 50px;
    line-height: 47px;
    letter-spacing: 0.5px;
    width: auto;
    transition: 0.4s;
}

a.btn-default.btn-icon .icon,
button.btn-default.btn-icon .icon,
div.btn-default.btn-icon .icon {
    position: relative;
    top: 2px;
    padding-left: 4px;
}

a.btn-default.btn-icon .icon i,
button.btn-default.btn-icon .icon i,
div.btn-default.btn-icon .icon i {
    width: 20px;
    height: 20px;
}

a.btn-default:hover,
button.btn-default:hover,
div.btn-default:hover {
    box-shadow: var(--shadow-primary);
    -webkit-filter: brightness(125%);
    filter: brightness(125%);
    color: var(--color-white);
    text-decoration: none;
}

a.btn-default.btn-small,
button.btn-default.btn-small,
div.btn-default.btn-small {
    height: 40px;
    line-height: 36px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
}

a.btn-default.btn-medium,
button.btn-default.btn-medium,
div.btn-default.btn-medium {
    height: 65px;
    line-height: 61px;
    padding: 0 40px;
}

a.btn-default.btn-large,
button.btn-default.btn-large,
div.btn-default.btn-large {
    height: 60px;
    line-height: 56px;
    padding: 0 37px;
    font-size: 17px;
}

@media only screen and (max-width: 767px) {

    a.btn-default.btn-large,
    button.btn-default.btn-large,
    div.btn-default.btn-large {
        padding: 0 25px;
    }
}

a.btn-default.btn-extra-large,
button.btn-default.btn-extra-large,
div.btn-default.btn-extra-large {
    height: 90px;
    line-height: 90px;
    padding: 0 70px;
    font-size: 20px;
}

@media only screen and (max-width: 767px) {

    a.btn-default.btn-extra-large,
    button.btn-default.btn-extra-large,
    div.btn-default.btn-extra-large {
        padding: 0 44px;
    }
}

a.btn-default.btn-border,
button.btn-default.btn-border,
div.btn-default.btn-border {
    background-image: inherit;
    border-color: var(--color-border);
    border-width: 2px;
    border-style: solid;
}

a.btn-default.btn-border:hover,
button.btn-default.btn-border:hover,
div.btn-default.btn-border:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

a.btn-default.text-underline,
button.btn-default.text-underline,
div.btn-default.text-underline {
    background-image: inherit;
    border-color: var(--color-white);
}

a.btn-default.text-underline span,
button.btn-default.text-underline span,
div.btn-default.text-underline span {
    position: relative;
    display: inline-block;
    line-height: 30px;
}

a.btn-default.text-underline:hover,
button.btn-default.text-underline:hover,
div.btn-default.text-underline:hover {
    box-shadow: none;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

a.btn-default.round,
button.btn-default.round,
div.btn-default.round {
    border-radius: 100px;
}

a.btn-default.square,
button.btn-default.square,
div.btn-default.square {
    border-radius: 0;
}

a.btn-default.rounded-player,
button.btn-default.rounded-player,
div.btn-default.rounded-player {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    padding: 0;
    line-height: 100px;
    font-size: 30px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {

    a.btn-default.rounded-player,
    button.btn-default.rounded-player,
    div.btn-default.rounded-player {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }
}

a.btn-default.rounded-player span i,
button.btn-default.rounded-player span i,
div.btn-default.rounded-player span i {
    position: relative;
    top: 1px;
    left: 2px;
}

a.btn-default.rounded-player.border,
button.btn-default.rounded-player.border,
div.btn-default.rounded-player.border {
    background-image: inherit;
    border-color: var(--color-white) !important;
    border-width: 2px !important;
}

a.btn-default.rounded-player.border:hover,
button.btn-default.rounded-player.border:hover,
div.btn-default.rounded-player.border:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary) !important;
}

a.btn-default.rounded-player.sm-size,
button.btn-default.rounded-player.sm-size,
div.btn-default.rounded-player.sm-size {
    width: 70px;
    height: 70px;
    line-height: 70px;
}

.btn-read-more span {
    position: relative;
    color: var(--color-heading);
    display: inline-block;
    line-height: 35px;
    transition: 0.3s;
}

.btn-read-more span::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: var(--color-border);
    transition: 0.3s;
    width: 100%;
    bottom: 3px;
}

.btn-read-more span::before {
    position: absolute;
    content: "";
    left: auto;
    bottom: 2px;
    background: var(--color-primary);
    width: 0;
    height: 2px;
    transition: 0.3s;
    right: 0;
}

.btn-read-more:hover span {
    color: var(--color-primary);
}

.btn-read-more:hover span::before {
    width: 100%;
    left: 0;
    right: auto;
}

.rainbow-badge {
    display: inline-block;
    background-color: var(--color-primary);
    padding: 0 16px;
    height: 30px;
    line-height: 30px;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.rainbow-badge-card {
    background-color: transparent;
    background: transparent;
    padding: 1px 10px;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    z-index: 2;
    letter-spacing: 0.5px;
}

.rainbow-badge-card::before {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    border-radius: 100px;
    opacity: 0.5;
}

.btn-collaborate-play i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 500px;
    display: inline-block;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

.btn-collaborate-play span {
    display: inline-block;
}

.btn-collaborate-play {
    height: 30px;
    width: 30px;
    line-height: 30px;
    align-items: center;
    transition: var(--transition);
}

.btn-collaborate-play span {
    margin-left: 15px;
}

.btn-collaborate-play:hover {
    color: var(--color-white);
}






/*---------------------
    Portfolio Styles  
-----------------------*/
 .rainbow-portfolio-area {
    position: relative;
    overflow: hidden;
  
}

.rainbow-card {
    overflow: hidden;
} 

/* .rainbow-card .inner .thumbnail {
    position: relative;
} 

 .rainbow-card .inner .content{
    position: relative;
  
  top:100px;
}

.rainbow-card .inner .thumbnail .card-image {
    overflow: hidden;
}

.rainbow-card .inner .thumbnail .card-image a {
    display: block;
    overflow: hidden;
}

.rainbow-card .inner .thumbnail .card-image img {
    overflow: hidden;
} 

.rainbow-card .inner .content {
    padding-top: 20px;

}

.rainbow-card .inner .content .subtitle {
    text-transform: capitalize;
}

.rainbow-card:hover {
    transform: translateY(-4px);
}

.rainbow-card:hover .inner .thumbnail .card-image img {
    transform: scale(1.05);
} 





/*-----------------------------
    Filter Button Style  
-------------------------------*/

.filter-button-default {
    display: flex;
    justify-content: center;
    margin: 0 -5px;
    flex-wrap: wrap;
}

.filter-button-default button {
    border: 0 none;
    padding: 10px 20px;
    text-transform: capitalize;
    background: transparent;
    color: var(--color-body);
    margin: 0 5px;
}

@media only screen and (max-width: 767px) {
    .filter-button-default button {
        padding: 10px 10px;
    }
}

.filter-button-default button.is-checked,
.filter-button-default button:hover {
    background-color: var(--color-primary);
    color: var(--color-white) !important;
}


/*----------------------------------
    Portfolio Grid Layout  
----------------------------------*/

.grid-metro2 {
    margin: 0 -15px;
}

.grid-metro2 .portfolio-2 {
    width: 50%;
    padding: 0 15px;
    margin-top: 30px;
}

@media only screen and (max-width: 575px) {
    .grid-metro2 .portfolio-2 {
        width: 100%;
    }
}

.grid-metro2 .resizer {
    width: 50%;
}

@media only screen and (max-width: 575px) {
    .grid-metro2 .resizer {
        width: 100%;
    }
}

.grid-metro3 {
    margin: 0 -15px;
}

.grid-metro3 .portfolio-3 {
    width: 33.33%;
    padding: 0 15px;
    margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro3 .portfolio-3 {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro3 .portfolio-3 {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro3 .portfolio-3 {
        width: 100%;
    }
}

.grid-metro3 .resizer {
    width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro3 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro3 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro3 .resizer {
        width: 100%;
    }
}

.grid-metro4 {
    margin: 0 -15px;
}

.grid-metro4 .portfolio-4 {
    width: 25%;
    padding: 0 15px;
    margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro4 .portfolio-4 {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro4 .portfolio-4 {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro4 .portfolio-4 {
        width: 100%;
    }
}

.grid-metro4 .resizer {
    width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro4 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro4 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro4 .resizer {
        width: 100%;
    }
}

.grid-metro4.no-gutter {
    margin: 0;
}

.grid-metro4.no-gutter .portfolio-4 {
    padding: 0;
    margin-top: 0;
}

.grid-metro4.no-gutter .rainbow-card {
    border-radius: 0;
    border: 0 none;
}

.no-gutter .rainbow-card .inner .thumbnail a img,
.no-gutter .rainbow-card .inner .thumbnail a {
    border-radius: 0;
}

.no-gutter .rainbow-card:hover {
    transform: none;
}



/*----------------------
    Blog Styles  
-----------------------*/

.rainbow-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 450px;
    word-wrap: break-word;
    /* border: 1px solid red; */
    background-color: var(--color-blackest);
    background-clip: border-box;
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
    border-radius: var(--radius);
    /* overflow: hidden; */
    transition: all 0.5s;
}


.rainbow-card .inner .content {
    /* display: flex; */
    /* flex-wrap: wrap; */
    padding: 30px;
    /* padding-top: 30px; */
    margin-top: 220px;
}

/*-----------------------------
    Portfolio details  
--------------------------------*/

.rainbow-portfolio-details .content-left .title {
    font-size: 38px;
    margin-bottom: 33px;
    
}

@media only screen and (max-width: 767px) {
    .rainbow-portfolio-details .content-left .title {
        font-size: 25px;
        margin-bottom: 20px;
    }
}

.rainbow-portfolio-details .content-left .single-list-wrapper {
    margin: -5px;
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list+.single-list {
    border-top: 1px solid var(--color-border);
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list label {
    font-size: 20px;
    color: #fff;
    display: inline-block;
    padding-right: 16px;
    margin: 0;
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list span {
    font-size: 18px;
}

.rainbow-portfolio-details .content-right .subtitle {
    font-size: 25px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.rainbow-portfolio-details .content-right .description p {
    margin-bottom: 10px;
}

.rainbow-portfolio-details .alignwide {
    margin-left: -100px;
    margin-right: -100px;
    max-width: 1400px;
    width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-portfolio-details .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-portfolio-details .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-portfolio-details .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}



/* over service */




.form-control {
    color: #233242 !important;
}

.form-control::-moz-placeholder {
    opacity: 0.5;
}

.form-control::placeholder {
    opacity: 0.5;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    cursor: no-drop;
}

.form-control:not(textarea) {
    height: 45px;

}

textarea.form-control {
    height: 140px;
}

.form-control:focus {
    border-color: #277b8f;
}

.form-group {
    position: relative;
    margin-top: 8px;
}

.form-group label {
    position: absolute;
    background-color: #fff;
    top: -13px;
    left: 12px;
    padding: 0 9px;
}
.fitoutform .form-group {
    margin-bottom: 1em !important;
}

.fitoutform .form-control {
    padding: 0;
    border: none;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #e5e3e3;
    border-radius: 0;
    box-shadow: none;
}



@media (max-width: 767px) {
    #popup_form_modal .fitoutform .form-group {
        margin-bottom: 0.8em !important;
    }

    .popup-contact-form .btn {
        margin-top: 10px;
        font-size: 14px;
        padding: 10px 35px;
    }

    #popup_form_modal.popup-form .title1 {
        font-size: 30px;

        color: #00f7ff;
    }

    #popup_form_modal.popup-form .popup-contact-form .social_list_sec ul {
        justify-content: center;
    }

    #popup_form_modal.popup-form {
        top: 0px !important;
    }

    #popup_form_modal.popup-form .modal-dialog {
        margin-top: 3.5rem;
    }
}


.form-control {
    background: #262626;
    border-radius: 2px;
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    min-height: 44px;
    border: unset;
    box-shadow: unset;
    min-width: 271px;
}

@media (max-width: 1119px) {
    .FtrFrm .form-control {
        min-width: 200px;
    }
}

.form-control {
    padding: 0;
    border: none;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #e5e3e3;
    border-radius: 0;
    box-shadow: none;
}

.brands-div {
    display: flex;
}

@media(max-width:768px) {
    .brands-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        text-align: center;
        margin-right: 60px;
    }
}

.content button {
    background-color: #059DFF;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 110px;
    height: 7vh;
}

.content {
    height: 30vh;
    
}

.heading-conatant {
    font-size: 18px;
    color: #059DFF;
    flex-wrap: wrap;
}
@media (max-width:520px) {
    .heading-conatant {
        margin-left: -250px;
    }
}


.main-modal .modal-butt {
    background-color: #03a9f4;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 130px;
    height: 6vh;
    font-size: 15px;
}


@media (max-width:768px) {
    .main-modal .modal-butt {
        padding-top: 10px;
    }
}

.main-modal .modal-butt:hover {
    background-color: blue;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 130px;
    height: 6vh;
    font-size: 15px;
}



.wrappers {
    width: 45%;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    color: black;
}

.wrappers .card-input input {
    border: 1px solid #C8D1DC;
    height: 7vh;
    color: black;
    border-radius: 5px;
}

.modal-body {
    width: 100%;
    /* border: 1px solid red; */
}
/* 
@media (max-width:530px) {
    .modal-body {
        width:100%;
    }
}


@media (max-width:430px) {
    .modal-body {
        width: 850px;
    }
}

@media (max-width:390px) {
    .modal-body {
        width: 770px;
    }
} */

.wrappers textarea {
    border: 1px solid #C8D1DC;
    height: 15vh;
    border-radius: 5px;
    color: black;
}

.card-form__inner {
    padding: 20px;
}

.wrappers input:placeholder-shown {
    color: rgba(0, 0, 0, 0.533);
}

.wrappers textarea:placeholder-shown {
    color: rgba(0, 0, 0, 0.533);
}

.card-form__button {
    height: 7vh;
    width: 120px;
    background-color: #059DFF;
    border: 1px solid transparent;
    color: white;
    border-radius: 5px;
}







