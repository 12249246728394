.testi-grid {
    position: relative;
    padding: 30px 20px;
    z-index: 2;
    -webkit-filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.05));
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.05));
    margin-top: 60px;
    border-radius: 20px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out
}

.news-container-inner {
    margin-top: 130px;
}

.testi-grid:before {
    content: "";
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-color: var(--white-color);
    z-index: -1
}

.testi-grid_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 30px
}

@media (max-width: 767px) {
    .testi-grid_wrapper {
        gap: 15px
    }
}

@media (max-width: 375px) {
    .testi-grid_wrapper {
        display: block
    }
}

.testi-grid_profile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out
}

.testi-grid_img {
    position: relative;
    min-width: 120px;
    display: block;
    margin: auto;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 2;
    margin: -50px 0 25px 0;
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%);
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%)
}

@media (max-width: 767px) {
    .testi-grid_img {
        min-width: 80px
    }
}

@media (max-width: 375px) {
    .testi-grid_img {
        -webkit-clip-path: polygon(6% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%);
        clip-path: polygon(6% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%)
    }
}

.testi-grid_img img {
    width: 120px;
    height: 120px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out
}

@media (max-width: 767px) {
    .testi-grid_img img {
        width: 80px;
        height: 80px
    }
}

.testi-grid_name {
    margin-bottom: 2px
}

.testi-grid_text {
    font-style: italic;
    margin-bottom: 10px
}

.testi-grid_desig {
    font-size: 14px;
    margin-bottom: -0.3rem
}

.testi-grid .star-icon i {
    font-size: 14px;
    color: #FFC700
}

@media (max-width: 767px) {
    .testi-grid .star-icon i {
        font-size: 10px
    }
}

.testi-grid .testi-quote {
    position: absolute;
    right: 70px;
    bottom: 30px;
    z-index: -1
}

.testi-grid_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
}

@media (max-width: 767px) {
    .testi-grid_wrapper {
        gap: 15px
    }
}

@media (max-width: 375px) {
    .testi-grid_wrapper {
        display: block
    }
}

.testi-grid_img {
    position: relative;
    min-width: 120px;
    display: block;
    margin: auto;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 2;
    margin: -50px 0 25px 0;
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%);
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%)
}

@media (max-width: 767px) {
    .testi-grid_img {
        min-width: 80px
    }
}

@media (max-width: 375px) {
    .testi-grid_img {
        -webkit-clip-path: polygon(6% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%);
        clip-path: polygon(6% 0%, 70% 0%, 100% 0, 100% 75%, 70% 100%, 30% 100%, 0 100%, 0% 30%)
    }
}

.testi-grid_img img {
    width: 120px;
    height: 120px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out
}

@media (max-width: 767px) {
    .testi-grid_img img {
        width: 80px;
        height: 80px
    }
}

.testi-grid .star-icon i {
    font-size: 14px;
    color: #FFC700
}

@media (max-width: 767px) {
    .testi-grid .star-icon i {
        font-size: 10px
    }
}

.testi-item .star-icon i {
    color: #FFC700;
    font-size: 12px
}

.testi-card-3 .star-icon {
    margin-bottom: 25px
}

.testi-card-3 .star-icon a {
    color: #FFC700
}


.testi-grid_text {
    font-style: italic;
    margin-bottom: 10px
}

.testi-grid_profile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out
}

.media-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.testi-grid_name {
    margin-bottom: 2px;
    color: blueviolet;
}

.testi-grid_desig {
    font-size: 14px;
    margin-bottom: -0.3rem;
    color: #666665f4;
}

.rowe-div {
    border: 1px solid #00FFFF;
    border-bottom-right-radius: 25%;
    border-top-left-radius: 30%;
}













.testimonials-style1-area {
    position: relative;
    display: block;
    background-image: -moz-linear-gradient(90deg, rgb(230, 254, 255) 0%, rgba(230, 254, 255, 0.4) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(230, 254, 255) 0%, rgba(230, 254, 255, 0.4) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(230, 254, 255) 0%, rgba(230, 254, 255, 0.4) 100%);
    padding: 150px 0 150px;
}

.testimonials-style1__content {
    position: relative;
    display: block;
    z-index: 100;
}


.single-testimonials-style1 {
    position: relative;
    display: block;
    background: black;
    padding: 20px 30px 50px;
    height: auto;
    border-radius: 15px;
    border: 1px solid #00FDFD;

}

.span-of-testi {
    color: rgba(255, 255, 255, 0.425);
}

.testimonials-new-div {
    display: flex !important;
    gap: 15px;
}

@media(max-width:768px) {
    .testimonials-new-div {
        display: flex !important;
        flex-direction: column;
        gap: 10px;
    }
}

.single-testimonials-style1 .client-info {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
}

.single-testimonials-style1 .client-info .img-box {
    width: 65px;
}

.single-testimonials-style1 .client-info .img-box img {
    width: 100%;
    border-radius: 50%;
}

.single-testimonials-style1 .client-info .title-box {
    position: relative;
    display: block;
    padding-left: 20px;
}

.single-testimonials-style1 .client-info .title-box h3 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin: 0 0 5px;
}

.single-testimonials-style1 .client-info .title-box span {}



.single-testimonials-style1 .text {
    position: relative;
    display: block;
}

.single-testimonials-style1 .text p {
    color: white;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
    font-family: var(--thm-font);
}

.single-testimonials-style1 .text .date-box {
    position: relative;
    display: block;
    padding-top: 16px;
}

.single-testimonials-style1 .text .date-box p {
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}


.testimonials-carousel_1 {
    position: relative;
    display: block;
}

.testimonials-carousel_1.owl-carousel .owl-stage-outer {
    padding-bottom: 0px;
    overflow: visible;
}

.testimonials-carousel_1.owl-carousel .owl-stage-outer .owl-item {
    opacity: 0;
    transition: all 500ms linear;
    transition-delay: 0.1s;
}

.testimonials-carousel_1.owl-carousel .owl-stage-outer .owl-item.active {
    opacity: 1;
}



/*** 
=============================================
   Testimonial Style2 Area Css
=============================================
***/
.testimonials-style2-area {
    position: relative;
    display: block;
    background: black;
    padding: 150px 0 0px;
}

.testimonials-style2-area.pdtop0 {
    padding-top: 0;
}

.testimonials-style2-area.pdbtm150 {
    padding-bottom: 150px;
}

.testimonials-style2-area .shape1 {
    position: absolute;
    top: -250px;
    right: -300px;
    opacity: 0.50;
}

.testimonials-style2-area .round-shape-box1 {
    position: absolute;
    top: -600px;
    right: -100px;
}

.testimonials-style2-area.pd-0-0-150 {
    padding: 0 0 150px;
}