/* Main container styling */
.Admin-control-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 30px 0px;
    gap: 30px;
    overflow: hidden;
}

.Admin-control-main-container h2 {
    color: white;
    font-weight: 700;
    font-size: 40px !important;
    font-family: Arial, Helvetica, sans-serif !important;
    margin-top: 40px;
    margin-bottom: 20px !important;
}

/* Inner container layout */
.Admin-control-inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 20px;
}

/* Card styling */
.a-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px; /* Initial padding */
    border-radius: 10px;
    margin-bottom: 30px;
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out !important; /* Smooth transition for transform and box-shadow */
    box-shadow: 0px 4px 8px 2px #80808021;
    max-width: 230px;
    width: 100%;
    height: auto; /* Auto height to adjust based on content */
    overflow: hidden; /* Prevent overflow */
    position: relative;
}

.a-card:hover {
    transform: scale(1.1) !important; /* Increased scale for a more noticeable zoom effect */
    box-shadow: 0px 12px 24px 4px #80808040; /* Enhanced shadow on hover for depth */
}
.card-icon-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

/* Styling for the icon */
.card__icon {
    margin-bottom: 10px;
    transition: transform 0.3s ease; /* Smooth transition for icon */
}

.icon__wrapper img {
    width: 80px;
    height: auto;
}

/* Styling the title */
.card__title {
    margin-bottom: 10px;
    font-size: calc(1.275rem + .3vw);
    color: #333;
    max-width: 200px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    transition: transform 0.3s ease; /* Smooth transition for title */
}

/* Styling for the description */
.card__description-wrapper {
    margin-top: 10px;
    position: relative;
    flex-grow: 1;
}

.card__description {
    font-size: 16px;
    line-height: 1.5;
    color: #b8b5b5;
    margin-bottom: 10px;
    max-height: fit-content; /* Limit the height of description */
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 0.3s ease; /* Smooth transition for height */
}

.card__description.expanded {
    max-height: fit-content; /* Allow full height when expanded */
}

/* Styling for the 'Read More' button */
.read-more-btn {
    background-color: #0a0a2e;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px; /* Add margin to separate from description */
    transition: background-color 0.3s ease; /* Smooth transition for button */
}

.read-more-btn:hover {
    background-color: #ff4f81;
}

/* Centering text */
.g-text,
.Admin-control-main-container h2 {
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

/* Responsive Design */
@media (max-width: 430px) {
    .card-discover {
        height: auto;
        width: 100% !important;
    }
}

@media (max-width: 620px) {
    .Admin-control-main-container {
        margin: 60px 0px 50px;
    }

    .Admin-control-inner-container {
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    .a-card {
        width: 90%;
        max-width: none;
        height: auto; /* Allow card to adjust height based on content */
    }
}