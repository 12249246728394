* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}

.card {
    width: 300px;
    display: inline-block;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .05), 0 1px 2px 0 rgba(0, 0, 0, .2);
    transition: all .2s ease;
    background: #fff;
    position: relative;
    overflow: hidden;

    &:hover,
    &.hover {
        // transform: translateY(-4px);
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .3), 0 0 1px 0 rgba(0, 0, 0, .25);

        & .card-content {
            box-shadow: inset 0 3px 0 0 #ccb65e;
            border-color: #ccb65e;
        }

        & .card-img .overlay {
            // background-color: rgba(25, 29, 38, .85);
    //         content: "";
    // position: absolute;
    // z-index: 1;
    // height: 320px;
    // top: 3%;
    // left: 0;
    // right: 0;
    // bottom: 0;
    background: #000000ab;
    // transform: scaleX(0);
    // transform-origin: 0 50%;
    // border-radius: 18px;
    // transition: transform .3s ease-out;
            transition: opacity .2s ease;
            opacity: 1;
        }
    }

    &-img {
        position: relative;
        height: 224px;
        width: 100%;
        background-color: #fff;
        transition: opacity .2s ease;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        & .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0;

            & .overlay-content {
                line-height: 224px;
                width: 100%;
                text-align: center;
                align-items: center;
                color: #fff;
                margin: auto;

                & a {
                    color: #fff;
                    padding: 0 2rem;
                    display: inline-block;
                    border: 1px solid rgba(255, 255, 255, .4);
                    height: 40px;
                    line-height: 40px;
                    border-radius: 20px;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover,
                    &.hover {
                        background: #ccb65e;
                        border-color: #ccb65e;
                    }
                }
            }
        }
    }

    &-content {
        width: 100%;
        min-height: 104px;
        background-color: #fff;
        border-top: 1px solid #E9E9EB;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 1rem 2rem;
        transition: all .2s ease;

        & a {
            text-decoration: none;
            color: #202927;
        }

        & h2,
        a h2 {
            font-size: 1rem;
            font-weight: 500;
        }

        & p,
        a p {
            font-size: .8rem;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(32, 41, 28, .8);
        }
    }
}