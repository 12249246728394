.why-it-seriveces-box {
    flex-wrap: wrap !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 44px !important;
}

.why-it-service-important {
    padding: 20px 10px;
    cursor: pointer;
    width: 270px !important;
    height: auto;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    border: 1px double white;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    /* box-shadow: 0px 0px 20px -6px gray; */
    /* padding: 35px ; */
    /* background: #000000; */

}

.why-it-service-important img {
    border-radius: 10px;
    width: 240px;
    height: 190px;
}

.why-it-service-important h3 {
    font-size: 20px;
}