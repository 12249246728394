.swiper-slider-images {
    height: 70vh;
    /* width: 300px !important; */
    background-repeat: no-repeat;
    object-fit: contain;
    background-position: center;
    mix-blend-mode: color-burn !important;
    background-size: cover;

}

.swiper-slider-images-1 {
    height: 80vh;
    width: 600px !important;
    background-repeat: no-repeat;
    object-fit: contain;
    background-position: center;
    mix-blend-mode: color-burn !important;
    background-size: cover;
}