        .container_index {
            min-height: 500px;
        }

        .breadcrumb_padd {
            padding: 100px 0 30px;
        }

        .page_why {
            margin: 20px auto;
            text-align: center;
        }

        .page_why h2,
        .page_why h3,
        .why_MetaBlock {
            color: #fff !important;
            font-weight: 700;
            text-align: center;
            text-transform: capitalize;
            margin: 10px 0px 5px 0px;
        }

        #cele_div .sub_head_text {
            font-size: 16px;
            line-height: 1.7;
            font-weight: 400;
        }

        .why_round {
            position: relative;
            width: 13px;
            height: 13px;
            background: #be48ff;
            border-radius: 50%;
            text-align: center;
            margin: 0px auto;
        }

        .why_round::before {
            border: 1px solid #be48ff;
            border-radius: 50%;
            content: "";
            height: 13px;
            position: absolute;
            width: 13px;
            left: -8px;
        }

        .why_round::after {
            border: 1px solid #be48ff;
            border-radius: 50%;
            content: "";
            height: 13px;
            position: absolute;
            width: 13px;
            left: 8px;
        }

        #cele_div .breadcrumb_padd {
            padding-top: 50px;
            background-color: #101010;
            color: #fff;
            height: 200px;
            display: flex;
            align-items: center;
        }

        #cele_div .list-inline>li {
            display: inline-block;
            padding-right: 5px;
            padding-left: 5px;
        }

        #cele_div .breadcrumb_section a {
            font-size: 14px;
            color: #fff;
            position: relative;
        }

        .celebration_box .inr_img {
            position: relative;
            z-index: 0;
            margin: 6px;
            padding: 0px 0px 10px;
        }

        .celebration_sec .inr_img img {
            display: block;
            max-width: 280px;
            height: 200px !important;
        }

        .celebration_box .inr_img:after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -6px;
            left: -6px;
            bottom: 6px;
            right: 6px;
            content: "";
            z-index: -1;
            background-color: #2a7bc1;
        }

        .celebration_box h3 {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            margin-top: 20px;
        }

        #cele_div .breadcrumb_padd {
            padding-top: 100px !important;
        }

        #cele_div .celebration_sec {
            padding-top: 50px;
        }

        #cele_div .celebration_sec .main_title {
            font-size: 35px;
            color: #fff;
            font-weight: 700;
            line-height: 1.4;
            text-transform: capitalize;
            font-family: "Urbanist", sans-serif;
            text-align: center;
            margin-bottom: 0px;
        }

        .custom-drp.show .dropdown-menu {
            display: flex;
        }

        .navMnuMn li:hover>.sub-menu {
            visibility: visible;
            opacity: 1;
            transform: translate(0, 0);
            border-radius: 5px;
            box-shadow: 0 12px 28px 1px rgb(0 0 0 / 47%), 0 2px 4px 0 rgb(8 8 8 / 37%);
        }

        .navMnuMn .nav-item {
            position: relative;
        }

        .navMnuMn .sub-menu {
            visibility: hidden;
            opacity: 0;
            margin: 0;
            padding: 10px 10px;
            position: absolute;
            /*left: -100px;*/
            background: #090909;
            backdrop-filter: blur(23px);
            z-index: 99;
            transform: translate(0, 20px);
            transition: all 0.2s ease-out;
            width: max-content;
            top: 60px;
            border: 1px solid #ffffff12;
            padding: 20px 30px;
        }

        .navMnuMn .sub-menu ul li {
            margin-top: 10px;
        }

        .navMnuMn .sub-menu li a {
            text-decoration: none !important;
            font-weight: 400;
            font-size: 14px;
        }

        .navMnuMn .sub-menu li a:before {
            display: none;
        }

        .navMnuMn .sub-menu li a:after {
            display: none;
        }

        .tbsMenu .nav-tabs {
            display: none;
        }

        .cmpy_Overflow {
            background: #090909;
        }

        .card-body {
            background: #090909;
        }

        /*Social Icons Animation Style Start*/

        .HddrLnks li.hasNewMenu a::before,
        .HddrLnks li.hasNewMenu a::after {
            display: none !important;
        }

        .HddrLnks .hasNewMenu {
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            background: #090909;
            place-items: center;
            border-radius: 50%;
            transition: .2s ease-in-out;
            position: relative;
            border: 1px solid #fff3;
            z-index: 0;
            cursor: pointer;
            margin-top: 5px;
        }

        .HddrLnks .hasNewMenu:hover {
            background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
            border-color: transparent;
            border: none;
        }

        .HddrLnks .hasNewMenu::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
            border-radius: 50%;
            will-change: box-shadow;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 0;
            z-index: -1;
            animation: 1s ease-out infinite ripple
        }

        .HddrLnks .hasNewMenu .nav-link .contactIconTop {
            width: 18px;
            height: 18px;
            position: absolute;
            left: 6px;
            top: 0px;
        }

        .mr-40 {
            margin-right: 40px
        }

        @keyframes ripple {

            100%,
            25% {
                box-shadow: 0 0 0 0 rgb(165 115 201 / 30%), 0 0 0 .2em rgb(165 115 201 / 10%)
            }

            50% {
                box-shadow: 0 0 0 0.5em rgb(165 115 201 / 30%), 0 0 0 1em rgb(165 115 201 / 30%)
            }

            75% {
                box-shadow: 0 0 0 2em rgb(165 115 201 / 30%), 0 0 0 2.5em rgb(165 115 201 / 10%)
            }

            from {
                opacity: 1;
                transform: scale(1)
            }

            to {
                opacity: 0;
                transform: scale(2)
            }
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu {
            position: absolute;
            border: 1px solid #ffffff17 !important;
            border-radius: 6px;
            top: 55px !important;
            min-width: 280px !important;
            width: 280px !important;
            transition: .1s ease-in-out !important;
            background: #1c1f21 !important;
            right: 0px !important;
            left: auto !important;
            padding: 0px !important;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 10px solid #8650ff;
            border-left: 10px solid transparent;
            top: -10px;
            right: 12px;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact {
            display: flex;
            justify-content: center;
            color: #fff;
            align-items: flex-start;
            border-radius: 6px 6px 0 0;
            background: #8650ff;
            padding: 5px 10px;
            border-bottom: 1px solid #efefef21;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu>p {
            padding: 14px 20px;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            border-bottom: 1px solid #ffffff38;
            margin-bottom: 0px;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul {
            padding: 15px 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li {
            margin-top: 0px !important;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li a {
            padding-left: 5px;
            margin-right: 0px !important;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li a {
            font-size: 14px;
            margin-bottom: 0px;
            padding-left: 10px;
            margin-right: 0px;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li a:hover {
            background: unset;
            color: #fff;
            -webkit-text-fill-color: unset;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li {
            display: flex;
            margin-top: 0px;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li.lastWhatsapp {
            display: flex;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li.lastWhatsapp a {
            line-height: 15px !important;
        }

        .HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li .middlePartMenu_img {
            width: 18px;
        }

        .HddrLnks .HddrBtns .menu_btn {
            padding: 10px 20px;
            /*width: 160px;*/
            font-size: 15px;
            min-height: 40px;
        }

        .HddrLnks .HddrBtns .menu_btn:focus {
            box-shadow: unset;
        }

        .HddrLnks .HddrBtns .menu_btn.button_christ .raindeer_img {
            width: 25px;
            padding-left: 3px;
        }

        .HddrLnks .HddrBtns .menu_btn.button_christ {
            position: relative;
            display: flex;
            align-items: center;
            background: #8650ff;
            padding: 10PX 15PX;
            line-height: 26px;
            text-decoration: none;
            color: #FFF;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
            border-radius: 15px;
            border: 1px solid #8650ff;
            box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25), inset 0 0 6px #be48ff, inset 0 80px 80px -40px #a54cff, 1px 1px 3px rgba(0, 0, 0, 0.75);
        }

        .HddrLnks .HddrBtns .menu_btn.button_christ::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: -7px;
            left: -3px;
            right: 0;
            height: 23px;
            background: url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow1.webp) no-repeat 0 0, url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow2.webp) no-repeat 50% 0, url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow3.webp) no-repeat 100% 0;
        }

        .HddrLnks li a.cus_title {
            color: #00fff5;
            animation: zoom-in-zoom-out1 3s ease-out infinite;
        }

        .HddrLnks li a.cus_title:before,
        .HddrLnks li a.cus_title:after {
            background: unset;
        }

        .HddrLnks li a.cus_title:hover {
            background: unset;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
            background-clip: unset;
            text-fill-color: unset;
            color: #00fff5;
        }

        @keyframes zoom-in-zoom-out1 {
            0% {
                transform: scale(1, 1);
            }

            50% {
                transform: scale(1.2, 1.2);
            }

            100% {
                transform: scale(1, 1);
            }
        }

        /*Social Icons Animation Style Start*/

        @media (min-width: 991px) {
            .tbsMenu .nav-tabs {
                display: flex;
                flex-flow: column nowrap;
            }

            .tbsMenu .nav-tabs {
                border-bottom: none;
                border-right: 1px solid #ddd;
                display: flex;
            }

            .tbsMenu .nav-tabs {
                margin: 0 15px;
            }

            .tbsMenu .nav-tabs .nav-item+.nav-item {
                margin-top: 0.25rem;
            }

            .tbsMenu .nav-tabs .nav-link {
                transition: border-color 0.125s ease-in;
                white-space: nowrap;
            }

            .tbsMenu .nav-tabs .nav-link:hover {
                background-color: #f7f7f7;
                border-color: transparent;
            }

            .tbsMenu .nav-tabs .nav-link.active {
                background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
                border-radius: 5px;
                border: none;
                color: #fff !important;
            }

            .tbsMenu .nav-tabs .nav-link.active:hover {
                background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
                color: #fff !important;
                -webkit-text-fill-color: unset;
            }

            .tbsMenu .card {
                border: none;
            }

            .tbsMenu .card .card-header {
                display: none;
            }

            .tbsMenu .card .collapse {
                display: block;
            }
        }

        @media (max-width: 991px) {
            .tbsMenu .tab-pane {
                display: block !important;
                opacity: 1;
            }

            .HddrLnks .HddrBtns .menu_btn {
                margin: 12px 0px;
            }
        }

        .cmpy_Overflow {
            height: 350px;
            overflow-x: hidden;
        }

        .tbsMenu ::-webkit-scrollbar {
            width: 3px;
        }

        /* Track */

        .tbsMenu ::-webkit-scrollbar-track {
            background: #ddd;
            border-radius: 50px;
        }

        /* Handle */

        .tbsMenu ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 50px;
        }

        /* Handle on hover */

        .tbsMenu ::-webkit-scrollbar-thumb:hover {
            background: #ccc;
            border-radius: 50px;
        }

        .serSubMnu {
            left: -300px !important;
        }

        .serSubMnu .tab-content {
            width: 700px !important;
        }

        .serTabsOver {
            height: 450px;
            overflow-x: hidden;
        }

        .sub-menu ul li {
            position: relative;
        }

        .sub-menu ul li:after {
            content: "\25B6";
            position: absolute;
            top: 7px;
            left: -16px;
            color: #000000;
            font-size: 1px;
        }

        .cmpyAfter:after {
            display: none;
        }

        @media(min-width: 992px) {
            .menu_res {
                display: none;
            }
        }

        @media(max-width: 991px) {
            .menu_desk {
                display: none;
            }

            .menu_res .dropdown-menu {
                padding: 15px;
                background-color: #090909;
                border: 1px solid rgb(122 122 122 / 15%);
                color: #fff;
                height: 260px;
                overflow-y: scroll;
            }

            .menu_res .dropdown-toggle:after {
                right: -16px;
                top: 22px;
                background: #090909;
                width: 0px !important;
            }

            .menu_res .dropdown-toggle:before {
                display: none;
            }

            .compyMenuResHight {
                height: 400px;
                overflow-x: hidden;
            }

            .serSubMnu {
                position: initial !important;
                height: 350px;
                overflow-x: hidden;
                visibility: initial !important;
                opacity: 1 !important;
                width: initial !important;
            }

            .cmpy_Overflow {
                height: 200px !important;
            }

            .card {
                margin-top: 20px;
            }

            .navMnuMn .sub-menu {
                margin-bottom: 30px;
            }
        }

        #navbarNavAltMarkup .dropdown_sub_menu {
            margin-top: 10px;
        }

        #navbarNavAltMarkup .dropdown_sub_menu .drop_right_menu {
            width: max-content;
            margin-left: 2.1rem;
            padding: 20px 30px;
            background: #090909;
            backdrop-filter: blur(23px);
            border: 1px solid #ffffff12;
            border-radius: 5px;
            box-shadow: 0 12px 28px 1px rgb(0 0 0 / 47%), 0 2px 4px 0 rgb(8 8 8 / 37%);
        }

        #navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #fff;
            margin-right: 5px;
            position: relative;
            display: inline-block;
        }

        #navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link::before {
            transition: unset;
        }

        #navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link:hover::before,
        #navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link:hover::after {
            background: unset;
            transition: unset;
        }

        #navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link::after {
            right: auto;
            background: unset;
            margin-left: 10px;
        }

        @media (max-width: 991px) {
            .HddrBg .navbar #navbarNavAltMarkup .HdrSroll {
                height: 703px;
                overflow-x: hidden;
                overflow-y: scroll;
            }
        }

        /*Side Conatct Form Style Start*/

        .HddrBg .web_menu_btn_sec {
            position: relative;
            z-index: 999;
            cursor: pointer;
        }

        .HddrBg .web_menu_btn_sec .contact_form_mainsec {
            position: absolute;
            border: 1px solid #ffffff17 !important;
            border-radius: 6px;
            top: 55px !important;
            min-width: 300px !important;
            width: 300px !important;
            transition: .1s ease-in-out !important;
            background: #1c1f21 !important;
            right: 0% !important;
            left: auto !important;
            display: none;
            padding: 0px !important;
        }

        .HddrBg .web_menu_btn_sec .contact_form_mainsec::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 10px solid #8650ff;
            border-left: 10px solid transparent;
            top: -10px;
            right: 20px;
        }

        .HddrBg .web_menu_btn_sec .contact_form_mainsec .topNewContact {
            display: flex;
            justify-content: center;
            color: #fff;
            align-items: flex-start;
            border-radius: 6px 6px 0 0;
            background: #8650ff;
            padding: 10px 10px;
            border-bottom: 1px solid #efefef21;
        }

        .HddrBg .web_menu_btn_sec .contact_form_mainsec .topNewContact .sub_title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
            text-transform: capitalize;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec {
            padding: 20px;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .side_contact_submit_btn {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 500;
            border-radius: 8px;
            background: #8650ff !important;
            margin-top: 0px;
            border: none;
            padding: 8px 20px;
            padding-left: 20px !important;
            width: fit-content;
            color: #fff;
            text-align: center;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input {
            background: rgb(255 255 255 / 6%) !important;
            backdrop-filter: blur(2px);
            border: 1px solid #ffffff36;
            border-radius: 5px;
            color: #fff !important;
            height: 40px;
            padding-left: 10px;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 13px;
            font-weight: 200;
            width: 100%;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_country select {
            background: rgb(255 255 255 / 6%) !important;
            backdrop-filter: blur(2px);
            border: 1px solid #ffffff36;
            border-radius: 5px;
            color: #fff !important;
            height: 40px;
            padding-left: 10px;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 13px;
            font-weight: 200;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_country select option {
            color: #000 !important;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div {
            display: flex;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list {
            background: rgb(255 255 255 / 6%) !important;
            border: 1px solid #ffffff36;
            border-radius: 5px;
            color: #fff !important;
            height: 40px;
            padding-left: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 13px;
            font-weight: 200;
            margin-right: 10px;
            width: 30%;
            align-items: center;
            display: grid;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .btn {
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none !important;
            color: #fff;
            padding: 5px;
            margin-top: 0px;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .btn i {
            font-size: 16px;
            padding-left: 10px;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg {
            background: rgb(255 255 255 / 6%) !important;
            backdrop-filter: blur(2px);
            border: 1px solid #ffffff36;
            border-radius: 5px;
            color: #fff !important;
            padding-left: 10px;
            margin-top: 0px;
            height: 90px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: 200;
            padding-top: 15px;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input::placeholder,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
            opacity: 1;
            /* Firefox */
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-ms-input-placeholder,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
            /* Internet Explorer 10-11 */
            color: #fff;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input::-ms-input-placeholder,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
            /* Microsoft Edge */
            color: #fff;
        }

        #popup_form_modal .fitoutform .form-group {
            margin-bottom: 1em !important;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select:-webkit-autofill,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form textarea:-webkit-autofill,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill:focus,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill:hover {
            -webkit-box-shadow: 0 0 0 30px #292c2e inset !important;
            background-image: none;
            -webkit-text-fill-color: #fff !important;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .select2-selection__rendered {
            color: #929292;
            margin: 0px;
            padding: 6px;
            background: #fff;
            padding-left: 15px
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_select {
            padding-left: 15px;
            font-size: 17px;
            color: #929292 !important;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .bootstrap-select.btn-group .dropdown-toggle .filter-option {
            display: unset;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form label.error {
            border-radius: 5px;
            background: red;
            color: #fff !important;
            padding: 2px;
            position: absolute;
            top: -12px;
            right: 0;
            width: auto;
            font-size: 10px;
            font-weight: 400;
            z-index: 1;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form label.error:after {
            content: " ";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: 0px;
            border-width: 5px;
            border-style: solid;
            border-color: red transparent transparent transparent;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a {
            background: #8650ff;
            color: #fff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            height: 40px;
            text-decoration: none;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a {
            height: 40px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            height: 40px;
            color: #000;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a::before,
        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a::after {
            background: unset !important;
            -webkit-text-fill-color: unset !important;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a:hover {
            -webkit-text-fill-color: unset !important;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a:hover {
            background: #8650ff !important;
            color: #fff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            height: 40px;
            text-decoration: none;
        }

        .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #side_contact_form_error_msg {
            font-size: 14px !important;
        }

        /*Side Conatct Form Style End*/

        /*Metaverse Blink Button Style*/

        .single_trending_btn>p>a:hover {
            -webkit-text-fill-color: unset !important;
            -webkit-background-clip: unset;
            background: #bd48ff;
        }

        .single_trending_btn>p>a:hover:before,
        .single_trending_btn>p>a::before,
        .single_trending_btn>p>a::after {
            background: unset;
        }

        .single_trending_btn {
            position: absolute;
            right: 5px;
            bottom: -34px;
        }

        .single_trending_btn>p {
            margin-bottom: 0;
        }

        .single_trending_btn>p>a {
            color: #fff;
            background: #bd48ff;
            display: block;
            font-size: 14px;
            font-weight: 500;
            padding: 6px 25px;
            text-align: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            text-decoration: none;
            margin-right: 0px;
            line-height: unset;
        }

        .single_trending_btn>p>a img {
            filter: invert(1);
            width: 20px;
        }

        .single_trending_btn .text_blink_animate {
            display: block;
            animation: zoom-in-zoom-out1 3s ease-out infinite;
        }

        @keyframes zoom-in-zoom-out1 {
            0% {
                transform: scale(1, 1);
            }

            50% {
                transform: scale(1.2, 1.2);
            }

            100% {
                transform: scale(1, 1);
            }
        }

        /*Metaverse Blink Button Style*/

        @media(max-width: 991px) {
            .HddrBg .web_menu_btn_sec .side_menu_btn.button_christ {
                position: relative;
                display: flex;
                align-items: center;
                background: #8650ff;
                padding: 10PX 15PX;
                line-height: 23px;
                text-decoration: none;
                color: #FFF;
                text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
                border-radius: 12px;
                border: 1px solid #8650ff;
                box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25), inset 0 0 6px #be48ff, inset 0 80px 80px -40px #a54cff, 1px 1px 3px rgba(0, 0, 0, 0.75);
            }

            .HddrBg .web_menu_btn_sec .side_menu_btn.button_christ::before {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: -7px;
                left: -3px;
                right: 0;
                height: 23px;
                background: url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow1.webp) no-repeat 0 0, url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow3.webp) no-repeat 100% 0;
            }
        }

        .HddrBg .navbar .logo_header .mbl_res_logo {
            width: 80%;
        }

        @media(max-width: 767px) {
            .HddrBg .navbar .logo_header .mbl_res_logo {
                width: 210px;
            }

            .HddrBg .web_menu_btn_sec .side_menu_btn {
                padding: 8px 10px !important;
                font-size: 13px !important;
            }

            .HddrBg .web_menu_btn_sec .contact_form_mainsec::after {
                right: 50px !important;
            }

            .HddrBg .web_menu_btn_sec .contact_form_mainsec {
                right: -30% !important;
            }

            .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .side_contact_submit_btn {
                font-size: 14px !important;
            }
        }

        @media(max-width: 467px) {
            .HddrBg .web_menu_btn_sec .side_menu_btn {
                font-size: 12px !important;
                padding: 6px 8px !important;
                font-weight: 400 !important;
            }

            .HddrBg .logo_header {
                width: 55% !important;
            }

            .HddrBg .navbar {
                padding: 0.25rem 0.50rem;
            }
        }

        /*On Hover Hide Metaverse button*/

        .HddrLnks .hasNewMenu:hover~.single_trending_sec {
            opacity: 0 !important;
        }

        .HddrBg .web_menu_btn_sec .side_menu_btn {
            padding: 8px 20px;
            font-size: 16px;
            background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
            color: #fff;
            font-weight: 500;
            border: none;
        }

        .HddrBg .web_menu_btn_sec .side_menu_btn:focus {
            box-shadow: unset;
        }

        /*Social Media Icons Style*/

        .menu_social_icon_main {
            padding: 20px 0px;
        }

        .menu_social_icon_main .contents {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
            font-style: italic;
        }

        .menu_social_icon_main .menu_social_listsec {
            width: fit-content;
        }

        .menu_social_icon_main .menu_social_listsec .menu_social_list {
            list-style: none;
            display: inline-block;
            position: relative;
            margin: 0 3px;
            transition: 0.3s all ease-in-out;
        }

        .menu_social_icon_main .menu_social_listsec .menu_social_list img {
            width: 35px;
        }

        .menu_social_icon_main .menu_social_listsec:hover>li {
            opacity: 0.4;
            transition: 0.3s all ease-in-out;
        }

        .menu_social_icon_main .menu_social_listsec>li:hover {
            opacity: 1;
            transition: 0.3s all ease-in-out;
            transform: scale(1.3);
        }

        /*Social Media Icons Style*/

        /*Mobile Sub Menu Style Start*/

        .menu_res .dropdown-menu .mbl_sub_head,
        .navMnuMn .sub-menu ul li.menu_sub_title {
            padding-bottom: 2px;
            border-bottom: 1px solid #ae7ded;
            width: fit-content;
            color: #b4b1b1;
            font-weight: 600;
            text-transform: uppercase;
            margin: 5px 0px;
        }

        .menu_res .dropdown-menu .mbl_submenu_list {
            padding-left: 15px;
            position: relative;
        }

        .menu_res .dropdown-menu .mbl_submenu_list:before {
            position: absolute;
            content: '\2B9E';
            width: 10px;
            height: 10px;
            left: 5%;
            top: 0%;
            color: #ae7ded;
        }

        .menu_res .dropdown-menu .mbl_submenu_list a {
            margin-right: 0px;
            padding-left: 20px;
        }

        /*Mobile Sub Menu Style End*/

        .single-gallery-item img:hover {
            border: 2px solid black;
        }