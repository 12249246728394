@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
html, body {
  scroll-behavior: auto !important;
  overflow-y: auto !important;
  height: auto !important;
}


.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
}

.user-message {
  align-self: flex-end;
  background-color: #dcf8c6;
  padding: 8px 12px;
  margin: 4px 0;
  border-radius: 8px;
  max-width: 70%;
}

.bot-message {
  align-self: flex-start;
  background-color: #e5e7eb;
  padding: 8px 12px;
  margin: 4px 0;
  border-radius: 8px;
  max-width: 70%;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.input-field {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-right: 8px;
}

.send-button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.errorpage-button button {
  width: 170px;
  height: 8vh;
  border: 1px solid transparent;
  color: white;
  background-color: purple;
  border-radius: 18px;
}

.errorpage-main-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 13vh;
}

.errorpage-main-container h2 {
  text-align: center;
}

@media (max-width: 992px) {
  .errorpage-main-container {
    display: flex;
    height: 90vh;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 13vh;
  }
}

.admin_option {
  color: white;
}
