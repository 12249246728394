/*9.11.2020*/

body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Readex Pro", sans-serif;
}

a {
  color: #2f2d2d;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

a:focus,
a:hover {
  color: #2a7bc1;
  text-decoration: none;
}

hr {
  max-width: 100%;
  margin: 15px 0 10px 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: #fff;
}

.cbp-af-header.sticky .nav > li > a.btn-hire {
  padding: 7px 6px !important;
}

/*h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 200;
    font-family: "Open Sans", sans-serif !important;
}*/

p {
  font-size: 15px;
  margin-bottom: 20px;
}

section {
  padding: 10px 0;
}

section h2 {
  font-size: 50px;
}

@media (max-width: 767px) {
  .defi_banner_sec {
    background: #000;
    background-image: none !important;
  }

  .mob-text-center {
    text-align: center !important;
  }
}

.p-30-0 {
  padding: 30px 0;
}

#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background: #fff;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  font-family: Catamaran, Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand {
  color: #fdcc52;
  font-family: Catamaran, Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand:focus,
#mainNav .navbar-brand:hover {
  color: #fcbd20;
}

#mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #222;
}

/*.navbar-nav {
    padding-left: 20px !important
}*/

.navbar-nav li {
  padding-left: 0;
}

#mainNav .navbar-nav > li > a {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 700;
  color: #636363 !important;
  border-bottom: transparent 5px solid;
}

#mainNav .navbar-nav > li > a.active {
  color: #2a7bc1 !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#mainNav .navbar-nav > li > a,
#mainNav .navbar-nav > li > a:focus {
  color: #222;
}

#mainNav .navbar-nav > li > a:focus:hover,
#mainNav .navbar-nav > li > a:hover {
  color: #fdcc52;
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: #fff;
    padding: 0;
  }

  #mainNav .navbar-brand {
    color: fade(white, 70%);
  }

  #mainNav .navbar-brand:focus,
  #mainNav .navbar-brand:hover {
    color: #2a7bc1;
  }

  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    color: #0f0f0f;
  }

  #mainNav .navbar-nav > li > a:focus:hover,
  #mainNav .navbar-nav > li > a:hover {
    color: #2a7bc1;
  }

  #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #fff;
  }

  #mainNav.navbar-shrink .navbar-brand {
    color: #222;
  }

  #mainNav.navbar-shrink .navbar-brand:focus,
  #mainNav.navbar-shrink .navbar-brand:hover {
    color: #2a7bc1;
  }

  #mainNav.navbar-shrink .navbar-nav > li > a,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #222;
  }

  #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover,
  #mainNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #2a7bc1;
  }
}

@media (min-width: 768px) {
  .seven-cols .col-lg-1,
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-lg-1,
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1 {
    width: 14.285714285714285714285714285714%;
  }
}

@media (min-width: 1200px) {
  .seven-cols .col-lg-1,
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1 {
    width: 14.285714285714285714285714285714%;
  }
}

.sprite_icon_85 {
  width: 85px !important;
  height: 85px !important;
  object-fit: none;
}

.sprite_icon_60 {
  width: 60px !important;
  height: 60px !important;
  object-fit: none;
}

.sprite_icon_65 {
  width: 65px !important;
  height: 65px !important;
  object-fit: none;
}

.sprite_icon_75 {
  width: 75px !important;
  height: 75px !important;
  object-fit: none;
}

header.masthead {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
}

header.masthead .header-content {
  margin-bottom: 100px;
  text-align: center;
}

header.masthead .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
}

.h-100 {
  height: 100% !important;
}

header.masthead-hyper .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-hyper .header-content .expert-btn {
  background: #00dcff;
  color: #030542;
}

.hyp-about .hyp-buttons .expert-btn {
  margin: 0 auto;
  background: #0176bc;
  border-radius: 5px;
}

.hyp-about p {
  text-align: justify;
}

header.masthead-hyper .header-content .heading-tagline {
  text-align: left;
}

header.masthead-private .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-iot .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-finance .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
}

header.header-content h1 {
  font-size: 63px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-identity .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-cryptocurrency-wallet-development .header-content h1 {
  font-size: 51px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
  text-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
}

header.masthead-ico-development .header-content h1 {
  font-size: 45px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
  text-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
}

.header-content h1,
header.masthead-2d-animation-company .header-content h1,
header.masthead-3d-animation-company .header-content h1,
header.masthead-adobe-air-app-development .header-content h1,
header.masthead-ai .header-content h1,
header.masthead-ai-banner,
header.masthead-android-app-development .header-content h1,
header.masthead-android-game-development .header-content h1,
header.masthead-android-tablet-development .header-content h1,
header.masthead-angular-js-development .header-content h1,
header.masthead-angular8-development .header-content h1,
header.masthead-ar-core-for-android .header-content h1,
header.masthead-banking .header-content h1,
header.masthead-blockchain-ai-dev .header-content h1,
header.masthead-blockchain-application-for-voting .header-content h1,
header.masthead-blockchain-consulting-services .header-content h1,
header.masthead-blockchain-development .header-content h1,
header.masthead-blockchain-in-digital-records .header-content h1,
header.masthead-blockchain-in-ecommerce .header-content h1,
header.masthead-blockchain-in-gaming .header-content h1,
header.masthead-blockchain-in-insurance .header-content h1,
header.masthead-blockchain-in-real-estate .header-content h1,
header.masthead-blockchain-in-supplychain .header-content h1,
header.masthead-blockchain-iot-management .header-content h1,
header.masthead-bootstrap-web-development .header-content h1,
header.masthead-business .header-content h1,
header.masthead-cakephp-framework-development .header-content h1,
header.masthead-canvas5-web-design .header-content h1,
header.masthead-chainlink-blockchain .header-content h1,
header.masthead-chatbot .header-content h1,
header.masthead-cloud .header-content h1,
header.masthead-cloud-application-development .header-content h1,
header.masthead-codeigniter-framework-development .header-content h1,
header.masthead-crypto .header-content h1,
header.masthead-custom-web-design-development .header-content h1,
header.masthead-customers-inventory-management-software .header-content h1,
header.masthead-deep .header-content h1,
header.masthead-demand,
header.masthead-digital .header-content h1,
header.masthead-ecom .header-content h1,
header.masthead-engi .header-content h1,
header.masthead-enterprise-mobile-app-development .header-content h1,
header.masthead-enterprise-solutions .header-content h1,
header.masthead-eos-blockchain .header-content h1,
header.masthead-equihash .header-content h1,
header.masthead-ethereum-blockchain-development .header-content h1,
header.masthead-ethereum-classic-blockchain-development .header-content h1,
header.masthead-exchange-platform-development .header-content h1,
header.masthead-fmcg .header-content h1,
header.masthead-full-stack-development .header-content h1,
header.masthead-go .header-content h1,
header.masthead-gov .header-content h1,
header.masthead-hedera-hashgraph-dev .header-content h1,
header.masthead-hybrid-mobile-app-development .header-content h1,
header.masthead-hybris .header-content h1,
header.masthead-hyperledger-fabric-composer .header-content h1,
header.masthead-ico-marketing-agency .header-content h1,
header.masthead-ionic-mobile-app-development .header-content h1,
header.masthead-ipad-app-development .header-content h1,
header.masthead-ipad-ui-ux-design .header-content h1,
header.masthead-iphone-app-development .header-content h1,
header.masthead-iphone-game-development .header-content h1,
header.masthead-iphone-ui-ux-development .header-content h1,
header.masthead-jmeter .header-content h1,
header.masthead-joomla-web-development .header-content h1,
header.masthead-laravel-framework-development .header-content h1,
header.masthead-legal .header-content h1,
header.masthead-lisk-blockchain .header-content h1,
header.masthead-logo-design .header-content h1,
header.masthead-magento-development .header-content h1,
header.masthead-mean-stack-development .header-content h1,
header.masthead-mobile-application-maintenance .header-content h1,
header.masthead-mobility .header-content h1,
header.masthead-native-app-development-company .header-content h1,
header.masthead-neo-blockchain .header-content h1,
header.masthead-node-js-development .header-content h1,
header.masthead-online-customer-support .header-content h1,
header.masthead-peer-to-peer-blockchain .header-content h1,
header.masthead-phonegap-application-development .header-content h1,
header.masthead-php .header-content h1,
header.masthead-pivx .header-content h1,
header.masthead-private-public-blockchain-development .header-content h1,
header.masthead-publish .header-content h1,
header.masthead-python .header-content h1,
header.masthead-quorum-blockchain-development .header-content h1,
header.masthead-react-js-development .header-content h1,
header.masthead-react-native-development .header-content h1,
header.masthead-real .header-content h1,
header.masthead-res .header-content h1,
header.masthead-responsive-website-design-company .header-content h1,
header.masthead-retail .header-content h1,
header.masthead-ruby .header-content h1,
header.masthead-sap .header-content h1,
header.masthead-scrypt .header-content h1,
header.masthead-sha-256 .header-content h1,
header.masthead-shop .header-content h1,
header.masthead-signage .header-content h1,
header.masthead-smart-contract-audit .header-content h1,
header.masthead-sto-development .header-content h1,
header.masthead-sto-marketing .header-content h1,
header.masthead-stratis-blockchain .header-content h1,
header.masthead-supply .header-content h1,
header.masthead-svelte-js-development .header-content h1,
header.masthead-tele .header-content h1,
header.masthead-trans .header-content h1,
header.masthead-tron-blockchain-development .header-content h1,
header.masthead-waves-blockchain .header-content h1,
header.masthead-way .header-content h1,
header.masthead-web-design-company .header-content h1,
header.masthead-web-hosting-and-maintenance-services .header-content h1,
header.masthead-website-maintenance-company .header-content h1,
header.masthead-wordpress-development .header-content h1,
header.masthead-x11 .header-content h1,
header.masthead-yii-framework-development .header-content h1,
header.masthead-zend-framework-development .header-content h1 {
  font-size: 50px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px !important;
  color: #fff;
  text-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
}

header.masthead .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead .device-container .screen img {
  border-radius: 3px;
}

.my-auto {
  margin-top: auto !important;
}

@media (min-width: 992px) {
  header.masthead {
    height: 70vh;
    min-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
    margin-top: 8em;
  }

  header.masthead .header-content h1 {
    font-size: 50px;
  }

  header.masthead .device-container {
    max-width: 325px;
  }
}

section.download {
  position: relative;
  padding: 55px 0;
}

section.download h2 {
  font-size: 50px;
  margin-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px;
}

.float-right {
  float: right !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.p-20-0 {
  padding: 20px 0;
}

section.download .badges .badge-link:last-child {
  margin-bottom: 0;
}

section.download .badges .badge-link img {
  height: 60px;
}

@media (min-width: 768px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  section.download h2 {
    font-size: 70px;
  }
}

section.features .section-heading-1 {
  margin-bottom: 100px;
}

section.features .section-heading-1 h2 {
  margin-top: 0;
}
.section-heading-1 .Process .our-app {
  color: "black !important";
}

section.features .section-heading-1 p {
  margin-bottom: 0;
}

section.features .device-container,
section.features .feature-item {
  max-width: 325px;
  margin: 0 auto;
}

section.features .device-container {
  margin-bottom: 100px;
}

@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0;
  }
}

section.features .feature-item {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

section.features .feature-item h3 {
  font-size: 30px;
}

section.features .feature-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#7b4397),
    to(#dc2430)
  );
  background: linear-gradient(to left, #7b4397, #dc2430);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.solutions {
  background: #000;
  padding: 40px 0;
}

.resource {
  padding: 40px 0;
}

section.contact {
  text-align: center;
}

section.contact h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

section.contact h2 i {
  color: #dd4b39;
}

section.contact ul.list-social {
  margin-bottom: 0;
}

section.contact ul.list-social li a {
  font-size: 40px;
  line-height: 80px;
  display: block;
  width: 80px;
  height: 80px;
  color: #fff;
  border-radius: 100%;
}

section.contact ul.list-social li.social-twitter a {
  background-color: #1da1f2;
}

section.contact ul.list-social li.social-twitter a:hover {
  background-color: #0d95e8;
}

section.contact ul.list-social li.social-facebook a {
  background-color: #3b5998;
}

section.contact ul.list-social li.social-facebook a:hover {
  background-color: #344e86;
}

section.contact ul.list-social li.social-google-plus a {
  background-color: #dd4b39;
}

section.contact ul.list-social li.social-google-plus a:hover {
  background-color: #d73925;
}

#back_to_top_button {
  display: inline-block;
  background-color: #ae2a53;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 90px;
  right: 20px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  cursor: pointer;
}

#back_to_top_button::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 35px;
  color: #fff;
}

#back_to_top_button.show {
  opacity: 1;
  visibility: visible;
}

footer {
  background: #1a2325;
  padding: 0 0;
  border-bottom: 1px solid #463e3e;
}

footer h3 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

footer p {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
}

footer ul {
  padding: 0;
  margin: 0;
}

footer ul li {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  list-style: none;
}

footer ul li a {
  color: #fff;
  font-size: 13px;
}

footer ul li a.active,
footer ul li a:active,
footer ul li a:focus,
footer ul li a:hover {
  text-decoration: none;
}

footer ul li a:hover {
  color: #2a7bc1;
}

footer .form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  height: 43px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

[class^="hvr-shutter-out-vertical2"] {
  margin: 10px auto;
  padding: 10px 40px;
  cursor: pointer;
  background: #2a7bc1;
  text-decoration: none;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  width: auto;
  border: none !important;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
}

.bg-primary {
  background: #1c1955 !important;
}

.footer_bott {
  background: #333;
  padding: 30px 0;
  color: #626161;
  text-align: center;
}

.footer_bott a {
  color: #fff;
  padding: 0 10px;
  font-size: 14px;
}

.social {
  text-align: center;
  margin: 0 0 10px 0;
}

.social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.social ul li {
  display: inline-block;
  margin: 0 0 0 7px;
}

.social .fb a {
  background: #3c5b9b;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.social .fb a::before {
  content: "\f09a";
  font-family: FontAwesome;
}

.social .tw a::before {
  content: "\f099";
  font-family: FontAwesome;
}

.social .googleplus a::before {
  content: "\f0d5";
  font-family: FontAwesome;
}

.social .linkedin a::before {
  content: "\f0e1 ";
  font-family: FontAwesome;
}

.social .youtube a::before {
  content: "\f167";
  font-family: FontAwesome;
}

.social .pintrest a::before {
  content: "\f231";
  font-family: FontAwesome;
}

.social .instragram a::before {
  content: "\f16d";
  font-family: FontAwesome;
}

.social .medium a::before {
  content: "\f23a";
  font-family: FontAwesome;
}

.social .tw a {
  background: #359bed;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.social .linkedin a {
  background: #359bed;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.social .youtube a {
  background: red;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .pintrest a {
  background: #c8232c;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .instragram a {
  background: #c32aa3;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .medium a {
  background: #02b875;
  font-size: 18px;
  border-radius: 50%;
  line-height: 39px;
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  text-align: center;
  padding: 0;
  transform: rotate(0);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.copyright {
  background: #1a2325;
  padding: 20px;
  text-align: center;
  color: #fff;
  font-size: 11px;
}

.copyright a {
  color: #fff;
}

.social .googleplus a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .medium a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .instragram a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .pintrest a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .youtube a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .linkedin a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .tw a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.social .fb a:hover {
  background: #2a7bc1;
  color: #000;
  transform: rotate(360deg);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  text-decoration: none;
}

.text-primary {
  color: #fdcc52;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.btn-outline {
  color: #fff;
  border: 1px solid;
  border-color: #fff;
}

.btn-outline.active,
.btn-outline:active,
.btn-outline:focus,
.btn-outline:hover {
  color: #fff;
  border-color: #fdcc52;
  background-color: #fdcc52;
}

.btn {
  border-radius: 300px;
  font-family: Raleway, sans-serif;
  /*letter-spacing: 2px;*/
  text-transform: uppercase;
}

.btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

.time-list li {
  padding-left: 0;
}

.line-white {
  margin-top: 7px;
  color: #989898;
}

.marquee-sibling {
  padding: 0;
  background: #3bb0d6;
  width: 20%;
  height: 45px;
  line-height: 42px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  float: left;
  left: 0;
  z-index: 2000;
}

.marquee,
[class^="marquee"] {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
}

.marquee {
  margin-left: 25%;
}

.marquee-content-items {
  display: inline-block;
  padding: 5px;
  margin: 0;
  height: 45px;
  position: relative;
}

.marquee-content-items li {
  display: inline-block;
  line-height: 35px;
  color: #fff;
}

.marquee-content-items li:after {
  content: "|";
  margin: 0 1em;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .header-content h1,
  header.masthead-2d-animation-company .header-content h1,
  header.masthead-3d-animation-company .header-content h1,
  header.masthead-adobe-air-app-development .header-content h1,
  header.masthead-ai .header-content h1,
  header.masthead-ai-banner,
  header.masthead-android-app-development .header-content h1,
  header.masthead-android-game-development .header-content h1,
  header.masthead-android-tablet-development .header-content h1,
  header.masthead-angular-js-development .header-content h1,
  header.masthead-angular8-development .header-content h1,
  header.masthead-ar-core-for-android .header-content h1,
  header.masthead-banking .header-content h1,
  header.masthead-blockchain-ai-dev .header-content h1,
  header.masthead-blockchain-application-for-voting .header-content h1,
  header.masthead-blockchain-consulting-services .header-content h1,
  header.masthead-blockchain-development .header-content h1,
  header.masthead-blockchain-in-digital-records .header-content h1,
  header.masthead-blockchain-in-ecommerce .header-content h1,
  header.masthead-blockchain-in-gaming .header-content h1,
  header.masthead-blockchain-in-insurance .header-content h1,
  header.masthead-blockchain-in-real-estate .header-content h1,
  header.masthead-blockchain-in-supplychain .header-content h1,
  header.masthead-blockchain-iot-management .header-content h1,
  header.masthead-bootstrap-web-development .header-content h1,
  header.masthead-business .header-content h1,
  header.masthead-cakephp-framework-development .header-content h1,
  header.masthead-canvas5-web-design .header-content h1,
  header.masthead-chainlink-blockchain .header-content h1,
  header.masthead-chatbot .header-content h1,
  header.masthead-cloud .header-content h1,
  header.masthead-cloud-application-development .header-content h1,
  header.masthead-codeigniter-framework-development .header-content h1,
  header.masthead-crypto .header-content h1,
  header.masthead-custom-web-design-development .header-content h1,
  header.masthead-customers-inventory-management-software .header-content h1,
  header.masthead-deep .header-content h1,
  header.masthead-demand,
  header.masthead-digital .header-content h1,
  header.masthead-ecom .header-content h1,
  header.masthead-engi .header-content h1,
  header.masthead-enterprise-mobile-app-development .header-content h1,
  header.masthead-enterprise-solutions .header-content h1,
  header.masthead-eos-blockchain .header-content h1,
  header.masthead-equihash .header-content h1,
  header.masthead-ethereum-blockchain-development .header-content h1,
  header.masthead-ethereum-classic-blockchain-development .header-content h1,
  header.masthead-exchange-platform-development .header-content h1,
  header.masthead-fmcg .header-content h1,
  header.masthead-full-stack-development .header-content h1,
  header.masthead-go .header-content h1,
  header.masthead-gov .header-content h1,
  header.masthead-hedera-hashgraph-dev .header-content h1,
  header.masthead-hybrid-mobile-app-development .header-content h1,
  header.masthead-hybris .header-content h1,
  header.masthead-hyperledger-fabric-composer .header-content h1,
  header.masthead-ico-marketing-agency .header-content h1,
  header.masthead-ionic-mobile-app-development .header-content h1,
  header.masthead-ipad-app-development .header-content h1,
  header.masthead-ipad-ui-ux-design .header-content h1,
  header.masthead-iphone-app-development .header-content h1,
  header.masthead-iphone-game-development .header-content h1,
  header.masthead-iphone-ui-ux-development .header-content h1,
  header.masthead-jmeter .header-content h1,
  header.masthead-joomla-web-development .header-content h1,
  header.masthead-laravel-framework-development .header-content h1,
  header.masthead-legal .header-content h1,
  header.masthead-lisk-blockchain .header-content h1,
  header.masthead-logo-design .header-content h1,
  header.masthead-magento-development .header-content h1,
  header.masthead-mean-stack-development .header-content h1,
  header.masthead-mobile-application-maintenance .header-content h1,
  header.masthead-mobility .header-content h1,
  header.masthead-native-app-development-company .header-content h1,
  header.masthead-neo-blockchain .header-content h1,
  header.masthead-node-js-development .header-content h1,
  header.masthead-online-customer-support .header-content h1,
  header.masthead-peer-to-peer-blockchain .header-content h1,
  header.masthead-phonegap-application-development .header-content h1,
  header.masthead-php .header-content h1,
  header.masthead-pivx .header-content h1,
  header.masthead-private-public-blockchain-development .header-content h1,
  header.masthead-publish .header-content h1,
  header.masthead-python .header-content h1,
  header.masthead-quorum-blockchain-development .header-content h1,
  header.masthead-react-js-development .header-content h1,
  header.masthead-react-native-development .header-content h1,
  header.masthead-real .header-content h1,
  header.masthead-res .header-content h1,
  header.masthead-responsive-website-design-company .header-content h1,
  header.masthead-retail .header-content h1,
  header.masthead-ruby .header-content h1,
  header.masthead-sap .header-content h1,
  header.masthead-scrypt .header-content h1,
  header.masthead-sha-256 .header-content h1,
  header.masthead-shop .header-content h1,
  header.masthead-signage .header-content h1,
  header.masthead-smart-contract-audit .header-content h1,
  header.masthead-sto-development .header-content h1,
  header.masthead-sto-marketing .header-content h1,
  header.masthead-stratis-blockchain .header-content h1,
  header.masthead-supply .header-content h1,
  header.masthead-svelte-js-development .header-content h1,
  header.masthead-tele .header-content h1,
  header.masthead-trans .header-content h1,
  header.masthead-tron-blockchain-development .header-content h1,
  header.masthead-waves-blockchain .header-content h1,
  header.masthead-way .header-content h1,
  header.masthead-web-design-company .header-content h1,
  header.masthead-web-hosting-and-maintenance-services .header-content h1,
  header.masthead-website-maintenance-company .header-content h1,
  header.masthead-wordpress-development .header-content h1,
  header.masthead-x11 .header-content h1,
  header.masthead-yii-framework-development .header-content h1,
  header.masthead-zend-framework-development .header-content h1 {
    font-size: 27px !important;
    line-height: 35px;
  }

  .section-heading-1 h3 {
    font-size: 25px !important;
  }

  .navbar-nav {
    padding-left: 0 !important;
  }

  header.masthead {
    height: 510px;
    padding-top: 40px;
    padding-bottom: 0;
  }

  header.masthead .header-content h1 {
    font-size: 34px !important;
  }

  .header-content p {
    font-size: 16px;
    line-height: 26px;
  }

  .contact-cnt h4 {
    text-align: center;
  }

  .social ul {
    margin-left: 0;
  }

  .center-class {
    text-align: center;
  }
}

.header-content p {
  width: 90%;
  /*margin: 0 auto;*/
  line-height: 30px;
  font-size: 18px;
}

/*.header-content{
    margin-top: 30px;
}*/

.about {
  padding: 50px 0;
}

.ttmenu .nav > li > .btn-hire {
  background: #2a7bc1;
  color: #fff !important;
  padding: 7px 6px !important;
  border-radius: 0;
}

.talk_to_experts_btn_div .talk_to_experts_btn_a {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  padding: 12px 24px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  margin: 2px auto;
}

.section-heading-1 h2 {
  font-weight: 700;
  color: black;
  text-transform: capitalize;
  font-size: 25px;
  letter-spacing: 0;
  font-family: "Readex Pro", sans-serif;
  line-height: 1.5;
  text-align: left;
}

.section-heading-1 .talk_to_experts_btn_div {
  text-align: left;
}

.section-heading-1 h3 {
  font-weight: 700;
  color: #282828;
  text-transform: capitalize;
  font-size: 27px;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
  line-height: 1.2;
}

.section-heading-1 h4 {
  font-weight: 700;
  color: #282828;
  text-transform: capitalize;
  font-size: 28px;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
}

@media (max-width: 767px) {
  .section-heading-1 h2 {
    text-align: center;
    font-size: 20px;
  }

  .mob-width-100 {
    width: 100% !important;
  }
}

.section-heading-1 p {
  font-size: 15px;
  color: #fff;
  line-height: 25px;
  font-family: "Readex Pro", sans-serif;
  margin: 10px 0 5px;
  text-align: justify;
  font-weight: 300;
}

.section-heading-1 ul {
  list-style: none;
  padding-left: 20px;
}

.section-heading-1 li {
  font-size: 15px;
  color: #252525;
  line-height: 26px;
  font-family: Roboto, sans-serif;
}

.section-heading-1 ul li i {
  color: #2a7bc1;
  margin-right: 12px;
  font-size: 14px;
}

.blockchain_dev_aboutus_title {
  font-size: 35px !important;
}

@media (max-width: 767px) {
  .blockchain_dev_aboutus_title {
    font-size: 25px !important;
  }
}

.solution-box {
  background: #000;
  border-radius: 8px;
  padding: 45px 15px 30px 15px;
  height: 335px;
  margin: 25px;
  border: 1px solid #fff;
}

.solution-box:hover {
  box-shadow: 0 3px 4px #ddd;
  cursor: pointer;
}

.list_item li {
  margin-bottom: 15px;
  text-indent: -33px;
  font-size: 15px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.list_item li i {
  border: 2px solid #2a7bc1;
  color: #2a7bc1;
  display: inline;
  font-size: 12px;
  height: 10px;
  margin-right: 10px;
  padding: 1px;
  width: 10px;
}

.solution-box h3 {
  font-size: 18px;
  color: #333;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23px;
  font-family: "Readex Pro", sans-serif;
}

.solution-box p {
  letter-spacing: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #2f2d2d;
  width: 80%;
  text-align: center;
  display: table;
  margin: 0 auto;
}

.btn-more {
  background: #2a7bc1;
  padding: 10px 18px 10px 18px;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
}

.btn-more:hover {
  text-decoration: none;
  color: #fff;
}

.line-btm {
  width: 75px;
  height: 3px;
  background: #2a7ac1;
  border-radius: 50px;
  margin-top: 2em;
  margin-bottom: 1em;
}

.solution-box:hover {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
}

.resource-box {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}

.resource-box img {
  width: 100%;
  height: 250px;
}

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-body h4 {
  font-size: 19px;
  color: #222;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0;
}

.resource-date {
  background: #8d8d8d;
  font-size: 14px;
  padding: 7px 17px;
  color: #fff;
  border-radius: 100px;
  font-family: Roboto, sans-serif;
}

.resource-date:hover {
  text-decoration: none;
  background: #2b7dc4;
  color: #fff;
}

.read-more-para p a {
  font-style: italic;
  color: #2b7dc4;
  font-family: Roboto, sans-serif;
}

.read-more-para p a:hover {
  text-decoration: none;
}

.case-study-img h3 {
  font-size: 28px;
  font-family: Raleway, sans-serif;
  color: #0f0f0f;
  font-weight: 600;
  line-height: 34px;
}

.case-study-img p {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  font-family: Roboto, sans-serif;
}

.btn-download {
  background: #2a7bc1;
  font-size: 15px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
}

.btn-download:hover {
  color: #fff;
  text-decoration: none;
}

.contact-sec {
  padding: 20px 0;
  background-image: linear-gradient(
    to top,
    #2a7bc1,
    #2c81ca,
    #2e87d3,
    #308ddd,
    #3293e6
  );
}

.contact-cnt h4 {
  font-size: 24px;
  color: #fff;
  font-family: Raleway, sans-serif;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0;
}

.btn-contact {
  background: rgba(255, 255, 255, 0.8);
  color: #0f0f0f;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 32px;
  border-radius: 2px;
  transition: all 0.3s ease 0s;
}

.btn-contact:hover {
  text-decoration: none;
  color: #0f0f0f;
  background: #fff;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.awards-sec {
  padding: 30px 0;
}

.scroll-text {
  border: 1px solid red;
  width: 400px;
  height: 4.5em;
  overflow: hidden;
}

.scroll-text ul {
  width: 800px;
  height: 100px;
  overflow: hidden;
  margin: 0;
}

.scroll-text ul li {
  height: 1.5em;
}

.scroll-img {
  border: 1px solid red;
  width: 100%;
  height: 142px;
  overflow: hidden;
  font-size: 0;
}

.scroll-img ul {
  width: 700px;
  height: 600px;
  margin: 0;
}

.scroll-img ul li {
  display: inline-block;
  margin: 10px 0 10px 10px;
}

#demo4.scroll-img ul,
#demo5.scroll-img ul {
  width: 1500px;
}

#demo5-btn {
  width: 680px;
  padding-top: 10px;
}

#demo6 {
  width: 412px;
}

#demo6-queue {
  width: 680px;
}

#demo6 p,
#demo6-queue p {
  width: 124px;
  height: 124px;
  background-color: #d3d3d3;
  font-family: Amaranth, sans-serif;
  font-size: 82px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.case-study-img img {
  border-radius: 10px;
}

.blog .carousel-indicators {
  left: 0;
  top: auto;
  bottom: -40px;
}

.blog .carousel-indicators li {
  background: #a3a3a3;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.blog .carousel-indicators .active {
  background: #707070;
}

#blogCarousel img {
  width: auto;
  max-width: 100% !important;
  max-height: 102px;
  transform-style: preserve-3d;
}

.span-middle {
  display: table-cell;
  vertical-align: middle;
}

.md_1spn {
  display: table;
  width: 100%;
  height: 100%;
}

.bigdata-info img {
  max-width: 400px;
}

.menu-MetaBlock .dropdown-menu {
  position: absolute;
  top: 100%;
  left: -18px;
  z-index: 1000;
  float: left;
  min-width: 17rem;
  padding: 10px;
  margin: 0.7rem 1.5rem 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0;
  border-top: 0 solid #2b7bc1;
}

.menu-MetaBlock .dropdown-menu li a {
  color: #000 !important;
  font-size: 15px !important;
  padding: 6px 8px;
  text-transform: uppercase;
  letter-spacing: 0;
}

.menu-MetaBlock .dropdown-menu li {
  padding: 5px 0 5px 0;
}

.menu-MetaBlock .dropdown-menu li a:hover {
  color: #2a7bc1 !important;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.active.carousel-item-right,
.carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .active.carousel-item-right,
  .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.active.carousel-item-left,
.carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .active.carousel-item-left,
  .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev,
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.hire_page {
  font-family: Raleway, sans-serif;
}

.hire_page h1,
.hire_page h2,
.hire_page h3,
.hire_page h4,
.hire_page h5,
.hire_page h6 {
  font-family: Raleway, sans-serif;
}

.bannr_bg {
  /* background: url(public/images/hire_bg.png); */
  position: relative;
  min-height: calc(84vh - 7px);
  background-size: contain;
}

.banner_rc_img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.banner_tle h1 {
  font-size: 44px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  padding: 50px 0 20px;
  margin: 0;
}

.resp-tabs-list li span {
  display: block;
  text-align: center;
}

.hire_page section {
  background: #fff;
  padding: 0;
}

.hire_page section.hire_bannr {
  padding-top: 0;
}

.resp-tabs-list li span.tab_img {
  width: 63px;
  height: 63px;
  background: #f4f5fa;
  text-align: center;
  margin: 0 auto;
  line-height: 63px;
  border-radius: 50%;
}

.resp-tabs-list li {
  background: 0 0 !important;
  border: 0 !important;
}

.resp-tabs-list li {
  width: 16.66%;
  margin: 0;
  position: relative;
  transition: all 0.5s;
}

ul.resp-tabs-list.hor_1 {
  float: left;
  width: 100%;
}

ul.resp-tabs-list.hor_1 {
  float: left;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 90px -19px rgba(0, 0, 0, 0.8);
  margin-top: -54px;
  overflow: hidden;
}

.section_inr {
  background: #f4f5fa;
}

.resp-tabs-container,
.sect_content {
  padding: 0;
  background-color: transparent;
  clear: left;
  border: 0 !important;
  padding: 50px 32px 0;
}

.resp-tab-content {
  border: 0;
  padding: 0;
}

.resp-tabs-list li span.tab_tle {
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
}

.resp-tabs-list li.resp-tab-active {
  background: #2a7bc1 !important;
  color: #fff;
}

.resp-tabs-list li:after {
  content: "";
  position: absolute;
  right: 0;
  top: 22%;
  width: 1px;
  height: 54.62%;
  background: #f4f5fa;
}

.resp-tabs-list li.resp-tab-active::after,
.resp-tabs-list li:last-child:after {
  display: none;
}

.resp-tab-content p {
  font-size: 14px;
  color: #0f0f0f;
  line-height: 26px;
  font-family: Roboto, sans-serif;
}

.tab_logo {
  background: #fff;
  padding: 38px 0 0;
}

.tab_logo ul {
  list-style: none;
  padding: 0;
  text-align: center;
  margin-bottom: 0;
}

.tab_logo ul li {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-right: 4%;
  margin-bottom: 40px;
}

.tab_logo ul.list_2 {
  margin: 0 auto;
  max-width: 85%;
}

img {
  max-width: 100%;
}

.client_speak h3,
.hr_tle h3,
.section_h h3,
.tab_logo h3 {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 30px;
  color: #2a7bc1;
}

.tab_logo ul li:last-child,
.tab_logo ul li:nth-child(5n) {
  margin-right: 0;
}

.row.dev_pdf {
  margin: 0;
  margin-top: 38px;
}

.pdf_left {
  padding-left: 0;
}

.pdf_img {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 50px -20px rgba(0, 0, 0, 0.8);
}

.pdf_dets h4 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 18px;
  text-shadow: 0 0;
}

.pdf_dets {
  width: 94%;
}

.btn_link {
  display: inline-block;
  padding: 0 10px;
  min-width: 129px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  background: #2a7bc1;
  border-radius: 5px;
  color: #fff;
}

.btn_link.dwn_link::before {
  display: inline-block;
  content: "";
  /* background: url(../img/dwn_img.png); */
  width: 15px;
  height: 14px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: -5px;
  transition: all 0.5s;
}

.pdf_right {
  padding-right: 0;
}

.logo_items {
  text-align: center;
}

.logo_items img {
  max-width: 100%;
  width: auto !important;
  margin: 0 auto;
}

.mdl_img {
  display: table;
  width: 100%;
  height: 58px;
}

.md_img {
  display: table-cell;
  vertical-align: middle;
}

.r_logo_slider {
  padding: 0 15px;
}

.tab_logo.recnt_works {
  padding-bottom: 25px;
}

.client_speak {
  padding: 38px 0;
}

.cl_dets {
  max-width: 341px;
  text-align: center;
  position: relative;
}

.cl_spk {
  padding: 20px 9px 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 50px -20px rgba(0, 0, 0, 0.8);
  font-size: 14px;
  transition: all 0.5s;
}

.cl_img {
  position: absolute;
  bottom: -70px;
  left: 0;
  width: 100%;
}

.cl_img span {
  display: block;
  margin: 0 auto;
  width: 81px;
  height: 81px;
  border: 5px solid #f4f5fa;
  border-radius: 50%;
  overflow: hidden;
}

.c_items {
  padding: 42px 15px 50px;
  position: relative;
  top: 0;
  transition: all 0.5s;
  outline: 0 !important;
}

.clent_slides {
  margin: 0 -15px;
}

.c_items.slick-slide.slick-current.slick-active.slick-center {
  top: -28px;
}

.c_items.slick-slide.slick-current.slick-active.slick-center .cl_spk {
  background: #2a7ac1;
  background: -moz-linear-gradient(top, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0, #2a7ac1),
    color-stop(41%, #2a7ac1),
    color-stop(100%, #3497ee)
  );
  background: -webkit-linear-gradient(
    top,
    #2a7ac1 0,
    #2a7ac1 41%,
    #3497ee 100%
  );
  background: -o-linear-gradient(top, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  background: -ms-linear-gradient(top, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  background: linear-gradient(to bottom, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  color: #fff;
}

ul.slick-dots {
  padding: 0;
  text-align: center;
  list-style: none;
  font-size: 0;
  position: relative;
  top: 30px;
}

ul.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

ul.slick-dots li button {
  border: 0;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  padding: 0;
  transition: all 0.5s;
}

ul.slick-dots li.slick-active button {
  background: #2b7ec6;
}

.section_h h3 {
  margin: 40px 0;
}

.exprt_tle h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin: 20px 0;
  letter-spacing: 0;
}

.exp_list {
  margin-bottom: 10px;
}

.hr_tle h3 {
  color: #fff;
}

.hire_page section.hire_resource {
  background: #2a7ac1;
  background: -moz-linear-gradient(top, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0, #2a7ac1),
    color-stop(41%, #2a7ac1),
    color-stop(100%, #3497ee)
  );
  background: -webkit-linear-gradient(
    top,
    #2a7ac1 0,
    #2a7ac1 41%,
    #3497ee 100%
  );
  background: -o-linear-gradient(top, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  background: -ms-linear-gradient(top, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
  background: linear-gradient(to bottom, #2a7ac1 0, #2a7ac1 41%, #3497ee 100%);
}

.hr_tle h3 {
  color: #fff;
  margin: 22px 0;
  font-weight: 600;
}

.hr_tle {
  color: #fff;
  text-align: center;
}

.h_left_c,
.h_right_c {
  display: inline-block;
  vertical-align: top;
}

.h_left_c span {
  width: 111px;
  height: 111px;
  display: block;
  background: #b1e8f8;
  border-radius: 50%;
  text-align: center;
  line-height: 111px;
  transition: all 0.5s;
  overflow: hidden;
}

.hire_content:hover span {
  background: #f4f5fa;
}

.hire_content {
  color: #fff;
}

.h_right_c {
  width: 66%;
  padding-left: 25px;
}

.h_right_c h5 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.btn_link.h_btn_hr {
  background: #9f9f9f;
  background: -moz-linear-gradient(top, #9f9f9f 0, #fff 0, #9f9f9f 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0, #9f9f9f),
    color-stop(0, #fff),
    color-stop(100%, #9f9f9f)
  );
  background: -webkit-linear-gradient(top, #9f9f9f 0, #fff 0, #9f9f9f 100%);
  background: -o-linear-gradient(top, #9f9f9f 0, #fff 0, #9f9f9f 100%);
  background: -ms-linear-gradient(top, #9f9f9f 0, #fff 0, #9f9f9f 100%);
  background: linear-gradient(to bottom, #9f9f9f 0, #fff 0, #9f9f9f 100%);
  color: #000;
  font-weight: 600;
}

.hr_btn {
  margin: 40px 0 30px;
}

.cuntr_left,
.cuntr_righ {
  display: inline-block;
  width: 50%;
  margin-right: -3px;
}

.hr_counter ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  max-width: 85%;
  margin-left: 0;
}

.hr_counter ul li {
  display: inline-block;
  width: 50%;
  margin-left: -3px;
  position: relative;
  vertical-align: top;
}

.hr_counter {
  position: relative;
  z-index: 9;
}

.hr_counter .cuntr_righ ul {
  margin-left: auto;
  margin-right: 0;
}

.hr_counter p,
.rc_countr p {
  color: #fff;
  margin: 0;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  padding: 0 15px;
}

.hr_counter p span,
.rc_countr span {
  font-size: 60px;
}

.hr_counter ul li.aft_dvd::after {
  content: "";
  width: 1px;
  height: 46px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 50%;
}

.rc_countr {
  position: absolute;
  bottom: 20%;
  left: 0;
  width: 100%;
  text-align: center;
}

.rc_inr {
  margin: 0 auto;
  max-width: 19%;
}

.rc_countr p {
  color: #000;
}

.btn_link.dwn_link:hover {
  background: #fff;
  box-shadow: 0 8px 50px -10px rgba(0, 0, 0, 0.5);
}

.btn_link.dwn_link:hover::before {
  /* background: url(../img/dwn_img_hvr.png) */
}

/*.navbar-nav li.nav-item a.nav-link {
    margin-top: 30px;
    position: relative
}*/

/*.navbar-nav li.nav-item a.nav-link:after {
    content: '';
    width: 100%;
    height: 3px;
    background: #2a7bc1;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -12px;
    left: 0;
    transition: all .5s
}*/

a.nav-link.btn-hire:after {
  display: none;
}

.navbar-nav li.nav-item a.nav-link:hover:after {
  opacity: 1;
  visibility: visible;
}

.logo_main {
  width: auto;
}

.cbp-af-header.sticky .navbar-brand img {
  width: auto !important;
  max-width: 75%;
}

#blogCarousel img {
  margin: 0 auto;
}

.md_1spn {
  height: 102px;
}

.carousel-item {
  padding: 0 10px;
}

.section-heading-1 h2.awrd_tle {
  margin-bottom: 35px;
  color: #2a7bc1;
}

.navbar-nav li {
  padding-left: 0;
}

.navbar-nav li {
  padding-left: 0;
}

.cbp-af-header.sticky .nav > li > a {
  margin: 0 6px;
  margin-top: 0;
}

.h_left_c span img {
  transition: all 0.5s;
  position: relative;
  top: 0;
}

.hire_content:hover img.normal_img {
  top: -100%;
}

.hire_content:hover img.hvr_img {
  top: -100%;
}

.navbar-nav li:hover ul.dropdown-menu {
  display: block;
}

.expr_img {
  text-align: center;
}

body .owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}

@media screen and (max-width: 1199px) {
  .ttmenu .nav > li > a {
    margin: 0 9px;
    font-size: 12px;
  }

  .cbp-af-header.sticky .nav > li > a {
    margin: 0 9px;
  }

  .rc_inr {
    max-width: 30%;
  }

  .hr_counter p,
  .rc_countr p {
    font-size: 15px;
  }

  .hr_counter p span,
  .rc_countr span {
    font-size: 45px;
  }

  .hire_page .container {
    width: 100%;
  }

  .exprt_tle h4 {
    font-size: 15px;
  }

  .bannr_bg {
    min-height: 455px;
  }

  .ent_list {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 991px) {
  .navbar-header {
    width: 100%;
    text-align: center;
  }

  .logo_main {
    float: none;
    display: inline-block;
    height: auto;
  }

  .navbar-nav {
    float: none !important;
    margin-right: 0;
    padding: 0 !important;
    text-align: center;
  }

  .navbar-nav > li {
    float: none;
    display: inline-block;
  }

  .hr_counter ul {
    max-width: 100%;
  }

  .cuntr_left,
  .cuntr_righ {
    width: 100%;
    margin-right: 0;
  }

  .rc_countr {
    bottom: 0;
  }

  .hire_content,
  .pdf_left,
  .pdf_right {
    width: 50%;
  }

  .cbp-af-header.sticky .navbar-brand img {
    width: 100%;
  }

  h2.resp-accordion {
    font-size: 13px;
    border: 0;
    border-top: 0 solid #c1c1c1;
    margin: 0;
    padding: 0 !important;
  }

  h2.resp-accordion:first-child {
    border-top: 0;
  }

  span.tab_tle {
    display: inline-block;
    vertical-align: middle;
    padding-left: 15px;
    font-weight: 600;
  }

  h2.resp-accordion {
    background: #fff !important;
    padding: 10px !important;
  }

  h2.resp-tab-active {
    padding: 10px !important;
    border: 0 !important;
  }

  span.tab_img {
    display: inline-block;
    vertical-align: middle;
    width: 23%;
    text-align: center;
  }

  .resp-tab-content {
    padding: 10px;
  }

  .resp-tabs-container,
  .sect_content {
    padding: 30px 15px 0;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .resource-box {
    padding: 0 15px;
  }

  .btn-download {
    display: inline-block;
  }

  .case-study-img h3 {
    margin-top: 0;
  }

  .case-study-img {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .rc_inr {
    max-width: 100%;
  }

  .hire_content {
    width: 100%;
    margin-bottom: 15px;
  }

  .navbar-header {
    text-align: left;
  }

  .navbar-nav {
    text-align: left;
  }

  .navbar-nav > li {
    display: block;
  }

  .hr_counter p span,
  .rc_countr span {
    font-size: 38px;
  }

  .banner_tle h1 {
    font-size: 40px;
  }

  .logo_main {
    max-width: 70%;
  }

  .tab_logo {
    padding: 5px 0 0;
  }

  .client_speak h3,
  .hr_tle h3,
  .section_h h3,
  .tab_logo h3 {
    padding: 0 10px;
  }

  .pdf_left,
  .pdf_right {
    padding: 0;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
  .hr_counter ul li {
    width: 100%;
    margin-bottom: 10px;
  }

  .hr_counter ul li.aft_dvd::after {
    display: none;
  }

  .banner_rc_img {
    position: relative;
  }

  .hr_counter p span,
  .rc_countr span {
    font-size: 28px;
  }

  .h_left_c,
  .h_right_c {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }

  .h_left_c span {
    margin: 0 auto;
  }

  .hire_content,
  .pdf_left,
  .pdf_right {
    width: 100%;
  }

  .banner_tle h1 {
    padding: 50px 10px 20px;
    font-size: 30px;
  }

  .bigdata-info img {
    max-width: 100%;
  }

  header.masthead-ai {
    padding-top: 30px;
  }

  header.masthead-ai .header-content h1 {
    font-size: 40px !important;
  }
}

.navbar-nav li:hover ul.dropdown-menu {
  display: block;
}

.aditional_header .cbp-af-header.sticky .nav > li > a {
  margin: 0 10px;
}

.social .fb a,
.social .googleplus a,
.social .linkedin a,
.social .tw a {
  line-height: 39px;
  border: 0;
  text-decoration: none;
}

.aditional_header .cbp-af-header.sticky .nav > li > a.btn-hire {
  padding: 7px 6px !important;
}

@media screen and (max-width: 1260px) {
  .aditional_header .cbp-af-header .ttmenu .nav > li > a,
  .aditional_header .cbp-af-header.sticky .nav > li > a {
    margin: 0 9px;
  }
}

@media screen and (max-width: 1199px) {
  .ttmenu .nav > li > a {
    margin: 0 9px;
    font-size: 12px;
    border-bottom: 1px solid;
  }

  .cbp-af-header.sticky .nav > li > a {
    margin: 0 9px;
  }

  .rc_inr {
    width: 30%;
  }

  .hr_counter p,
  .rc_countr p {
    font-size: 15px;
  }

  .hr_counter p span,
  .rc_countr span {
    font-size: 45px;
  }

  .aditional_header .cbp-af-header.sticky .nav > li > a {
    margin: 0 7px;
  }

  .aditional_header .ttmenu .nav > li > a {
    margin: 0 6px;
    font-size: 12px;
  }
}

.mid_section .box_exchange,
.mid_section .box_exchange:hover {
  /*border:none!important;box-shadow:unset!important*/
}

.work_with_box {
  margin: 30px 0;
  padding: 20px;
  background-color: #000;
  /*box-shadow: 0 3px 4px #ddd;*/
  height: 370px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/*.work_with_box:hover {
    box-shadow: 0 11px 18px #ddd
}*/

.work_with_box .inr_img {
  display: block;
  margin: 10px auto;
  border-radius: 100%;
  -o-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  line-height: 80px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.ver_mid {
  display: inline-block;
  vertical-align: middle;
}

.inr_img img {
  max-width: 80px;
}

.exc_img_div img {
  max-width: 100%;
}

@media (min-width: 767px) {
  #accordion .panel-title a:after {
    content: "";
    width: 1px;
    height: 100%;
    background: #ececec;
    position: absolute;
    top: 0;
    right: 55px;
  }

  .hgt_350_desktop {
    height: 350px;
  }
}

.work_with_box h3 {
  font-size: 18px;
  color: #0f0f0f;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin: 10px 0;
}

.work_with_box p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}

.img-middle {
  display: block;
  margin: 0 auto;
}

section.mid_section {
  padding: 50px 0;
}

.grey-bg {
  background: #000;
}

.hire-para {
  margin-top: 30px;
  color: #c2bdbd !important;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}

.mob-text-center {
  text-align: center;
  margin-top: 30px;
}

.whybottom_box {
  padding: 30px 10px;
  background-color: #ddd;
  text-align: center;
  position: relative;
  z-index: 0;
  margin: 15px 0;
  height: 360px;
}

.whybottom_box1 {
  background-color: #3bbeb0;
}

.ondemand__app__sec .whybottom_box {
  background-color: #3bbeb0;
  min-height: 100px !important;
}

.whybottom_box:before {
  position: absolute;
  width: 0%;
  height: 0%;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.05);
}

.whybottom_box:after {
  position: absolute;
  width: 0%;
  height: 0%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.05);
}

.whybottom_box h3 {
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin-top: 30px;
}

.whybottom_box .inr_img {
  width: 83px;
  height: 83px;
  line-height: 80px;
  margin: 20px auto;
  background-color: #fff;
  text-align: center;
  display: block;
  position: relative;
  z-index: 0;
  border-radius: 100%;
  -o-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
}

.whybottom_box:hover .inr_img:after {
  transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  opacity: 1;
  -o-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -ms-opacity: 1;
}

.whybottom_box:hover:after,
.whybottom_box:hover:before {
  width: 100%;
  height: 100%;
}

.whybottom_box p {
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  margin: 0;
}

.whybottom_box2 {
  background-color: #3bb1d9;
}

.whybottom_box3 {
  background-color: #d9434e;
}

.whybottom_box4 {
  background-color: #f89433;
}

.whybottom_box5 {
  background-color: #3bb85d;
}

.whybottom_box6 {
  background-color: #7277d5;
}

.list-heading {
  font-size: 16px;
  color: #282828;
  text-align: left;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  font-family: Raleway, sans-serif;
}

.white-bg {
  background: #fff !important;
}

@media (min-width: 1200px) {
  .ht-480 {
    height: 480px !important;
  }
}

@media (max-width: 767px) {
  .work_with_box {
    height: auto;
  }

  .ht-480 {
    height: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sm-ht-400 {
    height: 400px !important;
  }
}

@media (max-width: 767px) {
  .solution-box {
    height: auto !important;
  }

  header.masthead-ai .header-content h1,
  header.masthead-business .header-content h1,
  header.masthead-chatbot .header-content h1,
  header.masthead-crypto .header-content h1,
  header.masthead-deep .header-content h1,
  header.masthead-digital .header-content h1,
  header.masthead-finance .header-content h1,
  header.masthead-hyper .header-content h1,
  header.masthead-identity .header-content h1,
  header.masthead-iot .header-content h1,
  header.masthead-legal .header-content h1,
  header.masthead-mobility .header-content h1,
  header.masthead-php .header-content h1 {
    font-size: 26px !important;
    line-height: 1.5;
  }
}

.solution-box img {
  display: table;
  margin: 0 auto;
}

.solution-box .line-btm {
  display: none;
}

@media (min-width: 1200px) {
  .work_with_box.ht-lg-355 {
    height: 355px !important;
  }

  .work_with_box.ht-lg-340 {
    height: 340px !important;
  }

  .solution-box.ht-350 {
    height: 350px !important;
  }

  .lg-ht-280 {
    height: 280px;
  }

  .ht-380 {
    height: 380px !important;
  }
}

h3 {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23px;
  font-family: Raleway, sans-serif;
}

.center-img-cnt img {
  display: table;
  margin: 15px auto;
}

header.masthead-mobility {
  position: relative;
}

header.masthead-mobility:after {
  position: absolute;
  content: "";
}

.hire-btn {
  display: table;
  background: #bd4a1e;
  color: #fff;
  padding: 10px 15px;
  margin: 0 auto;
  border-radius: 3px;
  min-width: 150px;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.hire-btn:hover {
  color: #fff !important;
  background: #bd4a1e;
}

.expert-btn {
  display: inline-block;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  color: #fff;
  padding: 10px 20px;
  margin: 30px auto;
  border-radius: 50px;
  min-width: 200px;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding-top: 15px;
  border: none;
}

.expert-btn:hover {
  color: #fff !important;
  border: 1px solid #000;
  background: #000;
}

.center-img-cnt h3 {
  text-align: center;
}

.hire-btn-cnt {
  margin-top: -30px;
}

.mid_section p {
  text-align: justify;
}

.blockchain__algo__sec .blockchain__algo__div .blockchain__algo__ul li {
  line-height: 2.2;
  font-size: 15px;
  list-style: none;
}

.blockchain__algo__sec
  .blockchain__algo__div
  .blockchain__algo__ul
  li
  i.fa-check-circle {
  color: #205a5d;
  margin-right: 10px;
}

.blockchain_algo .sol-box {
  position: relative;
  height: 25rem;
  margin-top: 20px;
  background: #fff;
  padding: 20px 20px;
  transition: all 0.3s ease 0s;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  box-shadow: 0 2px 4px 0 rgba(2, 2, 3, 0.09);
}

.blockchain_algo .al-sol-icon {
  text-align: left;
}

.blockchain_algo .sol-box h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-top: 10px;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center !important;
}

.cbp-af-header.sticky .navbar-brand img {
  width: 180px !important;
}

.breadcrumb-sec,
.breadcrumb-sec .breadcrumb {
  background: #000 !important;
}

.breadcrumb-sec li a {
  color: #007bff;
}

.breadcrumb-sec ol.breadcrumb {
  margin-bottom: 0;
  padding: 10px 2px;
}

.breadcrumb > li {
  font-size: 14px;
  font-weight: 700;
  display: inline;
  text-transform: capitalize;
}

label.error {
  color: red !important;
  font-weight: 400;
  font-size: 15px;
}

.header-shadow {
  position: relative;
}

.header-shadow:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
}

.heading-tagline {
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 3rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  .work_with_box {
    height: 380px;
  }
}

@media (min-width: 767px) {
  .services_sec {
    width: 1000px;
  }
}

.mt40 {
  margin-top: 40px !important;
}

.order-exchange .order-type-box {
  min-height: auto;
  margin-bottom: 30px;
  padding-bottom: 40px;
  background: #f5f5f5;
  border-radius: 5px;
  border: 2px solid #eee;
  border-bottom-color: #47b475;
}

.order-exchange .feature-box-3 .icon {
  background: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.order-exchange .feature-box-3 .common_h4 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.quick_p {
  font-size: 20px;
}

.tabbable-panel {
  padding: 10px;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0;
}

.tabbable-line > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #737373;
  text-align: center;
}

.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open,
.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid #5090f7;
}

.tabbable-line > .nav-tabs > li.open > a,
.tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: 0 0 !important;
  color: #333;
}

.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0;
}

.tabbable-line > .nav-tabs > li {
  opacity: 0.6;
  float: none;
  display: inline-block;
  padding: 0 14px;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #32465b;
  position: relative;
  opacity: 1;
}

.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333;
}

.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}

.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #000;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}

.tabbable-line li.wds .list-title {
  font-size: 18px;
  font-weight: 700;
}

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.tab-content .tech-link {
  color: #2a7bc1;
  font-size: 15px;
  font-weight: 600;
}

.tabs_solutions .wds.wds-ico-4 a::before {
  background-position: 0 -1292px !important;
  width: 74px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-4.active a::before,
.tabs_solutions .wds.wds-ico-4:hover a::before {
  background-position: -96px -1292px !important;
}

.tabs_solutions .wds.wds-ico-5 a::before {
  background-position: 0 -1352px !important;
  width: 74px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-5.active a::before,
.tabs_solutions .wds.wds-ico-5:hover a::before {
  background-position: 0 -1423px !important;
}

.tabs_solutions .wds.wds-ico-7 a::before {
  background-position: -174px -1352px !important;
  width: 74px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-7.active a::before,
.tabs_solutions .wds.wds-ico-7:hover a::before {
  background-position: -173px -1420px !important;
}

.tabs_solutions .wds.wds-ico-10 a::before {
  background-position: -378px -1350px !important;
  width: 74px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-10.active a::before,
.tabs_solutions .wds.wds-ico-10:hover a::before {
  background-position: -378px -1423px !important;
}

.tabs_solutions .wds.wds-ico-11::before {
  background-position: -512px -1300px !important;
}

.tabs_solutions .wds.wds-ico-12::before {
  background-position: -468px -1394px !important;
}

.tabs_solutions .wds.wds-ico-13::before {
  background-position: -18px -1508px !important;
}

.tabs_solutions .wds.wds-ico-14::before {
  background-position: -108px -1508px !important;
}

.tabs_solutions .wds.wds-ico-15::before {
  background-position: -198px -1508px !important;
}

.tabs_solutions .wds.wds-ico-16::before {
  background-position: -288px -1508px !important;
}

.tabs_solutions .wds.wds-ico-41::before {
  background-position: -198px -1868px !important;
}

.tabs_solutions .wds.wds-ico-42::before {
  background-position: -288px -1868px !important;
}

.tabs_solutions .wds.wds-ico-43::before {
  background-position: -378px -1868px !important;
}

.tabs_solutions .wds.wds-ico-44::before {
  background-position: -468px -1868px !important;
}

.tabs_solutions .wds.wds-ico-45::before {
  background-position: -18px -1958px !important;
}

.tabs_solutions .wds.wds-ico-46::before {
  background-position: -108px -1958px !important;
}

.tabs_solutions .wds.wds-ico-47::before {
  background-position: -198px -1958px !important;
}

.tabs_solutions .wds.wds-ico-48::before {
  background-position: -184px -4px !important;
}

.tabs_solutions .wds.wds-ico-49::before {
  background-position: -3px -5px !important;
}

.tabs_solutions .wds.wds-ico-50::before {
  background-position: -92px -3px !important;
}

.tabs_solutions .wds.wds-ico-51::before {
  background-position: -275px -3px !important;
}

.tabs_solutions .wds.wds-ico-52::before {
  background-position: -364px -3px !important;
}

.tabs_solutions .wds.wds-ico-53::before {
  background-position: -455px -3px !important;
}

.tabs_solutions .wds.wds-ico-54::before {
  background-position: -3px -93px !important;
}

.tabs_solutions .wds.wds-ico-55::before {
  background-position: -93px -93px !important;
}

.tabs_solutions .wds.wds-ico-56::before {
  background-position: -184px -93px !important;
}

.tabs_solutions .wds.wds-ico-24::before {
  background-position: -466px -1600px !important;
}

.tabs_solutions .wds.wds-ico-25::before {
  background-position: -467px -1509px !important;
}

.tabs_solutions .wds.wds-ico-26::before {
  background-position: -19px -1687px !important;
}

.tabs_solutions .wds.wds-ico-27::before {
  background-position: -378px -1600px !important;
}

.tabs_solutions .wds.wds-ico-28::before {
  background-position: -198px -1688px !important;
}

.tabs_solutions .wds.wds-ico-29::before {
  background-position: -288px -1688px !important;
}

.tabs_solutions .wds.wds-ico-30::before {
  background-position: -378px -1688px !important;
}

.tabs_solutions .wds.wds-ico-31::before {
  background-position: -468px -1688px !important;
}

.tabs_solutions .wds.wds-ico-32::before {
  background-position: -18px -1778px !important;
}

.tabs_solutions .wds.wds-ico-33::before {
  background-position: -108px -1778px !important;
}

.tabs_solutions .wds.wds-ico-34::before {
  background-position: -198px -1778px !important;
}

.tabs_solutions .wds.wds-ico-35::before {
  background-position: -288px -1778px !important;
}

.tabs_solutions .wds.wds-ico-36::before {
  background-position: -378px -1778px !important;
}

.tabs_solutions .wds.wds-ico-37::before {
  background-position: -468px -1778px !important;
}

.tabs_solutions .wds.wds-ico-38::before {
  background-position: -18px -1868px !important;
}

.tabs_solutions .wds.wds-ico-39::before {
  background-position: -18px -1868px !important;
}

.tabs_solutions .wds.wds-ico-40::before {
  background-position: -108px -1868px !important;
}

.tabs_solutions .wds.wds-ico-17::before {
  background-position: -378px -1508px !important;
}

.tabs_solutions .wds.wds-ico-18::before {
  background-position: -86px -1410px !important;
}

.tabs_solutions .wds.wds-ico-20::before {
  background-position: -18px -1598px !important;
}

.tabs_solutions .wds.wds-ico-21::before {
  background-position: -108px -1598px !important;
}

.tabs_solutions .wds.wds-ico-22::before {
  background-position: -198px -1598px !important;
}

.tabs_solutions .wds.wds-ico-23::before {
  background-position: -288px -1598px !important;
}

.tabs_solutions .wds.wds-ico-8 a::before {
  background-position: -241px -1350px !important;
  width: 74px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-8.active a::before,
.tabs_solutions .wds.wds-ico-8:hover a::before {
  background-position: -241px -1422px !important;
}

.tabs_solutions .wds.wds-ico-9 a::before {
  background-position: -306px -1350px !important;
  width: 74px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-9.active a::before,
.tabs_solutions .wds.wds-ico-9:hover a::before {
  background-position: -308px -1425px !important;
}

.tabs_solutions .wds.wds-ico-6 a::before {
  background-position: -86px -1350px !important;
  width: 83px;
  height: 74px;
}

.tabs_solutions .wds.wds-ico-6.active a::before,
.tabs_solutions .wds.wds-ico-6:hover a::before {
  background-position: -86px -1420px !important;
}

li.hire-dd-icon a:before {
  /* background: url(../images/ps-new-icon-min.png) no-repeat; */
  content: "";
  display: block;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
  width: 50px;
  top: -10px;
}

.tabs_solutions {
  padding: 20px 0 20px;
}

.tabs_solutions .wds a::before {
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.hire-dd-technology {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hire-dd-technology .hire-dd-tech-box {
  margin: 0 15px;
  padding-top: 20px;
}

.hire-dd-tech-box {
  padding: 30px 10px 20px;
}

.tabs_solutions .hire-dd-box-text::before {
  height: 84px;
  width: 84px;
}

.tabs_solutions .hire-dd-tech-box .hire-dd-box-text:before {
  /* background: url(../images/ps-new-icon-min.png) no-repeat; */
  content: "";
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
  top: -10px;
  z-index: 1;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.sol-website .sol-box {
  position: relative;
  height: 340px;
  background: #000;
  padding: 20px 20px;
  transition: all 0.3s ease 0s;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  box-shadow: 0 2px 4px 0 rgba(2, 2, 3, 0.09);
}

@media (max-width: 767px) {
  .sol-website .sol-box {
    height: auto;
  }

  .webd-intro-sprite {
    background-size: 102rem !important;
    height: 235px !important;
    background-position: -598px -449px !important;
  }

  header.masthead-custom-web-design-development .header-content h1,
  header.masthead-custom-web-design-development
    .header-content
    .heading-tagline {
    text-align: center !important;
  }

  .webdesi_bannerbtn {
    text-align: center;
  }

  .about#aboutus {
    background: unset !important;
    padding-bottom: 20px;
    text-align: center;
  }

  .about#aboutus .section-heading-1 {
    text-align: center !important;
  }

  .about#aboutus .get_demo_slick_btn {
    float: none;
  }

  .mid_section#webapp_solutuions {
    padding: 0px !important;
    background-color: #f1f1f1;
    background-image: unset !important;
  }

  #webdesign_frameworks .sol-box {
    margin-top: 82px;
    height: auto !important;
  }

  #webdesign_industry .row:nth-child(2),
  #webdesign_industry .row:nth-child(3) {
    display: block !important;
  }

  #webdesign_industry .webdesign_indusbox {
    margin-bottom: 20px;
  }

  #webdesign_industry,
  #webportel_dev,
  #adapwebdev_solution {
    padding-bottom: 0px !important;
  }

  #webportel_dev .sol-box {
    padding: 0 !important;
    margin-bottom: 40px;
  }

  #webdesign_frameworks .row:nth-child(2) {
    margin-top: 0 !important;
  }

  #webdesign_jsframeworks .sol-box {
    height: auto !important;
  }
}

@media (max-width: 1280px) {
  .about#aboutus {
    background-position: 356px 0px !important;
  }
}

header.masthead-custom-web-design-development .header-content h1,
header.masthead-custom-web-design-development .header-content .heading-tagline {
  text-align: left;
}

header.masthead-custom-web-design-development .header-content .heading-tagline {
  font-weight: 400;
  font-size: 18px;
}

.about.webdev_top_ratesec#aboutus {
  /*background: url(../images/inner-pages/custom_web_design_development/top-rated-web-development-company.jpg);*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

#webapp_solutuions .section-heading-1 h2 {
  color: #060606;
}

.mid_section#webapp_solutuions {
  /* background-image: url(../images/inner-pages/custom_web_design_development/enhance-your-brand-with-our-enterprise-app-and-web-solutions.jpg?v2); */
  background-position: center;
  padding: 70px;
  filter: invert(1);
}

#premium_webservice {
  background-image: radial-gradient(
      circle at center center,
      transparent,
      rgb(12, 11, 11)
    ),
    repeating-linear-gradient(
      135deg,
      rgb(12, 11, 11) 0px,
      rgb(12, 11, 11) 2px,
      transparent 2px,
      transparent 10px,
      rgb(12, 11, 11) 10px,
      rgb(12, 11, 11) 11px,
      transparent 11px,
      transparent 21px
    ),
    repeating-linear-gradient(
      45deg,
      rgb(51, 50, 50) 0px,
      rgb(51, 50, 50) 4px,
      transparent 4px,
      transparent 8px
    ),
    linear-gradient(90deg, rgb(12, 11, 11), rgb(12, 11, 11));
  padding-top: 50px;
  padding-bottom: 80px;
}

.country_prem_services {
  padding-top: 30px !important;
  padding-bottom: 80px !important;
}

#premium_webservice .section-heading-1 h2 {
  color: #fff;
  padding: 0;
  margin: 0;
  text-align: center;
}

#webdesign_jsframeworks {
  background-color: #000;
}

#webdesign_jsframeworks .section-heading-1 h2 {
  color: #fff;
  text-align: center;
}

#webdesign_jsframeworks .webjsframeworks_icons {
  float: right;
}

#webdesign_jsframeworks .sol-box {
  border-radius: 4px;
  height: 285px;
}

#webdesign_jsframeworks .sol-box .desc {
  min-height: 135px;
  margin-top: 23px;
}

#webdesign_jsframeworks .sol-box p {
  margin-bottom: 0;
}

#webdesign_jsframeworks .sol-box a.rd_mr_btn {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  padding: 10px;
  border-radius: 50px;
  min-width: 120px;
  color: #fff;
}

#webdesign_industry .webdesign_indusbox p {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

#webdesign_industry .webdesign_indusbox {
  text-align: center;
  border: 1px solid #d6d6d6;
  padding: 15px 0px;
  border-radius: 4px;
  box-shadow: 0px 7px 9px -6px #6d6d6d;
}

#webdesign_industry .section-heading-1 p {
  text-align: center;
}

#webdesign_industry .row:nth-child(2),
#webdesign_industry .row:nth-child(3) {
  display: flex;
  justify-content: center;
}

#webdesign_frameworks .sol-box {
  border: 1px solid #ad1a48;
  background-color: #000;
  border-radius: 4px;
  height: 265px;
  text-align: center;
  min-height: 290px;
}

#webdesign_frameworks .sol-box .title,
#webdesign_frameworks .sol-box .desc,
#webdesign_frameworks .sol-box .p {
  text-align: center;
}

.mid_section#webdesign_frameworks p {
  text-align: center;
  margin-bottom: 0;
}

#webdesign_frameworks .row:nth-child(2) {
  margin-bottom: 5rem;
  margin-top: 5rem;
}

#webdesign_frameworks .webdframeworks_icons {
  margin: 0 auto;
  margin-top: -69px;
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid #ae2a53;
  width: 85px;
  height: 85px;
  text-align: center;
  padding: 10px;
}

#webdesign_frameworks .sol-box a.rd_mr_btn {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  padding: 10px;
  border-radius: 50px;
  min-width: 120px;
  color: #fff;
}

#webdesign_frameworks,
#webdesign_jsframeworks,
#webdesign_industry,
#webportel_dev,
#adapwebdev_solution,
#custweb_servs {
  padding-top: 30px;
  padding-bottom: 80px;
}

#webportel_dev .sol-box {
  border: 0;
  box-shadow: unset;
  text-align: center;
  background: unset;
  text-align: center;
}

#webportel_dev .sol-box .title,
#webportel_dev .sol-box .desc,
#webportel_dev .sol-box p {
  text-align: center;
}

#webportel_dev .sol-box .title-write {
  margin-top: 5px;
}

#webportel_dev {
  background: #000;
}

.webporteldev_icons {
  text-align: center;
}

#webportel_dev .sol-box {
  height: 290px;
}

.webporteldev_hr {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  width: 90px;
  margin: 0 auto;
  height: 1px;
  margin-bottom: 10px;
}

#webportel_dev .sol-box a.rd_mr_btn {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  padding: 10px;
  border-radius: 50px;
  min-width: 120px;
  color: #fff;
}

#webportel_dev .container .row:nth-child(2) {
  margin-bottom: 40px;
}

#webportel_dev .sol-box .desc {
  min-height: 130px;
}

#adapwebdev_solution {
  /* background: url(../images/inner-pages/custom_web_design_development/solutions-bg.jpg);*/
}

#adapwebdev_solution .sol-box {
  border-radius: 4px;
  min-height: 280px;
  height: 350px;
}

#adapwebdev_solution .sol-box .title,
#adapwebdev_solution .sol-box .desc {
  text-align: center;
}

#webdev_process img {
  width: 80%;
  filter: contrast(0.5);
}

#webdev_process .section-heading-1 p {
  text-align: center;
}

#custweb_servs .custserv_ictit {
  display: flex;
  align-items: center;
}

#custweb_servs .sol-box {
  height: auto;
  min-height: 165px;
}

#custweb_servs {
  background: #000;
}

.webdesdev_hiresec {
  background: #000;
  padding: 50px 0px;
}

.webdesign_hire_sprite {
  width: 120px;
  height: 110px;
  object-fit: none;
}

.webdhire_content {
  color: #fff;
  text-align: center;
}

.webdhire_content .webdesdev_hire_title {
  color: #fff;
  font-size: 45px;
  text-align: center;
  text-transform: capitalize;
}

.webdhire_content p {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 0;
}

.webdhirebox {
  text-align: center;
}

.webdhirebox p {
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
}

.webdesdev_hiresec .row:nth-child(2),
.webdesdev_hiresec .row:nth-child(3) {
  margin-top: 2.5rem;
}

.sol-website#premium_webservice .sol-box .sol-icon {
  text-align: left;
}

@media (max-width: 767px) {
  .webdhire_content .webdesdev_hire_title {
    font-size: 28px;
  }

  .webdhire_content p {
    width: 100%;
  }

  .webdhirebox {
    margin-bottom: 19px;
  }
}

.premium_webservice_sprite {
  width: 80px;
  height: 80px;
  object-fit: none;
}

.webdesign_frameworks_sprite {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.webdesign_industry_sprite {
  width: 50px;
  height: 50px;
  object-fit: none;
}

.webportel_dev_sprite {
  width: 70px;
  height: 70px;
  object-fit: none;
}

.adapwebdev_solution_sprite {
  width: 80px;
  height: 80px;
  object-fit: none;
}

.custweb_servs_sprite {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.sol-website .sol-box .sol-icon {
  text-align: right;
}

.sol-website .sol-box .title-write {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  line-height: 25px;
}

.sol-website .sol-box .desc {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 0px;
}

.cc-slider {
  margin: 35px auto 0;
  max-width: 850px;
  position: relative;
  -webkit-transform: translateZ(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.hire-dd-slide-manu #six-slides input {
  filter: grayscale(100%);
}

.hire-dd-slide-manu #six-slides input:checked {
  filter: grayscale(0);
}

.hire-dd-slide-manu .cc-slider input {
  display: flex;
  margin: 0 auto;
  width: 25%;
  float: left;
  visibility: hidden;
  margin-bottom: 50px;
}

.tabs_solutions .cc-slider input {
  margin: 0 auto 85px !important;
  width: 33.33%;
}

.hire-dd-slide-manu #six-slide1::before {
  background-position: 0 -1422px !important;
  width: 74px;
  height: 74px;
}

.hire-dd-slide-manu #six-slide2::before {
  background-position: -170px -1422px !important;
  width: 74px;
  height: 74px;
}

.hire-dd-slide-manu #six-slide3::before {
  background-position: -378px -1422px !important;
  width: 83px;
  height: 74px;
}

.hire-dd-slide-manu #six-slide4::before {
  background-position: -241px -1422px !important;
  width: 74px;
  height: 74px;
}

.hire-dd-slide-manu #six-slide5::before {
  background-position: -306px -1422px !important;
  width: 74px;
  height: 74px;
}

.hire-dd-slide-manu #six-slide6::before {
  background-position: -86px -1422px !important;
  width: 74px;
  height: 74px;
}

.hire-dd-slide-manu .cc-slider input:checked:before {
  border-bottom: 2px solid #0087b2;
  padding-bottom: 2px;
}

.hire-dd-slide-manu input:before {
  /* background: url(../images/ps-new-icon-min.png) no-repeat; */
  content: "";
  display: block;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
  width: 50px;
  top: -10px;
  z-index: 997;
  visibility: visible;
}

.custom_tabs_div .tab-content .heading {
  font-weight: 700;
  font-size: 20;
}

.custom_tabs_div .nav-pills > li > a::after {
  content: none;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a:before {
  /* background: url(../images/ps-new-icon-min.png) no-repeat; */
  content: "";
  display: block;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
  width: 50px;
  top: -10px;
  z-index: 997;
  visibility: visible;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li.active a,
.custom_tabs_div .tab_nav_ul li.tab_nav_li.active a:focus,
.custom_tabs_div .tab_nav_ul li.tab_nav_li.active a:hover {
  color: #fff;
  background-color: unset;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li.active a::before {
  border-bottom: 2px solid #0087b2;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a#tab1::before {
  background-position: 0 -1422px !important;
  width: 74px;
  height: 74px;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a#tab2::before {
  background-position: -170px -1422px !important;
  width: 74px;
  height: 74px;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a#tab3::before {
  background-position: -378px -1422px !important;
  width: 74px;
  height: 74px;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a#tab4::before {
  background-position: -96px -1292px !important;
  width: 74px;
  height: 74px;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a#tab5::before {
  background-position: -241px -1422px !important;
  width: 74px;
  height: 74px;
}

.custom_tabs_div .tab_nav_ul li.tab_nav_li a#tab6::before {
  background-position: -306px -1422px !important;
  width: 74px;
  height: 74px;
}

.cc-slider #overflow {
  width: 100%;
  overflow: hidden;
}

#six-slides .hire-dd-slide-manu-box .hire-dd-manu-title {
  font-size: 18px;
  font-weight: 700;
}

#six-slide1:checked ~ #six-slides .inner {
  margin-left: 0;
}

#six-slide2:checked ~ #six-slides .inner {
  margin-left: -100%;
}

#six-slide3:checked ~ #six-slides .inner {
  margin-left: -200%;
}

#six-slide4:checked ~ #six-slides .inner {
  margin-left: -300%;
}

#six-slide5:checked ~ #six-slides .inner {
  margin-left: -400%;
}

#six-slide6:checked ~ #six-slides .inner {
  margin-left: -500%;
}

#six-slides.cc-slider .inner {
  width: 600%;
}

#six-slide1:checked ~ #six-slides,
#six-slide4:checked ~ #six-slides,
#six-slide5:checked ~ #six-slides,
#six-slide6:checked ~ #six-slides {
  margin-bottom: -26rem;
}

#six-slide2:checked ~ #six-slides {
  margin-bottom: -50rem;
}

.cc-slider .inner {
  -webkit-transform: translateZ(0);
  -webkit-transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

#six-slides.cc-slider article {
  width: 16.66%;
  position: relative;
  display: table-cell;
}

.cc-slider article {
  width: 20%;
  float: left;
}

.hire-dd-slide-manu-box-1 {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 20px;
}

.hire-dd-slide-manu .content {
  background-color: #f4f4f4 !important;
  background: rgba(255, 255, 255, 0.1);
  padding: 40px 10px;
  width: 87%;
  margin: 0 0 !important;
}

.hire-dd-slide-manu .wds.wds-ico-40::before {
  background-position: -198px -1868px !important;
}

.hire-dd-slide-manu .wds::before {
  content: "";
  position: relative;
  display: block;
  height: 84px;
  width: 84px;
  margin: 0 auto 15px;
  /* background: url(../images/ps-new-icon-min.png) no-repeat */
}

.custom_carousel_wrap {
  margin: 30px auto 0;
  padding: 0 5%;
  width: 80%;
  position: relative;
}

.custom_carousel_wrap .features_carousel_sec .item {
  position: relative;
  z-index: 100;
  -webkit-backface-visibility: hidden;
}

.custom_carousel_wrap .features_carousel_sec .owl-nav > div {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  color: #cdcbcd;
}

.custom_carousel_wrap .features_carousel_sec .owl-nav i {
  font-size: 52px;
}

.custom_carousel_wrap .features_carousel_sec .owl-nav .owl-prev {
  left: -30px;
}

.custom_carousel_wrap .features_carousel_sec .owl-nav .owl-next {
  right: -30px;
}

header.masthead-chatbot {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/chat-bot/banner/banner.jpg) no-repeat; */
  background-size: cover;
  height: 80vh;
  background-position: center;
}

header.masthead-mobility.transport {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/transportation_logistics/banner.jpg) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

@media (min-width: 1200px) {
  .lg-ht-400 {
    height: 400px !important;
  }
}

@media (min-width: 992px) {
  .md-ht-400 {
    height: 400px !important;
  }
}

@media (min-width: 768px) {
  .sm-ht-400 {
    height: 400px !important;
  }
}

header.masthead-finance.media {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/media-entertainment/media-and-entertainment.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.media.header-shadow:before {
  background: rgba(0, 0, 0, 0.5);
}

header.masthead-finance.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/health/healthcare-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.learning {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/learning-education/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.learning.header-shadow:before {
  background: rgba(0, 0, 0, 0.5);
}

header.masthead-finance.hospitality {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/hospitality/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.legal {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/legal/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.manufacturing {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/manufacturing/manufacturing-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.analytics {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/analytics_visualization/image/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.database {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/database/data-base-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-finance.devops {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/devops/devops-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-signage {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/signage/signage-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/demand/demand-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-python {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/python/python-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-ruby {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/ruby/ruby-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-engi {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/pro-eng/pro-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-trans {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/trans/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-cloud {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/cloud/big-data-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-hybris {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/hybris/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-banking {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/banking/banking-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-sap {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/sap/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-fmcg {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/fmcg/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-tele {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/tele/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-go {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/go/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-real {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/real/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-publish {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/publish/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-retail {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/retail/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-res {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/res/resource.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-ecom {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/ecom/e-commerce-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-gov {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/gov/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-way {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/way/banner.png) no-repeat; */
  background-size: cover;
  height: 80vh;
  background-position: center;
}

header.masthead-shop {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/shop/shop-floor-automation-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.lab {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/our-lab/Our_Digital_Lab.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.tensorflow {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/Banner-tensorflow.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.banner-ar-vr {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../oimages/banner-ar-vr.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.banner-vuejs {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/banner-vuejs.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.banner-meteorjs {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/banner-meteorjs.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.banner-nwjs {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/banner-nw-js.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.banner-proton-native {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/banner-proton-native-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.arkit {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/arkit-ios.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.unity-ban {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/Banner-unity.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
  margin-bottom: 0 !important;
}

header.masthead-demand.hyperledger-sawtoooth {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../oimages/hyperlefger-sawtooth-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.expertise {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/our-expertise/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-demand.Banner-solidity {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../oimages/Banner-solidity.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

.expert-cnt {
  display: block;
  width: 100%;
  padding: 8% 15px;
  background: #fff;
  box-shadow: 0 3px 4px #ddd;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #2a7bc1;
  position: relative;
  transition: all 0.5s;
  height: 360px;
}

.bgn-border {
  border-top: 5px solid #3bbeb0 !important;
}

.bl-border {
  border-top: 5px solid #3bb1d9 !important;
}

.rd-border {
  border-top: 5px solid #d9434e !important;
}

.yl-border {
  border-top: 5px solid #f89433 !important;
}

.gn-border {
  border-top: 5px solid #3bb85d !important;
}

.pl-border {
  border-top: 5px solid #7277d5 !important;
}

.expert-cnt:hover {
  box-shadow: 0 11px 18px #ddd;
}

.hgt_430 {
  height: 430px !important;
}

.feature-content_1 h4 {
  font-size: 18px;
  color: #0f0f0f;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
  margin: 10px 0;
}

.feature-content_1 h2 {
  font-size: 18px;
  color: #0f0f0f;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
  margin: 10px 0;
}

.feature-content_1 p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  text-align: left !important;
}

.hgt_350 {
  height: 350px !important;
}

.hgt_380 {
  height: 380px;
}

.navbar-default .navbar-nav > li > a.whatsup_button,
.navbar-default .navbar-nav > li > a.whatsup_button:active,
.navbar-default .navbar-nav > li > a.whatsup_button:focus {
  border-radius: 3px;
  color: #fff !important;
  padding: 0 10px !important;
  line-height: 30px;
  font-weight: 800;
  text-transform: initial;
  border-bottom: none;
  margin: 0 3px;
  margin-top: 14px;
}

.navbar-default .navbar-nav > li > a.whatsup_button:hover {
  background: #47b375;
  color: #fff !important;
}

.navbar-default .navbar-nav > li > a.telegram_button {
  background: #08c;
  border-radius: 3px;
  color: #fff !important;
  padding: 0 10px !important;
  line-height: 30px;
  font-weight: 400;
  text-transform: initial;
  border-bottom: none;
  margin: 0 3px;
  margin-top: 14px;
}

.navbar-default .navbar-nav > li > a.telegram_button:hover {
  background: #08c;
  color: #fff !important;
}

.navbar-default .navbar-nav > li > a.skype_button,
.navbar-default .navbar-nav > li > a.skype_button:active,
.navbar-default .navbar-nav > li > a.skype_button:focus {
  background: #00aff0;
  border-radius: 3px;
  color: #fff !important;
  padding: 0 10px !important;
  line-height: 30px;
  font-weight: 800;
  text-transform: initial;
  margin: 0 3px;
  margin-top: 14px;
  border-bottom: none;
}

.navbar-default .navbar-nav > li > a.carrer_button,
.navbar-default .navbar-nav > li > a.carrer_button:active,
.navbar-default .navbar-nav > li > a.carrer_button:focus {
  background: #a5264a;
  border-radius: 3px;
  color: #fff !important;
  padding: 0 10px !important;
  line-height: 30px;
  font-weight: 800;
  text-transform: initial;
  border-bottom: none;
  margin: 0 3px;
  margin-top: 14px;
}

.navbar-default .navbar-nav > li > a.carrer_button:hover {
  background: #a5264a;
  color: #fff !important;
}

.navbar-default .navbar-nav > li > a.skype_button:hover {
  background: #00aff0;
  color: #fff !important;
}

.navbar-default .navbar-nav > li > a.skype_button:hover,
.navbar-default .navbar-nav > li > a.telegram_button:hover,
.navbar-default .navbar-nav > li > a.whatsup_button:hover {
  border-bottom: 0;
}

.navbar-default .navbar-nav > li > a.skype_button i,
.navbar-default .navbar-nav > li > a.skype_button span,
.navbar-default .navbar-nav > li > a.telegram_button i,
.navbar-default .navbar-nav > li > a.telegram_button span,
.navbar-default .navbar-nav > li > a.whatsup_button i,
.navbar-default .navbar-nav > li > a.whatsup_button span {
  display: inline-block !important;
}

.hgt_480 {
  height: 480px !important;
}

.hgt_320 {
  height: 320px;
}

.hgt_520 {
  height: 520px;
}

header.masthead-blockchain-in-gaming.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain_gaming/Banner-2.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-blockchain-in-supplychain.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain_supplychain/blockchain-in-supplychain.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-blockchain-in-ecommerce.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain-in-ecommerce/blockchain-ecommerce-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-blockchain-in-real-estate.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain-in-realestate/blockchain-in-realestate.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-blockchain-application-for-voting.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain-in-voting/Banner2.png) no-repeat; */
  background-size: cover;
  height: 80vh;
  background-position: center;
}

header.masthead-blockchain-in-insurance.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain-in-insurance/blockchain-in-insurance-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-blockchain-in-digital-records.health {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/digital-record/Banner2.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-cloud-application-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/cloud-application/cloud-application-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

.feature-content_1 h4,
.feature-content_1 p,
.testing_img {
  text-align: center !important;
}

.feature-content_1 h2,
.feature-content_1 p,
.testing_img {
  text-align: center !important;
}

header.masthead-pivx {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/private-instant-verified-transaction-FINAL/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-scrypt {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/scrypt-final/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-sha-256 {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/BENEFITS-OF-SHA-256-final/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-x11 {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/X11-Final/Banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-equihash {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/equihash-final/Banner-01.jpg) no-repeat !important; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-iphone-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/iphone-app-development-final/Banner-.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-react-native-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/react-native-development-final/Banner-.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-ar-core-for-android {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/ar-core-for-android-final/Banner-.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-hyperledger-fabric-composer {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/hyperledger-fabric-composer-final/Banner-.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-jmeter {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/jmeter-final/Banner-.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-ico-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/ico_development/ICO-Development-Banner.jpg) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-ico-marketing-agency {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/ico_marketing/Banner.png) no-repeat; */
  background-size: cover;
  height: 80vh;
  background-position: center;
}

header.masthead-blockchain-consulting-services {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain_consulting/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-private-public-blockchain-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/private_public_blockchain_development/PUBLIC-PRIVATE-BLOCKCHAIN_Banner.jpg) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-cryptocurrency-wallet-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/cryptocurrency-wallet-development/banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-exchange-platform-development {
  position: relative;
  width: 100%;
  padding-top: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/cryptocurrency_exchange_platform/banner.png?v2) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-blockchain-iot-management {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/blockchain_Iot_management/Blockchain-IoT-Development-Banner.jpg) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

header.masthead-ico-marketing-agency {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/ico_marketing/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-smart-contract-audit {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/smart_contract_audit/Smart-Contract-Audit.banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-sto-marketing {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/sto_marketing/STO-Marketing-Banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-sto-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/sto_development/STO-DevelopmentBanner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-zend-framework-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/zend_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-yii-framework-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/yii_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-laravel-framework-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/laravel_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-cakephp-framework-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/cakephp/cakephp.banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-angular-js-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/angularjs/angularjs-banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-ethereum-blockchain-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/ethereum_blockchain_development/eth-blockchain-banner.png?v1) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

@media (min-width: 991px) {
  section.masthead-blockchain-development {
    position: relative;
    color: #fff;
    /* background: url(../images/inner-pages/blockchain_development/banner-new.png); */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top right;
    align-items: center !important;
    height: 100vh;
    display: flex;
  }
}

@media (max-width: 767px) {
  section.masthead-blockchain-development {
    background: linear-gradient(45deg, #011a30, #0b549a, #064b95);
    padding: 50px 0px;
    height: 550px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .masthead-blockchain-development .title {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .masthead-blockchain-development .cus_title {
    font-size: 35px !important;
  }

  .masthead-blockchain-development .blk_dev_bnr_btn,
  .blockchain_dev_talkcard_sec .blk_dev_talk_btn {
    font-size: 12px !important;
  }

  .blockchain_dev_talkcard_sec {
    background: linear-gradient(45deg, #000422, #004b82);
    display: flex;
    align-items: center;
    padding: 30px 0px;
    text-align: center;
  }

  .blockchain_dev_talkcard_sec .title {
    font-size: 22px !important;
    margin-top: 0px;
  }

  .blockchain_dev_talkcard_sec .contents {
    padding-top: 0px !important;
    margin-bottom: 5px !important;
  }

  .blockchain_dev_talkcard_sec .blk_dev_talk_btn_sec {
    margin-top: 20px !important;
  }
}

.header-shadow-custom:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #012150;
  background: linear-gradient(90deg, #0e1824 0, rgb(22 29 43 / 55%) 79%);
}

header.masthead-ethereum-classic-blockchain-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/ethereum_classic_blockchain_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

.masthead-blockchain-development .title {
  color: #fff;
  font-weight: 600;
  font-size: 35px;
  text-transform: capitalize;
  line-height: 45px;
}

.masthead-blockchain-development .cus_title {
  color: #fff;
  font-weight: 800;
  font-size: 55px;
  text-transform: uppercase;
}

.masthead-blockchain-development .contents {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.masthead-blockchain-development a.expert-btn {
  background: #db3c3b;
  font-weight: 700;
  /* margin-bottom: 0; */
  text-transform: uppercase;
  font-size: 16px;
  min-width: 235px;
  color: #fff;
  box-shadow: 0px 6px 16px -4px #050505;
  line-height: 1.5;
  border: 0;
}

.masthead-blockchain-development .blk_dev_bnr_btn_sec,
.blockchain_dev_talkcard_sec .blk_dev_talk_btn_sec {
  margin-top: 35px;
}

.masthead-blockchain-development .blk_dev_bnr_btn,
.blockchain_dev_talkcard_sec .blk_dev_talk_btn {
  background: #db3c3b;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  border-radius: 30px;
  padding: 12px 25px;
}

@media (min-width: 991px) {
  .blockchain_dev_talkcard_sec {
    position: relative;
    color: #fff;
    /* background: url(../images/inner-pages/blockchain_development/talk-sec-card-bg.png); */
    background-repeat: no-repeat !important;
    background-position: top right;
    align-items: center !important;
    display: flex;
    padding: 50px 0px;
    height: 400px;
  }
}

.blockchain_dev_talkcard_sec .title {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
}

.blockchain_dev_talkcard_sec .contents {
  font-size: 16px;
  padding-top: 8px;
  color: #fff;
}

header.masthead-quorum-blockchain-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/quorum_blockchain_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-neo-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/neo_blockchain_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-tron-blockchain-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/tron_dapps_development_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-lisk-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/lisk_blockchain_applicationLisk_blockchain_application_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-eos-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/eos_blockchain_development/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-chainlink-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/chainLink_blockchain_application_final/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-stratis-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/stratis_blockchain_application/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-waves-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/waves_blockchain_application/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-svelte-js-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/svelte_js_development/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-angular8-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/angular8_development_company/Banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-android-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/android_app_development/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-android-game-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/android_game_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-ipad-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/ipad_app_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-ipad-ui-ux-design {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/ipad_ui_ux_design/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-iphone-game-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/iphone_game_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-iphone-ui-ux-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/iphone_ui_ux_design/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-enterprise-mobile-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/enterprise_mobile_app_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-native-app-development-company {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/native_app_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-hybrid-mobile-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/hybrid_app_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-ionic-mobile-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/ionic_app_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-2d-animation-company {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/2D_design/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-adobe-air-app-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/adobe_air_app_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-android-tablet-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/android_tablet_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-codeigniter-framework-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/codeigniter_web_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-custom-web-design-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/custom_web_design_development/banner-bg.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-custom-web-design-development_countrybase {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/custom_web_design_development_countrybase/banner-bg.png?v1) no-repeat; */
  background-size: cover !important;
  background-position: center;
  height: 85vh;
}

.masthead-custom-web-design-development_countrybase .webdesi_bannerbtn {
  margin-top: 4rem;
}

.webdesi_bannerbtn a:hover {
  text-decoration: none;
  color: #fff;
}

.button_play {
  /*background: #ff4949;*/
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  padding: 16px 21px;
  transition: width 199ms cubic-bezier(0.25, 0, 0.25, 1) 233ms;
  font-weight: 800;
  font-size: 17px;
  background: #001a56;
  background: -moz-linear-gradient(
    -45deg,
    #4500b7 0%,
    #8283e8 50%,
    #4500b7 51%,
    #565bd8 71%,
    #4500b7 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #001a56e0 0%,
    #001a569e 50%,
    #001a56c4 51%,
    #001a56ad 71%,
    #001a56b5 100%
  );
  background: linear-gradient(
    135deg,
    #a71e44 0%,
    #e63a59 50%,
    #0c3d40 51%,
    #09383c 71%,
    #e02528 100%
  );
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: 0;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 31%;
  }

  50% {
    background-position: 100% 70%;
  }

  100% {
    background-position: 0% 31%;
  }
}

.masthead-custom-web-design-development_countrybase.header-shadow:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(24 30 41 / 60%) none repeat scroll 0 0;
}

header.masthead-custom-web-design-development_countrybase .header-content h1,
header.masthead-custom-web-design-development_countrybase
  .header-content
  .heading-tagline {
  text-align: left;
}

@media (max-width: 767px) {
  header.masthead-custom-web-design-development_countrybase .header-content h1,
  header.masthead-custom-web-design-development_countrybase
    .header-content
    .heading-tagline {
    text-align: center;
  }
}

header.masthead-enterprise-solutions {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/it_enterprise_solution_company/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-phonegap-application-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/phonegap_development_services/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-responsive-website-design-company {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/responsive_web_design/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-web-design-company {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/website_design/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-logo-design {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/logo_design/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-3d-animation-company {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/3d_design/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-react-js-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/react_js_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-node-js-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/nodejs_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-bootstrap-web-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/bootstrap_page/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-canvas5-web-design {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/canvas5_webdesign/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-joomla-web-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/joomla-development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-wordpress-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/wordpress_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-magento-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/magento_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-website-maintenance-company {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/website_maintanence_company/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-mobile-application-maintenance {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/mobile_application_development/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-web-hosting-and-maintenance-services {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/trusted_hosting_and_server_maintenance_service_company/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-customers-inventory-management-software {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/customer_and_inventory_management/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-online-customer-support {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/online_customer_support_services_company/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-full-stack-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/full_stack_development/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-mean-stack-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/mean_stack_development/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-hedera-hashgraph-dev {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/hedera_hasgroup/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

.masthead-hedera-hashgraph-dev:before {
  background: rgba(0, 0, 0, 0.73) !important;
}

header.masthead-blockchain-ai-dev {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background: url(../images/inner-pages/AI_blockchain/banner.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  padding-top: 40px;
}

header.masthead-peer-to-peer-blockchain {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/p2p_lending/banner.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

header.masthead-ai-banner {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../oimages/ai-banner.png) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

@media screen and (min-width: 767px) {
  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .bor-bot {
    border-bottom: 2px solid #205a5d !important;
  }
}

.hgt_490 {
  height: 490px;
}

.justify {
  text-align: justify;
}

@media (max-width: 767px) {
  .social_link {
    display: none;
  }

  .mobile-resp {
    display: block;
  }

  .caption {
    font-size: 14px !important;
  }

  body.events .cbp-af-header .cbp-af-inner {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .showcase-box h3 {
    text-align: left;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .showcase-box h3 {
    min-height: 50px;
  }
}

.photo_font {
  font-size: 25px !important;
  color: #000 !important;
  text-align: center;
}

.caption {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
  color: #fff;
  background: #397bc1;
  text-align: center;
  font-weight: 700;
  opacity: 1;
  top: 0;
  font-size: 20px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .expert-btn {
    margin: 10px auto !important;
  }

  .solution-box p {
    width: 100%;
  }

  .ttmenu .navbar-default .ttmenu-content {
    padding: 0;
  }

  .social ul li {
    display: inline-block;
    margin: 0 0 15px 1px;
  }

  header.masthead-demand.tensorflow {
    padding-top: 50px;
    height: 70vh;
  }

  .pre-header ul li a {
    font-size: 13px;
  }

  header.masthead-hyper {
    padding-top: 40px;
    height: 60vh;
  }

  header.masthead-demand.Banner-solidity {
    padding-top: 20px;
    height: 60vh;
  }

  header.masthead-blockchain-in-digital-records.health {
    padding-top: 30px;
    height: 60vh;
  }

  header.masthead-blockchain-in-insurance.health {
    padding-top: 20px;
    height: 60vh;
  }

  header.masthead-blockchain-in-gaming.health {
    padding-top: 20px;
    height: 60vh;
  }

  header.masthead-blockchain-application-for-voting.health {
    padding-top: 20px;
    height: 60vh;
  }

  header.masthead-blockchain-in-ecommerce.health {
    padding-top: 20px;
  }

  header.masthead-blockchain-in-real-estate.health {
    padding-top: 20px;
    height: 80vh;
  }

  header.masthead-blockchain-in-supplychain.health {
    padding-top: 20px;
    height: 70vh;
  }

  header.masthead-identity {
    padding-top: 20px;
    height: 65vh;
  }

  header.masthead-finance,
  header.masthead-mobility {
    padding-top: 20px;
    height: 65vh;
  }

  header.masthead-legal {
    padding-top: 20px;
    height: 70vh;
  }

  header.masthead-equihash {
    padding-top: 0;
    height: 90vh;
  }

  header.masthead-sha-256 {
    padding-top: 0;
    height: 90vh;
  }

  header.masthead-pivx {
    padding-top: 0;
    height: 90vh;
  }

  header.masthead-scrypt {
    padding-top: 10px;
    height: 85vh;
  }

  header.masthead-x11 {
    padding-top: 10px;
    height: 80vh;
  }

  header.masthead-cryptocurrency-wallet-development {
    padding-top: 10px;
    height: 80vh;
  }

  header.masthead-cryptocurrency-wallet-development .header-content h1 {
    font-size: 38px !important;
  }

  header.masthead-exchange-platform-development {
    padding-top: 50px;
  }

  header.masthead-business {
    padding-top: 40px;
    height: 65vh;
  }

  header.masthead-iot {
    padding-top: 20px;
    height: 60vh;
  }

  header.masthead-chatbot {
    padding-top: 10px;
    height: 60vh;
  }

  header.masthead-finance.devops {
    padding-top: 0;
    height: 100vh;
  }

  header.masthead-trans .header-content h1 {
    font-size: 38px !important;
  }

  header.masthead-engi .header-content h1 {
    font-size: 38px !important;
  }

  header.masthead-blockchain-development {
    height: 95vh;
    background: #183274;
  }

  .feature-content_1 p,
  .section-heading-1 p,
  .work_with_box p {
    font-size: 16px;
  }

  header.masthead-blockchain-development .header-content h1 {
    font-size: 30px !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  .heading-tagline {
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }

  .mid_section p {
    font-size: 16px;
  }

  .feature_1 {
    margin-top: 2em;
  }

  header.masthead-ai .header-content h1,
  header.masthead-angular-js-development .header-content h1,
  header.masthead-angular8-development .header-content h1,
  header.masthead-ar-core-for-android .header-content h1,
  header.masthead-banking .header-content h1,
  header.masthead-blockchain-application-for-voting .header-content h1,
  header.masthead-blockchain-consulting-services .header-content h1,
  header.masthead-blockchain-development .header-content h1,
  header.masthead-blockchain-in-digital-records .header-content h1,
  header.masthead-blockchain-in-ecommerce .header-content h1,
  header.masthead-blockchain-in-gaming .header-content h1,
  header.masthead-blockchain-in-insurance .header-content h1,
  header.masthead-blockchain-in-real-estate .header-content h1,
  header.masthead-blockchain-in-supplychain .header-content h1,
  header.masthead-blockchain-iot-management .header-content h1,
  header.masthead-business .header-content h1,
  header.masthead-cakephp-framework-development .header-content h1,
  header.masthead-chainlink-blockchain .header-content h1,
  header.masthead-chatbot .header-content h1,
  header.masthead-cloud .header-content h1,
  header.masthead-cloud-application-development .header-content h1,
  header.masthead-crypto .header-content h1,
  header.masthead-deep .header-content h1,
  header.masthead-demand .header-content h1,
  header.masthead-digital .header-content h1,
  header.masthead-ecom .header-content h1,
  header.masthead-engi .header-content h1,
  header.masthead-eos-blockchain .header-content h1,
  header.masthead-equihash .header-content h1,
  header.masthead-ethereum-blockchain-development .header-content h1,
  header.masthead-ethereum-classic-blockchain-development .header-content h1,
  header.masthead-exchange-platform-development .header-content h1,
  header.masthead-fmcg .header-content h1,
  header.masthead-go .header-content h1,
  header.masthead-gov .header-content h1,
  header.masthead-hybris .header-content h1,
  header.masthead-hyperledger-fabric-composer .header-content h1,
  header.masthead-ico-marketing-agency .header-content h1,
  header.masthead-iphone-app-development .header-content h1,
  header.masthead-jmeter .header-content h1,
  header.masthead-laravel-framework-development .header-content h1,
  header.masthead-legal .header-content h1,
  header.masthead-lisk-blockchain .header-content h1,
  header.masthead-mobility .header-content h1,
  header.masthead-neo-blockchain .header-content h1,
  header.masthead-php .header-content h1,
  header.masthead-pivx .header-content h1,
  header.masthead-private-public-blockchain-development .header-content h1,
  header.masthead-publish .header-content h1,
  header.masthead-python .header-content h1,
  header.masthead-quorum-blockchain-development .header-content h1,
  header.masthead-react-native-development .header-content h1,
  header.masthead-real .header-content h1,
  header.masthead-res .header-content h1,
  header.masthead-retail .header-content h1,
  header.masthead-ruby .header-content h1,
  header.masthead-sap .header-content h1,
  header.masthead-scrypt .header-content h1,
  header.masthead-sha-256 .header-content h1,
  header.masthead-shop .header-content h1,
  header.masthead-signage .header-content h1,
  header.masthead-smart-contract-audit .header-content h1,
  header.masthead-sto-development .header-content h1,
  header.masthead-sto-marketing .header-content h1,
  header.masthead-stratis-blockchain .header-content h1,
  header.masthead-supply .header-content h1,
  header.masthead-svelte-js-development .header-content h1,
  header.masthead-tele .header-content h1,
  header.masthead-trans .header-content h1,
  header.masthead-tron-blockchain-development .header-content h1,
  header.masthead-waves-blockchain .header-content h1,
  header.masthead-way .header-content h1,
  header.masthead-x11 .header-content h1,
  header.masthead-yii-framework-development .header-content h1,
  header.masthead-zend-framework-development .header-content h1 {
    font-size: 21px !important;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 30px;
  }

  header.masthead-blockchain-consulting-services {
    height: 60vh;
    padding-top: 10px;
  }

  header.masthead-custom-web-design-development {
    height: 97vh;
    padding-top: 133px;
  }

  header.masthead-custom-web-design-development .header-content h1 {
    font-size: 30px !important;
    font-weight: 700;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .mobile-resp .skype_button > span,
  .mobile-resp .whatsup_button > span {
    display: inline !important;
  }

  .social_link {
    display: block !important;
  }

  .mobile-resp {
    display: none;
  }

  .MetaBlock-new-slider .hero h1,
  .MetaBlock-new-slider .hero h2 {
    font-size: 34px;
    line-height: 52px;
  }

  .ttmenu .navbar-default .navbar-nav > li > a.carrer_button span {
    display: none !important;
  }

  .ttmenu .navbar-default .navbar-nav > li > a.skype_button span {
    display: none !important;
  }

  .ttmenu .navbar-default .navbar-nav > li > a.whatsup_button span {
    display: none !important;
  }

  .ttmenu .nav > li > a {
    padding: 4px 0 !important;
  }

  body .ttmenu .nav > li > a {
    border-bottom: 0 solid !important;
  }

  .cbp-af-header.sticky .nav > li > a {
    margin: 0 6px !important;
  }

  .ttmenu .nav > li > a {
    margin: 0 7px;
  }

  header.masthead-blockchain-development {
    height: 25vh;
    padding-top: 10px;
  }

  .bigdata-info img {
    max-width: 330px;
  }

  .hi-icon {
    width: 40px;
    height: 40px;
  }

  .hi-icon:before {
    font-size: 20px;
    line-height: 38px;
  }

  .loc_icons {
    margin-bottom: 0;
  }

  .loc_text ul {
    padding: 0;
  }

  .location .col-sm-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .loc_text ul li {
    font-size: 13px;
    line-height: 21px;
  }

  .location {
    padding: 10px 0;
  }
}

.algorithm-img-modal {
  height: 332px;
  -webkit-box-shadow: -1px 1px 5px 2px rgba(209, 207, 209, 0.39);
  -moz-box-shadow: -1px 1px 5px 2px rgba(209, 207, 209, 0.39);
  box-shadow: -1px 1px 5px 2px rgba(209, 207, 209, 0.39);
}

.img-modal .modal-header {
  background-color: unset;
  border-bottom: 0;
}

.img-modal .modal-footer {
  border-top: 0;
}

.pop-up-img-modal {
  max-width: 100%;
}

.img-modal .modal-header .btn {
  background-color: #928f8f;
  color: #fff;
  height: 39px;
  border-radius: 100%;
  float: right;
}

.expand-button {
  color: #fff;
}

.modal-open .modal {
  overflow-x: hidden;
  margin-top: -75px;
  overflow-y: auto;
}

.hgt_340 {
  height: 340px !important;
}

.hgt_auto {
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .whybottom_box {
    height: auto !important;
  }
}

.hgt_400 {
  height: 400px;
}

.gd-img img {
  max-width: none !important;
  width: 150px;
}

@media screen and (min-width: 767px) {
  .hgt_470 {
    height: 470px;
  }
}

.foot_link .contact-info p {
  padding-left: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.expert-btn {
  display: inline-block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px auto;
  border-radius: 50px;
  min-width: 200px;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding-top: 12px;
}

.platform-icon {
  width: 70px;
  height: 70px;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px rgba(34, 44, 55, 0.15);
  -moz-box-shadow: 0 0 20px rgba(34, 44, 55, 0.15);
  box-shadow: 0 0 20px rgba(34, 44, 55, 0.15);
}

div.platform-icon div.logo {
  background-color: transparent;
}

div.platform-icon a,
div.platform-icon div.logo {
  width: 50px;
  height: 50px;
  display: block;
  /* background-image: url(../images/inner-pages/way/platform-logos.png); */
  background-color: #fff;
}

div.platform-icon .ios {
  background-position: 0 -300px;
}

div.platform-icon {
  float: none;
  display: inline-block;
  margin: 5px;
}

.multiple-platforms {
  text-align: center;
}

.g-center {
  padding: 0 14px;
}

div.platform-icon .win {
  background-position: 0 0;
}

div.platform-icon .pc {
  background-position: 0 0;
}

div.platform-icon .winphone8 {
  background-position: 0 -50px;
}

div.platform-icon .windowsphone8 {
  background-position: 0 -50px;
}

div.platform-icon .winstore {
  background-position: 0 -100px;
}

div.platform-icon .windowsstore {
  background-position: 0 -100px;
}

div.platform-icon .windowsstoreapps {
  background-position: 0 -100px;
}

div.platform-icon .mac {
  background-position: 0 -150px;
}

div.platform-icon .linux {
  background-position: 0 -200px;
}

div.platform-icon .web {
  background-position: 0 -250px;
}

div.platform-icon .unitywebplayer {
  background-position: 0 -250px;
}

div.platform-icon .ios {
  background-position: 0 -300px;
}

div.platform-icon .android {
  background-position: 0 -350px;
}

div.platform-icon .blackberry {
  background-position: 0 -400px;
}

div.platform-icon .wiiu {
  background-position: 0 -450px;
}

div.platform-icon .ps3 {
  background-position: 0 -500px;
}

div.platform-icon .playstation3 {
  background-position: 0 -500px;
}

div.platform-icon .ps4 {
  background-position: 0 -550px;
}

div.platform-icon .playstation4 {
  background-position: 0 -550px;
}

div.platform-icon .xbox {
  background-position: 0 -600px;
}

div.platform-icon .facebook {
  background-position: 0 -650px;
}

div.platform-icon .playstationvita {
  background-position: 0 -700px;
}

div.platform-icon .unity {
  background-position: 0 -750px;
}

div.platform-icon .xboxone {
  background-position: 0 -800px;
}

div.platform-icon .xbox360 {
  background-position: 0 -850px;
}

div.platform-icon .tizen {
  background-position: 0 -900px;
}

div.platform-icon .psmobile {
  background-position: 0 -950px;
}

div.platform-icon .teamlicense {
  background-position: 0 -1000px;
}

div.platform-icon .oculus {
  background-position: 0 -1050px;
}

div.platform-icon .oculusright {
  background-position: 0 -1050px;
}

div.platform-icon .oculusrift {
  background-position: 0 -1050px;
}

div.platform-icon .webgl {
  background-position: 0 -1100px;
}

div.platform-icon .samsungtv {
  background-position: 0 -1150px;
}

div.platform-icon .gearvr {
  background-position: 0 -1200px;
}

div.platform-icon .androidtv {
  background-position: 0 -1250px;
}

div.platform-icon .hololens {
  background-position: 0 -1300px;
}

div.platform-icon .universal-windows {
  background-position: 0 -1350px;
}

div.platform-icon .playstationvr {
  background-position: 0 -1400px;
}

div.platform-icon .tvos {
  background-position: 0 -1450px;
}

div.platform-icon .nintendo3ds {
  background-position: 0 -1500px;
}

div.platform-icon .vive {
  background-position: 0 -1550px;
}

div.platform-icon .cardboard {
  background-position: 0 -1600px;
}

div.platform-icon .daydream {
  background-position: 0 -1650px;
}

div.platform-icon .nintendo-switch {
  background-position: 0 -1700px;
}

div.platform-icon .fireos {
  background-position: 0 -1750px;
}

div.platform-icon .fb-gameroom {
  background-position: 0 -1800px;
}

div.platform-icon .arkit {
  background-position: 0 -1850px;
}

div.platform-icon .vuforia {
  background-position: 0 -1900px;
}

div.platform-icon .arcore {
  background-position: 0 -1950px;
}

div.platform-icon .magic-leap {
  background-position: 0 -2000px;
}

.header-shadow .expert-btn {
  border: 2px solid #fff;
  background: 0 0;
}

.blkchn_devpmt_services:hover {
  filter: drop-shadow(1px 0 8px #205a5d);
}

.blkchn_devpmt_services {
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  min-height: 500px;
  height: 510px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .blkchn_devpmt_services {
    height: auto;
  }
}

.blkchn_devpmt_services .blkchn_devpmt_services_btn_div {
  margin: 15px auto;
  text-align: center;
}

.blkchn_devpmt_services
  .blkchn_devpmt_services_btn_div
  .blkchn_devpmt_services_btn {
  background: #ad2953;
  padding: 8px 16px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 767px) and (min-width: 0px) {
  #divisor {
    background-size: cover !important;
  }

  .our-works .carousel-inner {
    margin: 0 !important;
  }

  .first-shot {
    padding: 0 !important;
  }

  figure {
    background-size: contain !important;
  }

  .img-comparison {
    padding: 0 !important;
  }
}

.multiple-platforms {
  padding: 0;
  padding: 0;
  /* background-image: url(../images/inner-pages/way/multiplatform-bg.png); */
  background-color: #000;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

header.masthead-way {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  /* background-image: url(../images/inner-pages/way/wayfind-bg.png); */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

.our-works .first-shot {
  height: 420px;
  color: #fff;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 10px 8px 2px rgba(0, 0, 0, 0.3);
  height: 449px;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-works .first-shot h1 {
  font-weight: 700;
  color: #2a7bc1;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
}

.our-works .first-shot p {
  color: #000;
}

.our-works .carousel li {
  display: none;
}

.our-works .carousel-inner {
  margin: 25px 145px 50px;
  max-width: 1245px;
}

.our-works {
  /* background-image: url(../images/inner-pages/way/shots-slide-bg.png); */
  padding: 0;
  background-size: cover;
}

.our-works h1 {
  font-size: 50px;
  color: #2a7bc1;
  font-weight: 300;
  text-align: center;
  font-weight: 600;
}

.carousel-slider .carousel-control {
  z-index: 2;
  top: 171px;
  height: 0;
  width: 8%;
  bottom: 0;
  font-size: 77px;
  opacity: 1;
}

.carousel-control.left {
  right: 105% !important;
}

.shots .carousel-control.right {
  left: 105% !important;
}

.our-works h2 {
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
  font-size: 18px;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
}

.shots img {
  box-shadow: 0 10px 8px 2px rgba(0, 0, 0, 0.3);
}

div.platform-icon a,
div.platform-icon div.logo {
  border-radius: 50%;
}

.multiple-platforms h2 {
  color: #fff;
  font-weight: bolder;
}

.multiple-platforms h3 {
  color: #fff;
  font-size: 22px;
}

.g-center {
  padding: 0 15px 25px;
}

.img-comparison {
  padding: 0;
}

.img-comparison h2 {
  color: #2a7bc1;
  margin-bottom: 51px;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
}

.img-comparison h3 {
  color: #2a7bc1;
  text-align: center;
}

.img-comparison p {
  text-align: center;
}

#comparison {
  width: 100%;
  padding-bottom: 48%;
  overflow: hidden;
  position: relative;
}

figure {
  position: absolute;
  /* background-image: url(../images/inner-pages/way/gfloor-bp.png); */
  background-repeat: no-repeat;
  background-size: auto;
  font-size: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

#divisor {
  /* background-image: url(../images/inner-pages/way/gfloor-out.png); */
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  bottom: 0;
  height: 100%;
  -webkit-transition: width 2s;
  transition: width 2s;
}

#divisor:after,
#divisor:before {
  content: "";
  position: absolute;
  right: -2px;
  width: 4px;
  height: calc(50% - 25px);
  background: #000;
  z-index: 3;
}

#divisor:before {
  top: 0;
  box-shadow: 0 -3px 8px 1px rgba(0, 0, 0, 0.3);
}

#divisor:after {
  bottom: 0;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.3);
}

#handle {
  position: absolute;
  height: 50px;
  width: 50px;
  background-color: #000;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 3;
}

#handle:after,
#handle:before {
  content: "";
  width: 0;
  height: 0;
  border: 6px inset transparent;
  position: absolute;
  top: 50%;
  margin-top: -6px;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  background-color: transparent;
  width: calc(100% + 50px);
  display: none;
}

input[type="range"]:active,
input[type="range"]:focus {
  border: 0;
  outline: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: 0 0;
  border: 4px solid #fff;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.3);
}

input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 15px;
  width: 100%;
  background-color: transparent;
  position: relative;
  outline: 0;
}

.rd_mr_btn {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  display: inline-block;
  margin: 10px 0 0;
  line-height: 0;
}

.blkchn_devpmt_services .inr_arrow {
  font-size: 20px;
  margin-left: 5px;
  font-weight: 700;
}

.blkchn_devpmt_services h4 {
  min-height: 50px;
}

.blkchn_devpmt_services h2 {
  min-height: 50px;
}

.blkchn_devpmt_services .feature-content_1 p {
  min-height: 280px;
}

.list_img_div img {
  max-width: 60px;
  position: absolute;
}

.list_content_div {
  margin-left: 70px;
}

.industry_focus {
  padding: 10px;
}

.industry_focus p {
  font-size: 15px !important;
  line-height: 27px;
}

.industry_focus .slide-content .industries_solution_btn_p {
  margin: 20px 0;
}

.industry_focus .slide-content .industries_solution_btn_p a.explore_slick_btn {
  margin-right: 8px;
}

.industry_focus .item_pagination {
  padding: 15px !important;
}

.industry_focus .slick-carousel .slick-list {
  margin-top: 0;
}

.multiple-platforms p {
  font-size: 18px;
}

@media (max-width: 767px) {
  .blockchain__indus__sec #blockchain_indus_accordion {
    padding: 10px;
  }

  .blockchain__indus__sec
    #blockchain_indus_accordion
    .panel-default
    .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #183276;
    color: #fff;
  }

  .blockchain__indus__sec
    #blockchain_indus_accordion
    .panel-default
    .panel-heading
    .panel-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-right: 15px;
  }

  .blockchain__indus__sec
    #blockchain_indus_accordion
    .panel-default
    .panel-body
    p {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
  }

  .blockchain__indus__sec
    #blockchain_indus_accordion
    .panel-default
    .panel-body
    .blockchain_indus_buttons {
    padding: 0 10px;
    text-align: center;
  }
}

#why_development.CryptocurrencyPlatforms {
  /* background: rgba(0, 0, 0, 0) url(../crypto_platform/bitcoin-fire.png) no-repeat scroll center top/cover; */
  margin-top: 0;
}

#why_development .why_box {
  display: inline-block;
  margin-bottom: 25px;
  width: 100%;
}

#why_development .why_box span {
  background: rgba(172, 175, 158, 0.7) none repeat scroll 0 0;
  border-radius: 10em;
  display: block;
  height: 100px;
  margin: 0 auto 15px;
  width: 100px;
}

.why_development p {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 50px;
  text-align: center;
}

.cryp-heading h3 {
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  font-size: 28px;
  margin: 0 0 50px;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
}

#why_development .why_box span:hover {
  cursor: pointer;
  background: #bb3361;
}

.mob_industy_box {
  width: 180px;
  height: 230px;
  margin: 1em auto;
  color: #000;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.social_netw {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-position: -93px -39px;
}

.bank_finan {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-position: -333px -39px;
}

.entertain {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-position: -563px -39px;
}

.ondemand {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-position: -801px -39px;
}

.ecommerce {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-position: -1039px -39px;
}

.food {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-position: -1271px -39px;
}

.healthcare {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-size: 172rem;
  background-position: -102px -353px;
}

.realestate {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-size: 172rem;
  background-position: -367px -353px;
}

.education {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-size: 172rem;
  background-position: -618px -353px;
}

.transport {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-size: 172rem;
  background-position: -881px -353px;
}

.lifestyle {
  z-index: 2;
  transform: rotateY(0);
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-size: 172rem;
  background-position: -1142px -353px;
}

.cryptocurency {
  z-index: 2;
  transform: rotateY(0);
  /* /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v1); */
  background-size: 172rem;
  background-position: -368px -353px;
}

@media (max-width: 767px) {
  .ondemand__app__sec #ondemand__app__carousel .ondemand__app__box {
    padding: 10px;
  }

  .ondemand__app__sec #ondemand__app__carousel .ondemand__app__box .btn__div {
    text-align: center;
    position: relative;
    bottom: 10rem;
    z-index: 1;
  }

  .ondemand__app__sec #ondemand__app__carousel .owl-nav {
    position: absolute;
    top: 10rem;
    left: 0;
    right: 0;
  }

  .ondemand__app__sec #ondemand__app__carousel .owl-nav .owl-prev {
    position: absolute;
    left: 20px;
  }

  .ondemand__app__sec #ondemand__app__carousel .owl-nav .owl-next {
    position: absolute;
    right: 20px;
  }

  .ondemand__app__sec #ondemand__app__carousel .owl-nav .owl-next:before,
  .ondemand__app__sec #ondemand__app__carousel .owl-nav .owl-prev:before {
    border: 1px solid #148bfdbf;
    background: #148bfdbf;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
  }
}

.ondemand__app__sec .title {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}

.ondemand__app__sec #ondemand__app__carousel .ondemand__app__box .title__div {
  position: relative;
  bottom: 30px;
  text-align: center;
  background: #fff;
  padding: 5px;
  margin: 0 15px;
  border-radius: 3px;
  box-shadow: gray;
  box-shadow: 0 0 29.1px 0.9px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.ondemand__app__sec #ondemand__app__carousel .ondemand__app__box img {
  width: 100%;
}

.ondemand__app__sec #ondemand__app__carousel .item {
  padding: 10px 0;
}

.ondemand__app__sec #ondemand__app__carousel .ondemand__app__box .title__div p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.ondemand__app__sec
  #ondemand__app__carousel
  .ondemand__app__box
  .title__div
  p
  a {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #535e72;
}

@media (min-width: 767px) {
  .ondemand__app__sec
    #ondemand__app__carousel
    .ondemand__app__box:hover:before {
    transform: scaleX(1);
  }

  .ondemand__app__sec #ondemand__app__carousel .ondemand__app__box .btn__div {
    text-align: center;
    position: relative;
    bottom: 7rem;
    transition: 1s;
    opacity: 0;
    z-index: 1;
  }

  .ondemand__app__sec
    #ondemand__app__carousel
    .ondemand__app__box:hover
    .btn__div {
    bottom: 23rem;
    transition: 1s;
    opacity: 1;
  }
}

.ondemand__app__sec #ondemand__app__carousel .ondemand__app__box:before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 320px;
  top: 3%;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000ab;
  transform: scaleX(0);
  transform-origin: 0 50%;
  border-radius: 18px;
  transition: transform 0.3s ease-out;
}

.ondemand__app__sec
  #ondemand__app__carousel
  .ondemand__app__box
  .btn__div
  .get__demo__a {
  background: #148bfd;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
}

.ondemand__app__sec #ondemand__app__carousel .owl-dots {
  margin-top: 0 !important;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.ondemand__app__sec #ondemand__app__carousel .owl-dots .owl-dot.active span {
  background: #ad2953b8;
}

.ondemand__app__sec #ondemand__app__carousel .owl-dots .owl-dot span {
  display: block;
  background: #ccc;
  width: 13px;
  height: 13px;
  margin: 5px;
  border-radius: 50px;
  cursor: pointer;
}

.ondemand__app__sec #ondemand__app__carousel .owl-dots .owl-dot {
  display: inline-block;
}

.ondemad_head .banr-listing {
  padding: 0;
}

.ondemad_head .heading-tagline {
  font-size: 16px !important;
  color: #ffffff;
  line-height: 1.8;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  font-family: "Readex Pro", sans-serif;
}

.ondemad_head .banr-listing li {
  color: #fff;
  margin: 10px 0;
  font-size: 16px;
  position: relative;
  list-style: none;
  text-align: left;
  font-weight: 300;
}

header.ondemad_head {
  position: relative;
  width: 100%;
  padding-top: 75px !important;
  color: #fff;
  background: linear-gradient(50deg, #000 25%, #4a4949 76%);
  height: 100vh;
}

header.ondemad_head .header-content h1 {
  font-size: 38px !important;
  font-weight: 700;
  line-height: 1.2;
  font-family: "Readex Pro", sans-serif;
}

.ondemad_feature .whybottom_box .inr_img {
  top: -90px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

.ondemad_feature .whybottom_box {
  height: 300px;
}

.ondemad_feature .whybottom_box .inr_img:hover {
  transform: rotate(360deg);
  transition: 1s;
}

.why-business .whybottom_box {
  height: auto;
  box-shadow: 0 10px 25px 0 rgba(139, 180, 226, 0.4);
  -webkit-box-shadow: 0 10px 25px 0 rgba(139, 180, 226, 0.4);
  -moz-box-shadow: 0 10px 25px 0 rgba(139, 180, 226, 0.4);
  background-color: #0d4388;
}

.why-business .whybottom_box h3 {
  color: #fff;
}

.why-business .whybottom_box abbr {
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -10px;
  padding: 5px;
  -webkit-border-radius: 0 0 60% 60%;
  -moz-border-radius: 0 0 60% 60%;
  -ms-border-radius: 0 0 60% 60%;
  border-radius: 0 0 60% 60%;
  background: #ff3d9e;
  background: -moz-linear-gradient(-45deg, #ff3d9e 0, #481891 100%);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0, #ff3d9e),
    color-stop(100%, #481891)
  );
  background: -webkit-linear-gradient(-45deg, #ff3d9e 0, #481891 100%);
  background: -o-linear-gradient(-45deg, #ff3d9e 0, #481891 100%);
  background: linear-gradient(135deg, #ff3d9e 0, #481891 100%);
}

.why-business .whybottom_box abbr:before {
  position: absolute;
  left: -10px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: transparent #581c7f transparent transparent;
}

.why-business .whybottom_box abbr:after {
  position: absolute;
  right: -10px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #581c7f;
}

.on-dmnd-industries .od-ind-box {
  text-align: center;
  padding: 10px;
  position: relative;
}

.on-dmnd-industries .od-ind-box p {
  text-align: center;
  font-weight: bolder;
}

.on-dmnd-industries .all-features {
  text-align: center;
}

.on-dmnd-industries .all-features a.collapsed {
  color: #ff3d9d;
}

.on-dmnd-industries .all-features a.collapsed .rotate-icon {
  color: #ff3d9d;
  border-color: #ff3d9d;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(0);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.on-dmnd-industries .all-features a .vff {
  display: block;
  font-size: 15px;
  font-weight: bolder;
}

.fa-angle-double-right:before {
  content: "\f101" !important;
}

.on-dmnd-industries .all-features a .rotate-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: #8200f2;
  border: 1px solid #8200f2;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.on-dmnd-industries .all-features a .rotate-icon i {
  font-size: 16px;
  margin-top: 5px;
}

/*ON Demand App Banner Form - Start*/

.ondemand_app_banner_form_sec {
  background: #00000047;
  padding: 20px;
}

.ondemand_app_banner_form_sec .ondemand_app_banner_form_head .title {
  font-size: 28px;
  color: #fff;
  line-height: 1.3;
}

.ondemand_app_banner_form_sec #ondemad_app_banner_form .form-group {
  margin-bottom: 20px;
}

.ondemand_app_banner_form_sec #ondemad_app_banner_form input,
.ondemand_app_banner_form_sec #ondemad_app_banner_form select,
.ondemand_app_banner_form_sec #ondemad_app_banner_form textarea {
  border-radius: 0;
  box-shadow: none;
}

.ondemand_app_banner_form_sec #ondemad_app_banner_form .submit_btn {
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  display: block;
  width: 100%;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
}

/*ON Demand App Banner Form - End*/

.mt-9 {
  margin-top: 9rem;
}

.why-business .whybottom_box img {
  width: 50px;
  height: 50px;
  object-fit: none;
}

.on-dmnd-industries img {
  width: 64px;
  height: 64px;
  object-fit: none;
}

.collapsed .replaced_content {
  visibility: hidden;
  position: relative;
}

.collapsed .replaced_content:after {
  visibility: visible;
  content: "Load More";
}

.replaced_content:after {
  visibility: visible;
  content: "Load Less";
}

@media (max-width: 768px) and (min-width: 0px) {
  header.ondemad_head {
    background: #000;
    height: auto !important;
  }

  .ondemad_head h1 {
    font-size: 24px !important;
    text-align: center !important;
  }

  .ondemad_head .heading-tagline {
    text-align: center !important;
    font-size: 16px;
  }

  .about {
    padding: 0;
  }
}

.industry_focus .mob_industy_box h3 {
  font-size: 17px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 100px;
  text-shadow: 2px 2px 10px #0000006c;
}

.industry_focus #client_carousel .item .industries_quote_btn {
  background: #00000073;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 25px auto;
  letter-spacing: 0;
}

@media (min-width: 767px) {
  .industry_focus #client_carousel .item .industries_quote_btn_div {
    position: absolute;
    top: 15rem;
    bottom: 16px;
    left: 0;
    right: 0;
    display: none;
  }
}

@media (max-width: 767px) {
  .industry_focus #client_carousel .item .industries_quote_btn_div {
    position: absolute;
    top: 15rem;
    bottom: 16px;
    left: 0;
    right: 0;
  }
}

.mob_industy_box:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098d1;
  transform: scaleX(0);
  transform-origin: 0 50%;
  border-radius: 5px;
  transition: transform 0.3s ease-out;
}

.mob_industy_box:hover:before {
  transform: scaleX(1);
}

.mob_industy_box:hover .industries_quote_btn_div {
  display: block !important;
}

.why-cntnt {
  text-align: center;
}

.why_MetaBlock {
  padding: 30px 0;
}

.why-img-title {
  font-size: 18px;
  color: #0f0f0f;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
  margin: 10px 0;
}

.why-img-title {
  text-align: center;
}

.process_we_follow {
  background: #e3edf2;
  background: linear-gradient(135deg, #e3edf2 0, #b9c3cf 100%);
  padding: 30px 0;
}

.enterprise-mobile-app-development-countrybased {
  position: relative;
  width: 100%;
  padding-top: 170px;
  color: #fff;
  /* background: url(../oimages/enterprise-mobile-app-development/mobileapp_development_company.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

@media (max-width: 767px) {
  .enterprisemob_banbtn {
    text-align: center;
  }
}

@media (min-width: 767px) {
  .enterprise-mobile-app-development-countrybased .header-content .mb-5 {
    text-align: left;
  }

  .enterprise-mobile-app-development-countrybased .header-content {
    margin-top: 9rem;
  }

  .enterprise-mobile-app-development-countrybased .heading-tagline {
    text-align: left;
    font-size: 18px;
  }

  .enterprise-mobile-app-development-countrybased.header-shadow:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 0%) none repeat scroll 0 0;
  }
}

header.enterprise-mobile-app-development {
  position: relative;
  width: 100%;
  padding-top: 170px;
  color: #fff;
  /* background: url(../oimages/enterprise-mobile-app-development/banner.png?v1) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 85vh;
}

@media (max-width: 768px) and (min-width: 0px) {
  header.enterprise-mobile-app-development {
    height: 97vh;
    padding-top: 133px;
  }

  header.enterprise-mobile-app-development .header-content h1 {
    font-size: 30px !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  .intro_img_sprite {
    background-position: -992px -1247px !important;
  }

  header.masthead-blockchain-development .header-content .mb-5 {
    font-size: 24px !important;
    text-align: center;
    line-height: 1.5;
  }

  header.masthead-blockchain-development .header-content .heading-tagline {
    width: 100% !important;
    text-align: center;
  }

  .bck_request_btn {
    text-align: center;
  }

  header.masthead-blockchain-development .text-left {
    text-align: center !important;
  }
}

header.masthead-blockchain-development .text-left {
  text-align: left;
}

.header-shadow-custom .expert-btn {
  border: 2px solid #fff;
}

.enterprise_mobile_app_services:hover {
  filter: drop-shadow(1px 0 8px #205a5d);
}

.enterprise_mobile_app_services {
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  min-height: 480px !important;
  height: 475px !important;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .enterprise_mobile_app_services {
    min-height: auto;
    height: auto;
  }
}

.enterprise_mobile_app_services .mobile_app_services_btn_div {
  margin: 10px auto;
  text-align: center;
}

.enterprise_mobile_app_services
  .mobile_app_services_btn_div
  .mobile_app_services_btn {
  background: #ad2953;
  padding: 8px 16px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
}

.enterprise_mobile_app_services
  .mobile_app_services_btn_div
  .mobile_app_services_btn
  i {
  font-size: 20px;
  margin-left: 5px;
}

.enterprise_mobile_app_services .inr_arrow {
  font-size: 20px;
  margin-left: 5px;
  font-weight: 700;
}

.enterprise_mobile_app_services h4 {
  min-height: 50px;
}

.enterprise_mobile_app_services h2 {
  min-height: 50px;
}

.enterprise_mobile_app_services .feature-content_1 p {
  min-height: 220px !important;
}

.spr-feature-img-1 {
  object-fit: none;
  object-position: 0 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-2 {
  object-fit: none;
  object-position: -80px 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-3 {
  object-fit: none;
  object-position: -166px 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-4 {
  object-fit: none;
  object-position: -247px 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-5 {
  object-fit: none;
  object-position: -331px 0;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-6 {
  object-fit: none;
  object-position: 0 -81px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-7 {
  object-fit: none;
  object-position: -80px -81px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-8 {
  object-fit: none;
  object-position: -166px -81px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-9 {
  object-fit: none;
  object-position: -246px -81px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-10 {
  object-fit: none;
  object-position: -329px -81px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-11 {
  object-fit: none;
  object-position: -87px -160px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-12 {
  object-fit: none;
  object-position: 1px -160px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-13 {
  object-fit: none;
  object-position: -170px -160px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-14 {
  object-fit: none;
  object-position: -250px -160px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-15 {
  object-fit: none;
  object-position: -336px -161px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-16 {
  object-fit: none;
  object-position: -142px -548px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-17 {
  object-fit: none;
  object-position: -245px -548px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr-feature-img-18 {
  object-fit: none;
  object-position: -350px -548px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spr_img_consen {
  width: 60px;
  height: 60px;
  margin-left: 0 auto;
  /* background-image: url(../images/inner-pages/blockchain_development/blkch_sprite.png?v2) */
}

.techstack_sprite {
  width: 68%;
  height: 229px;
  background-position: -9px -120px;
  background-size: 111rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.process_sprite_res {
  width: 100%;
  height: 575px;
  background-position: -1413px -243px;
  background-size: 179rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.process_sprite {
  width: 65%;
  height: 287px;
  background-position: -11px -469px;
  background-size: 135rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.mobile_app_hire_sprite {
  width: 60%;
  height: 250px;
  background-position: -1875px -1480px;
  background-size: 110rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.hire_sprite {
  width: 77%;
  height: 200px;
  background-position: -1531px -1205px;
  background-size: 90rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.appio-sprite {
  width: 100%;
  border-radius: 10px;
  height: 540px;
  border: 1px solid #d2d2d2;
  background-size: 180rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/photos-sprite.png?v2) */
}

.appio-screen-img img {
  display: block;
  margin: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid #d2d2d2;
}

.sol-icon .cust_web_imgpack {
  /* background-image: url(../images/inner-pages/custom_web_design_development/new-sprite-svg.svg?v1) */
}

.service_sprite {
  background-size: 85rem;
  width: 77px;
  height: 90px;
}

.web_portal_sprite {
  margin: 0 63%;
  background-size: 174rem;
  width: 119px;
  height: 101px;
}

.web_development_sprite {
  margin: 0;
  background-size: 83rem;
  width: 119px;
  height: 101px;
}

.js_framework_sprite {
  margin: 0 63%;
  background-size: 70rem;
  width: 127px;
  height: 101px;
}

.web_development_sol_sprite {
  /* background-image: url(../images/inner-pages/custom_web_design_development/new-sprite-svg.svg?v1); */
  margin: 0 63%;
  background-size: 83rem;
  width: 127px;
  height: 122px;
}

.webd-intro-sprite {
  /* background-image: url(../images/inner-pages/custom_web_design_development/new-sprite-svg.svg?v1); */
  background-size: 166rem;
  width: 620px;
  height: 300px;
  background-position: -936px -787px;
}

.webdesign_services_sprite {
  /* background-image: url(../images/inner-pages/custom_web_design_development/new-sprite-svg.svg?v1); */
  margin: 0 auto;
  background-size: 280rem;
  width: 350px;
  height: 350px;
}

#screenshots {
  padding: 10px 0;
}

.swiper-container.appio-screen-view {
  text-align: center;
}

.swiper-container.appio-screen-view .swiper-slide {
  padding: 0 43px;
}

.swiper-container {
  width: 100%;
  padding-bottom: 50px;
}

.swiper-slide .appio-sprite {
  display: block;
  margin: auto;
  width: 100%;
  border-radius: 10px;
}

.swiper-slide {
  width: 390px;
  height: auto;
  padding: 0 15px;
}

.two .swiper-slide {
  width: 300px;
}

.swiper-slide .appio-sprite {
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
}

.swiper-slide .appio-screen-img .preview-icon {
  z-index: -1;
  width: calc(100% - 30px);
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  border-radius: 10px;
  display: inline-block;
  height: 5px;
  width: 20px;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background-color: #000;
}

.swiper-pagination-bullet:nth-child(5n + 1) {
  background-color: #9bcf50;
}

.swiper-pagination-bullet:nth-child(5n + 2) {
  background-color: #36c6eb;
}

.swiper-pagination-bullet:nth-child(5n + 3) {
  background-color: #ff7e30;
}

.swiper-pagination-bullet:nth-child(5n + 4) {
  background-color: #1e87f0;
}

.swiper-pagination-bullet:nth-child(5n + 5) {
  background-color: #bf095d;
}

.swiper-pagination-bullet-active {
  border: medium none;
  height: 5px;
  width: 30px;
  opacity: 1;
}

.process_we_follow img {
  width: 100% !important;
}

.why_crypto_development_sprite1,
.why_crypto_development_sprite2,
.why_crypto_development_sprite3,
.why_crypto_development_sprite4 {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.why_crypto_development_sprite1 {
  object-position: 0 0;
}

.why_crypto_development_sprite2 {
  object-position: -120px 0;
}

.why_crypto_development_sprite3 {
  object-position: -240px 0;
}

.why_crypto_development_sprite4 {
  object-position: -361px 0;
}

.crypto_services1,
.crypto_services2,
.crypto_services3,
.crypto_services4,
.crypto_services5,
.crypto_services6 {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.crypto_services1 {
  object-position: -482px 0;
}

.crypto_services2 {
  object-position: 0 -105px;
}

.crypto_services3 {
  object-position: -120px -105px;
}

.crypto_services4 {
  object-position: -244px -105px;
}

.crypto_services5 {
  object-position: -362px -105px;
}

.crypto_services6 {
  object-position: -483px -109px;
}

.crypto_platform1,
.crypto_platform10,
.crypto_platform11,
.crypto_platform12,
.crypto_platform2,
.crypto_platform3,
.crypto_platform4,
.crypto_platform5,
.crypto_platform6,
.crypto_platform7,
.crypto_platform8,
.crypto_platform9 {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.crypto_platform1 {
  object-position: 13px -211px;
}

.crypto_platform2 {
  object-position: -90px -211px;
}

.crypto_platform3 {
  object-position: -193px -211px;
}

.crypto_platform4 {
  object-position: -296px -211px;
}

.crypto_platform5 {
  object-position: -401px -211px;
}

.crypto_platform6 {
  object-position: -507px -211px;
}

.crypto_platform7 {
  object-position: 6px -293px;
}

.crypto_platform8 {
  object-position: -96px -290px;
}

.crypto_platform9 {
  object-position: -199px -292px;
}

.crypto_platform10 {
  object-position: -298px -292px;
}

.crypto_platform11 {
  object-position: -400px -292px;
}

.crypto_platform12 {
  object-position: -503px -292px;
}

.cryp_xchge_feature1,
.cryp_xchge_feature2,
.cryp_xchge_feature3,
.cryp_xchge_feature4,
.cryp_xchge_feature5,
.cryp_xchge_feature6,
.cryp_xchge_feature7,
.cryp_xchge_feature8,
.cryp_xchge_feature9 {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cryp_xchge_feature1 {
  object-position: 0 0;
}

.cryp_xchge_feature2 {
  object-position: -103px 0;
}

.cryp_xchge_feature3 {
  object-position: -193px 0;
}

.cryp_xchge_feature4 {
  object-position: -290px 0;
}

.cryp_xchge_feature5 {
  object-position: -393px 0;
}

.cryp_xchge_feature6 {
  object-position: -17px -91px;
}

.cryp_xchge_feature7 {
  object-position: -131px -93px;
}

.cryp_xchge_feature8 {
  object-position: -243px -99px;
}

.cryp_xchge_feature9 {
  object-position: -346px -99px;
}

.cryp_xchge_sfeature1,
.cryp_xchge_sfeature2,
.cryp_xchge_sfeature3,
.cryp_xchge_sfeature4 {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cryp_xchge_sfeature1 {
  object-position: -13px -225px;
}

.cryp_xchge_sfeature2 {
  object-position: -138px -225px;
}

.cryp_xchge_sfeature3 {
  object-position: -263px -225px;
}

.cryp_xchge_sfeature4 {
  object-position: -390px -225px;
}

.daapd-sprite-ctrl {
  object-fit: none;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dappd-sprite-pos-1 {
  object-position: -7px -4px;
}

.dappd-sprite-pos-2 {
  object-position: -100px -4px;
}

.dappd-sprite-pos-3 {
  object-position: -194px -4px;
}

.dappd-sprite-pos-4 {
  object-position: -287px -4px;
}

.dappd-sprite-pos-5 {
  object-position: -380px -4px;
}

.dappd-sprite-pos-6 {
  object-position: -472px -4px;
}

.dappd-sprite-pos-7 {
  object-position: -567px -4px;
}

.dappd-sprite-pos-8 {
  object-position: -6px -98px;
}

.dappd-sprite-pos-9 {
  object-position: -100px -98px;
}

.dappd-sprite-pos-10 {
  object-position: -193px -98px;
}

.dappd-sprite-pos-11 {
  object-position: -287px -98px;
}

.dappd-sprite-pos-12 {
  object-position: -379px -98px;
}

.dappd-sprite-pos-13 {
  object-position: -473px -98px;
}

.dappd-sprite-pos-14 {
  object-position: -567px -98px;
}

.supc-sprite-ctrl {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.supc-sprite-pos-1 {
  object-position: -5px -6px;
}

.supc-sprite-pos-2 {
  object-position: -122px -6px;
}

.supc-sprite-pos-3 {
  object-position: -241px -6px;
}

.supc-sprite-pos-4 {
  object-position: -362px -6px;
}

.supc-sprite-pos-5 {
  object-position: -481px -7px;
}

.supc-sprite-pos-6 {
  object-position: -604px -7px;
}

.hc-sprite-ctrl {
  object-fit: none;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hc-sprite-pos-1 {
  object-position: -4px -8px;
}

.hc-sprite-pos-2 {
  object-position: -125px -8px;
}

.hc-sprite-pos-3 {
  object-position: -246px -8px;
}

.hc-sprite-pos-4 {
  object-position: -367px -8px;
}

.hc-sprite-pos-5 {
  object-position: -15px -146px;
}

.hc-sprite-pos-6 {
  object-position: -136px -146px;
}

.hc-sprite-pos-7 {
  object-position: -257px -146px;
}

.hc-sprite-pos-8 {
  object-position: -378px -146px;
}

.bcd-sprite-ctrl-0 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -2px -250px;
}

.bcd-sprite-ctrl-1 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -71px -250px;
}

.bcd-sprite-ctrl-2 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -141px -250px;
}

.bcd-sprite-ctrl-3 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -210px -250px;
}

.bcd-sprite-ctrl-4 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -280px -250px;
}

.bcd-sprite-ctrl-5 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -351px -250px;
}

.bcd-sprite-ctrl-6 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -1px -316px;
}

.bcd-sprite-ctrl-7 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -73px -316px;
}

.bcd-sprite-ctrl-8 {
  object-fit: none;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -142px -316px;
}

.intro_img_sprite {
  width: 100%;
  height: 448px;
  background-position: -919px -1247px;
  background-size: 216rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.spr_img_mob_dev {
  width: 80px;
  height: 80px;
  background-size: 92rem;
  margin: 0 auto;
  /* background-image: url(../images/inner-pages/enterprise_mobile_app_development/mob-app-dev-vector-sprite.svg) */
}

.spr_img {
  object-fit: none;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.list-strategy .round-circle {
  margin: 0 auto 40px;
}

#accordion .panel {
  border: none;
  border-radius: 3px;
  box-shadow: none;
}

#accordion .panel-heading {
  padding: 0;
  border: none;
  border-radius: 3px;
  background: 0 0;
}

#accordion .panel-title a {
  display: block;
  padding: 12px 18px;
  background: #e6e6e6;
  font-size: 17px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #333;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  position: relative;
  transition: all 0.5s ease 0s;
  box-shadow: 0 1px 2px rgb(43 59 93 / 30%);
  border-radius: 7px;
}

#accordion .panel-title a.collapsed {
  box-shadow: none;
  color: #333;
  box-shadow: 0 1px 2px rgb(80 80 80 / 30%);
  font-weight: 600;
}

#accordion .panel-title a.collapsed:before,
#accordion .panel-title a:before {
  content: "\f067";
  font-family: FontAwesome;
  width: 25px;
  height: 25px;
  line-height: 28px;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: center;
  position: relative;
  float: right;
  transform: rotate(135deg);
  transition: all 0.3s ease 0s;
}

#accordion .panel {
  background-color: #fcfcfc;
}

#accordion .panel-body p {
  text-align: justify;
  color: #333;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

#accordion .panel-title a.collapsed:before {
  color: #333;
  transform: rotate(0);
}

.mid_section.faq__section {
  background: #f6f6f6;
}

#accordion .panel-body {
  padding: 0 65px 0 15px;
  /* border: 1px solid #000; */
  font-size: 15px;
  color: #615f5f;
  line-height: 27px;
  box-shadow: 0px 2px 10px -7px #797979;
}

.products-section {
  /* background-image: url(../images/inner-pages/blockchain_development/platforms-bg.png); */
  background-size: cover;
  padding: 20px !important;
  margin-top: 0;
}

.platform_heading {
  color: #fff !important;
  text-transform: capitalize;
  font-size: 28px;
  margin: 5px 0 10px;
  font-weight: 700;
}

.our-platform-inner {
  background: #fff;
  height: 381px;
  box-shadow: 0 0 22px 2px rgba(0, 0, 0, 0.07);
  padding: 15px 15px;
  margin: 12px;
  text-align: center;
  border-radius: 10px;
}

.products-section .our-plat-img {
  text-align: -webkit-center;
}

.our-plat-title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin: 10px 0;
  color: #333;
}

.products-section .our-plat-describe {
  font-size: 15px;
}

.our-plat-explore {
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.our-plat-explore a {
  color: #ba2352;
}

.our-plat-explore .fa-angle-right {
  margin-left: 7px;
  color: #ba2352;
}

.owl-theme .owl-dots .owl-dot:hover span,
.products-section .owl-theme .owl-dots .owl-dot.active span {
  background: #fff;
  margin-top: 20px;
}

.products-section .owl-theme .owl-nav {
  display: none;
}

.blck-platform-sprite {
  object-fit: none;
  object-position: -170px -160px;
  width: 120px !important;
  height: 100px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hyp-frame-sec {
  text-align: left;
  padding: 20px;
}

.hyp-box .normal_text {
  text-align: justify;
  min-height: 383px;
}

.hyp-frame-sec {
  background-color: #eefaff;
}

.hyp-box {
  padding: 30px;
  border: 1px solid #dcdcdc;
  min-height: 577px;
  background-color: #fff;
}

.hyp-buttons {
  border-radius: 5px;
}

.hyp-frame-sec .hyp-buttons {
  background-color: #20518d;
  color: #fff;
  font-weight: 600;
  border-radius: 30px;
  outline: 0 auto -webkit-focus-ring-color;
  outline-offset: 5px;
}

.hyp-tools-box {
  text-align: left;
  padding: 26px;
  height: auto;
  background-size: cover;
  min-height: 453px;
  background-color: #012a5e;
  box-shadow: 0 8px 20px -16px rgba(0, 0, 0, 0.63);
}

.hyp-tools-sec {
  background-color: #fafafa;
  padding: 30px;
}

.whyhy-enterprise {
  padding: 30px 0 55px;
}

@media (min-width: 768px) {
  .ten-columns > .col-sm-2 {
    width: 20%;
  }
}

.why-ent-box {
  text-align: center;
}

.whyent-head {
  font-size: 17px;
  color: #333;
  font-weight: 500;
}

.why-ent-box-border {
  border-right: 1px solid #dcdcdc;
}

.whyhy-enterprise-wrap {
  background-color: #fff;
  padding: 54px;
  border-radius: 3px;
  border: 1px solid #e2ecff;
  box-shadow: 0 8px 20px -16px rgba(0, 0, 0, 0.63);
}

.hypcontain-custom {
  margin: 0 auto;
  width: 97.5%;
  max-width: 1520px;
}

.hyp-indus-box {
  display: block;
  font-family: proxima_nova_rgbold;
  font-size: 42px;
  color: #fff;
  line-height: 44px;
  padding: 80px 27px 5px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  z-index: 1;
  color: #fff;
  min-height: 497px;
}

.hyp-indus-box1 {
  /* background-image: url(../images/inner-pages/hyper-ledger/supplychain.png) */
}

.hyp-indus-box2 {
  /* background-image: url(../images/inner-pages/hyper-ledger/healthcare.png) */
}

.hyp-indus-box3 {
  /* background-image: url(../images/inner-pages/hyper-ledger/identity.png) */
}

.hyp-indus-box4 {
  /* background-image: url(../images/inner-pages/hyper-ledger/finance.png) */
}

.hyp-indus-box5 {
  /* background-image: url(../images/inner-pages/hyper-ledger/realestate.png) */
}

.hyp-indus-box:before {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: -1;
  background: rgba(0, 5, 66, 0.47);
  -webkit-transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hyp-indus-box:hover:before {
  height: 100%;
}

.hyp_common_title {
  font-size: 35px;
  line-height: 45px;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.indus-hyp-sec {
  background-color: #f5f7fb;
  padding: 30px 0;
}

.why-MetaBlock-hyp {
  background-color: #000;
  padding: 30px;
  background-position: bottom;
}

.why_hyp_content .hyp_common_title {
  color: #fff;
}

.why_hyp_content p {
  color: #fff;
}

.why-MetaBlock-hyp .list-unstyled li {
  color: #fff;
  font-size: 15px;
  color: #fff;
  line-height: 25px;
  font-family: "Readex Pro", sans-serif;
  margin: 10px 0 5px;
  text-align: justify;
  font-weight: 300;
}

.yMetaBlock-hyp-border {
  border-left: solid #80a5b4 1px;
}

.hyp-indus-title,
.hyptool-title {
  color: #fff;
}

.hyp-tools-box .hyptool-p {
  color: #fff;
  text-align: justify;
}

.why_hyp_content .hyp_common_title {
  margin-top: 0;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  font-size: 25px;
  letter-spacing: 0;
  font-family: "Readex Pro", sans-serif;
  line-height: 1.5;
  text-align: center;
}

.hypprocess-sec {
  padding: 30px;
}

.hyp-proces-img {
  display: inline-block;
  width: 170px;
  height: 170px;
  border-radius: 100%;
  margin-bottom: 15px;
  border: 2px dashed #9b9b9b;
}

.hypprocess-line:before {
  position: absolute;
  content: "";
  height: 1px;
  top: 30%;
  right: -20%;
  width: 40%;
  border-bottom: 2px dashed #9b9b9b;
}

.hypproces-title {
  font-size: 18px;
  color: #012a5e;
  font-weight: 700;
  margin-bottom: 10px;
}

.hypp-count {
  background-color: #d53937;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  padding: 1px;
  margin-right: 6px;
  display: inline-block;
}

.hyptools-head {
  color: #fff;
  margin-top: 0;
  font-size: 25px;
  line-height: 27px;
}

@media (max-width: 767px) {
  .hypprocess-line:before {
    display: none;
  }

  .mob-pad-ctrl {
    padding: 0;
  }

  .why-ent-box-border {
    border-right: 0 solid #0b70d5;
  }

  .why-ent-box {
    text-align: center;
    margin-bottom: 18px;
  }

  .hyp-indus-box {
    margin-bottom: 18px;
  }

  .yMetaBlock-hyp-border {
    border-left: solid #1e4676 0;
  }

  .hyp-box,
  .hyp-tools-box {
    margin-bottom: 18px;
  }

  .hyp_common_title {
    font-size: 17px;
    line-height: 30px;
  }

  .why-MetaBlock-hyp {
    padding: 15px;
  }
}

.hyp-frame-sprite {
  width: 137px !important;
  height: 40px;
  object-fit: none;
}

.hyp-tool-sprite {
  width: 137px;
  height: 40px;
  object-fit: none;
}

.hyp-yent-sprite {
  width: 55px;
  height: 55px;
  object-fit: none;
}

.hyp-indus-sprite {
  width: 70px;
  height: 70px;
  object-fit: none;
}

.hyp-process-sprite {
  width: 90px;
  height: 90px;
  object-fit: none;
  margin: 40px;
}

.hyp-tic-sprite {
  height: 25px;
  width: 30px;
  object-fit: none;
  margin-right: 5px;
}

.hyp-ft-title {
  font-size: 18px;
  color: #333;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

.hyp-ft-box {
  text-align: center;
  box-shadow: 0 3px 19px -11px #01285a;
  padding: 20px;
  border: 1px solid #f6f6f6;
  min-height: 320px;
  background-color: #fff;
  border-radius: 6px;
}

.hyp-ft {
  padding: 30px;
  background: #f2f2f3;
}

.hyp-ft-desc {
  font-size: 15px;
}

.hyp-box .btn:focus {
  border: 0 solid #fff;
  outline: 0 auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.hyp-bt-sec {
  padding: 30px;
  background-color: #eefaff;
}

.hyp-btbpad-ctrl {
  padding: 0;
}

.nyp-btb-cont {
  text-align: center;
  padding: 26px;
  height: 146px;
}

.nyp-btb-cont1 {
  background-color: #0d4388;
}

.nyp-btb-cont2 {
  background-color: #e6f0f4;
}

.nyp-btb-cont3 {
  background-color: #012a5e;
}

.nyp-btb-cont4 {
  background-color: #4450d2;
}

.nyp-btb-cont5 {
  background-color: #0d4388;
}

.nyp-btb-cont6 {
  background-color: #af9cc9;
}

.nyp-btb-cont7 {
  background-color: #d7f4ff;
}

.hyp-btb-tile {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}

.hyp-frame-sec .owl-theme .owl-nav [class*="owl-"] {
  display: none;
}

.bt-benefit-ctrl {
  box-shadow: 0 3px 38px -17px rgba(12, 46, 92, 0.92);
}

.hyp-indus-sec .list-strategy-wrap .slick-dots li.slick-active {
  background: #00adf6;
}

.hyp-indus-sec .explore_slick_btn {
  border: 1px solid #d53937;
  padding: 8px;
  float: left;
  color: #fff;
  background: #d53937;
  font-weight: 700;
}

.hyp-indus-sec .get_demo_slick_btn {
  border: 1px solid #d53937;
  padding: 8px;
  float: left;
  color: #fff;
  background: #d53937;
  font-weight: 700;
  border-radius: 50px;
}

.hyp-indus-sec .slider__arrows ul li a {
  display: block;
  padding: 10px 20px;
  border: 2px solid #008dc9;
  border-radius: 2px;
  color: #fff;
}

.hyp-indus-sec .list-strategy-wrap {
  margin-top: 0;
}

.hyp-indus-sec {
  background-color: #f6f6f6;
}

.hyp-ft-sprite {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.hyp-bt-sprite {
  width: 47px;
  height: 47px;
  object-fit: none;
}

.hyp-indus-sprite {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.hyp-indus-sec .list-strategy .round-circle {
  margin: 0 auto 82px;
}

.hyp-indus-sec .bcd-sprite-ctrl-0 {
  object-fit: none !important;
  width: 100px !important;
  height: 123px;
  margin: 0 auto !important;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -4579px 24px !important;
}

.hyp-indus-sec .bcd-sprite-ctrl-1 {
  object-fit: none !important;
  width: 100px !important;
  height: 123px;
  margin: 0 auto !important;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -4690px 24px !important;
}

.hyp-indus-sec .bcd-sprite-ctrl-2 {
  object-fit: none !important;
  width: 100px !important;
  height: 123px;
  margin: 0 auto !important;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -4811px 24px !important;
}

.hyp-indus-sec .bcd-sprite-ctrl-3 {
  object-fit: none !important;
  width: 100px !important;
  height: 123px;
  margin: 0 auto !important;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -4930px 24px !important;
}

.hyp-indus-sec .bcd-sprite-ctrl-4 {
  object-fit: none !important;
  width: 100px !important;
  height: 123px;
  margin: 0 auto !important;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -5047px 24px !important;
}

.hyp-indus-sec .bcd-sprite-ctrl-5 {
  object-fit: none !important;
  width: 100px !important;
  height: 123px;
  margin: 0 auto !important;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-position: -5163px 24px !important;
}

.connect_chat_icons_sec {
  bottom: 8% !important;
}

.webdevelopment_new_custom_box {
  padding: 40px;
  min-height: 400px;
  position: relative;
  z-index: 0;
}

.webdevelopment_new_title {
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 12px;
  color: #fff;
}

.webdevelopment_new_custom_color-1 {
  background-color: #000000;
}

.webdevelopment_new_custom_color-2 {
  background-color: #414141;
}

.webdevelopment_new_custom_color-3 {
  background-color: #414141;
}

.webdevelopment_new_custom_color-4 {
  background-color: #000000;
}

.webdevelopment_new_custom_dark {
  color: #fff;
}

.webdevelopment_new_custom_light {
  color: #fff;
}

.webdevelopment_new_custom_bg {
  /* background-image: url(../images/inner-pages/website_design/ft-bgicons.svg); */
  background-repeat: no-repeat;
  width: 163px;
  height: 157px;
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  filter: hue-rotate(45deg);
}

@media (min-width: 767px) {
  .ethdapp_serv_box {
    min-height: 449px;
  }

  .ethh_dapp_pts_title {
    min-height: 70px;
  }

  .ethdapp_serv_box .feature-content_1 p {
    min-height: 200px;
    margin-bottom: 10px;
  }
}

header.masthead-dapp-development .header-content h1 {
  text-align: left;
}

header.masthead-dapp-development .heading-tagline {
  text-align: left;
}

.why-eth-dev {
  background-color: #eefaff;
}

.ethdapp_common_title {
  font-size: 35px;
  line-height: 45px;
  color: #012a5e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.why_dapp_box {
  margin: 30px 0;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  height: 370px;
  border: 1px solid #dcdcdc;
}

.ethdapp_serv_box {
  margin: 30px 0;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #dcdcdc;
  background-color: #fff;
}

.ethh_dapp_pts_title {
  font-size: 18px;
  color: #012a5e;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  font-family: Raleway, sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

.eth_dapp_platform_sec .list_item li {
  display: inline;
  float: left;
  padding: 30px;
  text-align: center;
}

.eth_dapp_platform_sec .list_item li img {
  display: grid;
  margin: 0 auto;
}

.eth_dapp_platform_sec .list_item ul.list-unstyled {
  padding-right: 0;
}

.ethhdapp_plats_title {
  font-size: 18px;
  color: #012a5e;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  font-family: Raleway, sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

.new_common_darkbg {
  background-color: #012a5e;
}

.dapp-tools-inner {
  background: #fff;
  height: auto;
  box-shadow: 0 0 22px 2px rgba(0, 0, 0, 0.07);
  padding: 15px 15px;
  margin: 12px;
  text-align: center;
  border-radius: 10px;
}

.eth_dapp_tools .owl-theme .owl-nav .owl-prev {
  background: #122a5e !important;
  position: absolute;
  top: -45px;
  right: 60px;
}

.eth_dapp_tools .owl-theme .owl-nav .owl-next {
  background: #122a5e !important;
  position: absolute;
  top: -45px;
  right: 10px;
}

.eth_dapp_tools .owl-theme .owl-nav .owl-next i.fa-long-arrow-right,
.eth_dapp_tools .owl-theme .owl-nav .owl-prev i.fa-long-arrow-left {
  font-size: 40px;
  padding: 0;
  line-height: 25px;
}

#eth_tools_carousal.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 5px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 50px;
}

#eth_tools_carousal.owl-theme .owl-dots .owl-dot.active span {
  border: 3px solid #ae2954;
  width: 15px;
  height: 15px;
}

.eth_dapp_tools p {
  color: #fff;
}

.eth_dapp_tool_content {
  margin: 20px 0 0 22px;
}

.eth_dapp_tools .ethdapp_common_title {
  font-size: 36px;
  color: #fff;
}

.new_common_lightgrey_bg {
  background: #eff2f5;
}

.eth_dapp_process_title {
  text-align: right;
}

.eth_dapp_process_desc,
.eth_dapp_process_num {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.342em;
}

.eth_dapp_process_num {
  font-size: 40px;
}

.ethdapp_process_para p {
  font-size: 17px !important;
  line-height: 2;
  margin: 0;
  text-align: justify;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.connect-c-c {
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto !important;
  /* background: url(../images/inner-pages/dapp_development/process-connect.svg) */
}

.eth_dapp_tools {
  padding: 54px 40px 20px 40px;
}

.eth_dapp_process {
  padding: 50px;
}

.new_common_tile_color1 {
  background: #012a5e;
}

.new_common_tile_color2 {
  background: #e6f0f4;
}

.new_common_tile_color3 {
  background: #0099da;
}

.new_common_tile_color4 {
  background: #044391;
}

.why_ethdapp_MetaBlock_box {
  min-height: 210px;
  padding: 0;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  padding-top: 30px;
}

.why_ethdapp_MetaBlock_title {
  line-height: 21px;
  margin-top: 15px;
}

.new_common_container {
  margin: 0 auto;
  width: 91.5%;
  max-width: 1520px;
}

.white-text {
  color: #fff !important;
  font-size: 15px;
  color: #fff;
  line-height: 25px;
  font-family: "Readex Pro", sans-serif;
  margin: 10px 0 5px;
  font-weight: 300;
  text-align: center;
}

@media (min-width: 767px) {
  .why_ethdapp_MetaBlock_bg {
    /* background: url(../images/inner-pages/dapp_development/whydapp_MetaBlock_bg.jpg) no-repeat; */
    height: 420px;
    background-size: cover;
    background-position: -165px 0;
  }
}

.why_ethdapp_MetaBlock {
  background: #eefaff;
}

@media (min-width: 767px) {
  .eth-dapp-hire {
    /* background: url(../images/inner-pages/dapp_development/eth_dapp_hire.jpg?v1) no-repeat; */
    background-size: cover;
    position: relative;
    padding: 50px;
  }
}

.eth-dap-hire-box {
  background: #000;
  color: #fff;
  padding: 30px;
}

.eth-daapd-sprite-ctrl {
  object-fit: none;
  object-position: -170px -160px;
  width: 120px !important;
  height: 120px;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.eth-why-sprite-ctrl {
  object-fit: none;
  object-position: -170px -160px;
  width: 80px !important;
  height: 80px;
  text-align: center !important;
  display: block;
  margin: 0 auto;
}

.ethdapp-process-ctrl {
  object-fit: none;
  object-position: -170px -160px;
  width: 120px !important;
  height: 120px !important;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

header.masthead-dapp-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/dapp_development/banner.jpg) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

.masthead-banner-btn .new_common_btn {
  margin: 20px auto;
}

.masthead-banner-btn a:hover {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .ethdapp-process {
    display: block;
  }

  .eth_dapp_process_desc,
  .eth_dapp_process_num,
  .ethdapp_process_para p {
    text-align: center;
  }

  .eth-dapp-hire {
    padding: 0;
  }

  header.masthead-dapp-development {
    background-position: unset;
  }

  header.masthead-dapp-development .header-content h1 {
    font-size: 33px !important;
  }

  .header-content .heading-tagline,
  header.masthead-dapp-development .header-content h1 {
    text-align: center;
  }

  .ethdapp_common_title {
    text-align: center;
    font-size: 21px;
    line-height: 35px;
  }

  .eth-dap-hire-box .ethdapp_common_title {
    font-size: 17px !important;
    line-height: 35px;
  }

  .eth_dapp_platform_sec .ethdapp_common_title {
    font-size: 22px;
  }

  .eth_dapp_tools {
    padding: 0 0 2px 0;
  }

  .eth_dapp_tools .ethdapp_common_title {
    font-size: 31px;
    color: #fff;
  }

  .why_ethdapp_MetaBlock .ethdapp_common_title {
    text-align: center;
    font-size: 21px;
    line-height: 30px;
  }

  .masthead-banner-btn {
    text-align: center;
  }

  .ethdapp_serv_box .feature-content_1 p {
    margin-bottom: 10px;
  }
}

@media (min-width: 767px) {
  .typs-ethtok-box {
    min-height: 570px;
  }
}

header.masthead-ethereum-token-development {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  /* background: url(../images/inner-pages/ethereum-token-development-company-FINAL/Banner.png?v1) no-repeat; */
  background-size: cover;
  height: 85vh;
  background-position: center;
}

.masthead-ethereum-token-development .header-content .heading-tagline,
header.masthead-ethereum-token-development .header-content h1 {
  text-align: left;
}

header.masthead-ethereum-token-development .header-content h1 {
  font-size: 47px !important;
}

.masthead-ethereum-token-development-btn .expert-btn {
  margin: 0;
}

.masthead-banner-btn a:hover {
  color: #fff;
  text-decoration: none;
}

.ethtoken_common_title {
  font-size: 35px;
  line-height: 45px;
  color: #fff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
}

.typs-ethtok-box {
  margin: 30px 0;
  padding: 20px;
  background-color: #000;
  text-align: center;
  border: 1px solid #dcdcdc;
}

.eth_tok_pts_title {
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  font-family: Raleway, sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

.eth_token_service {
  background-color: #000;
}

.ethtok_service_box {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  display: block;
  outline: 0;
  padding: 30px;
  width: 100%;
  margin: 10px;
  height: auto;
  outline: 0;
  text-align: center;
  background: #323232;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 6px 10px 11px -5px rgba(192, 203, 216, 0.67);
  min-height: 315px;
}

.eth_tok_models_wrap {
  text-align: center;
}

.ethtok_img {
  background-color: #fff;
  padding: 0;
  width: 85px;
  height: 85px;
  margin: 40px;
  border-radius: 5px;
  transform: rotate(45deg);
  position: relative;
  z-index: 1;
  box-shadow: 6px 10px 11px -5px rgba(192, 203, 216, 0.67);
}

.eth_tok_count {
  background-color: #d53937;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  padding: 1px;
  margin-right: 6px;
  display: inline-block;
}

.why-ethtok-MetaBlock-box {
  background-color: #000;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
  padding: 35px;
  border-radius: 5px;
}

.yethtok-MetaBlock-parent .yethtok-MetaBlock {
  border-bottom: solid #ddd 1px;
  border-right: solid #ddd 1px;
}

.yethtok-MetaBlock-parent .yethtok-MetaBlock:nth-child(4n) {
  border-left: none;
  border-right: none;
}

.yethtok-MetaBlock-parent-down .yethtok-MetaBlock-down {
  border-right: solid #ddd 1px;
}

.yethtok-MetaBlock-parent-down .yethtok-MetaBlock-down:nth-child(4n) {
  border-top: none;
  border-right: none;
}

.why-ethtok-MetaBlock {
  background: #000;
  padding: 45px 0;
}

.why-ethtok-MetaBlock-wrap {
  margin: 35px;
  text-align: center;
}

.ethtok_about .ethtoken_common_title {
  font-size: 29px;
  line-height: 40px;
  color: #fff;
}

.ethtok_type_sprite {
  width: 80px;
  height: 80px;
  object-fit: none;
  filter: brightness(100);
}

img.ethtok_process_sprite {
  transform: rotate(-45deg);
}

.ethtok_process_sprite {
  width: 80px;
  height: 80px;
  object-fit: none;
}

.ethtok_yeth_sprite {
  width: 80px;
  height: 80px;
  object-fit: none;
}

.eth_tok_models_wrap .eth_tok_pts_title {
  margin-top: 10px;
}

.eth_tok_models_connect:before {
  position: absolute;
  width: 100px;
  height: 100px;
  content: "";
  background-position: -31px 0;
  /* background: url(../images/inner-pages/ethereum-token-development-company-FINAL/icons-new.svg); */
  background-position: -1991px -13px;
  background-size: 210rem;
  left: -48px;
  top: 58px;
  z-index: 0;
}

.eth_tok_model {
  background: #000;
  position: relative;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  header.masthead-ethereum-token-development .header-content h1 {
    font-size: 34px !important;
    text-align: center;
  }

  .heading-tagline {
    text-align: center !important;
  }

  .ethtok_about .ethtoken_common_title {
    text-align: center;
  }

  .masthead-ethtoken-development-btn {
    text-align: center;
  }

  .ethtoken_common_title {
    font-size: 27px;
    line-height: 33px;
  }

  .ethtok_service_box {
    margin: 0 0 10px 0 !important;
  }

  .ethtok_img {
    margin: 0 auto;
  }

  .eth_tok_models_connect:before {
    display: none;
  }

  .eth_tok_count {
    margin-top: 30px !important;
  }

  .yethtok-MetaBlock-parent .yethtok-MetaBlock {
    border-bottom: solid #ddd 1px;
    border-right: solid #ddd 0;
  }

  .yethtok-MetaBlock-parent-down .yethtok-MetaBlock-down {
    border-bottom: solid #ddd 1px;
  }

  .yethtok-MetaBlock-parent-down .yethtok-MetaBlock-down {
    border-right: solid #ddd 0;
  }

  .ethtok_about .ethtoken_common_title {
    font-size: 17px;
    line-height: 26px;
  }
}

@media (min-width: 767px) {
  header.masthead-smart {
    /* background: url(../images/inner-pages/smart-contract/new/banner.png) no-repeat */
  }
}

header.masthead-smart {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: #fff;
  background-size: 100% 100% !important;
  height: 85vh;
  background-position: center;
}

header.masthead-smart .header-content h1 {
  font-size: 55px !important;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-smart .heading-tagline {
  text-align: left;
}

.scont-bt-wra {
  min-height: 240px;
  padding: 37px 23px 0 23px;
}

.scont-bt-pts {
  color: #fff;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
}

.text-dark {
  color: #022552 !important;
}

.scont-bt-title {
  font-size: 43px;
  line-height: 50px;
  color: #fff;
  font-weight: 700;
  padding: 55px 23px 0 23px;
}

.scont-titl-wra {
  /* background: url(../images/inner-pages/smart-contract/new/scont-bg.png) no-repeat; */
  min-height: 240px;
  background-size: cover;
}

.scont_common_title {
  font-size: 35px;
  line-height: 45px;
  color: #012a5e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
}

.scont-serv-parent .scont-serv-child {
  border-top: solid #ddd 1px;
  border-right: solid #ddd 0;
}

.scont-serv-child-down {
  min-height: 345px;
  padding: 40px 40px;
}

.scont-serv-parent .scont-serv-child:nth-child(3n) {
  border-top: solid #ddd 1px;
  border-right: solid #ddd 1px;
}

.scont-serv-parent .scont-serv-child-down {
  border-bottom: solid #ddd 1px;
  border-left: solid #ddd 1px;
}

.scont-serv-parent .scont-serv-child-down:nth-child(3n) {
  border-left: solid #ddd 1px;
  border-right: solid #ddd 1px;
}

.scont-serv-child {
  min-height: 345px;
  padding: 40px 40px;
  border-left: solid #ddd 1px;
  border-bottom: solid #ddd 1px;
}

.scont-serv-pts {
  font-size: 19px;
  color: #012a5e;
  text-align: left;
  font-weight: 600;
  padding-bottom: 13px;
  padding-left: 60px;
  line-height: 26px;
}

.scont-service-box img {
  float: left;
  width: 50px;
  height: 50px;
}

.scont-serv-desc {
  font-size: 15px;
  margin-bottom: 20px;
}

.scont_process_title {
  text-align: right;
}

.scont-process-ctrl {
  object-fit: none;
  object-position: -170px -160px;
  width: 120px !important;
  height: 120px !important;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.eth_dapp_process_num {
  font-size: 42px;
}

.scont_process_desc,
.scont_process_num {
  font-size: 29px;
  font-weight: 700;
  line-height: 1.342em;
}

.scont-indus-box {
  text-align: center;
  background: #fff;
  height: inherit !important;
  padding: 40px 20px 40px;
  margin: 10px auto;
  border-radius: 10px;
}

.scont-indsu-sec {
  background: #eff2f5;
}

.scont-indus-pts {
  color: #fff;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #022552;
}

.scont_serv_sprite {
  width: 80px;
  height: 80px;
  object-fit: none;
}

.scont_bt_sprite {
  object-fit: none;
  object-position: -170px -160px;
  width: 80px !important;
  height: 80px;
  text-align: center !important;
  display: block;
  margin: 0 auto;
}

.scont-process-ctrl {
  object-fit: none;
  width: 120px !important;
  height: 120px !important;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.scont-indus-ctrl {
  object-fit: none;
  width: 80px !important;
  height: 80px !important;
  margin: 0 auto;
  text-align: center !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.scont-indus-box h3 {
  margin-top: 10px;
}

.scont-bt-sec {
  background-color: #f3fbff;
}

.scont-hire-box {
  background: #012a5e;
  color: #fff;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  header.masthead-smart .header-content h1 {
    font-size: 27px !important;
  }

  .scont-about .scont_common_title {
    font-size: 22px;
    line-height: 29px;
  }

  .scont-about p {
    text-align: justify;
  }

  .scont-bt-title {
    font-size: 35px;
    line-height: 48px;
    padding: 71px 0 0 0;
    text-align: center;
  }

  .scont_process .scont_common_title {
    font-size: 28px;
    line-height: 35px;
  }

  .scont-indsu-sec .scont_common_title {
    font-size: 21px;
    line-height: 30px;
  }

  .scont-hire-box {
    padding: 0;
  }

  .scont_common_title {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 0;
  }

  header.masthead-smart {
    background: #24394c;
  }
}

@media (min-width: 767px) {
  header.masthead-cryptocurrency-development .header-content h1 {
    text-align: left !important;
    font-size: 47px !important;
  }
}

header.masthead-cryptocurrency-development .heading-tagline {
  text-align: left;
}

.why_cryptx {
  text-align: center;
  margin: 30px 0;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #dcdcdc;
  min-height: 520px;
}

.cryptx_pts_title {
  font-size: 18px;
  color: #012a5e;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  font-family: Raleway, sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cryptx_common_title {
  font-size: 35px;
  line-height: 45px;
  color: #012a5e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
}

.cryptx_common_desc {
  width: 75%;
  text-align: center !important;
  display: inline-block;
}

.common_light_blue_bg {
  background-color: #eefaff;
}

.cryptx_serv_box {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  display: block;
  outline: 0;
  padding: 30px;
  width: 100%;
  margin: 10px;
  height: auto;
  outline: 0;
  text-align: center;
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 6px 10px 11px -5px rgba(192, 203, 216, 0.67);
  min-height: 315px;
}

.new_cryptx_serv_sec .blkchn_devpmt_services:hover {
  filter: unset;
}

#why_development .crypt_platform span {
  background: rgba(172, 175, 158, 0.7) none repeat scroll 0 0;
  border-radius: 0.2em;
  display: block;
  height: 100px;
  margin: 0 auto 15px;
  width: 100px;
}

.crypt_platform {
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  padding: 10px;
}

.cryptx_platform p {
  color: #012a5e;
  font-size: 18px;
  margin: 0 0 10px;
  font-weight: 600;
  text-align: center;
}

.ycryptx_box_ctrl {
  padding: 25px;
  min-height: 200px;
}

.ycryptx_pts {
  font-size: 23px;
  margin: 0;
  line-height: 27px;
  color: #012a5e;
  font-weight: 600;
  margin-top: 17px;
}

.ycryptx-sprite-ctrl {
  object-fit: none;
  object-position: -170px -160px;
  width: 80px !important;
  height: 80px;
  margin: auto 0;
}

.ycryptx_MetaBlock_sec:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
}

.ycryptx_MetaBlock_sec {
  position: relative;
  z-index: 1;
  padding: 35px 25px 30px 25px;
}

.padding_right_ctrl {
  padding-right: 50px;
}

.ycryptx_MetaBlock_sec .cryptx_common_title {
  font-size: 31px;
  line-height: 40px;
}

#why_development.CryptocurrencyPlatforms {
  /* background: rgba(0, 0, 0, 0) url(../images/inner-pages/cryptocurrency_development/new/platform-bg.jpg) no-repeat scroll center top/cover; */
  margin-top: 0;
}

@media (min-width: 767px) {
  header.masthead-cryptocurrency-development {
    position: relative;
    width: 100%;
    padding-top: 150px;
    color: #fff;
    /* background: url(../images/inner-pages/cryptocurrency_development/new/banner.png) no-repeat; */
    background-size: cover;
    background-position: 100% 100%;
    height: 85vh;
  }
}

.cryptox_about p {
  text-align: justify;
}

.cryptx-hire-box {
  background: #012a5e;
  color: #fff;
  padding: 30px;
  /* background: url(../images/inner-pages/cryptocurrency_development/new/hire-bg-crypt.jpg) no-repeat; */
  background-size: cover;
  position: relative;
}

.CryptocurrencyPlatforms .crypt_platform span {
  background: #fff !important;
  display: block;
  height: 100px;
  margin: 0 auto 15px;
  width: 100px;
}

.cryptox_about .masthead-banner-btn .new_common_btn {
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  header.masthead-cryptocurrency-development {
    position: relative;
    width: 100%;
    padding-top: 150px;
    color: #fff;
    background: #1c1582;
    background: linear-gradient(150deg, #1c1582 7%, #252cc0 100%);
    background-size: cover;
    background-position: 100% 100%;
    height: 85vh;
  }

  header.masthead-cryptocurrency-development .header-content h1 {
    font-size: 37px !important;
  }

  .align-items-center {
    display: block;
    align-items: center;
  }

  .new_common_container {
    margin: 0 auto;
    width: 100%;
    max-width: 1520px;
  }

  .ycryptx_MetaBlock_sec .padding_right_ctrl {
    padding-right: 0;
  }

  .ycryptx_MetaBlock_sec .cryptx_common_title {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  .ycryptx_box_ctrl {
    text-align: center;
  }

  .cryptx-hire-box {
    padding: 0;
  }

  .cryptx_platform .cryptx_common_title {
    font-size: 23px;
    line-height: 30px;
    color: #012a5e;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .crypt_platform {
    margin-bottom: 15px;
  }

  .cryptx_platform .mt-5 {
    margin-top: 0;
  }

  .new_cryptx_serv_sec .cryptx_common_title,
  .ycryptx_dev .cryptx_common_title {
    font-size: 28px;
    line-height: 36px;
  }

  .cryptx_serv_box {
    margin: 0 0 17px 0;
  }
}

.new_common_btn {
  display: inline-block;
  font-size: 17px;
  color: #fff !important;
  outline: 0;
  padding: 10px 15px;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  border: none;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.defi_banner_sec {
  /* background-image: url(../images/inner-pages/defi/defi-banner.png); */
  height: 670px;
  padding: 131px 10px;
  color: #fff;
  background-position: bottom;
}

.about_defi {
  padding: 0 !important;
}

.defi_banner_sec h1 {
  color: #fff;
  font-weight: 800;
  margin-bottom: 2rem;
  text-align: left;
}

.defi_quote {
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 0;
}

.about_defi p {
  text-align: justify;
}

.defi_featuring {
  /*background-image:url(../images/inner-pages/defi/ft-bg.png);*/
  background: #000;
  color: #fff;
  padding: 40px 0;
  background-position: center;
}

.defi_futuristic_title {
  font-size: 35px;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
  margin-bottom: 2rem;
  text-align: left;
}

.defi_serv_box .desc {
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}

.defi_pts_head {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5px;
  margin-top: 5px;
}

.defi_sec_titles {
  font-size: 35px;
  line-height: 45px;
  color: #012a5e;
  font-weight: 700;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.defi_serv_box:hover {
  box-shadow: 0 5px 30px -10px rgb(38 38 38);
}

.defi_serv_box {
  box-shadow: 0 3px 24px -21px rgb(38 38 38);
  padding: 35px;
  border: 1px solid #e8e8e8;
  min-height: 360px;
}

.defi_serv_box .defi_serv_box_arrow {
  background: #0c143d;
  width: 45px;
  height: 45px;
  display: block;
  text-align: center;
  margin: 0 auto;
  border-radius: 50px;
  position: absolute;
  bottom: 15px;
  left: 45%;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
}

.defi_serv_box .defi_serv_box_arrow .indicator {
  position: relative;
  width: 50px;
  height: 50px;
  transform: rotate(317deg);
  left: 35px;
}

.defi_serv_box .defi_serv_box_arrow .indicator span {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  animation: arrow_moving_effect 1s linear infinite;
}

.defi_serv_box .defi_serv_box_arrow .indicator span:nth-child(1) {
  top: -20px;
  left: -20px;
  animation-delay: 0s;
}

.defi_serv_box .defi_serv_box_arrow .indicator span:nth-child(2) {
  top: -15px;
  left: -15px;
  animation-delay: 0.2s;
}

.defi_serv_box .defi_serv_box_arrow .indicator span:nth-child(3) {
  top: -10px;
  left: -10px;
  animation-delay: 0.4s;
}

@keyframes arrow_moving_effect {
  0% {
    border-color: #fff;
    transform: translate(0, 0);
  }

  20% {
    border-color: #fff;
    transform: translate(15px, 15px);
  }

  100%,
  20.1% {
    border-color: #ffd64a;
  }
}

.defi-bt-sec {
  padding: 30px;
  background-color: #000;
}

.defi_common_title {
  font-size: 35px;
  line-height: 45px;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.bt-benefit-ctrl {
  box-shadow: 0 3px 38px -17px rgba(12, 46, 92, 0.92);
}

.defi-btbpad-ctrl {
  padding: 0;
}

.nyp-btb-cont1 {
  background-color: #c139ff;
}

.defi-bt-sprite {
  width: 47px;
  height: 47px;
  object-fit: none;
}

.defi-btb-tile {
  font-size: 17px;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1;
  font-family: "Open Sans", sans-serif !important;
}

.nyp-btb-cont2 {
  background-color: #e6f0f4;
}

.nyp-btb-cont3 {
  background-color: #984cff;
}

.nyp-btb-cont4 {
  background-color: #504cc4;
}

.nyp-btb-cont5 {
  background-color: #6863ff;
}

.nyp-btb-cont7 {
  background-color: #d7f4ff;
}

.nyp-btb-cont {
  text-align: center;
  padding: 26px;
  height: 172px;
}

.defi_bt_box {
  text-align: center;
  padding: 15px;
  border: 1px solid #bdc0c5;
  border-radius: 5px;
  min-height: 285px;
  background-color: #000;
}

.defi_align_center {
  display: flex;
  align-items: center;
}

.defi_copd {
  padding: 20px 0;
}

.how_defi_transform_world_sec .how_defi_transform_world_box {
  background: #202121;
  color: #fff;
  margin: 10px auto;
}

.how_defi_transform_world_sec .how_defi_transform_world_box .box_title {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.how_defi_transform_world_sec .how_defi_transform_world_box .box_title .title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.how_defi_transform_world_sec
  .how_defi_transform_world_box
  .how_defi_transform_world_box_list {
  padding: 10px;
}

.how_defi_transform_world_sec
  .how_defi_transform_world_box
  .how_defi_transform_world_box_list
  .how_defi_transform_world_box_ul {
  padding: 0;
}

.how_defi_transform_world_sec
  .how_defi_transform_world_box
  .how_defi_transform_world_box_list
  .how_defi_transform_world_box_ul
  li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 10px auto;
  font-size: 15px;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  box-shadow: 2px 2px 15px 2px #0a1030;
  line-height: 1.5;
}

.how_defi_transform_world_sec
  .how_defi_transform_world_box
  .how_defi_transform_world_box_list
  .how_defi_transform_world_box_ul
  li
  img {
  padding-right: 1rem;
}

.defi_yield_right_arrow_icon {
  width: 20px;
}

.defi_yield_farming_sec {
  background: #000;
  color: #fff;
}

.defi_yield_farming_sec .defi_yield_farming_div ul.tabs {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
  height: 32px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.defi_yield_farming_sec .defi_yield_farming_div ul.tabs li {
  float: left;
  margin: 0;
  cursor: pointer;
  padding: 5px 20px;
  height: 40px;
  line-height: 25px;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  border-bottom: 1px solid #333;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  color: #fff;
  overflow: hidden;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
}

.defi_yield_farming_sec .defi_yield_farming_div .tab_last {
  border-right: 1px solid #333;
}

.defi_yield_farming_sec .defi_yield_farming_div ul.tabs li:hover {
  background-color: #d53166;
  color: #fff;
}

.defi_yield_farming_sec .defi_yield_farming_div ul.tabs li.active {
  background-color: #fff;
  color: #000;
  display: block;
  border-bottom: 3px solid #fff;
}

.defi_yield_farming_sec .defi_yield_farming_div .tab_container {
  clear: both;
  float: left;
  width: 100%;
  margin-top: 2rem;
  overflow: auto;
}

.defi_yield_farming_sec .defi_yield_farming_div .tab_content {
  padding: 20px;
  margin: 15px auto;
  display: none;
}

.defi_yield_farming_sec
  .defi_yield_farming_div
  .tab_content
  .how_works_cont
  ul {
  padding-left: 0;
}

.defi_yield_farming_sec
  .defi_yield_farming_div
  .tab_content
  .how_works_cont
  ul
  li {
  list-style: none;
}

.defi_yield_farming_sec .defi_yield_farming_div .tab_content .bene_list {
  border: 1px solid #fff;
  padding: 8px;
  margin: 10px auto;
  display: flex;
  align-items: center;
}

.defi_yield_farming_sec .defi_yield_farming_div .tab_content .bene_list p {
  margin-bottom: 0;
  margin-left: 8px;
}

.defi_yield_farming_sec
  .defi_yield_farming_div
  .tab_content
  .platform_protocols_sec
  .platform_protocols_ul
  li,
.defi_yield_farming_sec
  .defi_yield_farming_div
  .tab_content
  .tokens_sec
  .tokens_ul
  li {
  list-style: none;
  padding: 5px;
  margin: 5px;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}

.defi_yield_farming_sec
  .defi_yield_farming_div
  .tab_content
  .platform_protocols_sec
  .platform_protocols_ul
  li
  i,
.defi_yield_farming_sec
  .defi_yield_farming_div
  .tab_content
  .tokens_sec
  .tokens_ul
  li
  i {
  font-size: 13px;
}

.defi_yield_farming_sec .defi_yield_farming_div .tab_drawer_heading {
  display: none;
}

@media screen and (max-width: 480px) {
  .defi_yield_farming_sec .defi_yield_farming_div .tabs {
    display: none !important;
  }

  .defi_yield_farming_sec .defi_yield_farming_div .tab_drawer_heading {
    background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
    color: #fff;
    border-top: 1px solid #333;
    margin: 5px;
    padding: 10px 20px;
    display: block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.content {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  padding: 0 60px 0 0;
}

.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

.question {
  color: #fff;
  position: relative;
  background: #0d4388;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}

.answers {
  font-weight: 500;
  background: #f2f2f2;
  padding: 0 15px;
  margin: 0 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.questions:checked ~ .answers {
  height: auto;
  opacity: 1;
  padding: 15px;
}

.plus {
  color: #fff;
  position: absolute;
  margin-left: 10px;
  margin-top: 5px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.questions {
  display: none;
}

.defi-serv-sprite {
  object-fit: none;
  width: 60px;
  height: 60px;
  filter: brightness(24);
}

.defi-bt-sprite {
  object-fit: none;
  width: 80px;
  height: 80px;
  filter: hue-rotate(45deg);
}

.defi-yt-sprite {
  object-fit: none;
  width: 50px;
  height: 50px;
  filter: hue-rotate(45deg);
}

.defi_bts_sec {
  background-color: #000;
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .defi_featuring {
    padding: 25px;
  }

  .defi_futuristic_title {
    font-size: 23px;
  }

  .defi_featuring p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .defi_sec_titles {
    font-size: 19px;
    line-height: normal;
  }

  .defi_copd {
    padding: 0;
    padding-top: 25px;
  }

  .defi_serv_box {
    margin-bottom: 30px;
  }

  .defi_bt_box {
    margin-bottom: 20px;
  }

  .defi_common_title {
    font-size: 22px;
    line-height: normal;
    color: #012a5e;
    font-weight: 700;
    text-align: center;
  }

  .defi-bt-sec p {
    text-align: center;
  }
}

.defi-faq .content {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  padding: 0 60px 0 0;
}

.defi-faq .centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

.defi-faq .question {
  color: #fff;
  position: relative;
  background: #0d4388;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}

.defi-faq .answers {
  font-weight: 500;
  background: #f2f2f2;
  padding: 0 15px;
  margin: 0 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.defi-faq .questions:checked ~ .answers {
  height: auto;
  opacity: 1;
  padding: 15px;
}

.defi-faq .plus {
  color: #fff;
  position: absolute;
  margin-left: 10px;
  margin-top: 5px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.defi-faq .questions:checked ~ .defi-faq .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.defi-faq .questions {
  display: none;
}

.defi-faq .defi-serv-sprite {
  object-fit: none;
  width: 60px;
  height: 60px;
}

.defi-faq .defi-bt-sprite {
  object-fit: none;
  width: 80px;
  height: 80px;
}

.defi-faq .defi-yt-sprite {
  object-fit: none;
  width: 50px;
  height: 50px;
}

.defi-faq .defi_bts_sec {
  background-color: #fafafa;
  padding-bottom: 40px;
}

.home-new-abt-btn .btn {
  padding: 4px 10px;
  border-radius: 50px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
}

.home-new-abt-btn i {
  margin-right: 8px;
  font-size: 21px;
}

.about_blochainslns {
  padding-top: 50px;
  padding-bottom: 50px;
}

.enterprsieblckchain_banner_sec {
  /* background-image: url(../oimages/enterprise-blockchain-solutions/enterprise-blockchain-solution-banner.png); */
  height: auto;
  padding: 131px 10px;
  color: #fff;
  background-position: unset;
  background-repeat: no-repeat;
  background-size: cover;
}

.enterprsieblckchain_banner_sec h1 {
  color: #fff;
  font-weight: 800;
}

.ebs_common_title {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
  background: linear-gradient(94.84deg, #c139ff 1.15%, #546cff 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ebs_gamechange_descs {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 0;
}

.enterprise_blockchain_gamechangesec {
  background-color: #000;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ebs_stat_txtlite {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

.ebs_stat_txtstrong {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 36px;
  color: #5d5d5d !important;
}

.ebs_content_box {
  line-height: 1.2;
  margin-top: 20px;
}

.ebs-btbpad-ctrl {
  padding: 0;
}

.ebs-btb-cont {
  text-align: center;
  padding: 26px;
  height: 172px;
}

.ebs-yt-sprite {
  object-fit: none;
  width: 50px;
  height: 50px;
}

.ebs-btb-tile {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  line-height: 1;
}

.ebs_copd {
  padding: 20px 0;
}

.ebs_indus_content {
  display: flex;
  align-items: center;
}

.nyp-btb-cont .ebs_common_desc {
  color: #fff;
  text-align: left;
}

.ebs_benefit_box {
  display: flex;
  align-items: center;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  margin-bottom: 11px;
  border-radius: 3px;
  padding: 7px;
}

.ebs_integrate_techsec .expert-btn {
  margin: 15px auto;
}

.ebs_integrate_desc {
  font-size: 17px;
  line-height: 1.3;
  color: #fff !important;
  font-weight: 600;
  margin-top: 15px;
}

.epe_blockchainslns_ul {
  padding: 0;
  list-style: none;
}

.MetaBlock_box_titles {
  font-size: 24px;
  font-weight: 600;
}

.epe_blockchainslns_box {
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 10px -8px #000;
  padding: 28px;
  border-radius: 12px;
  color: #fff;
  position: relative;
  z-index: 0;
}

.epe_blcklns_bg1 {
  /* background-image: url(../oimages/enterprise-blockchain-solutions/pre-blockchain-solutions.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.epe_blcklns_bg2 {
  /* background-image: url(../oimages/enterprise-blockchain-solutions/post-blockchain-solutions.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

li.epe_blockchainslns_li {
  font-size: 17px;
  font-weight: 500;
  margin: 18px 0;
  background-color: #00000094;
  padding: 20px;
}

.epe_blockchainslns_box.epe_blcklns_bg1:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000de -3%, #ffffff29 100%);
  content: "";
  left: 0;
  top: 0;
  z-index: -8;
  border-radius: 12px;
}

.epe_blockchainslns_box.epe_blcklns_bg2:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(93.79deg, #e442ff -13.43%, #3c5bff 100.49%);
  content: "";
  left: 0;
  top: 0;
  z-index: -8;
  border-radius: 12px;
}

.ebs_blockchain_justify {
  display: flex;
  justify-content: center;
}

.enterprsieblckchain_banner_sec .expert-btn {
  margin: 0;
}

.about_blochainslns p {
  font-size: 20px;
}

.ebs-bt-sec .nyp-btb-cont {
  text-align: center;
  padding: 26px;
  min-height: 200px;
}

.ebs_integrate_techsec {
  background-color: #000 !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ebs_integrate_techsec .ebs_common_title {
  font-size: 30px;
}

@media (max-width: 767px) {
  .enterprsieblckchain_banner_sec h1 {
    font-size: 27px;
    text-align: center;
  }

  .ebs_blockchain_justify {
    display: block;
  }

  .enterprsieblckchain_banner_sec p {
    text-align: center;
  }

  .ebs_common_title {
    font-size: 24px;
    line-height: 30px;
  }

  .ebs_benefit_sec {
    padding: 0;
  }

  .enterprise_blockchain_gamechangesec {
    text-align: center;
  }

  .ebs_stat_txtstrong {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 30px;
    color: #5d5d5d !important;
  }

  .ebs_gamechange_descs {
    font-size: 16px;
  }

  .ebs_stat_txtlite {
    font-size: 19px;
  }

  .ebs_content_box {
    line-height: 1.2;
    margin-top: 10px;
  }

  .ebs_integrate_techsec .ebs_common_title {
    font-size: 18px;
  }

  .ebs_integrate_desc {
    font-size: 16px;
    line-height: 1.5;
    color: #fff !important;
    font-weight: 500;
    margin-top: 15px;
  }

  .epe_blcklns_bg2 {
    margin-top: 20px;
  }
}

/*==========================================COUNTRY BASED PAGES===============================*/

.masthead-blockchain-development-basedcountry {
  position: relative;
  width: 100%;
  padding-top: 195px;
  color: #fff;
  /* background: url(../images/inner-pages/countrybased_pages/blocckchain_development_company.png) no-repeat; */
  background-size: cover;
  background-position: center;
  height: 60rem;
}

.masthead-blockchain-development-basedcountry.header-shadow-custom:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: unset;
  background-color: #000000a1;
}

.masthead-blockchain-development-basedcountry .heading-tagline {
  text-transform: capitalize;
}

.masthead-blockchain-development-basedcountry .heroban_countrybased_btn {
  display: flex;
  justify-content: center;
}

.masthead-blockchain-development-basedcountry .heroban_countrybased_btn a {
  margin: 5px 5px;
  background-color: #b22b58;
}

.our-achievments .acheivements-list {
  max-width: 100%;
  margin: 0 70px;
}

.our-achievments .acheivements-list .col-6 {
  width: 19.6667%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.experience_div {
  /* background-image: url(../about_us/abouts-sprite.png); */
  width: 52px;
  background-position: 399px -984px;
  background-size: 20rem;
  margin-bottom: 30px;
  height: 51px;
  margin: 0px auto;
}

.our-achievments .acheivements-list .col-6 p.achievement {
  margin: 0;
  font-size: 20px;
  line-height: 23px;
  color: #303134;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
}

.achievement-details {
  text-align: center;
}

.bg_light.our-achievments {
  background-color: #f9f9f9;
}

@media (max-width: 767px) {
  .masthead-blockchain-development-basedcountry .heroban_countrybased_btn {
    display: block;
  }

  .our-achievments .acheivements-list {
    margin: 0px 0px;
  }

  .our-achievments .acheivements-list .col-6 {
    width: 100%;
  }
}

/*=========================NFT DEVELOPMENT============================*/

.onftcommon_ctrl {
  padding: 45px 0;
}

.MetaBlocknft_sec_titlewrap .onfs_title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 2.5rem;
  margin-top: 0rem;
  text-transform: capitalize;
}

.masthead-nft-development {
  /* background-image: url(../oimages/nft-development/banner.png); */
  position: relative;
}

.masthead-nft-development .header-content h1,
.masthead-nft-development .header-content h3 {
  text-align: left;
}

.masthead-nft-development .header-content h3 {
  width: 80%;
}

.masthead-nft-development.header-shadow-custom:before {
  /* background-image: url(../oimages/nft-development/nft_bannner.png); */
  background-position: center;
}

.MetaBlock_nft_intro .MetaBlocknft_sec_titlewrap {
  text-align: center;
}

.MetaBlock_nft_intro .row {
  display: flex;
  justify-content: center;
}

.MetaBlock_nft_services .MetaBlocknft_sec_titlewrap .onfs_title {
  color: #fff;
}

.MetaBlock_nft_services {
  background-color: #000;
}

.MetaBlocknft_box .MetaBlocknft_heads {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

.MetaBlock_nft_services .MetaBlocknft_box {
  padding: 15px;
  min-height: 200px;
  margin-bottom: 3.5rem;
}

.MetaBlock_nft_services
  .MetaBlocknft_box
  .MetaBlocknft_heads
  .MetaBlocknft_serv_frontwrap
  h3 {
  color: #fff;
  font-size: 23px;
  text-align: center;
}

.MetaBlocknft_serv_frontwrap {
  text-align: center;
}

.nftdev-sprite1 {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.MetaBlock_nft_services .MetaBlocknft_box .MetaBlocknft_desc {
  font-weight: 500;
  color: #494d4e;
  line-height: 1.5;
}

.MetaBlock_nft_workflow .MetaBlocknft_sec_titlewrap {
  text-align: center;
}

.MetaBlock_nft_workflow .MetaBlocknft_sec_titlewrap p {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.MetaBlock_nft_workflow.onftcommon_ctrl {
  padding: 45px 0px 0px 0px;
}

.MetaBlocknft_container_wrap {
  padding: 0px 70px;
}

.MetaBlock_nft_differences {
  background: #000;
}

.MetaBlock_nft_differences .MetaBlocknft_sec_titlewrap .onfs_title {
  color: #fff;
}

.MetaBlock_nft_differences .MetaBlocknft_box .MetaBlocknft_desc ul {
  padding: 0;
  color: #dbe5e6;
}

.MetaBlock_nft_differences .MetaBlocknft_box .MetaBlocknft_desc li {
  list-style: none;
  margin-bottom: 1.2rem;
  text-align: center;
}

.MetaBlock_nft_differences .MetaBlocknft_box {
  background-color: #000;
  display: flex;
  min-height: 480px;
  align-items: flex-end;
}

.MetaBlock_nft_differences .MetaBlocknft_box .ondif_wrap {
  padding: 35px;
  margin-top: 25rem;
}

.MetaBlock_nft_differences .MetaBlocknft_box .MetaBlocknft_heads {
  color: #fbfeff;
  text-align: center;
  font-size: 22px;
}

.MetaBlock_nft_differences .MetaBlocknft_box {
  background-size: cover;
  background-position: center;
  box-shadow: 1px 22px 28px -9px #797979;
  border-radius: 10px;
}

#sft_difbox {
  /* background-image: url(../oimages/nft-development/semi-fungible-token.png); */
  filter: grayscale(1);
}

#nft_difbox {
  /* background-image: url(../oimages/nft-development/nft-token.png); */
  filter: grayscale(1);
}

#dnft_difbox {
  /* background-image: url(../oimages/nft-development/dnft-token.png); */
  filter: grayscale(1);
}

.MetaBlock_nft_keystone {
  /* background-image: url(../oimages/nft-development/keystonebg1.png); */
  background-size: cover;
  background-position: right;
  filter: grayscale(10);
}

.MetaBlock_nft_keystone .MetaBlocknft_sec_titlewrap .onfs_title {
  color: #fafeff;
}

.MetaBlock_nft_keystone .MetaBlocknft_keystone_points .onfksp_point {
  color: #d2dcff;
  font-size: 17px;
  padding: 10px;
  border: 1px solid #7a7fbd;
  border-radius: 3px;
  background-color: #ffffff05;
  margin-bottom: 1.2rem;
  font-weight: 500;
}

.MetaBlock_nft_whychoose {
  background: #000;
}

.MetaBlock_nft_whychoose .MetaBlocknft_box {
  text-align: center;
}

.MetaBlock_nft_whychoose .MetaBlocknft_sec_titlewrap {
  text-align: center;
  margin-bottom: 2.5rem;
}

.MetaBlock_nft_whychoose .MetaBlocknft_sec_titlewrap .onfs_title {
  margin-bottom: 0rem;
}

.MetaBlock_nft_whychoose .MetaBlocknft_sec_titlewrap p {
  width: 51%;
  margin: 0 auto;
  margin-bottom: 0;
  padding: 10px 0px;
}

.MetaBlock_nft_whychoose .MetaBlocknft_box .MetaBlocknft_desc {
  font-size: 17px;
  font-weight: 600;
  color: #11383a;
}

.MetaBlock_nft_hire .MetaBlocknft_sec_titlewrap p {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding-top: 7rem;
}

.MetaBlock_nft_hire .MetaBlocknft_sec_titlewrap .onfs_title {
  text-align: center;
}

.base-timeline {
  list-style-type: none;
  counter-reset: number;
  /* number 2021*/
  position: relative;
  display: block;
  z-index: 2;
  width: 75%;
  /* change or remove*/
  margin: 0 auto;
}

.base-timeline::before {
  content: "";
  width: 95%;
  border-top: 2px solid steelblue;
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: -1;
}

.base-timeline__item {
  position: relative;
  display: inline-block;
  width: calc(100% / 3 - 15px);
  /* change width */
}

.base-timeline__item::before {
  display: flex;
  justify-content: center;
  align-items: center;
  counter-increment: number;
  /* number -1*/
  content: counter(number) "";
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: steelblue;
  color: white;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
}

/* modifier with use of the data-year attribute */

.base-timeline__item--data::before {
  content: attr(data-year);
  width: 60px;
  height: 60px;
}

/* hover element */

.base-timeline__item:hover::before {
  background-color: rgba(225, 114, 114, 0.9);
  transform: scale(1.5);
}

.base-timeline__item::before {
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.base-timeline__item:last-child {
  width: 0;
}

.base-timeline__summary-text {
  position: absolute;
  bottom: -3em;
  left: -64px;
  width: 168px;
  text-align: center;
  font-weight: 600;
}

.MetaBlock_nft_hire {
  background-color: #000;
}

.MetaBlock_nft_services .card {
  perspective: 100rem;
  position: relative;
}

.card__side {
  height: 100%;
  width: 100%;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 18px;
  line-height: 1.5;
}

.card__side--front {
  background-color: #222;
}

.card__side--back {
  background-color: #525b6b;
  transform: rotateX(180deg);
  padding: 20px;
}

.MetaBlock_nft_services .card:hover .card__side--front {
  transform: rotateX(-180deg);
}

.MetaBlock_nft_services .card:hover .card__side--back {
  transform: rotateX(0);
}

.education {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.credentialing {
  --bg-color: #b8f9d3;
  --bg-color-light: #e2fced;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
  --bg-color: #ceb2fc;
  --bg-color-light: #f0e7ff;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
  --bg-color: #dce9ff;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.MetaBlock_nft_whychoose .card {
  width: 220px;
  height: 321px;
  background: #8d8b8b;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  padding: 15px;
}

.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.MetaBlock_nft_whychoose .card:hover .overlay {
  transform: scale(4) translateZ(0);
}

.MetaBlock_nft_whychoose .card:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.MetaBlock_nft_whychoose .card:hover .circle:after {
  background: var(--bg-color-light);
}

.MetaBlock_nft_whychoose .card:hover p {
  color: var(--text-color-hover);
}

.MetaBlock_nft_whychoose .card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.MetaBlock_nft_whychoose .card p {
  font-size: 17px;
  color: #152135;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
  font-weight: 600;
}

.MetaBlocknft_circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.MetaBlocknft_circle:after {
  content: "";
  width: 80px;
  height: 80px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 23px;
  left: 24px;
  transition: opacity 0.3s ease-out;
}

.MetaBlocknft_circle img {
  z-index: 10000;
  transform: translateZ(0);
}

.MetaBlock_nft_whychoose .overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: #000;
  top: 56px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}

.MetaBlock_nft_workflow .MetaBlocknft_sec_titlewrap .onfs_title {
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .masthead-nft-development .header-content {
    text-align: center;
  }

  .masthead-nft-development .header-content h1,
  .masthead-nft-development .header-content h3 {
    text-align: center;
  }

  .masthead-nft-development .header-content h3 {
    width: 100%;
  }

  .MetaBlock_nft_differences .MetaBlocknft_box {
    box-shadow: 1px 22px 28px -9px #79797900;
    margin-bottom: 2rem;
  }

  .MetaBlock_nft_keystone {
    background-position: left;
  }

  .MetaBlock_nft_keystone .MetaBlocknft_sec_titlewrap .onfs_title {
    text-align: center;
  }

  .MetaBlock_nft_whychoose .MetaBlocknft_sec_titlewrap p {
    width: 100%;
  }

  .MetaBlock_nft_whychoose .card p {
    font-size: 19px;
    margin-top: 13px;
  }

  .MetaBlock_nft_whychoose .overlay {
    width: 118px;
    position: absolute;
    height: 118px;
    border-radius: 50%;
    background: var(--bg-color);
    top: 17px;
    left: 0;
    z-index: 0;
    transition: transform 0.3s ease-out;
    right: 0;
    margin: 0 auto;
  }

  .MetaBlock_nft_whychoose .card {
    width: 100%;
    margin-bottom: 2rem;
    height: auto;
  }

  .MetaBlocknft_container_wrap {
    padding: 0px 0px;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 70%;
    margin: 0 auto;
    padding: 0;
  }

  .base-timeline::before {
    display: none;
  }

  .base-timeline__item {
    position: relative;
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    align-items: center;
  }

  .base-timeline__summary-text {
    position: unset;
    bottom: -3em;
    left: -64px;
    width: 168px;
    text-align: left;
    font-weight: 600;
    margin-left: 5px;
  }

  .base-timeline__item:last-child {
    width: 100%;
  }

  .MetaBlock_nft_hire .MetaBlocknft_sec_titlewrap p {
    width: 100%;
    padding-top: 3rem;
  }

  .MetaBlocknft_sec_titlewrap .onfs_title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .MetaBlock_nft_whychoose .MetaBlocknft_container_wrap {
    padding: 0px 0px;
  }

  .MetaBlock_nft_whychoose .card {
    width: 100%;
    height: 275px;
  }

  .MetaBlock_nft_whychoose .overlay {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 30px;
  }
}

.MetaBlock_clonescript_store {
  padding: 88px 0px;
}

.MetaBlock_clonescriptstore_categories {
  padding: 130px 0px 40px 0px;
}

.MetaBlock_cloness_categorieslist {
  padding: 0;
}

.MetaBlock_clonescriptstore_categories {
  background-color: #091941;
}

.MetaBlock_clonescriptstore_categories .title {
  color: #fff;
  text-align: center;
  font-weight: 800;
}

.MetaBlockcss_navtabs.nav-tabs > li > a {
  color: #fff !important;
  font-weight: 700;
  padding: 5px 15px;
  border: 1px solid #6284c7;
  margin-right: 0px;
  border-radius: 50px;
  margin: 5px;
  background: transparent;
  text-transform: uppercase;
}

.MetaBlockcss_navtabs.nav-tabs > li:hover {
  margin-bottom: -1px;
  border-radius: 50px;
}

.MetaBlockcss_navtabs.nav-tabs > li.active > a {
  background: #3e6cff;
}

.nav.MetaBlockcss_navtabs > li > a:hover {
  background: #3e6cff;
}

/*.MetaBlockcss_navtabs.nav-tabs>li.active>a:hover{
  background-color: #fff;
    border: 1px solid transparent;
border-radius: 50px;

}*/

#innerTabs.nav-tabs {
  border-bottom: 0;
  padding-top: 10px;
}

#innerTabs.nav-tabs > li a {
  border: 0;
  font-weight: 600;
  color: #707070;
  border-radius: 50px;
  background: #fff;
  color: #000;
  margin: 0px 5px 10px 0px;
  padding: 5px 15px;
  box-shadow: 0px 1px 10px 2px #0000002e;
  text-transform: uppercase;
  font-size: 14px;
}

#innerTabs.nav-tabs > li.active > a {
  border: 0;
  /* border-radius: 0; */
  color: #fff;
  background: #091941;
}

#innerTabs.nav-tabs > li.active {
  /*border-bottom: 4px solid #00a68f;*/
}

.innertab_details_wrap .row {
  background-color: transparent;
}

#innerTabs {
  display: flex;
  justify-content: center;
}

.innertab_itembox {
  text-align: center;
  padding: 10px;
  border: 1px solid #0000;
  min-height: 172px;
  cursor: pointer;
  /*background: linear-gradient(
179deg
, rgba(255,255,255,1) 44%, rgb(217 217 217) 100%);*/
  background: linear-gradient(179deg, rgb(9 25 65) 44%, rgb(62 108 255) 100%);
  border-radius: 10px;
  box-shadow: 0px 10px 12px -9px #282828;
}

.innertab_itembox p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
  color: #fff;
  width: 121px;
  margin: 0 auto;
}

.innertabs_itemsarea {
  padding: 0px 100px;
  /*background-image: url(../images/inner-pages/cryptoclone-scripts/cryptobg.jpg);
    background-size: cover;
    background-position: right;
    position: relative;
    z-index: 0;*/
}

.innertabs_itemsarea:before {
  /*position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(47,58,82);
background: radial-gradient(circle, rgb(68 82 112 / 92%) 0%, rgb(5 10 22 / 94%) 100%);
    content: '';
    left: 0;
    z-index: -1;*/
}

.innertab_details_wrap {
  padding: 30px 0px;
}

.innertabs_itemsarea .row:nth-child(2) {
  margin-top: 2rem;
}

.innertab_details_wrap .row {
  justify-content: center;
  display: flex;
}

.innertab_itembox.selected_item {
  border: 1px solid #1f76f0;
  box-shadow: 0px 6px 23px -2px #15a9ff99;
  color: #07c1a7;
}

.MetaBlockcss_buynow_ct {
  text-align: center;
}

.MetaBlockcss_buynow_ct > a {
  background: rgb(9 25 65);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: normal;
  min-width: 160px;
  border: 0;
  padding: 9px 0px;
  border-radius: 5px;
  margin-bottom: 2rem;
}

#cryptocloneModal {
  padding-top: 10%;
}

.MetaBlockcss_buynow_ct .modal .modal-header {
  background-color: #fff !important;
  padding: 0;
  border-radius: 5px;
}

.MetaBlockcss_buynow_ct .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 600;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde1e5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.MetaBlockcss_buynow_ct .form-control:focus {
  border: 1px solid #529fffab;
  box-shadow: unset;
}

.MetaBlockcss_buynow_ct #cryptocloneModal .modal-header .close {
  color: #fff;
  font-size: 17px;
  font-weight: normal;
  opacity: 1;
  font-weight: 700;
  position: absolute;
  top: -10px;
  right: -15px;
  background-color: #d53937;
  width: 30px;
  height: 30px;
  border-radius: 27px;
}

.ccmodel_header {
  padding: 10px 0px;
}

.ccmodel_header h5 {
  font-size: 25px;
  font-weight: 700;
  color: #047077;
}

.MetaBlockcss_buynow_ct #cryptocloneModal .modal-dialog {
  border-radius: 50px;
}

.MetaBlockcss_buynow_ct #cryptocloneModal .modal-dialog .modal-content {
  border-radius: 5px;
}

.MetaBlockcss_buynow_ct .MetaBlockcss_form_cta button {
  background-color: #ac3258;
  color: #fff;
  letter-spacing: 0;
  font-size: 17px;
  font-weight: 600;
}

#cryptocloneModal ul.tags {
  padding: 0;
  text-align: left;
}

#cryptocloneModal ul.tags li {
  list-style: none;
  display: inline-block;
  background-color: #26ac62;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  margin: 2px;
  padding: 2px 10px;
  border-radius: 3px;
}

/*#nft-clone .innertabs_itemsarea{
  height: 425px;
    overflow-y: scroll;
}*/

#nft .innertabs_itemsarea .row {
  margin-bottom: 2rem;
}

.MetaBlockcc_cryptoclone_sprite {
  width: 110px;
  height: 110px;
  object-fit: none;
}

@media (min-width: 768px) {
  #cryptocloneModal .modal-dialog {
    max-width: 32%;
    margin: 30px auto;
  }
}

@media (max-width: 768px) {
  .innertabs_itemsarea {
    padding: 0;
    background-color: #f4f4f4;
    width: 100%;
    overflow-x: scroll;
  }

  .innertab_details_wrap {
    display: flex;
    align-items: baseline;
  }

  .MetaBlockcss_navtabs.nav-tabs > li {
    width: 329px;
  }

  .innertabs_itemsarea {
    height: auto !important;
  }

  #defi #innerTabs {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  #defi #innerTabs {
    justify-content: left;
  }

  #cryptocloneModal .modal-dialog {
    padding-top: 40%;
  }

  .MetaBlock_clonescriptstore_categories {
    padding: 100px 0px 20px 0px;
  }
}

#mainTabs {
  border: 0;
}

@media (max-width: 1440px) and (min-width: 1280px) {
  .MetaBlockcss_navtabs.nav-tabs > li > a {
    text-align: center;
    border-radius: 50px;
  }

  #mainTabs {
    display: flex;
    justify-content: center;
    border: 0;
  }

  .MetaBlockcss_navtabs.nav-tabs > li > a {
    font-size: 14px;
  }
}

.innertab_itembox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.innertab_itembox input:checked ~ .checkmark,
.innertab_itembox:hover input ~ .checkmark,
.checkmark {
  background-color: #212529;
}

.checkmark {
  position: absolute;
  top: 2px;
  display: inline-block;
  height: 35px;
  width: 35px;
  border: 2px solid #eee;
  border-radius: 50px;
  right: 3px;
}

.checkmark:after {
  left: 10px;
  top: 5px;
  width: 10px;
  height: 16px;
  border: solid #5efc50;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.innertab_itembox.selected_item .checkmark:after {
  display: block;
}

label.block-check {
  display: block;
  margin-bottom: unset;
  font-weight: unset;
}

.innertab_itembox {
  position: relative;
  overflow: hidden;
}

.innertab_itembox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.innertab_itembox:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}

.innertab_itembox:hover:after {
  left: 100%;
}

/*=========================================NFT MARKETPLACE DEVELOPMENT=================================*/

.masthead-nftmarketplace-development {
  /*background: url(../images/inner-pages/nft_marketplace/banner-bg.png?v6) no-repeat;*/
  background: #fff;
  background-position: center center;
  height: 630px !important;
  margin-top: 73px;
}

.MetaBlock_nftmarketplace {
  padding: 50px 0px !important;
  background: #f4f5ff;
}

.masthead-nftmarketplace-development .header-content h1 {
  text-align: left;
  font-family: Raleway, sans-serif !important;
  font-weight: 800;
}

.masthead-nftmarketplace-development .header-content h1 {
  font-weight: 900;
  font-size: 56px !important;
  /* font-style: italic; */
}

.masthead-nftmarketplace-development .header-content h1 span {
  color: #00e5ff;
}

.masthead-nftmarketplace-development .heading-tagline {
  text-align: left;
  color: #b8d3ea;
  font-weight: 600;
  text-transform: capitalize;
  width: 80%;
}

a.expert-btn {
  font-weight: 700;
  /* margin-bottom: 0; */
  text-transform: uppercase;
  font-size: 16px;
  min-width: 235px;
  color: #fff;
  box-shadow: 0px 6px 16px -4px #f17841;
  line-height: 1.5;
  border-radius: 50px !important;
  border: 2px solid #fff;
}

.masthead-nftmarketplace-development a.expert-btn {
  background-color: #00e5ff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  min-width: 235px;
  color: #08146a;
  box-shadow: 0px 6px 16px -4px #0e1116;
  line-height: 1.5;
}

.onfmarkis_title {
  font-weight: 700;
  font-size: 37px;
  line-height: 45px;
}

#nftmarki_intro {
  position: relative;
}

#nftmarki_intro:before {
  position: absolute;
  content: "";
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  background-position: top;
  z-index: -1;
}

#nftmarki_what:before {
  /* background: url(../images/inner-pages/nft_marketplace/nftmarki_what.png); */
  position: absolute;
  content: "";
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  background-position: top;
  z-index: -1;
}

#nftmarki_intro:after {
  position: absolute;
  content: "";
  background-color: #fff;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.9;
  z-index: -1;
}

#nftmarki_what .MetaBlocknftmarki_sec_titlewrap {
  width: 80%;
}

#nftmarki_intro .onfmarkis_title {
  color: #0c1968;
}

#nftmarki_intro .MetaBlocknftmarki_sec_titlewrap p {
  color: #0e143c;
  line-height: 30px;
}

#nftmarki_tokendev {
  /* background: url(../images/inner-pages/nft_marketplace/nfttoken_developmentbg.jpg) no-repeat; */
  position: relative;
}

.nfttoken_lt_sec img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35%;
}

#nftmarki_tokendev .onfmarkis_title {
  color: #fff;
}

#nftmarki_tokendev .MetaBlocknftmarki_sec_titlewrap p {
  color: #fff;
  font-weight: 600;
}

#nftmarki_uft {
  /* background: url(../images/inner-pages/nft_marketplace/nftmarkbg.jpg) no-repeat; */
  position: relative;
}

#nftmarki_uft:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 29%,
    rgb(50 60 73 / 57%) 54%,
    rgb(22 25 32) 130%
  );
  top: 0;
  opacity: 0.7;
}

#nftmarki_uft .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
  color: #ec861d;
}

#nftmarki_uft .MetaBlocknftmarki_sec_titlewrap p {
  color: #c7d0d7;
}

#nftmarki_uft .nftmarket_box {
  background: #ffffff05;
  border: 1px solid #ffffff1a;
  border-radius: 0;
  padding: 18px;
}

.nftmarket_box .nftmarketb_head {
  color: #fff;
  font-size: 21px;
  font-weight: 500;
}

.nftmarket_box .nftmarketb_desc {
  color: #d1d1d1;
  font-size: 14px;
  margin-top: 14px;
}

#nftmarki_what {
  /* background: url(../images/inner-pages/nft_marketplace/whatisnft_mp_bg.png) no-repeat;*/
  background-position: left;
}

#nftmarki_what .onfmarkis_title {
  color: #0c1968;
  line-height: 58px;
}

#nftmarki_what .MetaBlocknftmarki_sec_titlewrap p {
  color: #0e143c;
  line-height: 30px;
}

#nftmarki_what {
  position: relative;
}

.nftwat_lt_sec img {
  position: absolute;
  top: 0;
  width: 38%;
  left: 0;
}

#nftmarket_placeft {
  /*background-image: linear-gradient(
323deg
, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 16.667%,rgba(46, 46, 46, 0.01) 16.667%, rgba(46, 46, 46, 0.01) 33.334%,rgba(226, 226, 226, 0.01) 33.334%, rgba(226, 226, 226, 0.01) 50.001000000000005%,rgba(159, 159, 159, 0.01) 50.001%, rgba(159, 159, 159, 0.01) 66.668%,rgba(149, 149, 149, 0.01) 66.668%, rgba(149, 149, 149, 0.01) 83.33500000000001%,rgba(43, 43, 43, 0.01) 83.335%, rgba(43, 43, 43, 0.01) 100.002%),linear-gradient(
346deg
, rgba(166, 166, 166, 0.03) 0%, rgba(166, 166, 166, 0.03) 25%,rgba(240, 240, 240, 0.03) 25%, rgba(240, 240, 240, 0.03) 50%,rgba(121, 121, 121, 0.03) 50%, rgba(121, 121, 121, 0.03) 75%,rgba(40, 40, 40, 0.03) 75%, rgba(40, 40, 40, 0.03) 100%),linear-gradient(
347deg
, rgba(209, 209, 209, 0.01) 0%, rgba(209, 209, 209, 0.01) 25%,rgba(22, 22, 22, 0.01) 25%, rgba(22, 22, 22, 0.01) 50%,rgba(125, 125, 125, 0.01) 50%, rgba(125, 125, 125, 0.01) 75%,rgba(205, 205, 205, 0.01) 75%, rgba(205, 205, 205, 0.01) 100%),linear-gradient(
84deg
, rgba(195, 195, 195, 0.01) 0%, rgba(195, 195, 195, 0.01) 14.286%,rgba(64, 64, 64, 0.01) 14.286%, rgba(64, 64, 64, 0.01) 28.572%,rgba(67, 67, 67, 0.01) 28.572%, rgba(67, 67, 67, 0.01) 42.858%,rgba(214, 214, 214, 0.01) 42.858%, rgba(214, 214, 214, 0.01) 57.144%,rgba(45, 45, 45, 0.01) 57.144%, rgba(45, 45, 45, 0.01) 71.42999999999999%,rgba(47, 47, 47, 0.01) 71.43%, rgba(47, 47, 47, 0.01) 85.71600000000001%,rgba(172, 172, 172, 0.01) 85.716%, rgba(172, 172, 172, 0.01) 100.002%),linear-gradient(
73deg
, rgba(111, 111, 111, 0.03) 0%, rgba(111, 111, 111, 0.03) 16.667%,rgba(202, 202, 202, 0.03) 16.667%, rgba(202, 202, 202, 0.03) 33.334%,rgba(57, 57, 57, 0.03) 33.334%, rgba(57, 57, 57, 0.03) 50.001000000000005%,rgba(197, 197, 197, 0.03) 50.001%, rgba(197, 197, 197, 0.03) 66.668%,rgba(97, 97, 97, 0.03) 66.668%, rgba(97, 97, 97, 0.03) 83.33500000000001%,rgba(56, 56, 56, 0.03) 83.335%, rgba(56, 56, 56, 0.03) 100.002%),linear-gradient(
132deg
, rgba(88, 88, 88, 0.03) 0%, rgba(88, 88, 88, 0.03) 20%,rgba(249, 249, 249, 0.03) 20%, rgba(249, 249, 249, 0.03) 40%,rgba(2, 2, 2, 0.03) 40%, rgba(2, 2, 2, 0.03) 60%,rgba(185, 185, 185, 0.03) 60%, rgba(185, 185, 185, 0.03) 80%,rgba(196, 196, 196, 0.03) 80%, rgba(196, 196, 196, 0.03) 100%),linear-gradient(
142deg
, rgba(160, 160, 160, 0.03) 0%, rgba(160, 160, 160, 0.03) 12.5%,rgba(204, 204, 204, 0.03) 12.5%, rgba(204, 204, 204, 0.03) 25%,rgba(108, 108, 108, 0.03) 25%, rgba(108, 108, 108, 0.03) 37.5%,rgba(191, 191, 191, 0.03) 37.5%, rgba(191, 191, 191, 0.03) 50%,rgba(231, 231, 231, 0.03) 50%, rgba(231, 231, 231, 0.03) 62.5%,rgba(70, 70, 70, 0.03) 62.5%, rgba(70, 70, 70, 0.03) 75%,rgba(166, 166, 166, 0.03) 75%, rgba(166, 166, 166, 0.03) 87.5%,rgba(199, 199, 199, 0.03) 87.5%, rgba(199, 199, 199, 0.03) 100%),linear-gradient(
238deg
, rgba(116, 116, 116, 0.02) 0%, rgba(116, 116, 116, 0.02) 20%,rgba(141, 141, 141, 0.02) 20%, rgba(141, 141, 141, 0.02) 40%,rgba(152, 152, 152, 0.02) 40%, rgba(152, 152, 152, 0.02) 60%,rgba(61, 61, 61, 0.02) 60%, rgba(61, 61, 61, 0.02) 80%,rgba(139, 139, 139, 0.02) 80%, rgba(139, 139, 139, 0.02) 100%),linear-gradient(
188deg
, rgba(227, 227, 227, 0.01) 0%, rgba(227, 227, 227, 0.01) 20%,rgba(105, 105, 105, 0.01) 20%, rgba(105, 105, 105, 0.01) 40%,rgba(72, 72, 72, 0.01) 40%, rgba(72, 72, 72, 0.01) 60%,rgba(33, 33, 33, 0.01) 60%, rgba(33, 33, 33, 0.01) 80%,rgba(57, 57, 57, 0.01) 80%, rgba(57, 57, 57, 0.01) 100%),linear-gradient(
90deg
, #212830,#010101);*/
  background-size: cover;
  background: #f2ecec;
}

#nftmarket_placeft .onfmarkis_title {
  text-align: center;
  color: #ec861d;
}

#nftmarket_placeft .MetaBlocknftmarki_sec_titlewrap p {
  color: #000;
  text-align: center;
}

#nftmarket_placeft .nftmarketb_head {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

#nftmarket_placeft .nftmarket_box img {
  text-align: center;
  border: 3px solid #ecb21d;
  padding: 15px;
  margin-bottom: 1.5rem;
}

#nftmarket_placeft .nftmarket_box {
  text-align: center;
}

#nftmarket_placeft .row:nth-child(2) {
  margin-bottom: 2.5rem;
}

#nftmarki_markettechstack {
  /*background: url(../images/inner-pages/nft_marketplace/whatisnft_mp_bg.png) no-repeat;*/
  background: #fff;
  background-position: center;
  background-size: cover;
}

#nftmarket_topreason {
  position: relative;
  background-image: linear-gradient(
      55deg,
      rgba(208, 208, 208, 0.03) 0%,
      rgba(208, 208, 208, 0.03) 20%,
      rgba(55, 55, 55, 0.03) 20%,
      rgba(55, 55, 55, 0.03) 40%,
      rgba(81, 81, 81, 0.03) 40%,
      rgba(81, 81, 81, 0.03) 60%,
      rgba(208, 208, 208, 0.03) 60%,
      rgba(208, 208, 208, 0.03) 80%,
      rgba(191, 191, 191, 0.03) 80%,
      rgba(191, 191, 191, 0.03) 100%
    ),
    linear-gradient(
      291deg,
      rgba(190, 190, 190, 0.02) 0%,
      rgba(190, 190, 190, 0.02) 14.286%,
      rgba(105, 105, 105, 0.02) 14.286%,
      rgba(105, 105, 105, 0.02) 28.572%,
      rgba(230, 230, 230, 0.02) 28.572%,
      rgba(230, 230, 230, 0.02) 42.858%,
      rgba(216, 216, 216, 0.02) 42.858%,
      rgba(216, 216, 216, 0.02) 57.144%,
      rgba(181, 181, 181, 0.02) 57.144%,
      rgba(181, 181, 181, 0.02) 71.42999999999999%,
      rgba(129, 129, 129, 0.02) 71.43%,
      rgba(129, 129, 129, 0.02) 85.71600000000001%,
      rgba(75, 75, 75, 0.02) 85.716%,
      rgba(75, 75, 75, 0.02) 100.002%
    ),
    linear-gradient(
      32deg,
      rgba(212, 212, 212, 0.03) 0%,
      rgba(212, 212, 212, 0.03) 12.5%,
      rgba(223, 223, 223, 0.03) 12.5%,
      rgba(223, 223, 223, 0.03) 25%,
      rgba(11, 11, 11, 0.03) 25%,
      rgba(11, 11, 11, 0.03) 37.5%,
      rgba(86, 86, 86, 0.03) 37.5%,
      rgba(86, 86, 86, 0.03) 50%,
      rgba(106, 106, 106, 0.03) 50%,
      rgba(106, 106, 106, 0.03) 62.5%,
      rgba(220, 220, 220, 0.03) 62.5%,
      rgba(220, 220, 220, 0.03) 75%,
      rgba(91, 91, 91, 0.03) 75%,
      rgba(91, 91, 91, 0.03) 87.5%,
      rgba(216, 216, 216, 0.03) 87.5%,
      rgba(216, 216, 216, 0.03) 100%
    ),
    linear-gradient(
      312deg,
      rgba(113, 113, 113, 0.01) 0%,
      rgba(113, 113, 113, 0.01) 14.286%,
      rgba(54, 54, 54, 0.01) 14.286%,
      rgba(54, 54, 54, 0.01) 28.572%,
      rgba(166, 166, 166, 0.01) 28.572%,
      rgba(166, 166, 166, 0.01) 42.858%,
      rgba(226, 226, 226, 0.01) 42.858%,
      rgba(226, 226, 226, 0.01) 57.144%,
      rgba(109, 109, 109, 0.01) 57.144%,
      rgba(109, 109, 109, 0.01) 71.42999999999999%,
      rgba(239, 239, 239, 0.01) 71.43%,
      rgba(239, 239, 239, 0.01) 85.71600000000001%,
      rgba(54, 54, 54, 0.01) 85.716%,
      rgba(54, 54, 54, 0.01) 100.002%
    ),
    linear-gradient(
      22deg,
      rgba(77, 77, 77, 0.03) 0%,
      rgba(77, 77, 77, 0.03) 20%,
      rgba(235, 235, 235, 0.03) 20%,
      rgba(235, 235, 235, 0.03) 40%,
      rgba(215, 215, 215, 0.03) 40%,
      rgba(215, 215, 215, 0.03) 60%,
      rgba(181, 181, 181, 0.03) 60%,
      rgba(181, 181, 181, 0.03) 80%,
      rgba(193, 193, 193, 0.03) 80%,
      rgba(193, 193, 193, 0.03) 100%
    ),
    linear-gradient(
      80deg,
      rgba(139, 139, 139, 0.02) 0%,
      rgba(139, 139, 139, 0.02) 14.286%,
      rgba(114, 114, 114, 0.02) 14.286%,
      rgba(114, 114, 114, 0.02) 28.572%,
      rgba(240, 240, 240, 0.02) 28.572%,
      rgba(240, 240, 240, 0.02) 42.858%,
      rgba(221, 221, 221, 0.02) 42.858%,
      rgba(221, 221, 221, 0.02) 57.144%,
      rgba(74, 74, 74, 0.02) 57.144%,
      rgba(74, 74, 74, 0.02) 71.42999999999999%,
      rgba(201, 201, 201, 0.02) 71.43%,
      rgba(201, 201, 201, 0.02) 85.71600000000001%,
      rgba(187, 187, 187, 0.02) 85.716%,
      rgba(187, 187, 187, 0.02) 100.002%
    ),
    linear-gradient(
      257deg,
      rgba(72, 72, 72, 0.03) 0%,
      rgba(72, 72, 72, 0.03) 16.667%,
      rgba(138, 138, 138, 0.03) 16.667%,
      rgba(138, 138, 138, 0.03) 33.334%,
      rgba(54, 54, 54, 0.03) 33.334%,
      rgba(54, 54, 54, 0.03) 50.001000000000005%,
      rgba(161, 161, 161, 0.03) 50.001%,
      rgba(161, 161, 161, 0.03) 66.668%,
      rgba(17, 17, 17, 0.03) 66.668%,
      rgba(17, 17, 17, 0.03) 83.33500000000001%,
      rgba(230, 230, 230, 0.03) 83.335%,
      rgba(230, 230, 230, 0.03) 100.002%
    ),
    linear-gradient(
      47deg,
      rgba(191, 191, 191, 0.01) 0%,
      rgba(191, 191, 191, 0.01) 16.667%,
      rgba(27, 27, 27, 0.01) 16.667%,
      rgba(27, 27, 27, 0.01) 33.334%,
      rgba(66, 66, 66, 0.01) 33.334%,
      rgba(66, 66, 66, 0.01) 50.001000000000005%,
      rgba(36, 36, 36, 0.01) 50.001%,
      rgba(36, 36, 36, 0.01) 66.668%,
      rgba(230, 230, 230, 0.01) 66.668%,
      rgba(230, 230, 230, 0.01) 83.33500000000001%,
      rgba(93, 93, 93, 0.01) 83.335%,
      rgba(93, 93, 93, 0.01) 100.002%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);
}

#nftmarki_markettechstack .onfmarkis_title {
  color: #000;
}

#nftmarki_markettechstack .MetaBlocknftmarki_sec_titlewrap p {
  color: #000;
}

#nftmarki_markettechstack .MetaBlocknftmarki_sec_titlewrap {
  text-align: center;
}

#nftmarket_topreason:before {
  position: absolute;
  content: "";
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  background-position: top;
  z-index: -1;
  opacity: 0.6;
}

#nftmarket_topreason:after {
  position: absolute;
  content: "";
  background: linear-gradient(
    0deg,
    rgb(255 255 255) 0%,
    rgb(255 255 255 / 67%) 100.51%
  );
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.9;
  z-index: -1;
}

#nftmarket_topreason .nftmarket_box .nftmarketb_head {
  color: #3d3d3d;
  font-size: 17px;
  font-weight: 600;
}

#nftmarket_topreason .nftmarket_box {
  padding: 15px 20px;
  border: 1px solid #acacac;
  background-color: #ffffff6e;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.nftmarket_box.nftmarket_art {
  min-height: 310px;
  background-size: cover;
  position: relative;
}

#nftmarket_artbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-01.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_fansportbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-02.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_musicbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-03.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_realesbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-04.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_vidclipbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-05.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_sportbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-06.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_gamesbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-07.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_accesbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-08.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_domainbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-09.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_infrabg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-10.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_digicollectbg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-11.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_photobg {
  /* background: url(../images/inner-pages/nft_marketplace/nft-12.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_swlicense {
  /* background: url(../images/inner-pages/nft_marketplace/nft-13.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_xchange {
  /* background: url(../images/inner-pages/nft_marketplace/nft-14.jpg?v2) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_digicont {
  /* background: url(../images/inner-pages/nft_marketplace/nft-15.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

#nftmarket_investcollat {
  /* background: url(../images/inner-pages/nft_marketplace/nft-16.jpg) no-repeat; */
  background-size: cover;
  border-radius: 20px;
}

.nftmarketbox_wrap {
  position: absolute;
  bottom: -9px;
  text-align: center;
  padding: 17px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.nftmarket_box.nftmarket_art:before {
  background: linear-gradient(180deg, rgba(5, 49, 126, 0) 33.54%, #05317e 100%);
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  bottom: 0;
  border-radius: 20px;
}

.nftmarket_box.nftmarket_art .nftmarketb_head {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.1;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#nftmarki_fields {
  background-color: #14171c;
}

#nftmarki_fields .MetaBlocknftmarki_sec_titlewrap {
  text-align: center;
}

#nftmarki_fields .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
  color: #0c1968;
}

#nftmarki_fields .MetaBlocknftmarki_sec_titlewrap p {
  color: #0e143c;
}

#nftmarki_fields .row:nth-child(2),
#nftmarki_fields .row:nth-child(3),
#nftmarki_fields .row:nth-child(4) {
  margin-bottom: 3rem;
}

#nftmarki_whyMetaBlock {
  /* background: url(../images/inner-pages/nft_marketplace/nftmarki_whyMetaBlockbg.png?v1) no-repeat; */
  background-size: cover;
  background-position: center;
}

#nftmarki_whyMetaBlock .MetaBlocknftmarki_sec_titlewrap {
  text-align: center;
}

#nftmarki_whyMetaBlock .nftwhyMetaBlock_box {
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px;
  border: 2px solid #ff8f1c;
  margin-bottom: 35px;
  min-height: 90px;
  background-color: #ffd6bb0f;
  border-radius: 8px;
}

/*.nftwhyMetaBlock_box img{
position: absolute;
    right: -26px;
    top: -22px;
    border: 3px solid #ff8f1c;
    border-radius: 50px;
    background-color: #181614;
}*/

#nftmarki_whyMetaBlock .row {
  margin-bottom: 1rem;
}

.nftmarki_whysprite {
  width: 51px;
  height: 51px;
  object-fit: none;
}

.nftwhyMetaBlock_box .nftwhyMetaBlock_head {
  color: #fff;
}

#nftmarki_whyMetaBlock .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
  color: #ec861d;
}

#nftmarki_whyMetaBlock .MetaBlocknftmarki_sec_titlewrap p {
  color: #fff;
}

#nftmarki_whyMetaBlock .MetaBlocknftmarki_sec_titlewrap p a {
  color: #fff;
}

a.osimarknftcta_btn {
  background-color: #ffcf33;
  text-align: center;
  padding: 10px 19px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.osimarknft_cta {
  text-align: center;
}

#nftmarki_marketplatform {
  position: relative;
}

#nftmarki_marketplatform .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #ec861d;
  opacity: 1;
  display: inline-block;
  margin: 3px;
}

#nftmarki_marketplatform .owl-dots {
  margin: 30px 2px 0px 2px;
}

#nftmarki_pl1 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-01.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl2 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-02.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl3 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-03.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl4 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-04.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl5 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-05.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl6 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-06.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl7 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-07.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl8 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-08.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl9 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-09.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl10 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-10.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl11 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-11.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl12 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-12.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl13 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-13.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl14 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-14.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl15 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-15.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl16 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-16.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl17 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-17.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl18 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-18.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl19 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-19.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl20 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-20.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl21 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-21.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl25 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-16.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl26 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-16.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl27 {
  /* background: url(../images/inner-pages/nft_marketplace/nftfields-16.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl28 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-01.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl29 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-02.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl30 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-03.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl31 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-04.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl32 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-05.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl33 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-06.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl34 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-07.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_pl35 {
  /* background: url(../images/inner-pages/nft-game-development/nftfields-07.jpg?v2) no-repeat; */
  background-size: cover;
}

#nftmarki_marketplatform .nftmarket_box {
  height: 310px;
  width: 290px;
}

#nftmarki_marketplatform .nftmarket_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  position: relative;
}

#nftmarki_fields:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  opacity: 0.8;
}

#nftmarki_fields {
  /*background: url(../images/inner-pages/nft_marketplace/nftmarkbg.jpg) no-repeat;*/
  position: relative;
  background-size: cover;
  background: #fff;
}

/*#nftmarki_marketplatform .nftmarket_box:before {
    background: linear-gradient( 
0deg
 , rgb(32 33 55) 14%, rgb(3 4 5 / 0%) 100.51%);
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    right: 0;
}*/

#nftmarki_marketplatform .nft_marketb_wrap {
  background-color: #14202dde;
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#nftmarki_marketplatform .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
  color: #1e1e1e;
}

#nftmarki_marketplatform .MetaBlocknftmarki_sec_titlewrap p {
  color: #333333;
}

#nftmarki_marketplatform .MetaBlocknftmarki_sec_titlewrap {
  text-align: left;
}

#nftmarki_marketplatform:before {
  position: absolute;
  content: "";
  /* background: url(../images/inner-pages/nft_marketplace/intro_bg.png) no-repeat; */
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  background-position: top;
  z-index: -1;
  opacity: 0.6;
}

#nftmarki_marketplatform:after {
  position: absolute;
  content: "";
  background: linear-gradient(
    0deg,
    rgb(255 255 255) 0%,
    rgb(255 255 255 / 67%) 100.51%
  );
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.9;
  z-index: -1;
}

#nftmarki_marketplatform .nft_marketb_wrap .nftmarki_bxplatform {
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

#nftmarki_marketplatform .owl-dots {
  display: flex;
  justify-content: center;
}

#nftmarki_marketplatform .owl-nav {
  position: absolute;
  top: -80px;
  right: 0;
  display: flex;
}

#nftmarki_marketplatform .owl-nav .owl-prev,
#nftmarki_marketplatform .owl-nav .owl-next {
  border: 3px solid #000;
  margin: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*#nftmarki_intro .row{
    display: flex;
    align-items: center;
}*/

.nftmarki_sprite1 {
  width: 90px;
  height: 90px;
  object-fit: none;
}

.nftmarki_sprite2 {
  width: 50px;
  height: 50px;
  object-fit: none;
  margin-right: 10px;
}

#nftmarket_topreason .MetaBlocknftmarki_sec_titlewrap {
  text-align: center;
}

#nftmarket_topreason .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 3rem;
}

#nftmarki_intro {
  padding: 0;
}

#nftmarki_whitelablesln {
  /* background : linear-gradient(0deg, rgba(22, 28, 76, 1) 0%, rgba(103, 45, 145, 1) 100%);*/
  background: #fff;
}

#nftmarki_whitelablesln .MetaBlocknftmarki_sec_titlewrap {
  color: #000;
}

#nftmarki_whitelablesln .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
  color: #000;
}

#nftmarki_developmentprocess {
  /* background: url(../images/inner-pages/nft_marketplace/nftmarkbg.jpg) no-repeat; */
  position: relative;
  background-size: cover;
}

#nftmarki_developmentprocess .onfmarkis_title {
  color: #fff;
}

#nftmarki_developmentprocess .MetaBlocknftmarki_sec_titlewrap p {
  color: #fff;
}

#nftmarki_developmentprocess .MetaBlocknftmarki_sec_titlewrap {
  text-align: center;
}

#nftmarki_developmentprocess .row {
  display: flex;
  justify-content: center;
}

#nftmarketplace_tab {
  display: flex;
  justify-content: center;
  border: 0;
}

.nft-market-tabselectul {
  background-color: #1d2023;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom: 1px solid #353535;
}

#nftmarketplace_tab.nav-tabs > li.active > a,
#nftmarketplace_tab.nav-tabs > li.active > a:focus,
#nftmarketplace_tab.nav-tabs > li.active > a:hover {
  background-color: #00000038;
  border: 0;
  border-bottom: 5px solid #ff9905;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

#nftmarketplace_tab.nav-tabs > li > a {
  font-weight: 500;
  font-size: 18px;
  border: 0;
  border-bottom: 5px solid #1d202300;
  color: #ffffff6b;
}

#nftmarketplace_tab.nav > li > a:focus,
#nftmarketplace_tab.nav > li > a:hover {
  text-decoration: none;
  background-color: #00000066;
}

.nft-market-tabcontul {
  background-color: #161616;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.tabpane_nfttabswrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.tabpane_nfttabswrap .tpnft_box {
  text-align: center;
  width: 100px;
  height: 116px;
  background-color: #ffffff08;
  margin: 0px 5px;
  border-radius: 4px;
  border: 1px solid #2d2d2d;
  padding: 10px 0px;
}

.tabpane_nfttabswrap .tpnft_head {
  color: #eaf4ff;
  font-size: 15px;
  margin: 5px 0px;
  font-weight: 500;
}

#nftmarketplace_tab.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 40px;
}

.nftmarkitech_sprite {
  width: 60px;
  height: 60px;
  object-fit: none;
}

@media (max-width: 767px) {
  .masthead-nftmarketplace-development .header-content h1 {
    font-weight: 900;
    font-size: 33px !important;
    text-align: center;
  }

  .masthead-nftmarketplace-development .heading-tagline {
    width: 100%;
  }

  .masthead-nftmarketplace-development {
    background-position: left;
    height: 489px !important;
  }

  .nftmarkibanner_cta {
    text-align: center;
  }

  #nftmarki_intro .onfmarkis_title {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
  }

  #nftmarki_intro .MetaBlocknftmarki_sec_titlewrap p {
    text-align: justify;
  }

  .MetaBlock_nftmarketplace {
    padding: 30px 0px;
  }

  .onfmarkis_title {
    text-align: center;
    font-size: 22px;
  }

  #nftmarki_tokendev .MetaBlocknftmarki_sec_titlewrap p {
    text-align: justify;
  }

  .nfttoken_lt_sec img {
    display: none;
  }

  #nftmarki_uft .MetaBlocknftmarki_sec_titlewrap p {
    text-align: center;
  }

  #nftmarki_uft .nftmarket_box {
    margin-bottom: 1rem;
  }

  .nftwat_lt_sec img {
    display: none;
  }

  #nftmarki_what .MetaBlocknftmarki_sec_titlewrap p {
    text-align: justify;
  }

  #nftmarket_placeft .nftmarket_box {
    text-align: center;
    margin-bottom: 1rem;
  }

  .nftmarket_box.nftmarket_art {
    margin-bottom: 2rem;
  }

  #nftmarki_marketplatform .owl-nav {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
  }

  #nftmarki_marketplatform .nftmarket_box {
    height: 310px;
    width: auto;
  }

  #nftmarki_marketplatform .owl-nav {
    margin-top: 1rem;
    justify-content: center;
  }

  #nftmarki_marketplatform .owl-dots {
    margin: 5px 2px 0px 2px;
  }

  #nftmarki_marketplatform .MetaBlocknftmarki_sec_titlewrap p {
    color: #333333;
    text-align: center;
  }

  #nftmarki_whyMetaBlock {
    /* background: url(../images/inner-pages/nft_marketplace/nftmarki_whyMetaBlockbg.png) no-repeat; */
    background-position: center;
    background-size: cover;
  }

  #nftmarket_topreason .MetaBlocknftmarki_sec_titlewrap .onfmarkis_title {
    font-weight: 700;
    font-size: 22px;
  }

  #nftmarki_marketplatform .owl-dots .owl-dot.active span {
    background-color: #000;
  }

  #nftmarki_what {
    background-size: cover;
  }

  .tabpane_nfttabswrap {
    overflow-x: scroll;
  }

  #nftmarketplace_tab {
    display: block;
  }

  #nftmarketplace_tab.nav-tabs > li {
    float: none;
  }

  #nftmarketplace_tab.nav > li > a {
    text-align: center;
  }

  .tabpane_nfttabswrap .tpnft_box {
    min-width: 90px;
    width: auto;
    min-height: 135px;
  }

  .tabpane_nfttabswrap {
    justify-content: left;
  }

  .nftwhyMetaBlock_box img {
    position: unset;
  }

  .nftwhyMetaBlock_box .nftwhyMetaBlock_head {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
  }

  #nftmarki_whyMetaBlock .nftwhyMetaBlock_box {
    min-height: auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 1440px) and (min-width: 1280px) {
  #nftmarki_whyMetaBlock {
    /*background: url(../images/inner-pages/nft_marketplace/nftmarki_whyMetaBlockbg.png) no-repeat;*/
    background-size: cover;
    background-position: center;
    background: #fff;
  }
}

/*==================================PANCAKESWAP CLONE SCRIPT===============================*/

.masthead-pancakesclo-development {
  /* background: url(../images/inner-pages/pancaksswap-clone/pancakeswap-bg.jpg) no-repeat; */
  background-position: right;
  height: 665px !important;
}

.masthead-pancakesclo-development .header-content h1 {
  font-weight: 900;
  font-size: 48px !important;
  text-align: left;
  font-family: Raleway, sans-serif !important;
  text-transform: uppercase;
}

.masthead-pancakesclo-development .heading-tagline {
  text-align: left;
  color: #b8d3ea;
  font-weight: 600;
  text-transform: capitalize;
}

.masthead-pancakesclo-development .header-content h1 span {
  color: #fea901;
}

.masthead-pancakesclo-development .heading-tagline {
  color: #fff2d9;
}

.masthead-pancakesclo-development .header-content p {
  color: #dbd7ce;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.masthead-pancakesclo-development .header-content {
  margin-top: 9rem;
}

.masthead-pancakesclo-development .header-content a.expert-btn {
  background: -webkit-linear-gradient(271deg, #ffd86b, #542b08);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  min-width: 235px;
  color: #fff;
  box-shadow: 0px 2px 16px -4px #e6ba3e;
  line-height: 1.5;
}

.MetaBlock_panckeswapclo {
  padding: 50px 0px;
}

.pancswap_title {
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 2rem;
  text-transform: capitalize;
  background: linear-gradient(94.84deg, #fea901 1.15%, #f7e3a5 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#pancswap_intro {
  /* background: url(../images/inner-pages/pancaksswap-clone/intro-bg.png) no-repeat; */
  background-size: contain;
  background-position: left;
}

#pancswap_intro .pancswap_title {
  background: linear-gradient(94.84deg, #fea901 1.15%, #f7e3a5 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
}

#pancswap_featuresnew .pancswap_bft_box .pancswapb_head {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(94.84deg, #fea901 1.15%, #f7e3a5 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#pancswap_features .row {
  display: flex;
  align-items: center;
}

#pancswap_featuresnew .pancswap_bft_box {
  text-align: center;
}

#pancswap_featuresnew .row {
  margin-bottom: 3rem;
}

#pancswap_featuresnew .row:nth-child(5) {
  margin-bottom: 0rem;
  display: flex;
  justify-content: center;
}

#pancswap_featuresnew {
  background-color: #000;
}

.panft_sprite {
  width: 70px;
  height: 70px;
  object-fit: none;
  margin-bottom: 1rem;
}

#pancswap_featuresnew .pancswap_title {
  text-align: center;
  background: linear-gradient(94.84deg, #fea901 1.15%, #f7e3a5 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MetaBlock_panckeswapclo .pancswapb_head {
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

#pancswap_bftofpc .pancswap_bft_box .pancswapb_head p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 6px;
  margin: 2px 4px;
}

#pancswap_bftofpc .pancswap_bft_box .pancswapb_head {
  display: flex;
  align-items: baseline;
}

#pancswap_bftofpc .pancswap_bft_box {
  display: flex;
  align-items: center;
}

#pancswap_bft {
  background: rgb(32, 32, 32);
  position: relative;
  background: radial-gradient(
    circle,
    rgba(32, 32, 32, 1) 8%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
}

#pancswap_bft:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  /* background: url(../images/inner-pages/pancaksswap-clone/springbg.png) no-repeat; */
  background-size: contain;
  background-position: center;
  opacity: 0.1;
}

.pcs_container_swap {
  padding: 0px 80px;
}

#pancswap_bft .pancswap_title {
  background: linear-gradient(94.84deg, #fea901 1.15%, #f7e3a5 101.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#pancswap_bft .pancswap_desc {
  color: #ddcfc1;
  width: 60%;
  margin: 19px auto;
  line-height: 1.5;
}

#pancswap_bft .pancswap_sec_titlewrap {
  text-align: center;
}

#pancswap_bft .row:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pancswap_bft_wrap ul {
  padding: 0;
}

.pancswap_bft_wrap ul li {
  list-style: none;
}

.pancswap_bftlt_wrap .pancswap_bft_box {
  text-align: left;
}

.pancswap_bftlt_wrap .pancswap_bft_box .pancswapb_head {
  text-align: right;
  color: #eab53f;
  margin-bottom: 0.6rem;
}

.pancswap_bftlt_wrap .pancswap_bft_box .pancswapb_desc {
  color: #cbcbcb;
  text-align: right;
}

.pancswap_bftrt_wrap .pancswap_bft_box .pancswapb_desc {
  color: #cbcbcb;
}

.pancswap_bftrt_wrap .pancswap_bft_box .pancswapb_head {
  text-align: left;
  color: #eab53f;
  margin-bottom: 0.6rem;
}

.pancswap_bftlt_wrap .pancswap_bft_box {
  background-color: #0006;
  border: 1px solid #474747;
  padding: 15px 22px;
  margin-bottom: 2rem;
}

.pancswap_bftrt_wrap .pancswap_bft_box {
  background-color: #0006;
  border: 1px solid #474747;
  padding: 15px 22px;
  margin-bottom: 2rem;
}

#pancswap_features {
  /*background: url(../images/inner-pages/pancaksswap-clone/pancswapfeatures_bg.jpg) no-repeat;*/
  background-size: cover;
}

#pancswap_features .pancswap_sec_titlewrap p {
  text-align: justify;
}

.pancswapb_ftsec {
  display: flex;
}

.pancswapb_ftsec ul {
  padding: 0;
  display: inline-block;
  padding-right: 10px;
}

.pancswapb_ftsec .pancswapb_desc {
  font-size: 14px;
  font-weight: 500;
}

.pancswapb_ftsec ul li {
  list-style: none;
}

#pancswap_features .pancswapb_head {
  font-size: 18px;
  font-weight: 700;
  color: #ee8400;
  text-align: left;
  margin-bottom: 0.5rem;
}

.pancswapb_ftsec .pancswap_bft_box {
  margin-bottom: 1rem;
}

#pancswap_wallet {
  /* background: url(../images/inner-pages/pancaksswap-clone/wallet-bg.jpg) no-repeat;*/
  background-size: cover;
  background-position: bottom;
}

#pancswap_wallet .pancswap_bft_box {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ffaf3412;
  border: 1px solid #c16f2f;
  border-radius: 5px;
}

#pancswap_wallet .pancswap_title {
  color: #fff;
  text-align: center;
}

#pancswap_wallet .pancswap_sec_titlewrap p {
  color: #ddcfc1;
  text-align: center;
}

#pancswap_wallet .pancakewallet_wrap {
  text-align: center;
}

#pancswap_rewards {
  /*background: url(../images/inner-pages/pancaksswap-clone/reward_multipliers.jpg) no-repeat;*/
  background-size: cover;
  background-position: bottom;
}

#pancswap_rewards .pancswap_bft_box {
  display: inline-block;
}

#pancswap_rewards .pancswap_title {
  color: #fff;
  text-align: center;
}

#pancswap_rewards .pancswap_bft_box .pancswapb_head {
  color: #ffd3ae;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}

#pancswap_rewards .pancswap_bft_box .pancswapb_head span {
  display: block;
  font-weight: 300;
}

#pancswap_rewards .pancswap_bft_box {
  display: inline-block;
  padding: 10px;
  text-align: left;
  background: linear-gradient(90deg, rgb(75 40 29) 0%, rgb(52 19 9) 100%);
  border-radius: 4px;
  border: 1px solid #cf8b00;
  margin-bottom: 1rem;
  min-width: 145px;
}

#pancswap_clonesoftware {
  /*background-image: radial-gradient(circle at 21% 44%, rgba(23, 23, 23,0.05) 0%, rgba(23, 23, 23,0.05) 50%,rgba(109, 109, 109,0.05) 50%, rgba(109, 109, 109,0.05) 100%),radial-gradient(circle at 21% 96%, rgba(92, 92, 92,0.05) 0%, rgba(92, 92, 92,0.05) 50%,rgba(199, 199, 199,0.05) 50%, rgba(199, 199, 199,0.05) 100%),radial-gradient(circle at 25% 37%, rgba(230, 230, 230,0.05) 0%, rgba(230, 230, 230,0.05) 50%,rgba(25, 25, 25,0.05) 50%, rgba(25, 25, 25,0.05) 100%),linear-gradient(
90deg
, rgb(77 26 0),rgb(223 124 0));*/
  padding: 10px 0px;
}

#pancswap_clonesoftware .row {
  display: flex;
  align-items: center;
}

#pancswap_clonesoftware .pancswap_title {
  color: #fff;
}

#pancswap_clonesoftware .pancswap_sec_titlewrap p {
  color: #fff;
  text-align: justify;
}

#pancswap_bftofpc {
  /*background-image: linear-gradient(135deg, transparent 0%, transparent 6%,
    rgba(71, 71, 71,0.04) 6%, rgba(71, 71, 71,0.04) 22%,transparent 22%, 
    transparent 100%),linear-gradient(45deg, transparent 0%, transparent 20%,
    rgba(71, 71, 71,0.04) 20%, rgba(71, 71, 71,0.04) 47%,transparent 47%, transparent 100%),
    linear-gradient(135deg, transparent 0%, transparent 24%,rgba(71, 71, 71,0.04) 24%, 
    rgba(71, 71, 71,0.04) 62%,transparent 62%, transparent 100%),linear-gradient(45deg, 
    transparent 0%, transparent 73%,rgba(71, 71, 71,0.04) 73%, rgba(71, 71, 71,0.04) 75%,
    transparent 75%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));*/
}

#pancswap_bftofpc .pancswap_sec_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#pancswap_bftofpc .pancswapb_head {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-align: left;
}

#pancswap_bftofpc .pancswap_title {
  color: #fff;
  font-size: 30px;
}

#pancswap_bftofpc .pancswap_bft_box {
}

#pancswap_currency {
  /*background: url(../images/inner-pages/pancaksswap-clone/currencybg.png) no-repeat;*/
  background-position: center;
  background-size: cover;
}

#pancswap_currency .pancswap_sec_titlewrap {
  text-align: center;
}

#pancswap_currency .pancswapb_head {
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

#pancswap_whydex {
  /* background: url(../images/inner-pages/pancaksswap-clone/whitelabel.jpg) no-repeat;*/
  background-position: center;
  background-size: cover;
}

#pancswap_whydex .pancswap_title {
  color: #fff;
  text-align: center;
}

#pancswap_whydex .pancswap_sec_titlewrap p {
  color: #fff;
  text-align: left;
}

#pancswap_whydex .row {
  justify-content: center;
  display: flex;
}

#pancswap_dexbin {
  /*  background: url(../images/inner-pages/pancaksswap-clone/pancakeswap-binnace.jpg) no-repeat;*/
  background-position: right;
  background-size: cover;
}

#pancswap_dexbin .pancswap_title {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 2rem;
  color: #fff;
  text-align: center;
}

#pancswap_dexbin .pancswap_sec_titlewrap p {
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.pancswap_sec_titlewrap p,
.pancswap_desc {
  font-size: 15px;
  margin-bottom: 8px;
  text-align: left;
}

.pancakebox_wrap {
  text-align: center;
}

.pancakeswap_coins {
  width: 100px;
  height: 100px;
  object-fit: none;
}

#pancswap_currency .pancswap_bft_box {
  text-align: center;
}

.pancswap_dexbin_btn {
  display: flex;
  align-items: center;
}

.pancswap_dexbin_btn.watsp {
  background-color: #20a652;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  margin-right: 10px;
}

.pancswap_dexbin_btn.tgrm {
  background-color: #0083db;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
}

.pancakeswap_socialccta .pancswap_dexbin_btn i {
  font-size: 27px;
  margin-right: 5px;
}

.pancakeswap_socialccta .pancswap_dexbin_btn img {
  margin-right: 5px;
}

.pancakeswap_socialccta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pancakeswap_socialccta img {
  width: 25px;
  background-color: #fff;
  border-radius: 50px;
  padding: 4px;
}

@media (max-width: 767px) {
  .masthead-pancakesclo-development {
    /* background: url(../images/inner-pages/pancaksswap-clone/pancakeswap-bg.jpg) no-repeat; */
    background-position: left;
    height: 665px !important;
    background-size: cover !important;
  }

  .masthead-pancakesclo-development .header-content h1 {
    font-weight: 900;
    font-size: 40px !important;
    text-align: center;
    font-family: Raleway, sans-serif !important;
    text-transform: uppercase;
    line-height: 1.1;
  }

  .masthead-pancakesclo-development .header-content p {
    text-align: center;
  }

  .MetaBlock_panckeswapclo {
    padding: 20px 0px;
  }

  .pancswap_title {
    font-size: 22px;
    text-align: center;
    line-height: 1.3;
    text-transform: capitalize;
  }

  #pancswap_bftofpc .pancswap_title {
    color: #fff;
    font-size: 24px;
  }

  #pancswap_bftofpc .pancswap_sec_titlewrap {
    text-align: center !important;
  }

  #pancswap_bftofpc .pancswap_sec_titlewrap p {
    text-align: center !important;
  }

  .pancswap_sec_titlewrap p,
  .pancswap_desc {
    font-size: 18px;
    text-align: justify;
  }

  #pancswap_features .pancakeswap_socialccta {
    display: block;
  }

  #pancswap_features .pancswap_dexbin_btn.watsp {
    justify-content: center;
  }

  #pancswap_features .pancswap_dexbin_btn.tgrm {
    justify-content: center;
  }

  #pancswap_intro {
    background: unset;
  }

  #pancswap_bft .pancswap_desc {
    color: #ddcfc1;
    width: 100%;
  }

  #pancswap_bft .row:nth-child(2) {
    display: block;
  }

  .pancswap_bftlt_wrap .pancswap_bft_box .pancswapb_head {
    text-align: left;
  }

  .pancswap_bftlt_wrap .pancswap_bft_box .pancswapb_desc {
    text-align: left;
  }

  #pancswap_features {
    background: unset;
  }

  .pancswapb_ftsec {
    display: block;
  }

  #pancswap_wallet .pancswap_bft_box {
    display: block;
    padding: 10px 15px;
    background-color: #ffaa343d;
    border: 1px solid #b8682afa;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  #pancswap_rewards .pancakebox_wrap {
    text-align: center;
  }

  #pancswap_whydex {
    /* background: url(../images/inner-pages/pancaksswap-clone/whitelabel.jpg) no-repeat; */
    background-position: center;
    background-size: cover;
  }

  #pancswap_dexbin {
    background-position: left;
    background-size: cover;
  }

  #pancswap_dexbin .pancswap_title {
    font-weight: 700;
    font-size: 20px;
  }

  #pancswap_dexbin .pancakeswap_socialccta {
    display: block;
  }

  #pancswap_dexbin .pancswap_dexbin_btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pancswap_dexbin_btn.watsp {
    margin-right: 0px;
    margin-bottom: 1rem;
  }

  #pancswap_featuresnew .row:nth-child(5) {
    display: block;
  }
}

.cxscommon_ctrl p {
  margin-bottom: 0;
}

#cxsbannner {
  /* background: url(../images/inner-pages/cryptocurrency-exchange-script/banner.png) no-repeat; */
  background-size: contain;
  background-position: top;
}

#cxsbannner .header-content h1 {
  text-align: left;
  font-size: 39px !important;
  font-weight: 700;
}

header#cxsbannner {
  /*height: 580px !important;*/
}

#cxsbannner .heading-tagline {
  text-align: left;
  font-size: 17px;
}

#cxsbannner a.expert-btn {
  background-color: #1cbec9;
  border: 0;
  border-radius: 0 !important;
  color: #fff;
  box-shadow: unset;
  font-size: 16px;
  min-width: 200px;
}

.cxscommon_ctrl {
  padding: 50px 0px;
}

#cxs_intro {
  /* background: url(../images/inner-pages/cryptocurrency-exchange-script/intro-bg.jpg); */
  text-align: center;
}

.MetaBlockcxs_sec_titlewrap .oncxs_title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 3rem;
  margin-top: 0rem;
  text-transform: capitalize;
}

#cxs_whatis {
  /*background-color: #1b334d;*/
}

#cxs_whatis .MetaBlockcxs_sec_titlewrap .oncxs_title {
  color: #282828;
}

#cxs_whatis .MetaBlockcxs_sec_titlewrap p {
  color: black;
  margin-bottom: 0;
}

#cxs_whitelabel {
  background-size: cover;
  /* background: url(../images/inner-pages/cryptocurrency-exchange-script/cxs_whitelabel_bg.png) no-repeat; */
}

#cxs_whitelabel .MetaBlockcxs_sec_titlewrap {
  text-align: center;
}

#cxs_whitelabel .MetaBlockcxs_sec_titlewrap .oncxs_title {
  color: #fff;
}

#cxs_whitelabel .MetaBlockcxs_sec_titlewrap p {
  color: #fff;
}

#cxs_fts {
  background-color: #1b334d;
}

#cxs_fts .MetaBlockcxs_sec_titlewrap .oncxs_title {
  color: #fff;
}

#cxs_fts .MetaBlockcxs_sec_titlewrap p {
  color: #fff;
}

#MetaBlockcxstabs.nav-tabs > li.active > a {
  border-radius: 50px;
  color: #0d233b;
  font-weight: 600;
  font-size: 20px;
  min-width: 146px;
  text-align: center;
  border: 0;
}

#MetaBlockcxstabs.nav-tabs > li > a {
  border-radius: 50px;
  color: #95a5b6;
  font-weight: 600;
  font-size: 20px;
  min-width: 146px;
  text-align: center;
  border: 0;
}

#MetaBlockcxstabs.nav-tabs > li > a:hover {
  background-color: #081d35;
  border: 0;
}

#MetaBlockcxstabs.nav-tabs > li.active > a:hover {
  background-color: #fff;
}

#MetaBlockcxstabs.nav-tabs {
  border-bottom: 0px solid #ddd;
  width: fit-content;
  margin: 0 auto;
  margin-top: 2rem;
}

#MetaBlockcxstabs {
  background-color: #0f2135;
  padding: 7px;
  border-radius: 50px;
}

#cxs_fts .MetaBlockcxs_sec_titlewrap {
  text-align: center;
}

#cxs_fts .ocsxt_box {
  display: inline-block;
  padding: 15px;
  background-color: #29a0cf;
  min-width: 200px;
  border-radius: 2px;
}

#cxs_fts .ocsx_head {
  color: #fff;
  font-weight: 600;
  font-size: 17px;
}

#cxs_fts .tab-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

#cxs_benefits .ocsxt_box .ocsx_head {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-left: 10px;
}

#cxs_benefits {
  background-color: #f6faff;
}

#cxs_benefits .ocsxt_box {
  border: 1px solid #cddff2;
  background-color: #fff;
  padding: 15px;
  /*min-height: 190px;*/
  margin-bottom: 3rem;
  box-shadow: 0 8px 9px -10px #1b334d;
  display: flex;
  align-items: center;
}

#cxs_benefits .ocsx_desc {
  color: #5e6f83;
}

#cxs_benefits .MetaBlockcxs_sec_titlewrap .oncxs_title {
  color: #1b334d;
  text-align: center;
  margin-bottom: 2rem;
}

#cxs_cryptlaunch {
  background-color: #1b334d;
}

#cxs_cryptlaunch .MetaBlockcxs_sec_titlewrap .oncxs_title {
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;
}

#cxs_cryptlaunch .ocsx_head {
  color: #7fdbff;
  font-weight: 600;
  min-height: 45px;
  text-align: center;
}

#cxs_cryptlaunch .ocsx_desc {
  color: #d7ecf4;
  text-align: center;
  font-size: 15px;
}

#cxs_cryptlaunch .ocsxt_box {
  border: 1px solid #3e5268;
  padding: 15px;
  min-height: 260px;
  background-color: #00000047;
  text-align: center;
}

#cxs_whycrypt {
  background-color: #1782ac;
}

#cxs_whycrypt .MetaBlockcxs_sec_titlewrap .oncxs_title {
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
}

#cxs_whycrypt .ocsxt_box .ocsx_head {
  color: #dff6ff;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

#cxs_clonescripts {
  background-color: #1b334d;
}

#cxs_clonescripts .MetaBlockcxs_sec_titlewrap .oncxs_title {
  text-align: left;
  color: #c7efff;
  font-size: 25px;
}

#cxs_clonescripts .ocsxt_box {
  background: linear-gradient(
    179deg,
    rgba(255, 255, 255, 1) 44%,
    rgb(217 217 217) 100%
  );
  padding: 20px 15px;
  text-align: center;
  border-radius: 4px;
  min-height: 235px;
}

#cxs_clonescripts .ocsxt_box .ocsx_head {
  color: #08233f;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1rem;
}

#cxs_clonescripts .ocsxt_box .ocsx_desc {
  color: #373b3f;
  font-size: 16px;
}

#cxs_clone_carousel .owl-nav {
  position: absolute;
  top: -73px;
  right: 0;
}

#cxs_clone_carousel .owl-nav .owl-prev,
#cxs_clone_carousel .owl-nav .owl-next {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  background-color: #07182b;
  border: 3px solid #658ebf;
}

#cxs_clone_carousel .owl-nav .owl-prev i {
  font-size: 24px;
  color: #7eade6;
  padding-right: 3px;
}

#cxs_clone_carousel .owl-nav .owl-next i {
  font-size: 24px;
  color: #7eade6;
  padding-left: 4px;
}

#cxs_clone_carousel .owl-dots {
  padding-top: 3rem;
}

.cxsclone_wrap {
  padding: 0px 30px;
}

.sprite1 {
  width: 50px;
  height: 50px;
  object-fit: none;
  margin-bottom: 1rem;
}

.sprite2 {
  width: 60px;
  height: 60px;
  object-fit: none;
  margin-bottom: 1rem;
}

#cxs_whycrypt .ocsxt_box {
  text-align: center;
}

.sprite3 {
  width: 60px !important;
  height: 60px !important;
  object-fit: none;
  margin-bottom: 1rem;
}

.btn.cbwatsapp {
  background-color: #1a9a49;
  color: #fff;
  font-weight: 700;
  margin-right: 10px;
}

.btn.cbtelegram {
  background-color: #0097cf;
  color: #fff;
  font-weight: 700;
}

#cxs_intro .cxs_btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.cxs_btn .cxsbtn_box i {
  font-size: 25px;
}

.cxs_btn .cxsbtn_box a.btn {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  #cxs_intro .cxs_btn {
    display: block;
  }

  .btn.cbwatsapp {
    margin-bottom: 10px;
  }

  .MetaBlockcxs_sec_titlewrap .oncxs_title {
    font-size: 21px;
    font-weight: 600;
    font-weight: 700;
    line-height: 1.5;
  }

  #cxs_whitelabel {
    background-size: cover;
    background: unset;
    background-color: #1782ac;
    background-position: bottom;
  }

  #MetaBlockcxstabs.nav-tabs > li.active > a {
    font-size: 16px;
    min-width: auto;
  }

  #MetaBlockcxstabs.nav-tabs > li > a {
    font-size: 15px;
    min-width: auto;
    padding: 4px;
  }

  #MetaBlockcxstabs {
    padding: 5px;
  }

  .ocsx_tab_wrap {
    text-align: center;
  }

  #cxs_fts .ocsxt_box {
    display: inline-block;
    padding: 7px;
    background-color: #29a0cf;
    min-width: 330px;
    border-radius: 2px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cxsclone_wrap {
    padding: 0px;
  }

  #cxs_cryptlaunch .ocsxt_box {
    min-height: auto;
    margin-bottom: 1.3rem;
  }

  #cxs_whycrypt .ocsxt_box {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  #cxs_clone_carousel .owl-nav {
    position: unset;
    top: -73px;
    right: 0;
  }

  #cxs_clone_carousel .owl-dots {
    display: none;
  }

  #cxs_clonescripts .MetaBlockcxs_sec_titlewrap .oncxs_title {
    text-align: center;
  }

  #cxsbannner .header-content h1 {
    text-align: center;
    font-size: 30px !important;
    font-weight: 700;
  }

  .header-content {
    text-align: center;
  }

  #cxsbannner {
    background-position: left;
  }
}

@media (max-width: 1280px) and (max-width: 1440px) {
  #cxs_clonescripts .ocsxt_box {
    background: linear-gradient(
      179deg,
      rgba(255, 255, 255, 1) 44%,
      rgb(217 217 217) 100%
    );
    padding: 20px 15px;
    text-align: center;
    border-radius: 4px;
    min-height: 300px;
  }
}

header.masthead-sportsbet .header-content {
  text-align: left;
  padding-top: 90px;
}

header.masthead-sportsbet .header-content h1 {
  font-size: 45px !important;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 20px !important;
  color: #fff;
}

header.masthead-sportsbet .header-content .heading-tagline {
  text-align: left;
  font-size: 18px;
}

header.masthead-sportsbet .header-content .expert-btn {
  background: #ff5aae;
  color: #ffffff;
  border: 1px solid #ff76bc;
}

header.masthead-sportsbet .sportbet_hero_img img {
  position: absolute;
  max-width: none;
  right: 0;
  left: 0;
}

header.masthead-sportsbet {
  overflow: hidden;
}

.MetaBlock_sportsbet .MetaBlocksportbet_box .onfsportsbe_head {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.MetaBlock_sportsbet {
  padding: 30px 0px 30px 0px;
}

.MetaBlock_sportsbet .onfsportsbet_title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.MetaBlock_sportsbet .MetaBlocksportsbet_titlewrap p,
.MetaBlock_sportsbet .MetaBlocksportbet_box p {
  margin-bottom: 0;
}

#sportsbet_ftsec {
  background-image: radial-gradient(
      circle at 85% 1%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 96%,
      transparent 96%,
      transparent 100%
    ),
    radial-gradient(
      circle at 14% 15%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 1%,
      transparent 1%,
      transparent 100%
    ),
    radial-gradient(
      circle at 60% 90%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 20%,
      transparent 20%,
      transparent 100%
    ),
    radial-gradient(
      circle at 79% 7%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 78%,
      transparent 78%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 65%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 52%,
      transparent 52%,
      transparent 100%
    ),
    linear-gradient(176deg, rgb(37, 56, 222), rgb(96, 189, 244));
}

#sportsbet_ftsec .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#sportsbet_ftsec .MetaBlocksportsbet_titlewrap .onfsportsbet_title {
  color: #fff;
}

#sportsbet_ftsec .MetaBlocksportbet_box {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 3rem;
  border-radius: 5px;
  box-shadow: 0 6px 6px -2px #161b406b;
  min-height: 31rem;
}

#sportsbet_ftsec .MetaBlocksportbet_box .onfsportsbe_head {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

#sportsbet_btsec {
  background-image: radial-gradient(
      circle at center center,
      rgba(217, 217, 217, 0.05) 0%,
      rgba(217, 217, 217, 0.05) 15%,
      rgba(197, 197, 197, 0.05) 15%,
      rgba(197, 197, 197, 0.05) 34%,
      rgba(178, 178, 178, 0.05) 34%,
      rgba(178, 178, 178, 0.05) 51%,
      rgba(237, 237, 237, 0.05) 51%,
      rgba(237, 237, 237, 0.05) 75%,
      rgba(138, 138, 138, 0.05) 75%,
      rgba(138, 138, 138, 0.05) 89%,
      rgba(158, 158, 158, 0.05) 89%,
      rgba(158, 158, 158, 0.05) 100%
    ),
    radial-gradient(
      circle at center center,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 6%,
      rgb(255, 255, 255) 6%,
      rgb(255, 255, 255) 12%,
      rgb(255, 255, 255) 12%,
      rgb(255, 255, 255) 31%,
      rgb(255, 255, 255) 31%,
      rgb(255, 255, 255) 92%,
      rgb(255, 255, 255) 92%,
      rgb(255, 255, 255) 97%,
      rgb(255, 255, 255) 97%,
      rgb(255, 255, 255) 100%
    );
  background-size: 42px 42px;
}

#sportsbet_btsec .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#sportsbet_btsec .MetaBlocksportbet_box {
  background-color: #2b3896;
  padding: 20px;
  margin-bottom: 3rem;
  border-radius: 5px;
  box-shadow: 0 6px 6px -2px #161b406b;
  min-height: 24rem;
  text-align: center;
}

#sportsbet_btsec .MetaBlocksportbet_box .onfsportsbe_head {
  color: #fff;
}

#sportsbet_btsec .MetaBlocksportbet_box p {
  color: #e2e5f8;
}

#sportsbet_hwitwrks {
  background-image: radial-gradient(
      circle at 40% 91%,
      rgba(251, 251, 251, 0.04) 0%,
      rgba(251, 251, 251, 0.04) 50%,
      rgba(229, 229, 229, 0.04) 50%,
      rgba(229, 229, 229, 0.04) 100%
    ),
    radial-gradient(
      circle at 66% 97%,
      rgba(36, 36, 36, 0.04) 0%,
      rgba(36, 36, 36, 0.04) 50%,
      rgba(46, 46, 46, 0.04) 50%,
      rgba(46, 46, 46, 0.04) 100%
    ),
    radial-gradient(
      circle at 86% 7%,
      rgba(40, 40, 40, 0.04) 0%,
      rgba(40, 40, 40, 0.04) 50%,
      rgba(200, 200, 200, 0.04) 50%,
      rgba(200, 200, 200, 0.04) 100%
    ),
    radial-gradient(
      circle at 15% 16%,
      rgba(99, 99, 99, 0.04) 0%,
      rgba(99, 99, 99, 0.04) 50%,
      rgba(45, 45, 45, 0.04) 50%,
      rgba(45, 45, 45, 0.04) 100%
    ),
    radial-gradient(
      circle at 75% 99%,
      rgba(243, 243, 243, 0.04) 0%,
      rgba(243, 243, 243, 0.04) 50%,
      rgba(37, 37, 37, 0.04) 50%,
      rgba(37, 37, 37, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(34, 222, 237), rgb(135, 89, 215));
  padding-bottom: 70px;
}

#sportsbet_hwitwrks .MetaBlocksportsbet_titlewrap .onfsportsbet_title,
#sportsbet_hwitwrks .MetaBlocksportsbet_titlewrap .onfsportsbet_desc {
  color: #fff;
}

#sportsbet_hwitwrks .MetaBlocksportbet_box {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 3rem;
  border-radius: 5px;
  box-shadow: 0 6px 6px -2px #161b406b;
  min-height: 28rem;
  position: relative;
}

#sportsbet_hwitwrks .MetaBlocksportbet_box:before {
  position: absolute;
  width: 3px;
  height: 41px;
  content: "";
  background-color: #fff;
  bottom: -29px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

#sportsbet_hwitwrks .MetaBlocksportbet_box:after {
  position: absolute;
  width: 30px;
  height: 30px;
  content: "";
  background-color: #ff3b84;
  bottom: -44px;
  right: 0;
  left: 0;
  margin: 0 auto;
  border-radius: 50px;
  border: 4px solid #fff;
}

#sportsbet_hwitwrks .MetaBlocksportbet_box span {
  background-color: #2d39cc;
  width: 25px;
  height: 25px;
  padding: 0px;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 50px;
}

#sportsbet_hwitwrks .MetaBlocksportsbet_titlewrap {
  margin-bottom: 3rem;
}

#sportsbet_hwitwrks .row:nth-child(2) {
  position: relative;
}

#sportsbet_hwitwrks .row:nth-child(2):before {
  position: absolute;
  width: 100%;
  height: 3px;
  content: "";
  bottom: 0;
  border: none;
  border-top: 4px dotted #fff;
  color: #fff;
}

#sportsbet_typesofsportsbit .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

.ocean {
  background-image: linear-gradient(
      135deg,
      transparent 0%,
      transparent 6%,
      rgba(71, 71, 71, 0.04) 6%,
      rgba(71, 71, 71, 0.04) 22%,
      transparent 22%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 20%,
      rgba(71, 71, 71, 0.04) 20%,
      rgba(71, 71, 71, 0.04) 47%,
      transparent 47%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 24%,
      rgba(71, 71, 71, 0.04) 24%,
      rgba(71, 71, 71, 0.04) 62%,
      transparent 62%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 73%,
      rgba(71, 71, 71, 0.04) 73%,
      rgba(71, 71, 71, 0.04) 75%,
      transparent 75%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

#sportsbet_typesofsportsbit .MetaBlocksportbet_box {
  background-color: #663784;
  padding: 20px;
  margin-bottom: 3rem;
  border-radius: 5px;
  box-shadow: 0 6px 6px -2px #161b406b;
  min-height: 33rem;
  position: relative;
  text-align: center;
}

#sportsbet_typesofsportsbit .MetaBlocksportbet_box .onfsportsbe_head {
  color: #fff;
}

#sportsbet_typesofsportsbit .MetaBlocksportbet_box p {
  color: #e2e5f8;
}

#sportsbet_appdev {
  overflow: hidden;
  position: relative;
  background: rgb(17, 22, 32);
  background: linear-gradient(
    180deg,
    rgb(72 125 232 / 81%) 0%,
    rgb(255 76 167 / 62%) 100%
  );
}

#sportsbet_appdev:before {
  position: absolute;
  width: 100%;
  content: "";
  height: 100%;
  /* background: url(../images/inner-pages/sports-betting/sports-bg.jpg) no-repeat; */
  background-size: cover;
  top: 0;
  filter: blur(2px);
  z-index: -1;
  background-position: center;
}

#sportsbet_appdev .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game01 {
  /* background: url(../images/inner-pages/sports-betting/games-01.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game02 {
  /* background: url(../images/inner-pages/sports-betting/games-02.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game03 {
  /* background: url(../images/inner-pages/sports-betting/games-03.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game04 {
  /* background: url(../images/inner-pages/sports-betting/games-04.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game05 {
  /* background: url(../images/inner-pages/sports-betting/games-05.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game06 {
  /* background: url(../images/inner-pages/sports-betting/games-06.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game07 {
  /* background: url(../images/inner-pages/sports-betting/games-07.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game08 {
  /* background: url(../images/inner-pages/sports-betting/games-08.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game09 {
  /* background: url(../images/inner-pages/sports-betting/games-09.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game10 {
  /* background: url(../images/inner-pages/sports-betting/games-10.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game11 {
  /* background: url(../images/inner-pages/sports-betting/games-11.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game12 {
  /* background: url(../images/inner-pages/sports-betting/games-12.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportbet_box#sportsbet_game13 {
  /* background: url(../images/inner-pages/sports-betting/games-13.jpg) no-repeat; */
}

#sportsbet_appdev .MetaBlocksportsbet_titlewrap .onfsportsbet_title,
#sportsbet_appdev .MetaBlocksportsbet_titlewrap .onfsportsbet_desc {
  color: #fff;
}

#sportsbet_appdev .MetaBlocksportbet_box {
  background-size: cover !important;
  padding: 20px;
  text-align: right;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
  z-index: 0;
  box-shadow: 0 5px 7px -2px #ff83da;
}

#sportsbet_appdev .MetaBlocksportbet_box:before {
  background: linear-gradient(
    270deg,
    rgb(17 22 32 / 81%) 0%,
    rgb(27 30 44 / 26%) 100%
  );
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 4px;
  z-index: -1;
}

#sportsbet_appdev.MetaBlock_sportsbet .MetaBlocksportbet_box .onfsportsbe_head {
  margin: 0;
  color: #fff;
}

#sportsbet_nxtlevel .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#sportsbet_nxtlevel .MetaBlocksportbet_box {
  background-color: #2b3896;
  padding: 20px;
  margin-bottom: 3rem;
  border-radius: 5px;
  box-shadow: 0 6px 6px -2px #161b406b;
  min-height: 24rem;
  text-align: center;
}

#sportsbet_nxtlevel .MetaBlocksportbet_box .onfsportsbe_head {
  color: #fff;
}

#sportsbet_nxtlevel .MetaBlocksportbet_box .onfsportsbe_dt {
  color: #e2e5f8;
}

#sportsbet_revenue_model {
  background-image: linear-gradient(
      45deg,
      transparent 0%,
      transparent 55%,
      rgba(64, 64, 64, 0.04) 55%,
      rgba(64, 64, 64, 0.04) 76%,
      transparent 76%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 14%,
      rgba(64, 64, 64, 0.04) 14%,
      rgba(64, 64, 64, 0.04) 41%,
      transparent 41%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 2%,
      rgba(64, 64, 64, 0.04) 2%,
      rgba(64, 64, 64, 0.04) 18%,
      transparent 18%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 61%,
      rgba(64, 64, 64, 0.04) 61%,
      rgba(64, 64, 64, 0.04) 71%,
      transparent 71%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

#sportsbet_revenue_model .MetaBlocksportbet_box {
  text-align: center;
}

#sportsbet_revenue_model .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#sportsbet_blockchainplatform {
  /* background: url(../images/inner-pages/sports-betting/blockchain-bg.png) no-repeat; */
  background-size: cover;
  position: relative;
}

#sportsbet_blockchainplatform:before {
  background-color: #071d3cc7;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
}

#sportsbet_blockchainplatform .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 3rem;
}

#sportsbet_blockchainplatform .MetaBlocksportsbet_titlewrap .onfsportsbet_title,
#sportsbet_blockchainplatform .MetaBlocksportsbet_titlewrap .onfsportsbet_desc {
  color: #fff;
}

#sportsbet_blockchainplatform .MetaBlocksportbet_box {
  background-color: #0314249e;
  padding: 20px;
  margin-bottom: 3rem;
  border-radius: 5px;
  box-shadow: 0 6px 6px -2px #161b406b;
  text-align: center;
  border: 1px solid #8fc2d25c;
  min-height: 325px;
}

#sportsbet_blockchainplatform .MetaBlocksportbet_box .onfsportsbe_head {
  color: #fff;
}

#sportsbet_blockchainplatform .MetaBlocksportbet_box .onfsportsbe_dt {
  color: #e2e5f8;
}

#sportsbet_softdevelopment {
  background-image: repeating-linear-gradient(
      90deg,
      hsla(298, 16%, 68%, 0.06) 0px,
      hsla(298, 16%, 68%, 0.06) 1px,
      transparent 1px,
      transparent 21px,
      hsla(298, 16%, 68%, 0.06) 21px,
      hsla(298, 16%, 68%, 0.06) 22px,
      transparent 22px,
      transparent 72px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(298, 16%, 68%, 0.06) 0px,
      hsla(298, 16%, 68%, 0.06) 1px,
      transparent 1px,
      transparent 21px,
      hsla(298, 16%, 68%, 0.06) 21px,
      hsla(298, 16%, 68%, 0.06) 22px,
      transparent 22px,
      transparent 72px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(298, 16%, 68%, 0.06) 0px,
      hsla(298, 16%, 68%, 0.06) 1px,
      transparent 1px,
      transparent 21px,
      hsla(298, 16%, 68%, 0.06) 21px,
      hsla(298, 16%, 68%, 0.06) 22px,
      transparent 22px,
      transparent 72px
    ),
    linear-gradient(90deg, hsl(275, 3%, 97%), hsl(275, 3%, 97%));
}

#sportsbet_techstack {
  /* background: url(../images/inner-pages/sports-betting/techstac-bg.png) no-repeat; */
  background-size: cover;
  position: relative;
}

#sportsbet_techstack:before {
  position: absolute;
  content: "";
  top: 0;
  background: rgb(17, 22, 32);
  background: linear-gradient(
    181deg,
    rgb(23 17 32 / 97%) 0%,
    rgb(154 81 152 / 40%) 100%
  );
  width: 100%;
  height: 100%;
}

#sportsbet_techstack .MetaBlocksportsbet_titlewrap {
  text-align: center;
  margin-bottom: 2rem;
}

#sportsbet_techstack .MetaBlocksportsbet_titlewrap .onfsportsbet_title {
  text-align: center;
  color: #fff;
}

#sportsbet_techstack.MetaBlock_sportsbet .MetaBlocksportsbet_titlewrap p {
  color: #fff;
}

#sportsbet_techstack .MetaBlocksportbet_techstack_sec #techTabs li a {
  background-color: #230b1394;
  color: #eaeaea;
  font-weight: 700;
  border-radius: 60px;
  width: 126px;
  text-align: center;
}

#sportsbet_techstack .MetaBlocksportbet_techstack_sec #techTabs li.active a {
  background-color: #d83068;
}

#sportsbet_techstack .MetaBlocksportbet_techstack_sec #techTabs {
  justify-content: center;
  display: flex;
  border-bottom: 0;
  margin-bottom: 2rem;
}

.MetaBlocksportbet_techstack_sec .tab-content .row {
  justify-content: center;
  margin-bottom: 1rem;
  display: flex;
}

#sportsbet_techstack
  .MetaBlocksportbet_techstack_sec
  .tab-content
  .MetaBlocksportbet_box
  .onfsportsbe_head {
  text-align: center;
  color: #fff;
}

.tech-sprite {
  width: 55px;
  height: 55px;
  object-fit: none;
}

#sportsbet_techstack .tab-content .MetaBlocksportbet_box {
  background-color: #120b218c;
  text-align: center;
  padding: 10px;
  border: 1px solid #e4c8ee52;
  border-radius: 5px;
}

#sportsbet_whychoose {
  background-image: linear-gradient(
      135deg,
      transparent 0%,
      transparent 6%,
      rgba(71, 71, 71, 0.04) 6%,
      rgba(71, 71, 71, 0.04) 22%,
      transparent 22%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 20%,
      rgba(71, 71, 71, 0.04) 20%,
      rgba(71, 71, 71, 0.04) 47%,
      transparent 47%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 24%,
      rgba(71, 71, 71, 0.04) 24%,
      rgba(71, 71, 71, 0.04) 62%,
      transparent 62%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 73%,
      rgba(71, 71, 71, 0.04) 73%,
      rgba(71, 71, 71, 0.04) 75%,
      transparent 75%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.whychoose_pts_wrap ul {
  padding: 0;
  margin-bottom: 0;
}

.whychoose_pts_wrap ul li {
  background-image: radial-gradient(
      circle at 21% 44%,
      rgba(23, 23, 23, 0.05) 0%,
      rgba(23, 23, 23, 0.05) 50%,
      rgba(109, 109, 109, 0.05) 50%,
      rgba(109, 109, 109, 0.05) 100%
    ),
    radial-gradient(
      circle at 21% 96%,
      rgba(92, 92, 92, 0.05) 0%,
      rgba(92, 92, 92, 0.05) 50%,
      rgba(199, 199, 199, 0.05) 50%,
      rgba(199, 199, 199, 0.05) 100%
    ),
    radial-gradient(
      circle at 25% 37%,
      rgba(230, 230, 230, 0.05) 0%,
      rgba(230, 230, 230, 0.05) 50%,
      rgba(25, 25, 25, 0.05) 50%,
      rgba(25, 25, 25, 0.05) 100%
    ),
    linear-gradient(90deg, rgb(156, 48, 160), rgb(75, 105, 255));
  list-style: none;
  margin-bottom: 1rem;
  padding: 10px;
  color: #fff;
  font-weight: 500;
}

#sportsbet_hire {
  /* background: url(../images/inner-pages/sports-betting/hire-bg.png) no-repeat; */
}

#sportsbet_hire .MetaBlocksportsbet_titlewrap .onfsportsbet_title {
  text-align: center;
  color: #fff;
}

#sportsbet_hire.MetaBlock_sportsbet .MetaBlocksportsbet_titlewrap p {
  color: #fff;
}

.sportbet-sprite {
  width: 51px;
  height: 51px;
  object-fit: none;
}

#sportsbet_whychoose .row {
  align-items: center;
  display: flex;
}

@media (max-width: 767px) {
  header.masthead-sportsbet .header-content h1 {
    font-size: 34px !important;
  }

  .MetaBlock_sportsbet .onfsportsbet_title {
    font-size: 24px;
    line-height: 32px;
  }

  .MetaBlock_sportsbet .MetaBlocksportsbet_titlewrap {
    text-align: center;
  }

  #sportsbet_hwitwrks .row:nth-child(2):before {
    display: none;
  }

  #sportsbet_appdev.MetaBlock_sportsbet
    .MetaBlocksportbet_box
    .onfsportsbe_head {
    font-size: 25px;
  }

  #sportsbet_revenue_model .MetaBlocksportbet_box {
    text-align: center;
  }

  #sportsbet_techstack .MetaBlocksportbet_techstack_sec #techTabs li a {
    width: 80px;
    text-align: center;
    padding: 5px;
    font-size: 14px;
  }

  .MetaBlocksportbet_techstack_sec .tab-content .row {
    display: block;
    margin-bottom: 0;
  }

  #sportsbet_techstack .tab-content .MetaBlocksportbet_box {
    margin-bottom: 2rem;
  }

  #sportsbet_whychoose .row {
    display: block;
  }

  #sportsbet_hire {
    background-size: cover;
    background-position: right;
  }

  #sportsbet_typesofsportsbit .MetaBlocksportbet_box {
    min-height: unset;
  }
}

.MetaBlock_cryptoexchange_tabs .nav-tabs {
  display: none;
}

.MetaBlock_cryptoexchange_tabs .nav-tabs > li.active > a,
.MetaBlock_cryptoexchange_tabs .nav-tabs > li.active > a:focus,
.MetaBlock_cryptoexchange_tabs .nav-tabs > li.active > a:hover {
  border-bottom-color: initial;
}

.MetaBlock_cryptoexchange_tabs .nav-justified .nav-item {
  text-align: center;
}

.MetaBlock_cryptoexchange_tabs .nav-tabs li a {
  position: relative;
  margin-right: 0px !important;
  padding: 20px 40px !important;
  font-size: 17px;
  border: none !important;
  color: #1b334d;
  font-weight: 700;
}

.MetaBlock_cryptoexchange_tabs .nav-tabs {
  position: relative;
  border: 1px solid #1b334d;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%);
  border-radius: 6px;
  background-color: #ecf3ff;
}

.MetaBlock_cryptoexchange_tabs #cryptoexchange_tabs {
  width: 80%;
  margin: 0px auto;
}

.MetaBlock_cryptoexchange_tabs .nav-tabs li {
  margin: 0px !important;
  border-right: 1px solid #1b334d;
}

.MetaBlock_cryptoexchange_tabs .nav-tabs.nav-justified > li {
  width: 100%;
}

.MetaBlock_cryptoexchange_tabs .nav-tabs > li.active > a,
.MetaBlock_cryptoexchange_tabs .nav-tabs > li.active > a:focus,
.MetaBlock_cryptoexchange_tabs .nav-tabs > li.active > a:hover {
  background: #1b334d;
  border: none !important;
  color: #fff !important;
  border-radius: 0;
}

.MetaBlock_cryptoexchange_tabs .crypto_exchange_card {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 8px 9px -2px rgb(125 127 156 / 35%);
  border: 1px solid #d6d6d6;
  border-radius: 7px;
  min-height: 93px;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-transform: capitalize;
  margin-top: 15px;
  display: flex;
}

.MetaBlock_cryptoexchange_tabs .crypto_exchange_card_head {
  font-weight: 700;
  font-size: 16px;
  margin: 10px;
}

.tech_stack_img {
  width: 60%;
}

.MetaBlock_cryptoexchange_tabs .card:hover {
  transform: none;
}

@media (min-width: 768px) {
  .MetaBlock_cryptoexchange_tabs .nav-tabs {
    display: flex;
  }

  .MetaBlock_cryptoexchange_tabs .card {
    border: none;
  }

  .MetaBlock_cryptoexchange_tabs .card .card-header {
    display: none;
  }

  .MetaBlock_cryptoexchange_tabs .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .MetaBlock_cryptoexchange_tabs .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }

  .MetaBlock_cryptoexchange_tabs .crypto_mobile_acc {
    background: #1b334d;
    font-weight: bold;
    padding: 10px;
    display: flex;
    margin-top: 15px;
  }

  .MetaBlock_cryptoexchange_tabs .crypto_mobile_acc_head {
    color: #fff;
    font-weight: bold;
  }

  .MetaBlock_cryptoexchange_tabs #cryptoexchange_tabs {
    width: 100%;
  }

  .tech_stack_img {
    width: 100%;
  }

  .MetaBlock_cryptoexchange_tabs .cryptocurrency_tabs_scroll_mobile {
    overflow: scroll;
    height: 317px;
  }
}

/*=========================================NFT Game Platform DEVELOPMENT=================================*/

.nft_gameplatform {
  padding: 50px 0px;
}

#nftgp_hero {
  background: radial-gradient(
    circle at 80% 50%,
    rgb(42 46 59) 0%,
    rgb(31 35 45) 42.71%,
    rgb(14 17 23) 100%
  );
  padding: 130px 0px 50px 0px;
}

#nftgp_hero .header-content {
  padding-top: 5rem;
}

#nftgp_hero .header-content h1 {
  text-transform: uppercase;
  font-weight: 900;
  text-align: left;
  font-size: 45px !important;
}

#nftgp_hero .header-content .heading-tagline {
  text-align: left;
  font-size: 18px;
}

#nftgp_hero .header-content a.expert-btn {
  box-shadow: 0px 6px 16px -4px #140f0d;
  border: 0;
  background-color: #af27e7;
}

.nftgp_custom_container {
  padding: 0px 50px;
}

#nftgp_sec1 {
  padding-top: 50px;
  padding: 0;
  overflow: hidden;
}

#nftgp_sec1 .row {
  display: flex;
  align-items: center;
}

#nftgp_sec1 p:nth-child(2) {
  margin-bottom: 2rem;
}

.nft_gameplatform#nftgp_sec2 .row:nth-child(2) {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

.nft_gameplatform#nftgp_sec2 .row {
  display: flex;
  justify-content: center;
}

.nftgb_dark_theme {
  background-color: #222631;
}

.nftgb_lite_theme .nftgp_sectitle_wrap .nftgp_sec_title {
  color: #222631;
}

.nftgb_dark_theme .nftgp_sectitle_wrap .nftgp_sec_title {
  color: #fff;
}

.nft_gameplatform .nftgp_sectitle_wrap .nftgp_sec_title {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 2rem;
  margin-top: 0;
}

.nft_gameplatform#nftgp_sec2 .nftgp_sectitle_wrap {
  text-align: center;
}

.nft_gameplatform p {
  margin-bottom: 0;
}

.nftgb_dark_theme .nftgp_sectitle_wrap p {
  color: #dcdee4;
}

.nftgb_dark_theme .nftgp_box .nftgp_head {
  color: #fff;
}

.nftgb_dark_theme .nftgp_box .nftgp_desc {
  color: #dcdee4;
}

.nft_gameplatform .nftgp_box .nftgp_desc {
  font-size: 15px;
}

.nft_gameplatform .nftgp_box .nftgp_head {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}

#nftgp_sec2 .nftgp_box {
  text-align: center;
  padding: 15px;
  min-height: 182px;
}

#nftgp_sec2 .nftgp_box .nftgp_head {
  color: #fff;
}

#nftgp_sec3 .row {
  display: flex;
  justify-content: center;
}

#nftgp_sec3 {
  padding: 0;
  padding-top: 50px;
  overflow: hidden;
}

#nftgp_sec4 .row {
  display: flex;
  justify-content: center;
}

#nftgp_sec3 .nftgp_sectitle_wrap {
  text-align: center;
}

#nftgp_sec4 .nftgp_sectitle_wrap {
  text-align: center;
}

#nftgp_sec4 .nftgp_sectitle_wrap {
  margin-bottom: 5rem;
}

#nftgp_sec4 .nftgp_box {
  text-align: center;
  padding: 15px;
  border: 1px solid #7d8395;
  border-radius: 10px;
}

#nftgp_sec4 .nftgp_box img {
  border-radius: 10px;
  transition: 0.3s;
}

#nftgp_sec4 .nftgp_box img:hover {
  transform: scale(1.01);
}

#nftgp_sec4 .row:nth-child(2),
#nftgp_sec4 .row:nth-child(3),
#nftgp_sec4 .row:nth-child(4) {
  margin-bottom: 4rem;
}

#nftgp_sec4.nft_gameplatform .nftgp_box .nftgp_head {
  margin-top: 1rem;
}

#nftgp_sec5 .row:nth-child(2) {
  justify-content: center;
  display: flex;
}

#nftgp_sec5 .nftgp_sectitle_wrap {
  text-align: center;
}

#nftgp_sec5 .row:nth-child(2) .col-md-4 {
  padding: 0;
}

#nftgp_sec5 .row .col-md-4:nth-child(1) {
  background-color: #222631;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 1px 0px 10px -2px #2226318a;
}

#nftgp_sec5 .row .col-md-4:nth-child(2) {
  background-color: #af27e7;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: -1px 0px 10px -2px #2226318a;
}

#nftgp_sec5 .col-md-4:nth-child(1) .nftgp_diff_pts ul li.nftgp_diff_head {
  border-top-left-radius: 20px;
}

#nftgp_sec5 .col-md-4:nth-child(2) .nftgp_diff_pts ul li.nftgp_diff_head {
  border-top-right-radius: 20px;
}

#nftgp_sec5 .col-md-4 .nftgp_diff_pts ul {
  padding: 0;
}

#nftgp_sec5 .col-md-4 .nftgp_diff_pts ul li {
  list-style: none;
  color: #fff;
  padding: 20px 20px;
  border-bottom: 1px solid #ffffff2b;
}

#nftgp_sec5 .col-md-4 .nftgp_diff_pts ul li.nftgp_diff_head {
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  background-color: #0000004d;
}

#nftgp_sec6 .nftgp_sectitle_wrap {
  text-align: center;
}

#nftgp_sec7 .nftgp_sectitle_wrap {
  text-align: center;
}

#nftgp_sec7.nft_gameplatform .nftgp_box .nftgp_head {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

#nftgp_sec7.nft_gameplatform .nftgp_box {
  padding: 15px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 3px -2px black;
  border-radius: 5px;
  min-height: 100px;
}

#nftgp_sec7 .row:nth-child(2),
#nftgp_sec7 .row:nth-child(3) {
  margin-bottom: 2rem;
}

.nftgp_techsprite {
  width: 50px;
  height: 50px;
  object-fit: none;
}

#nftgp_sec7 .nftgp_techtitle {
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  background-color: #ffffffe6;
  color: #222631;
  padding: 20px 0px;
}

/*.nftgp_head {
    color: #222631;
    margin: 10px 0px;
    font-weight: 500;
}*/

.nftgp_techlist ul li {
  display: inline-block;
  text-align: center;
}

.nftgp_techlist ul {
  padding: 20px 0px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
}

.nftgp_techstack_wrap {
  background-color: #ededed;
  margin-bottom: 3rem;
}

.keyfac-sprite {
  width: 120px;
  height: 120px;
  object-fit: none;
  transition: 0.2s;
}

.keyfac-sprite:hover {
  transform: scale(1.1);
}

.nftgp_whychoose {
  width: 50px;
  height: 50px;
  object-fit: none;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .nftgp_custom_container {
    padding: 0px 00px;
  }

  #nftgp_hero .header-content h1 {
    font-size: 26px !important;
    text-align: center;
  }

  #nftgp_sec1 {
    padding: 35px 0px;
  }

  #nftgp_sec3 {
    padding: 35px 0px;
  }

  #nftgp_sec4 .nftgp_box {
    margin: 20px 0px;
  }

  #nftgp_hero .header-content {
    text-align: center;
  }

  .nftgp_sectitle_wrap {
    text-align: center;
  }

  .nft_gameplatform .nftgp_sectitle_wrap .nftgp_sec_title {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .nft_gameplatform#nftgp_sec2 .row,
  #nftgp_sec4 .row {
    display: block;
  }

  #nftgp_sec5 .row:nth-child(2) {
    justify-content: center;
    display: block;
  }

  #nftgp_sec5 .col-md-4:nth-child(1) .nftgp_diff_pts ul li.nftgp_diff_head,
  #nftgp_sec5 .row .col-md-4:nth-child(1),
  #nftgp_sec5 .row .col-md-4:nth-child(2),
  #nftgp_sec5 .col-md-4:nth-child(2) .nftgp_diff_pts ul li.nftgp_diff_head {
    border-radius: 0;
  }

  .nftgp_techlist ul {
    display: block;
    text-align: center;
  }

  .nftgp_techlist ul li {
    padding: 6px;
  }

  .nft_gameplatform#nftgp_sec2 .row:nth-child(2) {
    display: block;
  }
}

.nftgp_servsprite {
  width: 231px;
  height: 231px;
  object-fit: none;
}

#nftgp_sec7 .nftgp_box {
  display: flex;
  align-items: center;
}

/*
whitepaper style start*/

#white_paper_page {
  background: linear-gradient(272deg, #040014 0%, #040029 74%);
}

@media (min-width: 767px) {
  #white_paper_page .whitepaper_banner_sec {
    /* background-image: url('public/oimages/white-paper-img/whitepaper-banner.png'); */
    height: 600px;
    background-size: cover;
    background-position: top;
    display: grid;
    align-items: center;
  }
}

#white_paper_page .whitepaper_banner_sec .title {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
}

#white_paper_page .whitepaper_banner_sec .contents {
  color: #fff;
  font-size: 22px;
  line-height: 1.4;
  padding-top: 10px;
}

#white_paper_page .whitepaper_btn:hover {
  color: #fff;
}

#white_paper_page .what_whitepaper,
#white_paper_page .what_litepaper,
#white_paper_page .whitepaper_litepaper,
#white_paper_page .whitepaper_comprehensive,
#white_paper_page .whitepaper_aspects,
#white_paper_page .whitepaper_investors,
#white_paper_page .whitepaper_process,
#white_paper_page .whitepaper_needs,
#white_paper_page .whitepaper_why,
#white_paper_page .whitepaper_industries,
#white_paper_page .whitepaper_hire,
#white_paper_page .whitepaper_hire_do {
  padding: 0px;
}

#white_paper_page .what_whitepaper .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .what_whitepaper .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_btn {
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;
  color: #fff;
  background-image: linear-gradient(315deg, #fbd100 0%, #ff660c 74%);
  font-size: 16px;
  padding: 8px 10px;
  box-shadow: 0 1px 27px #ff660c;
}

#white_paper_page .what_whitepaper .sub_title {
  font-weight: 800;
  font-size: 38px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3;
}

#white_paper_page .what_whitepaper .sub_title_1 {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
}

#white_paper_page .what_whitepaper .contents_1 {
  text-align: justify;
  color: #fff;
}

#white_paper_page .what_litepaper .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .what_litepaper .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .what_litepaper .sub_title {
  font-weight: 800;
  font-size: 38px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3;
}

#white_paper_page .what_litepaper .sub_title_1 {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
}

#white_paper_page .what_litepaper .contents_1 {
  text-align: justify;
  color: #fff;
}

#white_paper_page .whitepaper_litepaper .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
  padding-bottom: 20px;
}

#white_paper_page .whitepaper_litepaper .contents {
  text-align: justify;
  color: #fff;
}

#white_paper_page .whitepaper_comprehensive .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
  padding-bottom: 20px;
}

#white_paper_page .whitepaper_comprehensive .whitepaper_comprehensive_card {
  padding: 10px;
  margin-top: 20px;
  min-height: 200px;
  box-shadow: 0 0px 9px #ff660c7d;
  background: linear-gradient(272deg, #040014 0%, #040029 74%);
  border: 1px solid #ff660c63;
  border-radius: 10px;
}

#white_paper_page
  .whitepaper_comprehensive
  .whitepaper_comprehensive_card_title {
  font-weight: 800;
  font-size: 25px;
  text-transform: capitalize;
  text-align: center;
  color: #ff660c;
}

#white_paper_page
  .whitepaper_comprehensive
  .whitepaper_comprehensive_card_content {
  color: #fff;
  text-align: center;
}

#white_paper_page .whitepaper_aspects .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
  padding-bottom: 20px;
  line-height: 1.3;
}

#white_paper_page .whitepaper_aspects .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_aspects .card {
  position: relative;
}

#white_paper_page .whitepaper_aspects .card .face {
  transition: 0.5s;
  height: 116px;
}

#white_paper_page .whitepaper_aspects .card .face.face1 {
  position: relative;
  background: #040029;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(86px);
  border: 1px solid #eeeef14a;
}

#white_paper_page .whitepaper_aspects .card:hover .face.face1 {
  background: #e15300;
  transform: translateY(0);
}

#white_paper_page .whitepaper_aspects .card .face.face1 .content__hover {
  padding: 10px;
  transition: 0.5s;
}

#white_paper_page .whitepaper_aspects .card .face.face1 .content__hover h3 {
  color: #fff;
  text-align: center;
  text-transform: capitalize;
}

#white_paper_page .whitepaper_aspects .card:hover .face.face1 .content__hover {
  opacity: 1;
}

#white_paper_page .whitepaper_aspects .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgb(0 0 0 / 80%);
  transform: translateY(-29px);
  height: 114px;
}

#white_paper_page .whitepaper_aspects .card:hover .face.face2 {
  transform: translateY(0);
}

#white_paper_page .whitepaper_aspects .card .face.face2 p {
  padding: 10px;
  margin: 0;
}

#white_paper_page .whitepaper_investors .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
  padding-bottom: 20px;
}

#white_paper_page .whitepaper_investors .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_investors .whitepaper_investors_card {
  min-height: 240px;
  background: linear-gradient(272deg, #040014 0%, #040029 74%);
  margin-top: 30px;
  border-left: 1px solid #ff660c;
  padding: 5px 10px;
  border-right: 1px solid #ff660c;
  box-shadow: 0 0px 9px #4343439e;
}

#white_paper_page .whitepaper_investors .whitepaper_investors_card_title {
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  padding-top: 5px;
  color: #ff660c;
  text-transform: capitalize;
}

#white_paper_page .whitepaper_investors .whitepaper_investors_card_content {
  text-align: center;
  margin-bottom: 0px;
  color: #fff;
}

#white_paper_page .whitepaper_process .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .whitepaper_process .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_process .whitepaper_process_card {
  min-height: 223px;
  margin-top: 30px;
  border: 2px dotted #50cef266;
  padding: 10px 7px;
  border-radius: 10px;
}

#white_paper_page .whitepaper_process .whitepaper_process_card_title {
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  padding-top: 5px;
  color: #ff660c;
  text-transform: capitalize;
}

#white_paper_page .whitepaper_process .whitepaper_process_card_content {
  color: #fff;
  text-align: center;
}

#white_paper_page .whitepaper_needs .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .whitepaper_needs .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_why .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .whitepaper_why .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_why ul li {
  color: #fff;
  padding-top: 20px;
  list-style: none;
}

#white_paper_page .whitepaper_industries .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .whitepaper_industries .white_paper_industry_img {
  width: 35px;
  height: 35px;
  object-fit: none;
}

#white_paper_page .whitepaper_industries .whitepaper_industries_card {
  padding: 20px 12px;
  border-radius: 4px;
  border: 1px solid #ff690078;
  color: #3e3e3e;
  margin-top: 2rem;
  text-align: center;
}

#white_paper_page .whitepaper_industries .white_paper_industry_card_title {
  font-size: 16px;
  font-weight: 800;
  text-transform: capitalize;
  padding-top: 10px;
  color: #fff;
}

#white_paper_page .whitepaper_hire .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 20px;
}

#white_paper_page .whitepaper_hire .contents {
  text-align: justify;
  color: #fff;
}

#white_paper_page .whitepaper_hire .whitepaper_hire_card {
  padding: 10px 7px;
  background: linear-gradient(178deg, #0c003d 0%, #03001f 74%);
  box-shadow: 0 0px 9px #ff690091;
  min-height: 265px;
  border: 1px solid #ff690073;
  border-radius: 10px;
}

#white_paper_page .whitepaper_hire .whitepaper_hire_card_title {
  color: #ff6900;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  text-transform: capitalize;
}

#white_paper_page .whitepaper_hire .whitepaper_hire_card_content {
  color: #fff;
  text-align: center;
}

#white_paper_page .whitepaper_hire_do .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  color: #fff;
}

#white_paper_page .whitepaper_hire_do .contents {
  text-align: center;
  color: #fff;
}

#white_paper_page .whitepaper_hire_do .whitepaper_hire_do_card {
  padding: 10px 10px;
  background: linear-gradient(272deg, #040014 0%, #040029 74%);
  box-shadow: 0 0px 9px #4343439e;
  min-height: 197px;
  margin-top: 20px;
}

#white_paper_page .whitepaper_hire_do .whitepaper_hire_card_do_title {
  color: #ff691e;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 10px;
}

#white_paper_page .whitepaper_hire_do .whitepaper_hire_card_do_content {
  color: #fff;
  text-align: center;
}

#white_paper_page .what_litepaper,
#white_paper_page .whitepaper_comprehensive,
#white_paper_page .whitepaper_investors,
#white_paper_page .whitepaper_needs,
#white_paper_page .whitepaper_industries,
#white_paper_page .whitepaper_hire_do {
  box-shadow: 0 0px 9px #00cbff7a;
  padding-top: 40px;
  padding-bottom: 40px;
}

#white_paper_page .whitepaper_why {
  position: relative;
}

#white_paper_page .whitepaper_why ul li:before {
  content: "\25BA";
  color: #ff6900;
  position: absolute;
  left: 30px;
}

#white_paper_page .whitepaper_process_row_align {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  #white_paper_page .whitepaper_banner_sec .title {
    font-size: 30px;
    text-align: center;
  }

  #white_paper_page .whitepaper_banner_sec .contents {
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
  }

  #white_paper_page .what_whitepaper .title {
    font-size: 25px;
  }

  #white_paper_page .what_whitepaper .contents {
    text-align: justify;
  }

  #white_paper_page .what_whitepaper .sub_title {
    text-align: center;
    font-size: 23px;
  }

  .whitepaper_btn_mobile {
    text-align: center;
  }

  #white_paper_page .what_whitepaper .sub_title_1 {
    text-align: center;
    font-size: 23px;
    padding-top: 10px;
  }

  #white_paper_page .what_litepaper .contents,
  #white_paper_page .whitepaper_aspects .contents,
  #white_paper_page .whitepaper_investors .contents,
  #white_paper_page .whitepaper_needs .contents,
  #white_paper_page .whitepaper_hire_do .contents {
    text-align: justify;
  }

  #white_paper_page .what_litepaper .sub_title_1 {
    text-align: center;
    font-size: 23px;
  }

  #white_paper_page .what_litepaper .sub_title {
    text-align: center;
    font-size: 23px;
  }

  #white_paper_page .whitepaper_litepaper .title,
  #white_paper_page .whitepaper_comprehensive .title,
  #white_paper_page .whitepaper_aspects .title,
  #white_paper_page .whitepaper_investors .title,
  #white_paper_page .whitepaper_process .title,
  #white_paper_page .whitepaper_needs .title,
  #white_paper_page .whitepaper_why .title,
  #white_paper_page .whitepaper_industries .title,
  #white_paper_page .whitepaper_hire .title,
  #white_paper_page .whitepaper_hire_do .title {
    font-size: 25px;
  }

  #white_paper_page .whitepaper_process .whitepaper_process_card {
    min-height: initial;
  }

  #white_paper_page .whitepaper_hire .whitepaper_hire_card {
    min-height: initial;
    margin-top: 30px;
  }

  #white_paper_page .whitepaper_industries .whitepaper_industries_card {
    min-height: 130px;
  }
}

/*
whitepaper style end*/

/*=========================================Metaverse DEVELOPMENT=================================*/

#metavdev_hero {
  background-color: #202036;
  /* background-image: url(../images/inner-pages/metaverse-development-company/banner1.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
}

.metavdev_custom_container {
  padding: 0px 50px;
}

#metavdev_hero .header-content {
  padding-top: 5rem;
}

#metavdev_hero .header-content .heading-tagline {
  text-align: left;
  font-size: 18px;
  color: #000;
}

#metavdev_hero .header-content a.expert-btn {
  box-shadow: 0px 6px 16px -4px #140f0d;
  border: 0;
  background-color: #f1ae2a;
  color: #fff;
}

#metavdev_se4 a.expert-btn {
  box-shadow: 0px 6px 16px -4px #140f0d;
  border: 0;
  background-color: #af27e7;
}

#metavdev_hero .header-content h1 {
  text-transform: capitalize;
  font-weight: 900;
  text-align: left;
  font-size: 50px !important;
  background-image: linear-gradient(90deg, #2f005b 0%, #682d86 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff00;
  text-shadow: none;
}

#metavdev_sec1 {
  padding: 0;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
}

#metavdev_sec1 p {
  color: #000;
}

.MetaBlock_metaverse_deve .nftgp_sectitle_wrap .nftgp_sec_title {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 2rem;
  margin-top: 0;
  color: #000;
  /* background-image: linear-gradient(90deg, #bf9eff 0%, #4be3ff 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff00;*/
  text-shadow: none;
  position: relative;
}

#metavdev_sec1 p:nth-child(2) {
  margin-bottom: 2rem;
}

.MetaBlock_metaverse_deve p {
  margin-bottom: 0;
}

#metavdev_sec1 .row {
  display: flex;
  align-items: center;
}

.MetaBlock_metaverse_deve {
  padding: 40px 0px;
}

.metavdev_dark_theme {
  background-color: #eefaff;
  padding: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
}

#nftgp_sec2 .nftgp_box {
  text-align: center;
  padding: 15px;
  min-height: 182px;
}

#nftgp_sec2 .nftgp_box .nftgp_head {
  color: #fff;
}

.MetaBlock_metaverse_deve .nftgp_box .nftgp_head {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 1rem;
  text-align: left;
  color: #000;
}

.MetaBlock_metaverse_deve .nftgp_box .nftgp_desc {
  font-size: 15px;
}

.metavdev_dark_theme .nftgp_box .nftgp_desc {
  color: #dcdee4;
}

/*#metavdev_sec2{
    position: relative;
    background-position: center;
        padding-top: 50px;
    background-image: url(https://images.unsplash.com/photo-1626379945538-6c8824dd898f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80);
}*/

/*#metavdev_sec2:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background:linear-gradient(359deg, rgb(17 11 29 / 93%) 0.18%, rgb(61 26 58 / 92%) 100%);
    top: 0;
    opacity: 0.7;
    }*/

#metavdev_sec2 ul li {
  display: inline-block;
  width: 19%;
  padding: 22px;
  text-align: center;
  border-radius: 7px;
  margin: 0px 10px;
  position: relative;
  z-index: 0;
}

#metavdev_sec2 ul li:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
}

#metavdev_sec2 ul {
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
}

/*#metavdev_sec2 ul:before {
position: absolute;
width: 100%;
height: 8px;
background-color: #ac93ee;
border-radius: 5px;
content: '';
bottom: -40px; 
}*/

#metavdev_sec2 .nftgp_sectitle_wrap {
  text-align: center;
  margin-bottom: 2.5rem;
}

#metavdev_sec2.MetaBlock_metaverse_deve .nftgp_sectitle_wrap .nftgp_sec_title {
  position: relative;
  margin-bottom: 0.2rem;
}

.MetaBlock_metaverse_deve
  .nftgp_sectitle_wrap
  .nftgp_sec_title.title_alict:before {
  background-image: linear-gradient(90deg, #bf9eff 0%, #4be3ff 74%);
  width: 73px;
  height: 3px;
  position: absolute;
  content: "";
  bottom: -10px;
  border-radius: 50px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.MetaBlock_metaverse_deve
  .nftgp_sectitle_wrap.title_alilt
  .nftgp_sec_title:before {
  background-image: linear-gradient(90deg, #bf9eff 0%, #4be3ff 74%);
  width: 73px;
  height: 3px;
  position: absolute;
  content: "";
  bottom: -10px;
  border-radius: 50px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.MetaBlock_metaverse_deve .metavdev_sectitle_wrap .metavdev_sec_title {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 2rem;
  margin-top: 0;
  color: #000;
  /*background-image: linear-gradient(90deg, #bf9eff 0%, #4be3ff 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff00;*/
  text-shadow: none;
  position: relative;
}

.MetaBlock_metaverse_deve .metavdev_sectitle_wrap .metavdev_sec_title:before {
  background-color: #fff;
  background-image: linear-gradient(90deg, #bf9eff 0%, #4be3ff 74%);
  width: 73px;
  height: 3px;
  position: absolute;
  content: "";
  bottom: -10px;
  border-radius: 50px;
}

/*#metavdev_sec2 ul li:before{
position: absolute;
    width: 25px;
    height: 25px;
    background-color: #ac93ee;
    border-radius: 50px;
    content: '';
    bottom: -48px;
    left: 0;
    right: 0;
    margin: 0 auto;
}*/

#metavdev_sec2.metavdev_dark_theme .nftgp_box .nftgp_desc {
  color: #000;
  font-size: 17px;
  font-weight: 500;
}

/*.metavdev_timestamp {
    position: absolute;
    bottom: -81px;
    left: 0;
    right: 0;
    font-size: 22px;
    font-weight: 700;
}*/

.metaban_span {
  display: block;
  font-weight: 300;
  font-size: 30px;
  margin-top: 1.5rem;
}

#metavdev_sec3 .nftgp_box {
  text-align: center;
  background-color: #ffffff17;
  border-radius: 5px;
  padding: 15px;
  min-height: 285px;
  /* margin-bottom: 3rem; */
  margin-top: 50px;
  box-shadow: inset -3px -6px 16px 0px #9f9ba1, inset 9px 5px 14px 0px #7c7c7c38;
}

/*#metavdev_sec3 .nftgp_box:hover {
    box-shadow: -3px -6px 16px 0px #fdfdfdb0, 9px 5px 14px 0px #00000026;
    transition: all .3s;
}*/

/*#metavdev_sec3 .nftgp_box:before {
    content: "";
    height: 60px;
    width: 60px;
    border-radius: 100px;
    background-color: #84c1ff2b;
    position: absolute;
    bottom: -28px;
    right: -10px;
    opacity: 0;
}*/

/*#metavdev_sec3 .nftgp_box:hover:before {
    opacity: 1;
}*/

/*.nftgp_box {
    transition: all .3s;
    padding: 0 45px 0px;
    margin: 20px;
    background: transparent linear-gradient(311deg, #ebecf0 0%, #ebecf0 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset -3px -6px 16px 0px #fdfdfdb0, inset 9px 5px 14px 0px #00000026;
    border-radius: 20px;
    height: 100%;
    position: relative;
}*/

#metavdev_sec3.MetaBlock_metaverse_deve .nftgp_box .nftgp_head {
  color: #000;
  padding-top: 10px;
  text-align: center;
}

#metavdev_se4 .nftgp_box .nftgp_head {
  color: #202036;
  font-size: 17px;
  margin-bottom: 0px;
  text-align: center;
}

/*#metavdev_se4{
    background-size: cover;
    padding: 50px;
    position: relative;
    background-image: url(https://images.unsplash.com/photo-1620120966883-d977b57a96ec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80);
}*/

/*#metavdev_se4:before{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    background : linear-gradient(359deg, rgb(65 31 61 / 90%) 0.18%, rgb(54 54 113 / 92%) 100%);
}*/

#metavdev_se4 .nftgp_box {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 31.18%,
    rgb(255 255 255) 100%
  );
  padding: 15px;
  /* margin-bottom: 1.5rem; */
  border-radius: 5px;
  text-align: center;
  margin-top: 30px;
  box-shadow: 0px 0px 6px #ccc;
  display: flex;
  align-items: center;
  min-height: 75px;
  justify-content: center;
}

#metavdev_se4 .nftgp_sectitle_wrap {
  text-align: center;
  margin-bottom: 2rem;
}

#metavdev_se4.MetaBlock_metaverse_deve .nftgp_sectitle_wrap .nftgp_sec_title {
  margin-bottom: 0.2rem;
}

#metavdev_se5 .row:nth-child(1) {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
}

#metavdev_se5 .nftgp_sectitle_wrap p {
  color: #000;
}

#metavdev_se5 .row:nth-child(2),
#metavdev_se5 .row:nth-child(3) {
  display: flex;
  justify-content: center;
}

#metavdev_se5.MetaBlock_metaverse_deve .nftgp_box {
  background: #fff;
  padding: 10px;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  min-height: 436px;
  box-shadow: 0px 0px 9px #ccc;
}

#metavdev_se5.MetaBlock_metaverse_deve .nftgp_box .nftgp_head {
  color: #3a0863;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
}

#metavdev_se5.MetaBlock_metaverse_deve .nftgp_box .nftgp_desc {
  color: #000;
  text-align: center;
}

#metavdev_se5.MetaBlock_metaverse_deve .nftgp_box .nftgp_content_wrap {
  margin: 27px 5px;
}

#metavdev_sec3 .nftgp_sectitle_wrap {
  text-align: center;
}

/*#sec7_bg1 {
    background: url(../images/inner-pages/nft_marketplace/nftfields-10.jpg?v2) no-repeat;
    background-size: cover;
}*/

#metavdev_se8 .nftgp_box {
  height: 195px;
  width: 182px;
  position: relative;
}

#metavdev_se8 .nftgp_content_wrap {
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(1deg, #4747c2 0%, #2c2c67 74%);
}

#metavdev_se8 .nftgp_head {
  background: linear-gradient(1deg, #3e0070 0%, #1d004c 74%);
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: 800;
  width: 195px;
  margin: 0 auto;
  font-size: 20px;
}

#metavdev_se8 .owl-nav .owl-prev,
#metavdev_se8 .owl-nav .owl-next {
  border: 3px solid #f3b5ff;
  margin: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #e14fff;
}

#metavdev_se8 .owl-nav .owl-prev {
  left: 0px;
}

#metavdev_se8 .owl-nav .owl-next {
  right: 0px;
}

#metavdev_se8 .owl-nav {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  width: 100%;
}

#metavdev_se8 .nftgp_sectitle_wrap {
  text-align: center;
}

#metavdev_se6 .nftgp_box {
  padding: 15px 20px;
  min-height: 175px;
  border-radius: 12px;
  border: 1px solid #3a0863;
  box-shadow: 4px 5px 0 #3a0863;
}

/*#metavdev_se6{
    position: relative;
    background-size: cover;
    background-image: url(https://images.unsplash.com/photo-1511447333015-45b65e60f6d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1255&q=80);
}*/

/*#metavdev_se6:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
     background: linear-gradient(22deg, #110d3178 0%, #181131 100%);
 }*/

#metavdev_se6 .nftgp_box .nftgp_head {
  text-align: center !important;
}

#metavdev_se6 .nftgp_box .nftgp_desc {
  color: #202036;
  font-weight: 500;
  text-align: center;
}

#metavdev_se6 .row {
  margin-bottom: 3rem;
}

#metavdev_se6 .row:nth-child(4) {
  margin-bottom: 0;
}

#metavdev_se11 .nav-tabs {
  display: none;
}

#opensea_clone_script #metavdev_se11 .nav-tabs > li > a {
  color: #fff;
  font-weight: 800;
  font-size: 18px;
}

#opensea_clone_script #metavdev_se11 .nav-tabs > li > a:hover {
  color: #e900ff !important;
}

#metavdev_se11 .nav-tabs > li.active > a,
#metavdev_se11 .nav-tabs > li.active > a:focus,
#metavdev_se11 .nav-tabs > li.active > a:hover {
  color: #e900ff !important;
  background: transparent;
  border: none;
  border-bottom: 2px solid #e900ff;
}

#metavdev_se11 .nav-tabs > li > a:hover {
  background: transparent;
  color: #e900ff;
}

#metavdev_se11 .nav-tabs > li > a {
  border: none;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

@media (min-width: 768px) {
  #metavdev_se11 .nav-tabs {
    display: flex;
    justify-content: center;
  }

  #metavdev_se11 .card {
    border: none;
  }

  #metavdev_se11 .card .card-header {
    display: none;
  }

  #metavdev_se11 .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  #metavdev_se11 .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }

  #metavdev_se4 .nftgp_box,
  #metavdev_se6 .nftgp_box {
    min-height: 0px !important;
  }

  #metavdev_sec1 .metavdev_sectitle_wrap .metavdev_sec_title {
    text-align: center !important;
  }

  #metavdev_sec1 .metavdev_sectitle_wrap .metavdev_sec_title:before {
    left: 40%;
  }
}

#metavdev_se11 .nav-tabs {
  border-bottom: 1px solid #dddddd57;
}

#metavdev_se11 .tech_stact_card_content {
  font-size: 18px;
  font-weight: 800;
  color: #000;
  padding-top: 10px;
  margin-bottom: 0px;
}

#metavdev_se11 .tech_stact_card {
  margin-top: 20px;
  border: 1px dotted #ffffff70;
  text-align: center;
  padding: 10px;
}

#opensea_clone_script #metavdev_se11 .techstack_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
  filter: brightness(100);
}

#metavdev_se11 .techstack_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
  filter: brightness(0);
}

/*#metavdev_sec2 ul li:hover{
  background: #7900ff;
      box-shadow: 0 0px 9px #7900ff;
    border: 1px solid #7900ff;
    
}*/

#metavdev_se8 .nftgp_img_sprite {
  width: 195px !important;
  height: 182px;
  object-fit: none;
}

@media only screen and (max-width: 600px) {
  #metavdev_hero {
    background: #440f2f;
  }

  #metavdev_hero .header-content h1 {
    background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  }

  #metavdev_hero .header-content .heading-tagline {
    color: #fff;
  }

  #metavdev_sec2 ul {
    display: grid;
  }

  #metavdev_sec2 ul li {
    width: 100%;
    margin: 10px 0px;
  }

  #metavdev_se5 .row:nth-child(2),
  #metavdev_se5 .row:nth-child(3) {
    display: grid;
  }

  #metavdev_se9 {
    display: none;
  }

  .metavdev_custom_container {
    padding: 0px;
  }

  #metavdev_hero .header-content h1 {
    text-align: center;
    font-size: 30px !important;
    line-height: 1;
  }

  #metaverse_dev_company .metaban_span {
    font-size: 23px;
  }

  #metaverse_dev_company
    .MetaBlock_metaverse_deve
    .metavdev_sectitle_wrap
    .metavdev_sec_title,
  #metavdev_sec2.MetaBlock_metaverse_deve .nftgp_sectitle_wrap .nftgp_sec_title,
  #metaverse_dev_company
    .MetaBlock_metaverse_deve
    .nftgp_sectitle_wrap
    .nftgp_sec_title,
  #metavdev_se4.MetaBlock_metaverse_deve .nftgp_sectitle_wrap .nftgp_sec_title {
    text-align: center;
    font-size: 27px;
  }

  #metavdev_sec1 p {
    text-align: center;
  }

  #metavdev_se6 .nftgp_box {
    margin-top: 30px;
  }

  #metavdev_se10 .nftgp_box {
    margin-top: 20px;
  }

  #metavdev_se11 [aria-expanded="true"] i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  #metavdev_se11 .card-header {
    background: #fff;
    margin-top: 20px;
  }

  #metavdev_se11 .fa-chevron-down:before {
    content: "\f078";
  }

  #mdc_collapse-C .card-body .row {
    display: grid;
  }

  #metavdev_se11 .card-header h5 {
    color: #000;
    font-weight: 800;
    font-size: 18px;
  }

  #metavdev_se11 .card-body {
    height: 200px;
    overflow: scroll;
  }
}

/*#metavdev_se7{
    position: relative;
    padding:50px 0px;
    background-size: 42px 42px;
    background-image: radial-gradient(circle at center center, rgba(255,255,255, 0.05) 0%, rgba(255,255,255, 0.05) 15%,rgba(197, 197, 197,0.05) 15%, rgba(197, 197, 197,0.05) 34%,rgba(178, 178, 178,0.05) 34%, rgba(178, 178, 178,0.05) 51%,rgba(237, 237, 237,0.05) 51%, rgba(237, 237, 237,0.05) 75%,rgba(138, 138, 138,0.05) 75%, rgba(138, 138, 138,0.05) 89%,rgba(158, 158, 158,0.05) 89%, rgba(158, 158, 158,0.05) 100%),radial-gradient(circle at center center, rgb(32,32,54) 0%, rgb(32,32,54) 6%,rgb(32,32,54) 6%, rgb(32,32,54) 12%,rgb(32,32,54) 12%, rgb(32,32,54) 31%,rgb(32,32,54) 31%, rgb(32,32,54) 92%,rgb(32,32,54) 92%, rgb(32,32,54) 97%,rgb(32,32,54) 97%, rgb(32,32,54) 100%); 

}*/

#metavdev_se7 .nftgp_sectitle_wrap {
  text-align: center;
}

#metavdev_se7:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}

#metavdev_se7 .nftgp_box {
  padding: 20px 30px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  /* box-shadow: 0 0px 9px #0ee3cf;
    border: 1px solid #0ee3c5;*/
  margin-top: 20px;
  background: #fff;
  box-shadow: 0px 0px 14px #c5c5c5c2;
}

/*#metavdev_se7 .nftgp_box:before {
    background: linear-gradient(90deg, #45457d 0%, #20203652 74%);
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: -1;
}*/

#metavdev_se7.MetaBlock_metaverse_deve .nftgp_box .nftgp_head {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #000;
}

/*#metadev7_clone {
    background-image: url(https://images.unsplash.com/photo-1624398891398-fbb14f1acc11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80);
    background-size: cover;
    background-position: center;
} */

#metavdev_se9 .nftgp_box {
  background-color: #fff;
  padding: 25px;
  position: relative;
  border: 1px solid #dcdcdc;
}

#metavdev_se9 .nftgp_box .sec9_count {
  background-color: #481b93;
  border-radius: 50px;
  width: 40px;
  /* text-align: center; */
  height: 40px;
  position: absolute;
  top: -20px;
  font-weight: 600;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-bottom: 4px;
}

#metavdev_se9 .nftgp_box.nftgp_horiline,
#metavdev_se9 .nftgp_box.nftgp_vertline,
#metavdev_se9 .nftgp_box.nftgp_lthoriline {
  position: relative;
  z-index: 0;
}

#metavdev_se9 .row:nth-child(2) {
  margin-bottom: 3rem;
}

#metavdev_se9 .nftgp_box.nftgp_horiline:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 20px;
  background-color: #481b93;
  right: -32px;
  top: 50%;
  z-index: -1;
}

#metavdev_se9 .nftgp_box.nftgp_vertline:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 32px;
  background-color: #fff;
  bottom: -31px;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#metavdev_se9 .nftgp_box.nftgp_lthoriline:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 20px;
  background-color: #481b93;
  left: -31px;
  top: 50%;
  z-index: -1;
}

#metavdev_se9.MetaBlock_metaverse_deve .nftgp_box .nftgp_head {
  color: #000;
}

#metavdev_se9 .nftgp_sectitle_wrap {
  text-align: center;
}

/*#metavdev_se10{
    position: relative;
    background:url(https://images.unsplash.com/photo-1604818640599-71bda0165d53?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80);
}
#metavdev_se10:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(22deg, #110d3178 0%, #181131 100%);
}*/

#metavdev_se10 .nftgp_box {
  padding: 10px;
  /* background-image: linear-gradient(22deg, #c4c8ec 0%, #ffffff 100%); */
  border-radius: 12px;
  background: #fff;
  border: 1px solid #3a0863;
  box-shadow: inset 3px -2px 0px 0px #3a0863;
  display: flex;
  align-items: center;
  justify-content: center;
}

#metavdev_se10.MetaBlock_metaverse_deve .nftgp_box .nftgp_head {
  font-size: 17px;
  text-align: center;
}

#metavdev_se10 .row:nth-child(2),
#metavdev_se10 .row:nth-child(3) {
  margin-bottom: 2rem;
}

/*=========================================Metaverse DEVELOPMENT -END=================================*/

/*opensea clone style start*/

#opensea_clone_script {
  background-color: #000000;
  background-image: linear-gradient(327deg, #000000ed 0%, #001d31 74%);
  padding-bottom: 60px;
}

#opensea_clone_script .row_align {
  justify-content: center;
  display: flex;
}

#opensea_clone_script .ocs_banner,
#opensea_clone_script .ocs_nft,
#opensea_clone_script .osc_video,
#opensea_clone_script .osc_whitelabel,
#opensea_clone_script .osc_features,
#opensea_clone_script .osc_advantages,
#opensea_clone_script .osc_customcurrencies,
#opensea_clone_script .osc_business,
#opensea_clone_script .osc_build_platform,
#opensea_clone_script .osc_collaboration,
#opensea_clone_script .osc_tech_stack,
#opensea_clone_script .osc_whychoose,
#opensea_clone_script .osc_live {
  padding: 0px;
}

#opensea_clone_script .osc_video,
#opensea_clone_script .osc_whitelabel,
#opensea_clone_script .osc_features,
#opensea_clone_script .osc_advantages,
#opensea_clone_script .osc_customcurrencies,
#opensea_clone_script .osc_business,
#opensea_clone_script .osc_build_platform,
#opensea_clone_script .osc_collaboration,
#opensea_clone_script .osc_tech_stack,
#opensea_clone_script .osc_whychoose,
#opensea_clone_script .osc_live {
  margin-top: 80px;
}

@media (min-width: 767px) {
  #opensea_clone_script .ocs_banner {
    height: 600px;
    display: flex;
    align-items: center;
    /* background: url(../images/inner-pages/opensea-clone/banner.png); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
}

#opensea_clone_script .ocs_banner .title {
  font-size: 35px;
  font-weight: 800;
  color: #fff;
}

#opensea_clone_script .ocs_banner .contents {
  font-size: 16px;
  color: #fff;
  padding-top: 10px;
}

#opensea_clone_script .ocs_nft .title {
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  text-align: center;
}

#opensea_clone_script .ocs_nft .contents {
  color: #fff;
  font-size: 16px;
  position: relative;
}

#opensea_clone_script .ocs_nft .contents:before {
  content: "\25BA";
  position: absolute;
  color: #9402d6;
  left: -24px;
}

#opensea_clone_script .ocs_btn {
  padding: 10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  background: linear-gradient(126deg, #ab05ff 0%, #8600bd 98%);
  border: transparent;
  border-radius: 5px;
  box-shadow: inset -4px -4px 0px #680593;
}

#opensea_clone_script .ocs_btn:hover {
  color: #fff;
}

#opensea_clone_script .osc_video .title {
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  text-align: center;
}

#opensea_clone_script .osc_video .contents {
  font-size: 16px;
  color: #fff;
}

#opensea_clone_script .osc_whitelabel .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
}

#opensea_clone_script .osc_whitelabel .contents {
  font-size: 16px;
  color: #fff;
}

#opensea_clone_script .osc_whitelabel .osc_whitelabel_design {
  padding: 20px;
}

#opensea_clone_script .osc_whitelabel .sub_title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
}

#opensea_clone_script .osc_whitelabel ul {
  padding-left: 0px;
}

#opensea_clone_script .osc_whitelabel ul li {
  list-style: none;
  padding-top: 15px;
  color: #fff;
  font-size: 16px;
}

#opensea_clone_script .osc_whitelabel .osc_whitelabel_subdesign {
  background: linear-gradient(327deg, #000000ed 0%, #001d31 74%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px -2px 12px 2px #680593;
}

#opensea_clone_script .osc_whitelabel .whitelabel_icons {
  line-height: 1.5;
  color: #680593;
}

#opensea_clone_script .osc_features .title {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  color: #fff;
}

#opensea_clone_script .osc_features .nav-tabs {
  display: none;
}

#opensea_clone_script .osc_features .nav-item .nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 800;
}

@media (min-width: 768px) {
  #opensea_clone_script .osc_features .nav-tabs {
    display: flex;
    justify-content: center;
    border-bottom: none;
  }

  #opensea_clone_script .osc_features .card {
    border: none;
  }

  #opensea_clone_script .osc_features .card .card-header {
    display: none;
  }

  #opensea_clone_script .osc_features .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  #opensea_clone_script .osc_features .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }
}

#opensea_clone_script .osc_features .osc_features_card {
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #9b02e2;
  box-shadow: 3px 3px 0px 0px #9b02e2;
  border-radius: 0px 20px;
}

#opensea_clone_script .osc_features .osc_features_content {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: 800;
}

#opensea_clone_script .osc_features .nav-tabs > li > a {
  margin-right: 0px;
  border: 1px solid transparent;
  border-radius: 0px;
}

#opensea_clone_script .osc_features .nav-tabs > li {
  border: 1px solid #9b02e2;
}

#opensea_clone_script .osc_features .nav-tabs > li.active > a,
#opensea_clone_script .osc_features .nav-tabs > li.active > a:focus,
#opensea_clone_script .osc_features .nav-tabs > li.active > a:hover {
  background-color: #9b02e2;
  color: #fff;
  border: 1px solid #9b02e2;
}

#opensea_clone_script .osc_features .nav > li > a:focus,
#opensea_clone_script .osc_features .nav > li > a:hover {
  background: transparent;
  border: transparent;
}

#opensea_clone_script .osc_tech_stack .nftgp_sec_title {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 2rem;
  margin-top: 0;
  color: #fff;
  text-shadow: none;
  position: relative;
}

#opensea_clone_script .osc_advantages .title {
  font-size: 28px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  line-height: 1.1;
}

#opensea_clone_script .osc_advantages .main {
  width: 250px;
  height: 250px;
  border: 1px solid #ccc;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#opensea_clone_script .osc_advantages .main:before {
  content: "";
  width: 30px;
  height: 30px;
  background: #9b02e2 !important;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: opensea_circle 6s linear infinite;
}

@keyframes opensea_circle {
  0% {
    transform: rotate(0deg) translate(-125px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate(-125px) rotate(-360deg);
  }
}

#opensea_clone_script .osc_advantages ul li {
  list-style: none;
  font-size: 18px;
  color: #fff;
  margin-top: 25px;
  font-weight: bold;
}

#opensea_clone_script .osc_advantages ul li i {
  color: #9b02e2;
}

#opensea_clone_script .osc_customcurrencies .title {
  font-size: 45px;
  text-align: left;
  font-weight: 800;
  color: #fff;
  line-height: 1.1;
}

#opensea_clone_script .osc_customcurrencies .sub_title {
  text-align: left;
  color: #fff;
}

#opensea_clone_script .osc_customcurrencies .sub_content {
  display: grid;
  justify-content: center;
}

#opensea_clone_script .osc_customcurrencies .sub_content ul li {
  color: #fff;
  padding-top: 20px;
  list-style: none;
}

#opensea_clone_script .osc_customcurrencies .sub_content ul li i {
  color: #9b02e2;
}

#opensea_clone_script .osc_customcurrencies .sub_title_1 {
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  margin-top: 50px;
}

#opensea_clone_script .osc_customcurrencies .osc_timeline {
  display: grid;
  justify-content: center;
  margin-top: 35px;
}

#opensea_clone_script .osc_customcurrencies .osc_timeline .timeline_card {
  list-style: none;
  color: #000;
  background-color: #fff;
  padding: 20px;
  box-shadow: 7px 7px 2px 0px #9b02e2;
  border-radius: 0px 30px 0px 30px;
  font-weight: 600;
}

#opensea_clone_script .osc_customcurrencies .osc_timeline .timeline_card p {
  margin-bottom: 0px;
}

#opensea_clone_script .osc_customcurrencies .osc_row_align {
  display: flex;
  align-items: center;
}

#opensea_clone_script .osc_customcurrencies .timeline_margin_right {
  margin-right: 60px;
  position: relative;
}

#opensea_clone_script .osc_customcurrencies .timeline_margin_left {
  margin-left: 60px;
}

#opensea_clone_script .osc_customcurrencies .timeline_vertical {
  position: relative;
}

#opensea_clone_script .osc_customcurrencies .timeline_vertical:after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 50%;
}

#opensea_clone_script .osc_customcurrencies .timeline_counter_before {
  position: relative;
  margin-top: 40px;
  counter-increment: timeline_counter_before;
}

#opensea_clone_script .osc_customcurrencies .timeline_counter_before:before {
  content: counter(timeline_counter_before);
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 20px 0px 20px 0px;
  position: absolute;
  left: 50%;
  box-shadow: 4px 4px 0px 0px #9401d9;
  transform: translate(-50%, 50%);
  color: #000;
  font-weight: 800;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#opensea_clone_script .osc_business .title {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-align: center;
}

#opensea_clone_script .osc_business .osc_business_card {
  margin-top: 30px;
  padding: 15px;
  background: #fff;
  border-radius: 25px 0px 25px 0px;
}

#opensea_clone_script .osc_business .osc_business_card_content {
  color: #000;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
}

#opensea_clone_script .osc_build_platform .title {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #fff;
}

#opensea_clone_script .osc_collaboration .title {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

#opensea_clone_script .osc_collaboration .osc_collaboration_card {
  padding: 20px;
  background: #fff;
  border-radius: 30px 0px 30px 0px;
  box-shadow: 6px 6px 0px 0px #9401d9;
}

#opensea_clone_script .osc_collaboration .osc_collaboration_card_head {
  color: #000;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 0px;
}

#opensea_clone_script .osc_collaboration .osc_collaboration_card_content {
  text-align: center;
  margin-bottom: 0px;
  padding-top: 10px;
}

#opensea_clone_script .osc_collaboration .osc_collaboration_before {
  position: relative;
}

#opensea_clone_script .osc_collaboration .osc_collaboration_before:before {
  content: "";
  height: 5px;
  width: 100%;
  background: #9401d9;
  position: absolute;
  top: 50%;
}

#opensea_clone_script .osc_whychoose .title {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: 30px;
  margin-bottom: 30px;
}

#opensea_clone_script .osc_whychoose ul li {
  list-style: none;
  padding-top: 20px;
  display: flex;
  align-items: center;
}

#opensea_clone_script .osc_whychoose ul li i {
  color: #9401d9;
}

#opensea_clone_script .osc_whychoose ul li p {
  margin-bottom: 0px;
  padding-left: 10px;
  color: #fff;
  font-size: 16px;
}

#opensea_clone_script .osc_live .title {
  font-size: 45px;
  color: #fff;
  font-weight: 800;
  line-height: 1.1;
}

#opensea_clone_script .osc_live .osc_live_card {
  background: #fff;
  padding: 20px;
  min-height: 145px;
  margin-top: 30px;
  border-radius: 30px 0px 30px 0px;
}

#opensea_clone_script .osc_live .osc_live_card_head {
  font-weight: 800;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0px;
}

#opensea_clone_script .osc_live .osc_live_card_content {
  margin-bottom: 0px;
  text-align: center;
  padding-top: 10px;
}

#opensea_clone_script .animate_border {
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  background: #9202d2;
  overflow: hidden;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-radius: 50px;
}

#opensea_clone_script .animate_border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 15px;
  bottom: 0;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  animation: opensea_animborder 2s linear infinite;
  border-radius: 50px;
}

@keyframes opensea_animborder {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

@media only screen and (max-width: 600px) {
  .osc_advantages .osc_row_align {
    display: grid;
  }

  #opensea_clone_script .ocs_banner .title,
  #opensea_clone_script .ocs_nft .title,
  #opensea_clone_script .osc_video .title,
  #opensea_clone_script .osc_whitelabel .title,
  #opensea_clone_script .osc_features .title,
  #opensea_clone_script .osc_customcurrencies .title,
  #opensea_clone_script .osc_business .title,
  #opensea_clone_script .osc_build_platform .title,
  #opensea_clone_script
    .MetaBlock_metaverse_deve
    .nftgp_sectitle_wrap
    .nftgp_sec_title,
  #opensea_clone_script .osc_collaboration .title,
  #opensea_clone_script .osc_whychoose .title,
  #opensea_clone_script .osc_live .title {
    font-size: 25px;
    text-align: center;
    line-height: 1.1;
  }

  #opensea_clone_script .ocs_banner .contents,
  #opensea_clone_script .osc_video .contents,
  #opensea_clone_script .osc_whitelabel .contents,
  #opensea_clone_script .osc_customcurrencies .sub_title {
    text-align: justify;
  }

  #opensea_clone_script .ul_mob {
    padding-left: revert !important;
  }

  #opensea_clone_script .osc_customcurrencies .sub_title_1 {
    font-size: 20px;
  }

  #opensea_clone_script .osc_customcurrencies .timeline_counter_before:before,
  #opensea_clone_script .osc_customcurrencies .timeline_vertical:after {
    display: none;
  }

  #opensea_clone_script .osc_customcurrencies .timeline_margin_right {
    margin-right: 0px;
  }

  #opensea_clone_script .osc_customcurrencies .timeline_margin_left {
    margin-left: 0px;
  }

  #opensea_clone_script .osc_customcurrencies .timeline_counter_before {
    margin-top: 20px;
  }

  #opensea_clone_script .osc_business_mob {
    height: 300px;
    overflow: scroll;
  }

  #opensea_clone_script .osc_collaboration .osc_collaboration_card {
    margin-top: 20px;
  }

  #opensea_clone_script .osc_collaboration .osc_collaboration_before:before {
    display: none;
  }

  #opensea_clone_script .osc_btn_mob {
    text-align: center !important;
  }

  #opensea_clone_script .ocs_banner {
    padding-top: 100px;
  }

  #opensea_clone_script .ocs_btn_mob {
    text-align: center;
  }

  #opensea_clone_script .ocs_nft .contents {
    text-align: justify;
  }

  #opensea_clone_script .ocs_nft .contents:before {
    display: none;
  }

  #opensea_clone_script .animate_border {
    margin: 0 auto;
  }

  #opensea_clone_script .osc_advantages .main {
    margin-top: 20px;
  }

  #opensea_clone_script .osc_customcurrencies .osc_row_align {
    display: grid;
  }

  #opensea_clone_script .osc_features .card-header {
    padding: 5px;
    background: #fff;
    margin-top: 20px;
  }

  #opensea_clone_script .osc_features .card-body {
    height: 300px;
    overflow: scroll;
  }

  #opensea_clone_script .osc_features [aria-expanded="true"] i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  #opensea_clone_script .fa-chevron-down:before {
    content: "\f078";
  }

  #opensea_clone_script .osc_features .card-header h5 {
    font-size: 17px;
    font-weight: 800;
    padding: 0px 10px;
  }
}

/*opensea clone style end*/

/*metaverse game dev style start*/

#metaverse_game_development .row_justify {
  display: flex;
  justify-content: center;
}

#metaverse_game_development .row_align {
  display: flex;
  align-items: center;
}

#metaverse_game_development .mgd_platform,
#metaverse_game_development .mgd_nft_game {
  background: linear-gradient(
    130deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_live,
#metaverse_game_development .mgd_collabration {
  background: linear-gradient(
    326deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_element,
#metaverse_game_development .mgd_why_MetaBlock {
  background: linear-gradient(
    21deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_benefits,
#metaverse_game_development .osc_tech_stack {
  background: linear-gradient(
    323deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_advantage {
  background: linear-gradient(
    106deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_main_benefits {
  background: linear-gradient(
    178deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_build_platform {
  background: linear-gradient(
    95deg,
    rgb(18 13 53) 24%,
    rgb(40 26 55) 55%,
    rgb(73 27 77) 88%
  );
}

#metaverse_game_development .mgd_banner,
#metaverse_game_development .mgd_platform,
#metaverse_game_development .mgd_live,
#metaverse_game_development .mgd_element,
#metaverse_game_development .mgd_benefits,
#metaverse_game_development .mgd_advantage,
#metaverse_game_development .mgd_main_benefits,
#metaverse_game_development .mgd_build_platform,
#metaverse_game_development .mgd_nft_game,
#metaverse_game_development .mgd_collabration,
#metaverse_game_development .mgd_why_MetaBlock {
  padding: 0px;
}

#metaverse_game_development .mgd_platform,
#metaverse_game_development .mgd_live,
#metaverse_game_development .mgd_element,
#metaverse_game_development .mgd_benefits,
#metaverse_game_development .mgd_advantage,
#metaverse_game_development .mgd_main_benefits,
#metaverse_game_development .mgd_build_platform,
#metaverse_game_development .mgd_nft_game,
#metaverse_game_development .mgd_why_MetaBlock,
#metaverse_game_development .mgd_collabration {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 767px) {
  #metaverse_game_development .mgd_banner {
    /* background-image: url('public/images/inner-pages/metaverse-game-dev/banner.png'); */
    height: 650px;
    display: grid;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
}

#metaverse_game_development .mgd_banner .title {
  color: #fff;
  font-size: 45px;
  text-align: left;
  font-weight: 800;
}

#metaverse_game_development .mgd_banner .contents {
  color: #fff;
  text-align: left;
  font-size: 22px;
  padding-top: 15px;
  line-height: 1.3;
}

#metaverse_game_development .mgd_btn {
  padding: 10px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(
    130deg,
    rgba(245, 74, 5, 1) 16%,
    rgba(166, 34, 21, 1) 58%,
    rgb(117 0 69) 91%
  );
  border-radius: 20px 0px 20px 0px;
  border: none;
}

#metaverse_game_development .mgd_btn:hover {
  color: #fff;
}

#metaverse_game_development .mgd_heighlights {
  background: -webkit-linear-gradient(#ff4a01, #a900b7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#metaverse_game_development .game_icon {
  font-size: 20px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1, 1);
  }
}

#metaverse_game_development .mgd_platform .title {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: 30px;
}

#metaverse_game_development .mgd_platform .contents {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

#metaverse_game_development .mgd_platform .sub_title {
  color: #fff;
  font-size: 22px;
  font-weight: 800;
}

#metaverse_game_development .mgd_platform .sub_content {
  color: #fff;
  font-size: 16px;
}

#metaverse_game_development .mgd_platform_main {
  margin-top: 40px;
}

#metaverse_game_development .mgd_live .title {
  font-size: 50px;
  color: #fff;
  font-weight: 800;
  line-height: 1.1;
}

#metaverse_game_development .mgd_live ul li {
  list-style: none;
  font-size: 16px;
  color: #fff;
  padding-top: 15px;
  display: flex;
  align-items: center;
}

#metaverse_game_development .mgd_live ul li p {
  margin-bottom: 0px;
  padding-left: 10px;
  font-size: 16px;
}

#metaverse_game_development .mgd_live ul li i {
  background: -webkit-linear-gradient(#ff4a01, #a900b7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rotation 2s infinite linear;
  font-size: 20px;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

#metaverse_game_development .mgd_element .title {
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 800;
}

#metaverse_game_development .mgd_element .mgd_element_card {
  padding: 10px;
  min-height: 158px;
  margin-top: 20px;
}

#metaverse_game_development .mgd_element .mgd_element_card_head {
  color: #fff;
  font-weight: 800;
  text-align: center;
  font-size: 20px;
}

#metaverse_game_development .mgd_element .mgd_element_card_content {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

#metaverse_game_development .mgd_element .mgd_element_img {
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #b12712;
  border-radius: 50px;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -1px 0px 6px 0px #d3390c;
}

#metaverse_game_development .mgd_element .mgd_element_img:hover {
  box-shadow: 0px -1px 0px 5px #d3390c;
}

#metaverse_game_development .mgd_benefits .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #fff;
}

#metaverse_game_development .mgd_benefits .contents {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

#metaverse_game_development .mgd_benefits .mgd_benefits_card {
  padding: 15px;
  box-shadow: inset -3px -6px 16px 0px #897f7f8c, inset 9px 5px 14px 0px #202036;
  background-color: #ffffff17;
  border-radius: 5px;
  margin-top: 30px;
  min-height: 200px;
  display: grid;
  align-items: center;
}

#metaverse_game_development .mgd_benefits .mgd_benefits_card_head {
  color: #fff;
  font-weight: 800;
  font-size: 22px;
  text-align: center;
  margin-bottom: 0px;
}

#metaverse_game_development .mgd_benefits .mgd_benefits_card_content {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

#metaverse_game_development .mgd_advantage .title {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #fff;
}

#metaverse_game_development .mgd_advantage .mgd_advantage_card {
  padding: 10px;
  margin-top: 20px;
  background: linear-gradient(
    130deg,
    rgb(44 1 39) 18%,
    rgb(11 11 46) 55%,
    rgb(34 9 37) 88%
  );
  box-shadow: 8px 0px 0px 0px #f77905;
  border-radius: 20px 0px 20px 0px;
}

#metaverse_game_development .mgd_advantage .mgd_advantage_card_content {
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}

#metaverse_game_development .mgd_main_benefits .title {
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  color: #fff;
}

#metaverse_game_development .mgd_main_benefits .mgd_main_benefits_card {
  padding: 20px;
  margin-top: 40px;
  border-radius: 20px;
  min-height: 285px;
  box-shadow: -1px 1px 6px 4px #00000087;
}

#metaverse_game_development .mgd_main_benefits .mgd_main_benefits_card_head {
  color: #fff;
  font-weight: 800;
  font-size: 22px;
  text-align: center;
  padding-top: 20px;
}

#metaverse_game_development .mgd_main_benefits .mgd_main_benefits_card_content {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

#metaverse_game_development .mgd_main_benefits .bene_game_icon_main {
  position: relative;
}

#metaverse_game_development .mgd_main_benefits .bene_game_icon {
  font-size: 40px;
  /*animation: zoom-in-zoom-out 2s ease-out infinite;*/
  color: #f77905;
  transform: rotate(323deg);
  position: absolute;
  top: -15px;
  left: -14px;
}

#metaverse_game_development .mgd_build_platform .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #fff;
}

#metaverse_game_development .mgd_build_platform .mgd_build_platform_card {
  padding: 15px;
  margin-top: 20px;
  min-height: 355px;
}

#metaverse_game_development .mgd_build_platform .mgd_build_platform_card_head {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  margin-top: 30px;
}

#metaverse_game_development
  .mgd_build_platform
  .mgd_build_platform_card_content {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

#metaverse_game_development .mgd_build_platform .mgd_build_platform_icon_in {
  height: 70px;
  width: 70px;
  background: #181226;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#metaverse_game_development .mgd_build_platform .mgd_build_platform_icon_out {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: #f77905;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

#metaverse_game_development
  .mgd_build_platform
  .mgd_build_platform_icon_out:before {
  content: "";
  clip-path: polygon(100% 0, 0 0, 51% 100%);
  position: absolute;
  bottom: -20px;
  width: 30px;
  height: 30px;
  background: #f77905;
}

#metaverse_game_development .mgd_nft_game .title {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #fff;
}

#metaverse_game_development .mgd_nft_game .mgd_nft_game_design {
  background: linear-gradient(
    130deg,
    rgb(44 1 39) 18%,
    rgb(11 11 46) 55%,
    rgb(34 9 37) 88%
  );
  display: flex;
  align-items: center;
  height: 450px;
  margin-top: 50px;
  padding: 30px;
  box-shadow: -1px -1px 4px 3px #fa7903fc;
  border-radius: 0px 80px 0px 80px;
}

#metaverse_game_development .mgd_nft_game .mgd_nft_game_card_content_design {
  background: linear-gradient(
    130deg,
    rgb(44 1 39) 18%,
    rgb(11 11 46) 55%,
    rgb(34 9 37) 88%
  );
  padding: 10px;
  border: 2px solid #ff6e00;
}

#metaverse_game_development .mgd_nft_game .mgd_nft_game_card_content {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 0px;
}

#metaverse_game_development .mgd_nft_game .owl-dots {
  display: none;
}

#metaverse_game_development .mgd_nft_game .owl-carousel .owl-item img {
  width: 100% !important;
}

#metaverse_game_development .mgd_nft_game .owl-nav {
  position: absolute;
  top: -56px;
  right: 67px;
}

#metaverse_game_development .mgd_nft_game .owl-theme .owl-nav [class*="owl-"] {
  background: #ff6a00;
  font-size: 20px;
}

#metaverse_game_development
  .mgd_nft_game
  .owl-theme
  .owl-nav
  [class*="owl-"]:hover {
  background: #ff6a00;
}

#metaverse_game_development .mgd_collabration .title {
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 800;
}

#metaverse_game_development .mgd_collabration .mgd_collabration_card {
  padding: 40px 20px;
  margin-top: 20px;
  border-top: 2px dotted #ff7300;
  border-left: 2px dotted #ff7300;
  border-right: 2px dotted #ff7300;
  position: relative;
  border-radius: 20px;
}

#metaverse_game_development .mgd_collabration .mgd_collabration_card_head {
  color: #fff;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  background: #ff7300;
  padding: 7px;
  position: absolute;
  top: 32px;
  left: 0;
}

#metaverse_game_development .mgd_collabration .mgd_collabration_card_content {
  text-align: left;
  font-size: 16px;
  color: #fff;
  margin-top: 45px;
}

#metaverse_game_development .mgd_why_MetaBlock .title {
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  margin-bottom: 30px;
}

#metaverse_game_development .mgd_why_MetaBlock ul li {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

#metaverse_game_development .mgd_why_MetaBlock ul li i {
  color: #ff7300;
  font-size: 18px;
}

#metaverse_game_development .mgd_why_MetaBlock ul li p {
  color: #fff;
  margin-bottom: 0px;
  font-size: 16px;
  padding-left: 15px;
}

#metaverse_game_development .mgd_why_MetaBlock .mgd_why_MetaBlock_ul {
  display: grid;
  justify-content: flex-end;
}

#metaverse_game_development .metaverse_game_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
}

#metaverse_game_development .mgf_line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

#metaverse_game_development .line {
  width: 40px;
  height: 4px;
  background: linear-gradient(45deg, #e3323b, #c81a75);
  display: block;
  margin-left: 10px;
  border-radius: 26px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
  #metaverse_game_development .row_justify {
    display: initial;
  }

  #metaverse_game_development .row_align {
    display: initial;
  }

  .mgd_banner {
    background: linear-gradient(
      95deg,
      rgb(18 13 53) 24%,
      rgb(40 26 55) 55%,
      rgb(73 27 77) 88%
    );
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #metaverse_game_development .mgd_banner .title {
    font-size: 30px;
    text-align: center;
  }

  #metaverse_game_development .mgd_banner .contents {
    text-align: center;
  }

  .mgd_btn_mob {
    text-align: center;
  }

  #metaverse_game_development .mgd_platform .title,
  #metaverse_game_development .mgd_live .title,
  #metaverse_game_development .mgd_element .title,
  #metaverse_game_development .mgd_benefits .title,
  #metaverse_game_development .mgd_build_platform .title,
  #metaverse_game_development .mgd_nft_game .title,
  .MetaBlock_metaverse_deve .nftgp_sectitle_wrap .nftgp_sec_title,
  #metaverse_game_development .mgd_collabration .title,
  #metaverse_game_development .mgd_why_MetaBlock .title {
    font-size: 25px;
    line-height: 1.3;
  }

  #metaverse_game_development .mgd_platform .contents {
    text-align: justify;
  }

  #metaverse_game_development .mgd_platform .sub_title,
  #metaverse_game_development .mgd_platform .sub_content {
    text-align: center;
  }

  #metaverse_game_development .mgd_build_platform .mgd_build_platform_card {
    margin-top: 0px;
    min-height: initial;
  }
}

/*metaverse game dev style end*/

/*celebrity nft marketplace style start*/

#celebrity_nft_marketplace_dev {
  background: linear-gradient(45deg, #2f0f37, #1d0b3c, #1a0817);
  padding-bottom: 80px;
}

#celebrity_nft_marketplace_dev .cnmd_banner,
#celebrity_nft_marketplace_dev .cnmd_business,
#celebrity_nft_marketplace_dev .cnmd_superlative,
#celebrity_nft_marketplace_dev .cnmd_platform,
#celebrity_nft_marketplace_dev .cnmd_top_celebrity,
#celebrity_nft_marketplace_dev .cnmd_features,
#celebrity_nft_marketplace_dev .cnmd_benefits,
#celebrity_nft_marketplace_dev .cnmd_revenue_stream,
#celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft,
#celebrity_nft_marketplace_dev .cnmd_process,
#celebrity_nft_marketplace_dev .cnmd_collaboration,
#celebrity_nft_marketplace_dev .MetaBlock_metaverse_deve,
#celebrity_nft_marketplace_dev .cnmd_turn,
#celebrity_nft_marketplace_dev .cnmd_why_choose {
  padding: 0px;
}

#celebrity_nft_marketplace_dev .cnmd_superlative,
#celebrity_nft_marketplace_dev .cnmd_platform,
#celebrity_nft_marketplace_dev .cnmd_top_celebrity,
#celebrity_nft_marketplace_dev .cnmd_features,
#celebrity_nft_marketplace_dev .cnmd_benefits,
#celebrity_nft_marketplace_dev .cnmd_revenue_stream,
#celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft,
#celebrity_nft_marketplace_dev .cnmd_process,
#celebrity_nft_marketplace_dev .cnmd_collaboration,
#celebrity_nft_marketplace_dev .MetaBlock_metaverse_deve,
#celebrity_nft_marketplace_dev .cnmd_turn,
#celebrity_nft_marketplace_dev .cnmd_why_choose {
  margin-top: 50px;
}

@media (min-width: 767px) {
  #celebrity_nft_marketplace_dev .cnmd_banner {
    /* background: url(public/images/inner-pages/celebrity-nft-marketplace/banner.jpg); */
    height: 600px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
}

#celebrity_nft_marketplace_dev .cnmd_banner .title {
  font-size: 40px;
  text-align: left;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 2px 2px #ff5e00;
}

#celebrity_nft_marketplace_dev .cnmd_banner .contents {
  font-size: 18px;
  text-align: left;
  color: #fff;
  padding-top: 10px;
  line-height: 1.6;
}

#celebrity_nft_marketplace_dev .row_justify {
  display: flex;
  justify-content: center;
}

#celebrity_nft_marketplace_dev .row_align {
  display: flex;
  align-items: center;
}

#celebrity_nft_marketplace_dev .cnmd_btn {
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  color: #fff;
  background: #ff5e00;
  border-radius: 5px;
}

#celebrity_nft_marketplace_dev .cnmd_business .title {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

#celebrity_nft_marketplace_dev .cnmd_business .contents {
  color: #fff;
  font-size: 17px;
}

#celebrity_nft_marketplace_dev .cnmd_superlative .title {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
}

#celebrity_nft_marketplace_dev .cnmd_superlative .contents {
  color: #fff;
  font-size: 17px;
  text-align: justify;
}

#celebrity_nft_marketplace_dev .cnmd_platform .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
}

#celebrity_nft_marketplace_dev .cnmd_platform .contents {
  text-align: center;
  color: #fff;
  font-size: 17px;
}

#celebrity_nft_marketplace_dev .cnmd_platform .cnmd_platform_card {
  padding: 20px 10px;
  background: #ffffff0f;
  margin-top: 35px;
  min-height: 280px;
  border: 1px solid #ffffff1c;
  border-radius: 10px;
  min-height: 335px;
}

#celebrity_nft_marketplace_dev
  .cnmd_platform
  .cnmd_platform_icons_design:after {
  top: 0%;
  right: -17px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-color: rgba(55, 139, 52, 0);
  border-left-color: #ff5e00;
  border-width: 9px;
  margin-left: 0px;
}

#celebrity_nft_marketplace_dev
  .cnmd_platform
  .cnmd_platform_icons_design:before {
  top: 0%;
  left: -17px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-color: rgba(55, 139, 52, 0);
  border-right-color: #ff5e00;
  border-width: 9px;
  margin-left: 0px;
}

#celebrity_nft_marketplace_dev .cnmd_platform .cnmd_platform_card_head {
  color: #fff;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 0px;
  padding-top: 10px;
}

#celebrity_nft_marketplace_dev .cnmd_platform .cnmd_platform_card_content {
  color: #fff;
  text-align: center;
  font-size: 17px;
  padding-top: 10px;
}

#celebrity_nft_marketplace_dev .cnmd_platform .cnmd_platform_icons_design {
  height: 70px;
  width: 70px;
  border-radius: 0px 0px 50% 50%;
  background: #ff5e00;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

#celebrity_nft_marketplace_dev .cnmd_top_celebrity .title {
  text-align: left;
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
}

#celebrity_nft_marketplace_dev .cnmd_top_celebrity ul {
  padding-left: 0px;
}

#celebrity_nft_marketplace_dev .cnmd_top_celebrity ul li:before {
  content: " \25C9";
  position: absolute;
  color: #ff5e00;
  left: -20px;
}

#celebrity_nft_marketplace_dev .cnmd_top_celebrity ul li {
  list-style: none;
  color: #fff;
  font-size: 17px;
  padding-top: 15px;
  line-height: 1.5;
  position: relative;
}

#celebrity_nft_marketplace_dev .cnmd_top_celebrity .cnmd_top_celebrity_card {
  background: #2f1a46b5;
  padding: 15px;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 4px 0px 0px #fb6711;
  border: 1px solid #e1d9e914;
  position: relative;
}

#celebrity_nft_marketplace_dev .cnmd_top_celebrity .cnmd_top_celebrity_counter {
  counter-reset: cnmd_counter_increment;
}

#celebrity_nft_marketplace_dev
  .cnmd_top_celebrity
  .cnmd_top_celebrity_card:before {
  counter-increment: cnmd_counter_increment;
  content: counter(cnmd_counter_increment);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: #ff5e00;
  position: absolute;
  top: 8px;
  left: -15px;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

#celebrity_nft_marketplace_dev
  .cnmd_top_celebrity
  .cnmd_top_celebrity_card_content {
  text-align: center;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
}

#celebrity_nft_marketplace_dev .cnmd_features .title {
  font-weight: 800;
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.2;
}

#celebrity_nft_marketplace_dev .cnmd_features .cnmd_features_card {
  margin-top: 30px;
}

#celebrity_nft_marketplace_dev .cnmd_features .cnmd_features_card_title {
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  color: #fff;
  margin-bottom: 0px;
  text-transform: capitalize;
}

#celebrity_nft_marketplace_dev .cnmd_features .cnmd_features_card_title:before {
  content: "|";
  color: #ff5e00;
}

#celebrity_nft_marketplace_dev .cnmd_features .cnmd_features_card_content {
  font-size: 17px;
  color: #fff;
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .title {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .nav-tabs {
  display: none;
}

@media (min-width: 768px) {
  #celebrity_nft_marketplace_dev .cnmd_benefits .nav-tabs {
    display: flex;
    border-bottom: initial;
    justify-content: center;
  }

  #celebrity_nft_marketplace_dev .cnmd_benefits .card {
    border: none;
  }

  #celebrity_nft_marketplace_dev .cnmd_benefits .card .card-header {
    display: none;
  }

  #celebrity_nft_marketplace_dev .cnmd_benefits .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  #celebrity_nft_marketplace_dev .cnmd_benefits .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }
}

#celebrity_nft_marketplace_dev .cnmd_benefits .nav-link {
  color: #fff;
  font-weight: 800;
  font-size: 20px;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .nav-tabs > li.active > a,
#celebrity_nft_marketplace_dev .cnmd_benefits .nav-tabs > li.active > a:focus,
#celebrity_nft_marketplace_dev .cnmd_benefits .nav-tabs > li.active > a:hover {
  background-color: #fb6711;
  color: #fff;
  border: none;
  border-radius: 40px 0px 40px 0px;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .nav > li > a:focus,
#celebrity_nft_marketplace_dev .cnmd_benefits .nav > li > a:hover {
  background-color: transparent;
  color: #fff;
  border: none;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .cnmd_benefits_card {
  padding: 10px;
  margin-top: 20px;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .cnmd_benefits_card_head {
  font-weight: 800;
  text-align: center;
  font-size: 19px;
  color: #fff;
  margin-bottom: 0px;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .cnmd_benefits_card_content {
  text-align: center;
  font-size: 17px;
  color: #fff;
  padding-top: 10px;
}

#celebrity_nft_marketplace_dev .cnmd_benefits .cnmd_benefits_card_icon_design {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  border: 2px solid #fb6711;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 15px;
  box-shadow: 0px 0px 4px 1px #fb6711;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream .title {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream .contents {
  font-size: 17px;
  color: #fff;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream ul li {
  list-style: none;
  color: #fff;
  font-size: 17px;
  padding-top: 20px;
  position: relative;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream ul li:before {
  content: "\276F";
  position: absolute;
  left: -15px;
  color: #ff5e00;
  font-weight: 800;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft .title {
  font-weight: 800;
  font-size: 30px;
  color: #fff;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft ul {
  padding-left: 0px;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft ul li {
  list-style: none;
  color: #fff;
  padding-top: 25px;
  font-size: 17px;
  position: relative;
}

#celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft ul li:before {
  content: "\25B6";
  position: absolute;
  left: -20px;
  color: #ff5e00;
}

#celebrity_nft_marketplace_dev .cnmd_process .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
  font-weight: 800;
}

#celebrity_nft_marketplace_dev .cnmd_process .contents {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

#celebrity_nft_marketplace_dev .cnmd_process .cnmd_process_card {
  display: flex;
  min-height: 295px;
  margin-top: 30px;
  border: 1px solid #ffffff38;
}

#celebrity_nft_marketplace_dev .cnmd_process .cnmd_process_card_content {
  color: #fff;
  font-size: 17px;
}

#celebrity_nft_marketplace_dev .cnmd_process .cnmd_process_card_head {
  color: #fff;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
}

#celebrity_nft_marketplace_dev .cnmd_process .cnmd_process_card_head_des {
  background: #fb6711;
  padding: 20px;
  border-radius: 0% 10px 90% 0px;
  width: 165px;
  height: 165px;
}

#celebrity_nft_marketplace_dev .cnmd_process .cnmd_process_content_des {
  padding: 10px 15px;
  text-align: justify;
}

#celebrity_nft_marketplace_dev .cnmd_collaboration .title {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #fff;
}

#celebrity_nft_marketplace_dev .cnmd_collaboration .cnmd_collaboration_card {
  padding: 10px;
  margin-top: 20px;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_card_head {
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 0px;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_card_head:before {
  content: "|";
  color: #fb6711;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_card_head:after {
  content: "|";
  color: #fb6711;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_card_content {
  text-align: center;
  font-size: 17px;
  color: #fff;
  padding-top: 10px;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_card_icon_design {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  border: 1px solid #fb6711;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 15px;
  box-shadow: 0px 0px 3px 2px #fb6711;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_card_round {
  width: 20px;
  height: 20px;
  background: #fb6711;
  border-radius: 50px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

#celebrity_nft_marketplace_dev .cnmd_collaboration .cnmd_collaboration_before {
  position: relative;
}

#celebrity_nft_marketplace_dev
  .cnmd_collaboration
  .cnmd_collaboration_before:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: absolute;
  right: 0;
  top: 130px;
  left: 0;
}

#celebrity_nft_marketplace_dev .cnmd_turn .title {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: 30px;
}

#celebrity_nft_marketplace_dev .cnmd_turn .cnmd_turn_card {
  padding: 15px 10px;
  margin-top: 20px;
  border: 1px dotted #ff5e00;
  min-height: 170px;
}

#celebrity_nft_marketplace_dev .cnmd_turn .cnmd_turn_card_head {
  color: #fff;
  font-weight: 800;
  text-align: center;
  font-size: 20px;
}

#celebrity_nft_marketplace_dev .cnmd_turn .cnmd_turn_card_content {
  color: #fff;
  text-align: center;
  font-size: 17px;
  min-height: 105px;
}

#celebrity_nft_marketplace_dev .cnmd_why_choose .title {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: 30px;
}

#celebrity_nft_marketplace_dev .cnmd_why_choose .contents {
  text-align: center;
  font-size: 17px;
  color: #fff;
}

#celebrity_nft_marketplace_dev .platform_icons {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

#celebrity_nft_marketplace_dev .platform_icons_new {
  width: 50px;
  height: 50px;
  object-fit: none;
}

#celebrity_nft_marketplace_dev .process_icons_new {
  height: 70px;
  width: 70px;
  object-fit: none;
  margin: 10px;
}

#celebrity_nft_marketplace_dev .cnmd_line {
  width: 100px;
  height: 2px;
  background: #ff5e00;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  #celebrity_nft_marketplace_dev .cnmd_banner {
    height: 500px;
    display: flex;
    align-items: center;
  }

  #celebrity_nft_marketplace_dev .cnmd_banner .title,
  #celebrity_nft_marketplace_dev .cnmd_business .title,
  #celebrity_nft_marketplace_dev .cnmd_superlative .title,
  #celebrity_nft_marketplace_dev .cnmd_platform .title,
  #celebrity_nft_marketplace_dev .cnmd_top_celebrity .title,
  #celebrity_nft_marketplace_dev .cnmd_features .title,
  #celebrity_nft_marketplace_dev .cnmd_benefits .title,
  #celebrity_nft_marketplace_dev .cnmd_revenue_stream .title,
  #celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft .title,
  #celebrity_nft_marketplace_dev .cnmd_process .title,
  #celebrity_nft_marketplace_dev .cnmd_collaboration .title,
  #celebrity_nft_marketplace_dev .cnmd_turn .title,
  #celebrity_nft_marketplace_dev .cnmd_why_choose .title {
    font-size: 25px;
    text-align: center;
    line-height: 1.1;
  }

  #celebrity_nft_marketplace_dev .cnmd_banner .contents,
  #celebrity_nft_marketplace_dev .cnmd_revenue_stream .contents {
    text-align: center;
  }

  #celebrity_nft_marketplace_dev .cnmd_btn_mob {
    text-align: center;
  }

  #celebrity_nft_marketplace_dev .cnmd_business .contents,
  #celebrity_nft_marketplace_dev .cnmd_why_choose .contents {
    text-align: justify;
  }

  #celebrity_nft_marketplace_dev .cnmd_line {
    margin: 0 auto;
  }

  #celebrity_nft_marketplace_dev .cnmd_platform .cnmd_platform_card {
    min-height: auto;
  }

  #celebrity_nft_marketplace_dev .row_align {
    display: grid;
  }

  #celebrity_nft_marketplace_dev
    .cnmd_collaboration
    .cnmd_collaboration_before:before {
    display: none;
  }

  #celebrity_nft_marketplace_dev
    .cnmd_collaboration
    .cnmd_collaboration_card_round {
    display: none;
  }

  #celebrity_nft_marketplace_dev .cnmd_top_celebrity ul li:before,
  #celebrity_nft_marketplace_dev .cnmd_revenue_stream_nft ul li:before {
    display: none;
  }

  #celebrity_nft_marketplace_dev .cnmd_benefits .card-header {
    background: #fff;
    padding: 8px;
  }

  #celebrity_nft_marketplace_dev .cnmd_benefits .card-header h5 {
    font-weight: 800;
    font-size: 18px;
  }

  #celebrity_nft_marketplace_dev .cnmd_benefits .card-body {
    height: 250px;
    overflow: scroll;
  }
}

/*celebrity nft marketplace style end*/

/*cryptocurrency exchange script new style start*/

.cryptocurrency_exchange_script {
  background: #fff;
  padding-bottom: 50px;
}

.cryptocurrency_exchange_script .cesd_banner,
.cryptocurrency_exchange_script .cesd_what,
.cryptocurrency_exchange_script .cesd_whitelabel,
.cryptocurrency_exchange_script .cesd_scope,
.cryptocurrency_exchange_script .cesd_workflow,
.cryptocurrency_exchange_script .cesd_various_business,
.cryptocurrency_exchange_script .cesd_features,
.cryptocurrency_exchange_script .cesd_corefeatures,
.cryptocurrency_exchange_script .cesd_clone,
.cryptocurrency_exchange_script .cesd_platform,
.cryptocurrency_exchange_script .cesd_collaboration,
.cryptocurrency_exchange_script .cesd_project {
  padding: 0px;
}

.cryptocurrency_exchange_script .cesd_whitelabel,
.cryptocurrency_exchange_script .cesd_scope,
.cryptocurrency_exchange_script .cesd_workflow,
.cryptocurrency_exchange_script .cesd_various_business,
.cryptocurrency_exchange_script .cesd_features,
.cryptocurrency_exchange_script .cesd_corefeatures,
.cryptocurrency_exchange_script .cesd_clone,
.cryptocurrency_exchange_script .cesd_platform,
.cryptocurrency_exchange_script .cesd_collaboration,
.cryptocurrency_exchange_script .cesd_project {
  /* margin-top: 70px;*/
  padding-top: 60px;
}

.cryptocurrency_exchange_script .row_justify {
  display: flex;
  justify-content: center;
}

.cryptocurrency_exchange_script .row_align {
  display: flex;
  align-items: center;
}

.cryptocurrency_exchange_script .cesd_banner {
  background: #fff;
  height: 100%;
  padding-top: 130px;
  padding-bottom: 80px;
}

.cryptocurrency_exchange_script .cesd_banner .title {
  font-weight: 800;
  text-align: left;
  font-size: 35px;
  text-transform: uppercase;
  color: #000;
}

.cryptocurrency_exchange_script .cesd_banner .contents {
  font-size: 18px;
  text-align: left;
  padding-top: 10px;
  color: #000;
}

.cryptocurrency_exchange_script .cesd_btn {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  background: #9d0035;
  border-radius: 5px;
  margin-top: 30px;
}

.cryptocurrency_exchange_script .cesd_banner .cesd_banner_card {
  border: 1px solid #000;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
  width: fit-content;
}

.cryptocurrency_exchange_script .cesd_banner .cesd_banner_card_main {
  width: auto;
}

.cryptocurrency_exchange_script .cesd_what .title {
  text-align: center;
  font-weight: 800;
  color: #000;
  font-size: 30px;
  text-transform: uppercase;
}

.cryptocurrency_exchange_script .cesd_what .contents {
  color: #000;
  font-size: 16px;
  text-align: center;
}

.cryptocurrency_exchange_script .cesd_whitelabel {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}

.cryptocurrency_exchange_script .cesd_whitelabel .title {
  font-weight: 800;
  color: #000;
  font-size: 30px;
  text-transform: uppercase;
}

.cryptocurrency_exchange_script .cesd_whitelabel .contents {
  color: #000;
  font-size: 16px;
  text-align: justify;
}

.cryptocurrency_exchange_script .cesd_scope .title {
  font-size: 35px;
  color: #000;
  font-weight: 800;
  text-align: left;
  line-height: 1.1;
  text-transform: uppercase;
}

.cryptocurrency_exchange_script .cesd_scope ul li {
  list-style: none;
  color: #000;
  font-size: 16px;
  padding-top: 15px;
  position: relative;
}

.cryptocurrency_exchange_script .cesd_scope ul li:before {
  content: "\25B6";
  color: #1ef4f4;
  position: absolute;
  left: -20px;
}

.cryptocurrency_exchange_script .cesd_workflow {
  background: #fff;
  padding-bottom: 30px;
}

.cryptocurrency_exchange_script .cesd_workflow .title {
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  color: #000;
  padding-bottom: 20px;
}

.cryptocurrency_exchange_script .cesd_various_business .title {
  color: #000;
  font-weight: 800;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.1;
}

.cryptocurrency_exchange_script .cesd_various_business .contents {
  color: #000;
  text-align: center;
  font-size: 16px;
}

.cryptocurrency_exchange_script
  .cesd_various_business
  .cesd_various_business_card {
  margin-top: 30px;
  min-height: 408px;
  border: 1px solid #ae2a54;
  padding: 20px;
  box-shadow: 0px 0px 4px 2px #ae2a54;
}

.cryptocurrency_exchange_script
  .cesd_various_business
  .cesd_various_business_card_head {
  color: #000;
  text-transform: capitalize;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
  padding-top: 20px;
}

.cryptocurrency_exchange_script
  .cesd_various_business
  .cesd_various_business_card_content {
  color: #000;
  text-align: justify;
  font-size: 16px;
}

.cryptocurrency_exchange_script
  .cesd_various_business
  .cesd_various_business_icon_design {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d0035;
  box-shadow: 0px 0px 6px 1px #9d0035;
  background: #ae2a54;
}

.cryptocurrency_exchange_script .cesd_features .title {
  text-align: center;
  font-weight: 800;
  color: #000;
  font-size: 30px;
  text-transform: uppercase;
}

.cryptocurrency_exchange_script .cesd_features .contents {
  color: #000;
  text-align: center;
  font-size: 16px;
}

.cryptocurrency_exchange_script .cesd_features .cesd_features_card {
  padding: 10px;
  margin-top: 30px;
}

.cryptocurrency_exchange_script .cesd_features .cesd_features_card_head {
  color: #000;
  font-weight: 800;
  font-size: 20px;
  text-transform: capitalize;
}

.cryptocurrency_exchange_script .cesd_features .cesd_features_card_head:before {
  content: "|";
  color: #9d0035;
}

.cryptocurrency_exchange_script .cesd_features .cesd_features_card_content {
  color: #000;
  font-size: 16px;
}

.cryptocurrency_exchange_script .cesd_features .cesd_corefeatures {
  background: linear-gradient(
    144deg,
    #16a7af,
    transparent,
    transparent,
    transparent,
    transparent,
    #16a7af
  );
  padding-bottom: 30px;
}

.cryptocurrency_exchange_script .cesd_corefeatures .title {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #000;
  text-transform: uppercase;
}

.cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs {
  display: none;
}

.cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs > li > a {
  color: #000;
  font-weight: 800;
  font-size: 20px;
}

@media (min-width: 768px) {
  .cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs {
    display: flex;
    border-bottom: none;
    justify-content: center;
    border: 1px solid #15e9f4;
    width: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs > li {
    border-left: 1px solid #15e9f4;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs > li > a:hover {
    background: none;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs > li > a {
    margin-right: 0px;
    border: none;
    border-radius: 0px;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus,
  .cryptocurrency_exchange_script
    .cesd_corefeatures
    .nav-tabs
    > li.active
    > a:hover {
    background-color: #16a7af;
    color: #fff;
    border: 0px;
    border-bottom-color: #fff;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .card {
    border: none;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .card .card-header {
    display: none;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .cryptocurrency_exchange_script .cesd_corefeatures .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }
}

.cryptocurrency_exchange_script .cesd_corefeatures .cesd_corefeatures_card {
  margin-top: 20px;
  border: 1px solid #16a7af;
  padding: 13px;
  border-radius: 30px 0px 30px 0px;
  box-shadow: 3px 3px 0px #16a7af;
  min-height: 212px;
}

.cryptocurrency_exchange_script
  .cesd_corefeatures
  .cesd_corefeatures_card_head {
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 10px;
  padding-top: 10px;
}

.cryptocurrency_exchange_script
  .cesd_corefeatures
  .cesd_corefeatures_card_content {
  text-align: center;
  font-size: 16px;
  color: #000;
}

.cryptocurrency_exchange_script .cesd_clone .title {
  font-weight: 800;
  text-align: left;
  font-size: 30px;
  text-transform: uppercase;
  color: #000;
  line-height: 1.1;
}

.cryptocurrency_exchange_script .cesd_clone .contents {
  text-align: left;
  color: #000;
  font-size: 16px;
}

.cryptocurrency_exchange_script .cesd_clone .cesd_clone_card {
  padding: 20px;
}

.cryptocurrency_exchange_script .cesd_clone .cesd_clone_card_head {
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}

.cryptocurrency_exchange_script .cesd_clone .cesd_clone_card_content {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.cryptocurrency_exchange_script .cesd_clone .item {
  background: #001b1d;
  /* box-shadow: 1px 2px 1px 1px #fff; */
  border: 1px solid #053e42;
  border-radius: 20px;
}

.cryptocurrency_exchange_script .cesd_clone .cesd_clone_card_icon_design {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #d40042;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0px 0px 4px 1px #d80043;
}

.cryptocurrency_exchange_script .cesd_clone .owl-dots {
  display: none;
}

.cryptocurrency_exchange_script .cesd_clone .owl-nav {
  position: absolute;
  left: 47%;
  bottom: 30px;
}

.cryptocurrency_exchange_script
  .cesd_clone
  .owl-theme
  .owl-nav
  [class*="owl-"] {
  color: #fff;
  font-size: 20px;
  margin: 5px;
  padding: 4px 10px;
  background: #c7094d;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.cryptocurrency_exchange_script .cesd_platform {
  background: #fff;
  padding-bottom: 30px;
}

.cryptocurrency_exchange_script .cesd_platform .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
}

.cryptocurrency_exchange_script .cesd_platform .cesd_platform_card {
  padding: 20px 30px;
}

.cryptocurrency_exchange_script .cesd_platform .cesd_platform_card_head {
  color: #000;
  font-size: 20px;
  font-weight: 800;
}

.cryptocurrency_exchange_script .cesd_platform .cesd_platform_card_content {
  color: #000;
  font-size: 16px;
}

.cryptocurrency_exchange_script .timeline_after {
  position: relative;
}

.cryptocurrency_exchange_script .timeline_after:after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0px;
  background: #000;
  left: 50%;
  right: 50%;
  width: 1px;
}

.cryptocurrency_exchange_script .timeline_counter_after {
  position: relative;
  overflow: hidden;
  counter-increment: timeline_counter_before;
}

.cryptocurrency_exchange_script .timeline_counter_after:after {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #0eb4bd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
  content: counter(timeline_counter_before);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: 800;
}

.cryptocurrency_exchange_script .timeline_counter_after:before {
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
  border: 2px solid #fff;
  border-left-color: #c7094d;
  border-right-color: #c7094d;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.cryptocurrency_exchange_script .cesd_collaboration {
  background: #fff;
  margin-bottom: 35px;
}

.cryptocurrency_exchange_script .cesd_collaboration .title {
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #000;
}

.cryptocurrency_exchange_script .cesd_collaboration .cesd_collaboration_card {
  margin-top: 35px;
  padding: 20px;
  background: linear-gradient(4deg, #b11848, #e01a58, #bd194c);
  border: 1px dotted #ffffff24;
  border-radius: 20px 0px 20px 0px;
}

.cryptocurrency_exchange_script
  .cesd_collaboration
  .cesd_collaboration_card_head {
  color: #fff;
  font-weight: 800;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.cryptocurrency_exchange_script
  .cesd_collaboration
  .cesd_collaboration_card_content {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.cryptocurrency_exchange_script .cesd_project {
  background: #fff;
}

.cryptocurrency_exchange_script .cesd_project .title {
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  color: #000;
  font-weight: 800;
}

.cryptocurrency_exchange_script .cesd_project .cesd_project_card {
  padding: 10px;
  margin-top: 30px;
}

.cryptocurrency_exchange_script .cesd_project .cesd_project_card_head {
  color: #000;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 10px;
}

.cryptocurrency_exchange_script .cesd_project .cesd_project_card_content {
  color: #000;
  text-align: center;
  font-size: 16px;
}

.cryptocurrency_exchange_script .cesd_project .cesd_project_card_icon_design {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background: #16b4bd;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0eb4bda8;
  box-shadow: 0px 0px 4px 3px #16b4bd;
}

.cryptocurrency_exchange_script .project_after_line {
  position: relative;
}

.cryptocurrency_exchange_script .project_after_line:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #0eb4bd;
  position: absolute;
  top: 140px;
}

.cryptocurrency_exchange_script .project_after_round {
  width: 20px;
  height: 20px;
  background: #0eb4bd;
  border-radius: 50px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cryptocurrency_exchange_script .cesd_business_icon {
  height: 60px;
  width: 60px;
  object-fit: none;
}

.cryptocurrency_exchange_script .cesd_clone_icon {
  width: 90px;
  height: 90px;
  object-fit: none;
}

.cryptocurrency_exchange_script .cesd_project_icon {
  height: 50px;
  width: 50px;
  object-fit: none;
}

.cryptocurrency_exchange_script .cesd_line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cryptocurrency_exchange_script .line {
  width: 40px;
  height: 4px;
  background: linear-gradient(45deg, #e3323b, #c81a75);
  display: block;
  margin-left: 10px;
  border-radius: 26px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
  .cryptocurrency_exchange_script .row_justify {
    display: initial;
  }

  .cryptocurrency_exchange_script .row_align {
    display: initial;
  }

  .cryptocurrency_exchange_script .cesd_banner .title,
  .cryptocurrency_exchange_script .cesd_whitelabel .title,
  .cryptocurrency_exchange_script .cesd_scope .title,
  .cryptocurrency_exchange_script .cesd_various_business .title,
  .cryptocurrency_exchange_script .cesd_features .title,
  .cryptocurrency_exchange_script .cesd_corefeatures .title,
  .cryptocurrency_exchange_script .cesd_clone .title,
  .cryptocurrency_exchange_script .cesd_platform .title,
  .cryptocurrency_exchange_script .cesd_collaboration .title,
  .cryptocurrency_exchange_script .cesd_project .title {
    font-size: 25px;
    text-align: center;
    line-height: 1.1;
  }

  .cryptocurrency_exchange_script .cesd_banner .contents {
    text-align: center;
  }

  .cesd_btn_align {
    text-align: center;
  }

  .cryptocurrency_exchange_script .cesd_banner {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .cryptocurrency_exchange_script .cesd_what .title {
    font-size: 25px;
  }

  .cryptocurrency_exchange_script .cesd_what .contents {
    text-align: justify;
  }

  .cryptocurrency_exchange_script .cesd_whitelabel,
  .cryptocurrency_exchange_script .cesd_scope,
  .cryptocurrency_exchange_script .cesd_workflow,
  .cryptocurrency_exchange_script .cesd_various_business,
  .cryptocurrency_exchange_script .cesd_features,
  .cryptocurrency_exchange_script .cesd_corefeatures,
  .cryptocurrency_exchange_script .cesd_clone,
  .cryptocurrency_exchange_script .cesd_platform,
  .cryptocurrency_exchange_script .cesd_collaboration,
  .cryptocurrency_exchange_script .cesd_project {
    margin-top: 0px;
  }

  .cryptocurrency_exchange_script .cesd_features .cesd_features_card {
    margin-top: 0px;
  }

  .cryptocurrency_exchange_script .cesd_corefeatures .cesd_corefeatures_card {
    min-height: initial;
  }

  .cryptocurrency_exchange_script .cesd_btn {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .cryptocurrency_exchange_script .cesd_clone .owl-nav {
    left: 36%;
  }

  .cryptocurrency_exchange_script .timeline_counter_after:before {
    display: none;
  }

  .cryptocurrency_exchange_script .timeline_counter_after:after {
    display: none;
  }

  .cryptocurrency_exchange_script .timeline_after:after {
    display: none;
  }

  .cryptocurrency_exchange_script .cesd_platform .cesd_platform_card {
    padding: 0px;
  }

  .cryptocurrency_exchange_script .project_after_round {
    display: none;
  }

  .cryptocurrency_exchange_script .project_after_line:after {
    display: none;
  }

  .cryptocurrency_exchange_script .cesd_project .cesd_project_card {
    margin-top: 0px;
  }

  .cryptocurrency_exchange_script .card-header {
    background: #fff;
    margin-top: 10px;
  }

  .cryptocurrency_exchange_script .card-header h5 {
    font-size: 20px;
    font-weight: 800;
  }

  .cryptocurrency_exchange_script .card-body {
    height: 200px;
    overflow: scroll;
  }

  .cryptocurrency_exchange_script .cesd_line {
    display: none;
  }

  .cryptocurrency_exchange_script .line {
    display: none;
  }
}

/*cryptocurrency exchange script new style end*/

/* Blockchain Game Devlopment new style starts */

.blockchain_game_dev .row_justify {
  display: flex;
  justify-content: center;
}

.blockchain_game_dev .bgd_banner {
  padding: 50px;
}

.blockchain_game_dev .bgd_top_notch,
.blockchain_game_dev .bgd_services,
.blockchain_game_dev .bgd_game,
.blockchain_game_dev .bgd_benefits,
.blockchain_game_dev .bgd_platform,
.blockchain_game_dev .bgd_usecase,
.blockchain_game_dev .bgd_process,
.blockchain_game_dev .bgd_tech,
.blockchain_game_dev .bgd_stand {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 50px;
}

.blockchain_game_dev .row_align {
  display: flex;
  align-items: center;
}

.blockchain_game_dev .bgd_banner_btn {
  font-size: 16px;
  color: #fff !important;
  font-weight: 800;
  text-align: center;
  background: #fe5b6d;
  border-radius: 5px;
}

@media (min-width: 767px) {
  .blockchain_game_dev .bgd_banner {
    background: linear-gradient(45deg, #151027, #1b122a, #151027);
    height: 100vh;
    display: flex;
    align-items: center;
    /* background: url('public/images/inner-pages/blockchain-game-dev/banner.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
  }
}

.blockchain_game_dev .bgd_banner .title {
  text-align: left;
  color: #fff;
  font-weight: 800;
  font-size: 45px;
  background: linear-gradient(to right, #fff 5%, #ff243c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blockchain_game_dev .bgd_banner .contents {
  color: #fff;
  text-align: left;
  font-size: 20px;
  padding-top: 10px;
  line-height: 1.5;
}

.blockchain_game_dev .bgd_top_notch,
.blockchain_game_dev .bgd_services,
.blockchain_game_dev .bgd_game,
.blockchain_game_dev .bgd_process,
.blockchain_game_dev .bgd_tech,
.blockchain_game_dev .bgd_stand {
  background: linear-gradient(45deg, #151027, #1b122a, #151027);
}

.blockchain_game_dev .bgd_top_notch .title {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(to right, #fff 5%, #ff243c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blockchain_game_dev .bgd_top_notch .contents {
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 10px;
  line-height: 1.5;
}

.blockchain_game_dev .bgd_bg {
  width: 15px;
  height: 15px;
  background: #ff5c6e;
  display: block;
  border-radius: 50px;
  box-shadow: 0px 0px 20px 20px #ff5c6e;
}

.blockchain_game_dev .bgd_services .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  background: linear-gradient(to right, #fff 5%, #ff243c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blockchain_game_dev .bgd_services .contents {
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
}

.blockchain_game_dev .bgd_services .bgd_services_list_design {
  background: linear-gradient(
    130deg,
    rgb(234 83 102 / 37%) 0%,
    rgb(11 11 46) 15%,
    rgb(49 23 55) 100%
  );
  display: flex;
  align-items: center;
  height: 500px;
  margin-top: 50px;
  padding: 30px;
  box-shadow: -1px -1px 4px 3px #f25669;
  border-radius: 0px 80px 0px 80px;
}

.blockchain_game_dev .bgd_services .bgd_service_list_content_design {
  background: linear-gradient(
    130deg,
    rgb(245 88 106 / 33%) 0%,
    rgb(11 11 46) 39%,
    rgb(36 19 52) 88%
  );
  padding: 10px;
  border: 2px solid #ff5c6e;
}

.blockchain_game_dev .bgd_services .bgd_service_list_content {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 0px;
}

.blockchain_game_dev .bgd_services .owl-carousel .owl-item img {
  width: 100% !important;
}

.blockchain_game_dev .bgd_services .owl-nav {
  position: absolute;
  top: -56px;
  right: 67px;
}

.blockchain_game_dev .bgd_services .owl-theme .owl-nav [class*="owl-"] {
  background: #ff6a00;
  font-size: 20px;
}

.blockchain_game_dev .bgd_services .owl-dots {
  display: none;
}

.blockchain_game_dev .bgd_game .title {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: 30px;
  background: linear-gradient(to right, #fff 5%, #ff243c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blockchain_game_dev .bgd_game .contents {
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
  padding-top: 10px;
}

.blockchain_game_dev .bgd_game .bgd_game_list {
  padding: 25px;
  margin-top: 35px;
  box-shadow: 0px 0px 11px 6px #1502095c;
  border-radius: 20px;
  background: linear-gradient(45deg, #60264330, #130e30a1);
  min-height: 295px;
  position: relative;
}

.blockchain_game_dev .bgd_game .bgd_game_list .sub_title {
  color: #ff6273;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 0px;
}

.blockchain_game_dev .bgd_game .bgd_game_list .sub_content {
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0px;
  padding-top: 10px;
}

.blockchain_game_dev .bgd_game .bgd_game_list:after {
  content: "";
  border: 1px solid #ff627369;
  position: absolute;
  width: 93%;
  height: 88%;
  left: 15px;
  border-radius: 20px;
  top: 15px;
}

.blockchain_game_dev .bgd_benefits .title {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #000;
}

.blockchain_game_dev .bgd_benefits .bgd_benefits_list {
  display: flex;
  margin-top: 20px;
}

.blockchain_game_dev .bgd_benefits .bgd_benefits_list .sub_title {
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 18px;
}

.blockchain_game_dev .bgd_benefits .bgd_benefits_list .sub_content {
  font-size: 16px;
  margin-bottom: 0px;
  padding-top: 5px;
}

.blockchain_game_dev .bgd_benefits .bgd_benefits_list_main {
  margin-left: 15px;
}

.blockchain_game_dev .bgd_platform .title {
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  color: #000;
}

.blockchain_game_dev .bgd_platform .contents {
  text-align: center;
  font-size: 16px;
}

.blockchain_game_dev .bgd_platform .bgd_platform_list {
  background: #191129;
  padding: 15px;
  box-shadow: 0px 0px 20px 13px #05050536;
  margin-top: 10px;
  min-height: 330px;
}

.blockchain_game_dev .bgd_platform .bgd_platform_list p {
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 0px;
  position: relative;
}

.blockchain_game_dev .bgd_platform .bgd_platform_list p:after {
  content: "";
  width: 70px;
  height: 2px;
  background: #ff6273;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -7px;
  transform: translate(-50%, 50%);
}

.blockchain_game_dev .bgd_platform .bgd_platform_list ul {
  display: grid;
}

.blockchain_game_dev .bgd_platform .bgd_platform_list ul li {
  list-style: none;
  padding-top: 20px;
  color: #fff;
  position: relative;
  padding-left: 20px;
}

.blockchain_game_dev .bgd_platform .bgd_platform_list ul li:before {
  content: "\25C9";
  position: absolute;
  left: 0px;
  color: #ff6273;
}

.blockchain_game_dev .bgd_usecase .title {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  text-align: center;
  padding-bottom: 20px;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_list {
  padding: 15px;
  margin-top: 15px;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_list .sub_title {
  margin-bottom: 0px;
  font-size: 19px;
  font-weight: 800;
  color: #000;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_list .sub_content {
  color: #000;
  font-size: 16px;
  padding-top: 7px;
  margin-bottom: 0px;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_main {
  position: relative;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_main:after {
  content: "";
  height: 100%;
  width: 1px;
  background: #191129;
  position: absolute;
  top: 0px;
  left: 50%;
  right: 50%;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_side {
  position: relative;
}

.blockchain_game_dev .bgd_usecase .bgd_usecase_side:after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: #ff6273;
  position: absolute;
  left: 100%;
  top: 15px;
  right: 0px;
  bottom: 0px;
  transform: translate(-50%, 50%);
  z-index: 1;
}

.blockchain_game_dev .bgd_process .title {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: 30px;
  background: linear-gradient(to right, #fff 5%, #ff243c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 10px;
}

.blockchain_game_dev .bgd_stand .title {
  text-align: left;
  font-weight: 800;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  background: linear-gradient(to right, #fff 5%, #ff243c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 10px;
}

.blockchain_game_dev .bgd_stand .contents {
  color: #fff;
  line-height: 1.5;
  font-size: 16px;
}

.blockchain_game_dev .bgd_stand .bgd_stand_list {
  background: #fff;
  padding: 15px;
  margin-top: 20px;
  border-radius: 15px;
}

.blockchain_game_dev .bgd_stand .bgd_stand_list .sub_title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0px;
  color: #000;
}

.blockchain_game_dev .bgd_stand .bgd_stand_list .sub_content {
  font-size: 16px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.blockchain_game_dev .mgf_line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.blockchain_game_dev .line {
  width: 40px;
  height: 4px;
  background: linear-gradient(45deg, #e3323b, #c81a75);
  display: block;
  margin-left: 10px;
  border-radius: 26px 0px 0px 0px;
}

.blockchain_game_dev .services_img {
  width: 320px;
  height: 340px;
  object-fit: none;
}

.bgd_benefits_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
  max-width: unset;
}

@media only screen and (max-width: 600px) {
  .blockchain_game_dev .bgd_banner {
    background: #161128;
    padding: 0px;
    height: 500px;
    display: flex;
    align-items: center;
  }

  .blockchain_game_dev .row_justify,
  .blockchain_game_dev .row_align {
    display: initial;
  }

  .blockchain_game_dev .bgd_banner .title {
    text-align: center;
    font-size: 30px;
  }

  .blockchain_game_dev .bgd_banner .contents,
  .blockchain_game_dev .bgd_stand .title,
  .blockchain_game_dev .bgd_stand .contents {
    text-align: center;
  }

  .bgd_banner_btn_mob {
    text-align: center !important;
  }

  .blockchain_game_dev .bgd_top_notch .title,
  .blockchain_game_dev .bgd_services .title,
  .blockchain_game_dev .bgd_game .title,
  .blockchain_game_dev .bgd_benefits .title,
  .blockchain_game_dev .bgd_platform .title,
  .blockchain_game_dev .bgd_usecase .title,
  .blockchain_game_dev .bgd_process .title,
  .blockchain_game_dev .bgd_stand .title {
    font-size: 25px;
  }

  .blockchain_game_dev .bgd_usecase .bgd_usecase_side:after,
  .blockchain_game_dev .bgd_usecase .bgd_usecase_main:after {
    display: none;
  }
}

/* Blockchain Game Devlopment new style ends */

/*white label cryptocurrency software start*/

.white_label_main .row_justify {
  display: flex;
  justify-content: center;
}

.white_label_main .wl_banner {
  height: 100vh;
  /* background: url('public/images/inner-pages/whitelabel-crypto/whitelabel-banner.png'); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.white_label_main .wl_banner .title {
  font-size: 45px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  line-height: 1.2;
}

.white_label_main .wl_banner .contents {
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  padding-top: 10px;
}

.white_label_main .wl_btn {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  padding: 15px;
  border-radius: 50px;
  margin-top: 7px;
}

.white_label_main .wl_btn_hover:hover {
  background: #fff;
  color: #000;
}

.white_label_main .wl_dev,
.white_label_main .wl_features_sec,
.white_label_main .wl_types,
.white_label_main .wl_benefits,
.white_label_main .wl_process,
.white_label_main .wl_why,
.white_label_main .wl_hire,
.white_label_main .wl_automate,
.white_label_main .wl_breadcrumb {
  padding: 0px;
}

.white_label_main .wl_dev {
  margin-top: 20px;
}

.white_label_main .wl_dev .title {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
  line-height: 1.3;
  text-align: center;
}

.white_label_main .wl_dev .contents {
  color: #000;
  font-size: 17px;
  text-align: center;
  line-height: 1.7;
  padding-top: 10px;
}

.white_label_main .wl_features_sec .wl_features_head .title {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.white_label_main .wl_features_sec .wl_features_head .contents {
  font-size: 17px;
  text-align: center;
  line-height: 1.5;
}

.white_label_main .wl_features_sec .wl_features_tab_content .panel {
  background: transparent;
}

@media (min-width: 768px) {
  .white_label_main .wl_features_sec .wl_features_nav_tabs {
    border-bottom: 0;
    text-align: center;
  }

  .white_label_main .wl_features_sec .wl_features_nav_tabs > li {
    float: none;
    display: inline-block;
  }

  .white_label_main .wl_features_sec .wl_features_nav_tabs > li > a {
    border: 1px solid #ad1650 !important;
    border-radius: 0px;
    margin: 5px 3px;
    font-size: 16px;
    font-weight: 800;
    color: #ad1650;
    text-transform: uppercase;
  }

  .white_label_main .wl_features_sec .wl_features_nav_tabs > li.active > a {
    background: #ad1650;
    color: #fff;
  }

  .white_label_main .wl_features_sec .wl_features_tab_content .panel-heading {
    display: none;
  }

  .white_label_main .wl_features_sec .wl_features_tab_content .panel {
    border: none;
    box-shadow: none;
  }

  .white_label_main .wl_features_sec .wl_features_tab_content .panel-collapse {
    height: auto;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content
    .panel-collapse.collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .white_label_main
    .wl_features_sec
    .wl_features_tab_content.tab-content
    .tab-pane {
    display: block;
    margin: 10px auto;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content.tab-content
    .tab-pane
    .panel-heading {
    background: #ad1650;
    padding: 15px 10px;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content
    .panel
    .panel-heading
    > a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content.tab-content
    .tab-pane
    .panel-body {
    height: 300px;
    overflow-y: scroll;
  }

  .white_label_main .wl_features_sec .wl_features_nav_tabs.nav-tabs {
    display: none;
  }

  .white_label_main .wl_features_sec .wl_features_tab_content .panel-title a {
    display: block;
  }

  .white_label_main .wl_features_sec .wl_features_tab_content .panel {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    margin-top: -2px;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content
    .tab-pane:first-child
    .panel {
    border-radius: 5px 5px 0 0;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content
    .tab-pane:last-child
    .panel {
    border-radius: 0 0 5px 5px;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content
    .panel
    a[data-toggle="collapse"]
    i:before {
    content: "\f078";
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content
    .panel
    a
    .panel-title {
    font-weight: 800;
    text-transform: uppercase;
  }

  .white_label_main
    .wl_features_sec
    .wl_features_tab_content.tab-content
    .tab-pane
    .features_list
    .feature-img {
    display: none;
  }
}

.white_label_main .wl_features_sec .features_list {
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  box-shadow: 1px 1px 12px 0px rgb(151 163 184 / 50%);
}

.white_label_main .wl_features_sec {
  margin-top: 20px;
  background: #cccccc38;
}

.white_label_main .wl_features_sec .features_list .sub_content {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.white_label_main .wl_types {
  margin-top: 20px;
}

.white_label_main .wl_types .title {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.white_label_main .wl_types .types_list {
  padding: 15px;
  margin-top: 20px;
  text-align: center;
  border: 1px dotted #000;
  min-height: 326px;
}

.white_label_main .wl_types .types_list .sub_title {
  font-size: 19px;
  font-weight: 800;
}

.white_label_main .wl_types .types_list .sub_content {
  font-size: 15px;
}

.white_label_main .wl_benefits {
  margin-top: 50px;
  background: #cccccc38;
  padding-top: 20px;
  padding-bottom: 20px;
}

.white_label_main .wl_benefits .title {
  color: #000;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.3;
}

.white_label_main .wl_benefits ul li {
  font-size: 16px;
  margin-top: 15px;
  list-style: none;
  position: relative;
}

.white_label_main .wl_benefits ul {
  padding-left: 0px;
}

.white_label_main .wl_benefits ul li:after {
  content: "\25B6";
  position: absolute;
  top: 0px;
  left: -20px;
  color: #ae2a53;
}

.white_label_main .line {
  display: block;
  height: 2px;
  width: 70px;
  background: #ae2a53;
}

.white_label_main .line_center {
  display: block;
  height: 2px;
  width: 70px;
  background: #ae2a53;
  margin: 0 auto;
  margin-bottom: 10px;
}

.white_label_main .row_align {
  display: flex;
  align-items: center;
}

.white_label_main .wl_process {
  margin-top: 20px;
}

.white_label_main .wl_process .title {
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  color: #000;
}

.white_label_main .wl_process .process_list {
  margin-top: 20px;
  text-align: center;
  padding: 5px;
}

.white_label_main .wl_process .process_list .sub_title {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 800;
}

.white_label_main .wl_process .process_list .sub_content {
  padding-top: 6px;
  margin-bottom: 0px;
}

.white_label_main .wl_process .process_round {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ae2a53;
  display: block;
  margin: 5px auto;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.white_label_main .wl_why {
  margin-top: 40px;
  background: #cccccc38;
  padding-top: 20px;
  padding-bottom: 20px;
}

.white_label_main .wl_why .title {
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  color: #000;
}

.white_label_main .wl_why .contents {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  margin-top: 10px;
}

.white_label_main .wl_why ul li {
  font-size: 16px;
  margin-top: 15px;
  list-style: none;
  position: relative;
}

.white_label_main .wl_why ul li:after {
  content: "\25B6";
  position: absolute;
  top: 0px;
  left: -20px;
  color: #ae2a53;
}

.white_label_main .wl_hire {
  margin-top: 30px;
  margin-bottom: 30px;
}

.white_label_main .wl_hire .title {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  text-align: center;
  text-transform: uppercase;
}

.white_label_main .wl_hire .contents {
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}

.wl_automate {
  margin-top: 40px;
}

.wl_automate_div {
  border: 2px solid #205a5d;
  padding: 40px;
  text-align: center;
  border-radius: 5px;
}

.wl_automate .contents {
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.3;
}

.wl_breadcrumb {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .white_label_main .row_justify,
  .white_label_main .row_align {
    display: inherit;
  }

  .white_label_main .wl_banner .title,
  .white_label_main .wl_dev .title,
  .white_label_main .wl_features_sec .wl_features_head .title,
  .white_label_main .wl_types .title,
  .white_label_main .wl_benefits .title,
  .white_label_main .wl_process .title,
  .white_label_main .wl_why .title,
  .white_label_main .wl_hire .title,
  .wl_automate .contents {
    font-size: 25px;
  }

  .white_label_main .wl_banner .contents {
    font-size: 18px;
  }

  .white_label_main .wl_dev .contents,
  .white_label_main .wl_hire .contents {
    text-align: justify;
  }

  .white_label_main .wl_types .types_list {
    min-height: initial;
  }

  .white_label_main .wl_benefits ul {
    padding-left: revert;
  }
}

/*white label cryptocurrency software end*/

/* web 3.0 Development company starts  */

.web-3-0-development-company
  .cesd_various_business
  .cesd_various_business_card {
  min-height: 330px;
}

.web-3-0-development-company
  .cesd_various_business
  .cesd_various_business_icon_design {
  margin: 0px auto;
}

.web-3-0-development-company .MetaBlock_nft_services .contents,
.web-3-0-development-company .cesd_collaboration .contents,
.web-3-0-development-company .cesd_corefeatures .contents {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.web-3-0-development-company .card__side {
  min-height: 230px;
  height: 230px;
}

.web-3-0-development-company .cesd_collaboration .cesd_collaboration_card {
  min-height: 255px;
  margin-bottom: 22px;
}

.web-3-0-development-company
  .cesd_collaboration
  .cesd_various_business_icon_design {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d0035;
  box-shadow: 0px 0px 6px 1px #9d0035;
  position: absolute;
  margin-top: -61px;
  margin-left: -27px;
}

.web-3-0-development-company .cesd_clone .owl-nav {
  bottom: 5px;
}

.web-3-0-development-company .bcgnd_clr {
  background-image: radial-gradient(
      circle at center center,
      transparent 0%,
      rgb(0, 0, 0) 85%
    ),
    linear-gradient(
      78deg,
      rgba(192, 192, 192, 0.05) 0%,
      rgba(192, 192, 192, 0.05) 50%,
      rgba(60, 60, 60, 0.05) 50%,
      rgba(60, 60, 60, 0.05) 100%
    ),
    linear-gradient(
      227deg,
      rgba(97, 97, 97, 0.05) 0%,
      rgba(97, 97, 97, 0.05) 50%,
      rgba(52, 52, 52, 0.05) 50%,
      rgba(52, 52, 52, 0.05) 100%
    ),
    linear-gradient(
      240deg,
      rgba(98, 98, 98, 0.05) 0%,
      rgba(98, 98, 98, 0.05) 50%,
      rgba(249, 249, 249, 0.05) 50%,
      rgba(249, 249, 249, 0.05) 100%
    ),
    linear-gradient(
      187deg,
      rgba(1, 1, 1, 0.05) 0%,
      rgba(1, 1, 1, 0.05) 50%,
      rgba(202, 202, 202, 0.05) 50%,
      rgba(202, 202, 202, 0.05) 100%
    ),
    linear-gradient(
      101deg,
      rgba(61, 61, 61, 0.05) 0%,
      rgba(61, 61, 61, 0.05) 50%,
      rgba(254, 254, 254, 0.05) 50%,
      rgba(254, 254, 254, 0.05) 100%
    ),
    linear-gradient(
      176deg,
      rgba(237, 237, 237, 0.05) 0%,
      rgba(237, 237, 237, 0.05) 50%,
      rgba(147, 147, 147, 0.05) 50%,
      rgba(147, 147, 147, 0.05) 100%
    ),
    linear-gradient(
      304deg,
      rgba(183, 183, 183, 0.05) 0%,
      rgba(183, 183, 183, 0.05) 50%,
      rgba(57, 57, 57, 0.05) 50%,
      rgba(57, 57, 57, 0.05) 100%
    ),
    radial-gradient(
      circle at center center,
      hsl(351, 4%, 12%),
      hsl(351, 4%, 12%)
    );
}

.web-3-0-development-company .cryptocurrency_exchange_script {
  padding-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .web-3-0-development-company .cesd_banner {
    padding-top: 100px;
  }

  .web-3-0-development-company
    .cesd_collaboration
    .cesd_various_business_icon_design {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
  }

  .web-3-0-development-company
    .cesd_collaboration
    .cesd_various_business_icon_design {
    margin: 0px auto;
  }

  .web-3-0-development-company .card-body {
    height: auto;
    overflow: hidden;
  }
}

/* web 3.0 Development company ends  */

/*================TESTIMONAL CSS - START ======================*/

.essential_page_testimonal #essential_sec1 {
  /*background-color: #eee;*/
  /* background-image: url(../oimages/essentials/testimonials/bg-cover.png); */
  height: 500px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

#essential_sec1 {
  /* margin-top: 8rem;
    background-position: bottom;*/
}

#essential_sec1 .essential_content_wrap .essential_title {
  color: #193060;
  font-size: 45px;
  font-weight: 800;
  padding-top: 30px;
}

#essential_sec1 .essential_content_wrap .essential_descs {
  font-weight: 600;
  font-size: 20px;
  color: #526283;
  margin-top: 20px;
}

#essential_sec2 .essential_content_wrap #mainTabs {
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #de4141;
}

#essential_sec2 .essential_content_wrap #mainTabs li a {
  font-weight: 700;
  font-size: 20px;
  border-bottom: 0;
  border: 0;
}

#essential_sec2 .essential_content_wrap #mainTabs li.active a {
  background-color: #de4141;
  color: #fff;
}

#essential_sec2 .essential_content_wrap #mainTabs li > a:hover {
  border: 0;
  color: #de4141db;
}

#essential_sec2 .essential_content_wrap #mainTabs li.active a:hover {
  color: #fff;
}

.testimonal_content_wrap {
  margin-top: 5rem;
  min-height: 190px;
}

.testimonal_customername {
  font-size: 27px;
  font-weight: 700;
  color: #d12b2b;
}

.testimonal_location {
  font-size: 21px;
  font-weight: 500;
  color: #8c8c8c;
}

.testimonal_feedback {
  margin-top: 1rem;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
}

.testimonal_customer_detail {
  display: flex;
  align-items: center;
  font-style: italic;
  margin-bottom: 5px;
}

.testimonal_customer_detail .circle_dot {
  width: 6px;
  height: 5px;
  background-color: #9d9d9d;
  border-radius: 20px;
  margin: 0px 6px;
}

.testimonal_content_wrap iframe {
  border-radius: 10px;
}

@media (max-width: 767px) {
  #essential_sec1 {
    background-position: left;
    text-align: center;
    margin-top: 3rem;
  }

  #essential_sec1 .essential_content_wrap .essential_title {
    color: #193060;
    font-size: 36px;
    font-weight: 800;
  }

  #essential_sec2 .essential_content_wrap #mainTabs li a {
    font-size: 16px;
  }

  .testimonal_customer_detail {
    display: block;
  }

  .testimonal_customer_detail .circle_dot {
    display: none;
  }

  .testimonal_customername {
    margin-bottom: 1.5rem;
  }

  .testimonal_content_wrap {
    min-height: auto;
  }

  .testimonal_content_wrap {
    margin-top: 4rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #dcdcdc;
  }

  .testimonal_content_wrap iframe {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }

  .essential_pages#essential_sec2 {
    padding-bottom: 0;
  }
}

/*================TESTIMONAL CSS - END ======================*/

/*==================== SCAMMER PAGE - START ================== */

.essential_page_scammer #essential_sec1 {
  padding: 60px 0px;
  text-align: center;
  /* background-image: url(../oimages/essentials/scam/banner.png); */
}

.essential_page_scammer #essential_sec1 .essential_content_wrap {
  text-align: left;
}

.essential_page_scammer #essential_sec1 .essential_content_wrap p {
  margin-bottom: 0;
  font-weight: 700;
  margin-top: 2rem;
  color: #193060;
}

#essential_sec1 .essential_content_wrap .essential_descs {
  font-weight: 600;
  font-size: 17px;
  color: #526283;
  line-height: 1.4;
}

.essential_page_scammer #essential_sec2 .essential_content_wrap {
  padding: 10px;
}

.essential_page_scammer #essential_sec2 {
  position: relative;
}

.essential_page_scammer #essential_sec2:before {
  position: absolute;
  width: 100%;
  height: 20%;
  /*background-color:#e6eff4;*/
  content: "";
}

.scam_form #essential_form .essential_content_wrap {
  padding: 40px;
  box-shadow: 0 0 4px -2px black;
  border-radius: 10px;
  background-color: #fff;
}

.essential_page_scammer #essential_form {
  padding-top: 0;
}

.scam_form #essential_form .essential_content_wrap .form-group select {
  display: block;
  width: 100%;
  padding: 10px;
  height: 50px;
  border-radius: 3px;
  border-color: #cdcdcd;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.scam_form #essential_form .essential_content_wrap .form-group .form_column {
  padding: 15px;
}

.scam_form
  #essential_form
  .essential_content_wrap
  .form-group
  .form_column
  label {
  font-weight: 600;
  color: #193060;
  font-size: 16px;
  margin-bottom: 1rem;
}

.scam_form #essential_form .form-control {
  height: 50px;
  padding: 6px 12px;
}

.scam_form
  #essential_form
  .essential_content_wrap
  .form-group
  .form_column
  textarea {
  width: 100%;
  padding: 17px;
  border-color: #cdcdcd;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 3px;
}

.scam_form
  #essential_form
  .essential_content_wrap
  .form-group
  .essential_formbtn {
  background-color: #de4141;
  color: #fff;
  border: 0;
  padding: 13px;
  font-weight: 700;
  min-width: 161px;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 35px;
  text-align: center;
}

@media (max-width: 767px) {
  .essential_page_scammer #essential_sec1 .essential_content_wrap {
    text-align: center;
  }

  .scam_form #essential_form .essential_content_wrap {
    padding: 25px;
    margin-bottom: 2rem;
  }
}

/*==================== SCAMMER PAGE - END ================== */

/*==================== CASESTUDIES - START ================== */

.essential_page_casestudydetails
  #essential_sec1
  .essential_content_wrap
  .essential_title {
  color: #000;
  font-size: 35px;
  font-weight: 800;
  letter-spacing: -1px;
  padding-bottom: 2rem;
  margin-top: 1rem;
}

.essential_page_casestudydetails #essential_sec1 {
  margin-top: 1rem;
  background-position: top;
  padding-top: 0;
}

.casetudy_details_descs {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.39;
  color: #49505e;
  margin-bottom: 1.9rem;
}

.casetudy_details_descs.casestudydetails_title {
  font-weight: 800;
  font-size: 24px;
  color: #193060;
}

.casetudy_details_descs.casestudydetails_heads {
  color: #193060;
  font-size: 20px;
  font-weight: 700;
}

.essential_page_casestudydetails #essential_form {
  padding-top: 0;
}

.essential_page_casestudydetails #essential_form .essential_title {
  font-weight: 800;
  font-size: 24px;
  color: #193060;
  margin-top: 0;
}

.essential_page_casestudydetails #essential_form .essential_descs {
  font-weight: 600;
  font-size: 17px;
  color: #526283;
  line-height: 1.4;
}

.essential_page_casestudydetails #essential_form p {
  margin-bottom: 0;
  font-weight: 700;
  margin-top: 2rem;
  color: #193060;
}

.essential_page_casestudydetails #essential_form {
  background-color: #f3f3f3;
  padding: 80px 0px;
}

.essential_page_casestudydetails #essential_form .form_wrap {
  text-align: center;
  width: 60%;
  margin: 0 auto 2rem auto;
}

.essential_page_casestudydetails .custom_row {
  justify-content: center;
  display: flex;
}

.casestudy_breadcrump ol {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 1rem;
}

.casestudy_breadcrump ol li {
  font-size: 15px;
  font-weight: 700;
  list-style: none;
  display: inline-block;
}

.casestudy_breadcrump ol > li + li:before {
  padding: 0 5px;
  color: #111;
  content: ">";
}

@media (max-width: 767px) {
  .case_stu_sec_img {
    padding-top: 100px !important;
  }

  .essential_page_casestudydetails #essential_sec1 {
    text-align: left;
  }

  .essential_page_casestudydetails #essential_sec1 {
    margin-top: 0rem;
  }

  .essential_page_casestudydetails #essential_form .form_wrap {
    width: 100%;
  }
}

/*==================== CASESTUDIES - END ================== */

/*==================== FEEDBACK - START ================== */

.essential_page_feedback #essential_sec1 {
  /* background-image: url(../oimages/essentials/feedback/feedback.png); */
}

.essential_page_feedback #essential_form .selection_wrap label {
  font-weight: 500;
  font-size: 14px;
}

.essential_page_feedback #essential_form .feedback_head {
  font-weight: 700;
  margin-bottom: 1rem;
}

.essential_page_feedback #essential_form .form_column {
  margin-bottom: 1rem;
}

.essential_page_feedback #essential_form .inner_container {
  padding: 40px;
  box-shadow: 0 0 4px -2px black;
  border-radius: 10px;
  background-color: #fff;
}

.essential_page_feedback #essential_form {
  padding-top: 0;
  position: relative;
}

.essential_page_feedback #essential_form:before {
  /*background-color: #e6eff4;*/
  position: absolute;
  content: "";
  width: 100%;
  height: 8%;
  z-index: -1;
}

.essential_page_feedback {
}

.essential_page_feedback #essential_form .feedback_inputs_wrap {
  margin: 1.6rem 0;
}

.essential_page_feedback #essential_form .essential_formbtn {
  background-color: #de4141;
  color: #fff;
  border: 0;
  padding: 13px;
  font-weight: 700;
  min-width: 161px;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 35px;
  text-align: center;
}

@media (max-width: 767px) {
  .essential_page_feedback #essential_form {
    padding-bottom: 0;
  }
}

.essential_page_feedback .starrating.risingstar {
  flex-direction: row-reverse;
}

.essential_page_feedback .starrating > input {
  display: none;
}

/* Remove radio buttons */

.essential_page_feedback .starrating > label:before {
  content: "\f005";
  /* Star */
  margin: 2px;
  font-size: 1.5em;
  font-family: FontAwesome;
  display: inline-block;
}

.essential_page_feedback .starrating > label {
  color: #222222;
  /* Start color when not clicked */
}

.essential_page_feedback .starrating > input:checked ~ label {
  color: #ff8f00;
}

/* Set yellow color when star checked */

.essential_page_feedback .starrating.risingstar {
  flex-direction: row-reverse;
  justify-content: left;
}

.essential_page_feedback .starrating > input:hover ~ label {
  color: #ff8f00;
}

/* Set yellow color when star hover */

/*==================== FEEDBACK - END ================== */

/*==================== CASESTUDY - START ================== */

.essential_page_casestudyhome #essential_sec1 {
  /* background-image: url(../oimages/essentials/casestudies/casestudy-home.jpg); */
  background-position: top;
  position: relative;
}

.essential_page_casestudyhome #essential_sec1:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #07102194;
  content: "";
  top: 0;
}

.essential_page_casestudyhome
  #essential_sec1
  .essential_content_wrap
  .essential_title {
  color: #fff;
}

.essential_page_casestudyhome
  #essential_sec1
  .essential_content_wrap
  .essential_descs {
  font-weight: 600;
  font-size: 17px;
  color: #f1f5ff;
  line-height: 1.4;
}

.essential_page_casestudyhome
  #casestudy_cards
  .essential_content_wrap
  .essential_title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #000;
  margin-top: 0;
  margin-bottom: 15px;
}

.essential_page_casestudyhome
  #casestudy_cards
  .essential_content_wrap
  .essential_descs {
  color: #494848;
  font-weight: 500;
}

.essential_page_casestudyhome #casestudy_cards .essential_content_wrap {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 1px 5px -3px black;
  min-height: 450px;
  margin-top: 30px;
}

.essential_page_casestudyhome #casestudy_cards .casestudy_cardswrap {
  padding: 25px 30px;
}

.essential_page_casestudyhome #casestudy_cards {
  padding-top: 25px;
}

.essential_page_casestudyhome #casestudy_cards .casestudy_breadcrump ol li {
  font-size: 15px;
}

.essential_page_casestudyhome #casestudy_cards .casestudy_breadcrump ol {
  margin-bottom: 1rem;
}

.essential_page_casestudyhome #casestudy_cards .casestudy_breadcrump {
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .essential_page_casestudyhome #essential_sec1:before {
    left: 0;
  }
}

/*==================== CASESTUDY - END ================== */

/*==================== COMPLAINT BOARD - END ================== */

.essential_page_complaintboard #essential_form .essential_content_wrap {
  padding: 40px;
  box-shadow: 0 0 4px -2px black;
  border-radius: 10px;
  background-color: #fff;
}

.essential_page_complaintboard
  #essential_form
  .essential_content_wrap
  .form-group
  select {
  display: block;
  width: 100%;
  padding: 10px;
  height: 50px;
  border-radius: 3px;
  border-color: #cdcdcd;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.essential_page_complaintboard
  #essential_form
  .essential_content_wrap
  .form-group
  textarea {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border-color: #cdcdcd;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.essential_page_complaintboard #essential_form .form-control {
  height: 50px;
  padding: 6px 12px;
}

.essential_page_complaintboard
  #essential_form
  .essential_content_wrap
  .form-group
  .form_column
  label {
  font-weight: 600;
  color: #193060;
  font-size: 16px;
  margin-bottom: 1rem;
}

.essential_page_complaintboard
  #essential_form
  .essential_content_wrap
  .form-group
  .form_column {
  padding: 15px;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 25px;
  border: 2px dashed rgb(34 33 33 / 40%);
  border-radius: 3px;
  transition: 0.2s;
  justify-content: center;
  background-color: #f7f7f7;
}

.choose-file-button {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 0px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  margin-right: 10px;
  color: #3586ff;
  font-weight: 600;
}

.file-message {
  font-weight: 500;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.essential_page_complaintboard
  #essential_form
  .essential_content_wrap
  .form-group
  .essential_formbtn {
  background-color: #de4141;
  color: #fff;
  border: 0;
  padding: 13px;
  font-weight: 700;
  min-width: 161px;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 35px;
  text-align: center;
}

.essential_page_complaintboard #essential_form {
  padding-top: 0;
}

.essential_page_complaintboard #essential_sec1 {
  /* background-image: url(../oimages/essentials/complaint-board/banner.jpg); */
}

/*.essential_page_complaintboard #essential_form:before {
    background-color: #e6eff4;
    position: absolute;
    content: '';
    width: 100%;
    height: 10%;
    z-index: -1;
}*/

@media (max-width: 767px) {
  .essential_page_complaintboard #essential_form .essential_content_wrap {
    padding: 20px;
  }

  .essential_page_complaintboard #essential_form {
    padding-bottom: 20px;
  }
}

@media (max-width: 991px) {
  #nftmarki_what:before {
    background: none;
    background-color: #f2f4f8;
  }

  #nftmarki_what .MetaBlocknftmarki_sec_titlewrap {
    width: 100%;
  }
}

/*binance new style start*/

.binance_main .banner_sec {
  padding: 0px;
  /* background: url('public/images/inner-pages/binance_new/banner.png'); */
  height: 100vh;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
}

.binance_main .banner_sec:after {
  content: "Binance clone script";
  font-size: 75px;
  font-weight: 800;
  color: #ffffff00;
  text-transform: uppercase;
  position: absolute;
  top: 15%;
  left: 0px;
  right: 0px;
  -webkit-text-stroke: 1px #ffffff14;
  transform: translate(17%, 50%);
  letter-spacing: 3px;
}

.binance_main .row_justify {
  display: flex;
  justify-content: center;
}

.binance_main .row_align {
  display: flex;
  align-items: center;
}

.binance_main .banner_sec .title {
  font-weight: 700;
  font-size: 50px;
  text-transform: uppercase;
  color: #ffffff;
}

.binance_main .banner_sec .contents {
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
  line-height: 1.8;
  margin-top: 30px;
}

.binance_main .clone_sec {
  padding: 50px 0px;
}

.binance_main .clone_sec .title {
  font-weight: 800;
  font-size: 40px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 20px;
}

.binance_main .clone_sec .contents {
  font-size: 17px;
  line-height: 1.9;
  text-align: justify;
}

.binance_main .clone_bg {
  position: relative;
}

.binance_main .clone_bg:after {
  content: "";
  /* background: url('public/images/inner-pages/binance_new/clone-bg.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 600px;
  height: 100%;
}

.binance_main .whitelabel_sec {
  padding: 50px 0px;
  background: #f1ebeb;
  position: relative;
}

.binance_main .whitelabel_sec:after {
  content: "";
  /* background: url('public/images/inner-pages/binance_new/whitelabel-bg.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 600px;
  height: 100%;
}

.binance_main .whitelabel_sec .title {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.binance_main .whitelabel_sec .contents {
  font-size: 17px;
  line-height: 1.9;
  text-align: justify;
}

.binance_main .whitelabel_sec .white_index {
  z-index: 1;
  position: relative;
}

.binance_main .features_sec {
  padding: 50px 0px;
}

.binance_main .features_sec .title {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}

.binance_main .features_sec .features_list {
  padding: 10px;
  margin-top: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-height: 335px;
  transition: 0.5s;
}

.binance_main .features_sec .features_list:hover {
  transform: scale(1.1);
  background: #e4970021;
}

.binance_main .features_sec .features_list .sub_title {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
}

.binance_main .features_sec .features_list .sub_content {
  font-size: 17px;
  line-height: 1.8;
}

.binance_main .features_sec .features_icons {
  width: 55px;
  height: 55px;
  object-fit: none;
}

.binance_main .security_sec {
  background: linear-gradient(
    180deg,
    rgba(67, 66, 66, 0.26) 0%,
    rgba(241, 235, 235, 0) 100%
  );
  padding: 50px 0px;
}

.binance_main .security_sec .title {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}

.binance_main .security_sec .security_list {
  padding: 10px;
  margin-top: 10px;
  min-height: 260px;
}

.binance_main .security_sec .security_list .sub_title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
}

.binance_main .security_sec .security_list .sub_content {
  font-size: 17px;
  line-height: 1.9;
  text-align: justify;
}

.binance_main .security_sec .row_after_line {
  position: relative;
}

.binance_main .security_sec .row_after_line:after {
  content: "";
  top: 25px;
  width: 1px;
  height: 750px;
  background-color: #00000059;
  position: absolute;
  left: 33%;
}

.binance_main .security_sec .border_bottom {
  border-bottom: 1px solid #00000059;
}

.binance_main .app_sec {
  padding: 50px 0px;
  border-top: 1px solid #ccc;
}

.binance_main .app_sec .title {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.binance_main .app_sec .contents {
  font-size: 17px;
  line-height: 1.9;
  text-align: justify;
}

.binance_main .benefits_sec {
  padding: 50px 0px;
  background: linear-gradient(
    90deg,
    #fef3a7 -38.65%,
    #ffffff 48.12%,
    #fef3a8 136.72%
  );
}

.binance_main .benefits_sec .title {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}

.binance_main .benefits_sec .benefits_list {
  padding: 10px;
  margin-top: 30px;
  background: linear-gradient(
    179.97deg,
    #fef29f 0.02%,
    rgba(255, 255, 255, 0) 86.75%
  );
  border: 1px solid #ccc;
  box-shadow: 0px 0px 4px 3px rgba(102, 80, 1, 0.24);
  border-radius: 10px;
  min-height: 300px;
  transition: 0.5s;
}

.binance_main .benefits_sec .benefits_list:hover {
  transform: scale(1.1);
}

.binance_main .benefits_sec .benefits_list:hover .benefits_img {
  background: #ffc700;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
}

.binance_main .benefits_sec .benefits_list:hover .benefits_icons {
  filter: brightness(0);
}

.binance_main .benefits_sec .benefits_list .sub_title {
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 20px;
}

.binance_main .benefits_sec .benefits_list .sub_content {
  font-size: 17px;
  line-height: 1.9;
}

.binance_main .benefits_sec .benefits_img {
  background: #000000;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.binance_main .benefits_sec .benefits_icons {
  width: 55px;
  height: 55px;
  object-fit: none;
}

.binance_main .crypto_sec {
  padding: 50px 0px;
}

.binance_main .crypto_sec .title {
  font-size: 38px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}

.binance_main .crypto_sec .crypto_list {
  padding: 10px;
}

.binance_main .crypto_sec .crypto_list .sub_title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

.binance_main .crypto_sec .crypto_list .sub_content {
  font-size: 16px;
  line-height: 1.9;
  margin-bottom: 0px;
}

.binance_main .crypto_sec .crypto_list .counter {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffc700;
  font-size: 30px;
  color: #fff;
}

.binance_main .tech_sec {
  padding: 50px 0px;
  background: #000000;
}

.binance_main .tech_sec .title {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.binance_main .tech_sec .contents {
  text-align: center;
  font-size: 17px;
  color: #fff;
}

.binance_main .unique_sec {
  padding: 50px 0px;
}

.binance_main .unique_sec .title {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.binance_main .unique_sec .contents {
  font-size: 17px;
  line-height: 1.9;
}

.binance_main .unique_sec ul li {
  list-style: none;
  font-size: 17px;
  font-weight: 600;
  margin-top: 20px;
  position: relative;
}

.binance_main .unique_sec ul li:after {
  content: "\25B6";
  position: absolute;
  top: 0px;
  left: -25px;
  color: #deaf06;
  font-size: 20px;
}

.binance_main .banner_btn {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 800;
  background: #e6ca00;
  border-radius: 0px;
  color: #000 !important;
  padding: 10px 30px;
  clip-path: polygon(9% 0%, 100% 0%, 89% 100%, 0% 100%);
  position: relative;
  border: 1px solid #fff;
}

.binance_main .banner_btn:after {
  border-top-style: solid;
  top: 0px;
  left: 0px;
  border-color: #000;
  border-width: 5px;
  content: "";
  display: block;
  height: 50px;
  pointer-events: none;
  position: absolute;
  width: 100px;
  animation: binance_border_one 3s alternate infinite;
}

.binance_main .banner_btn:before {
  border-bottom-style: solid;
  bottom: 0px;
  right: 0px;
  border-color: #000;
  border-width: 5px;
  content: "";
  display: block;
  height: 50px;
  pointer-events: none;
  position: absolute;
  width: 100px;
  animation: binance_border_two 3s alternate infinite;
}

@keyframes binance_border_two {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-155px);
  }
}

@keyframes binance_border_one {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(155px);
  }
}

.binance-whatsapp {
  background: #006e29;
  font-weight: 800;
  font-size: 18px;
  color: #fff !important;
  padding: 10px 20px;
  margin: 10px;
}

.binance-telegram {
  background: #4eaff0;
  font-weight: 800;
  font-size: 18px;
  color: #fff !important;
  padding: 10px 20px;
  margin: 10px;
}

@media (max-width: 991px) {
  .binance_main .banner_sec {
    background: #181818;
    height: initial;
    padding: 100px 0px;
    text-align: center;
  }

  .binance_main .clone_bg:after,
  .binance_main .whitelabel_sec:after,
  .binance_main .security_sec .row_after_line:after,
  .binance_main .banner_sec:after {
    display: none;
  }

  .binance_main .clone_bg,
  .binance_main .whitelabel_sec,
  .binance_main .app_sec {
    text-align: center;
  }

  .binance_main .security_sec .border_bottom {
    border-bottom: none;
  }

  .binance_main .unique_sec .contents {
    text-align: justify;
  }

  .binance_main .unique_sec .title {
    text-align: center;
  }
}

@media (max-width: 765px) {
  .binance_main .banner_sec .title {
    font-size: 40px;
  }

  .binance_main .clone_sec .title,
  .binance_main .whitelabel_sec .title,
  .binance_main .features_sec .title,
  .binance_main .security_sec .title,
  .binance_main .app_sec .title,
  .binance_main .benefits_sec .title,
  .binance_main .crypto_sec .title,
  .binance_main .tech_sec .title,
  .binance_main .unique_sec .title {
    font-size: 30px;
  }

  .binance_main .features_sec .features_list,
  .binance_main .security_sec .security_list,
  .binance_main .benefits_sec .benefits_list {
    min-height: initial;
  }
}

/*binance new style end*/

/*========Move to Earn game development company START=======*/

@media (min-width: 991px) {
  #m2e_game_main .m2e_game_dev_banner {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
    align-items: center !important;
    height: 100vh;
    display: flex;
    /* background: url('public/images/inner-pages/move-to-earn-game-dev/banner.png'); */
  }
}

#m2e_game_main .m2e_game_dev_banner .title {
  color: #000;
  font-weight: 900;
  font-size: 45px;
  line-height: 55px;
}

#m2e_game_main .m2e_game_dev_banner .contents {
  color: #000;
  padding-top: 10px;
  font-size: 18px;
  line-height: 32px;
}

#m2e_game_main .m2e_game_dev_banner .m2e_game_dev_bnr_btn_sec {
  margin-top: 30px;
}

#m2e_game_main .m2e_game_dev_banner .m2e_game_dev_bnr_btn {
  padding: 15px 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(93.54deg, #0789cd 25.11%, #9c68ff 68.78%);
  border-radius: 15px;
  border: none;
  -webkit-animation: glowing 950ms infinite;
  -moz-animation: glowing 950ms infinite;
  -o-animation: glowing 950ms infinite;
  animation: glowing 950ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #0789cd;
    -webkit-box-shadow: 0 0 3px #6e40c6;
  }

  50% {
    background-color: #6e40c6;
    -webkit-box-shadow: 0 0 40px #6e40c6;
  }

  100% {
    background-color: #0789cd;
    -webkit-box-shadow: 0 0 3px #0789cd;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #0789cd;
    -moz-box-shadow: 0 0 3px #0789cd;
  }

  50% {
    background-color: #6e40c6;
    -moz-box-shadow: 0 0 40px #6e40c6;
  }

  100% {
    background-color: #0789cd;
    -moz-box-shadow: 0 0 3px #0789cd;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #0789cd;
    box-shadow: 0 0 3px #0789cd;
  }

  50% {
    background-color: #0789cd;
    box-shadow: 0 0 40px #7b58d1;
  }

  100% {
    background-color: #0789cd;
    box-shadow: 0 0 3px #0789cd;
  }
}

@keyframes glowing {
  0% {
    background-color: #0789cd;
    box-shadow: 0 0 3px #0789cd;
  }

  50% {
    background-color: #6e40c6;
    box-shadow: 0 0 40px #6e40c6;
  }

  100% {
    background-color: #1287d1;
    box-shadow: 0 0 3px #7e48e4;
  }
}

#m2e_game_main .m2e_game_dev_sec {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff;
}

#m2e_game_main .m2e_game_dev_sec .title {
  text-align: left;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_sec .contents {
  text-align: justify;
  font-size: 16px;
}

#m2e_game_main .m2e_game_dev_sec .m2e_game_dev_btn {
  padding: 15px 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(93.54deg, #0789cd 25.11%, #9c68ff 68.78%);
  border-radius: 15px;
  border: none;
  -webkit-animation: glowing 850ms infinite;
  -moz-animation: glowing 850ms infinite;
  -o-animation: glowing 850ms infinite;
  animation: glowing 850ms infinite;
}

#m2e_game_main .m2e_game_dev_comp_sec {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #dae9ff;
}

#m2e_game_main .m2e_game_dev_comp_sec .title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_comp_sec .contents {
  text-align: center;
  font-size: 16px;
}

#m2e_game_main .m2e_game_dev_comp_sec .m2e_game_dev_comp_about_btn_sec {
  margin-top: 20px;
  text-align: center;
}

#m2e_game_main .m2e_game_dev_comp_sec .m2e_game_dev_comp_about_btn {
  text-transform: uppercase;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  background: #4dd30d;
  color: #fff !important;
}

#m2e_game_main .m2e_game_dev_comp_sec .m2e_game_dev_comp_about_btn1 {
  text-transform: uppercase;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  background: #00aff0;
  color: #fff !important;
}

#m2e_game_main .m2e_game_dev_features {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff;
}

#m2e_game_main .m2e_game_dev_features .title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_features .features_box {
  padding: 20px;
  margin-top: 30px;
  border-radius: 2px 20px 2px 20px !important;
  background: linear-gradient(45deg, #66b8e37d, #a688df66);
  min-height: 320px;
  text-align: center;
}

#m2e_game_main .m2e_game_dev_features .sub_title {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #000;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_features .sub_contents {
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 0px;
  color: #000;
}

#m2e_game_main .m2e_game_dev_features .sprite_icons {
  width: 58px;
  height: 58px;
  object-fit: none;
}

#m2e_game_main .m2e_game_dev_features .features_box:hover {
  transform: scale(1.08);
  box-shadow: 0px 0px 9px #d3d3d3;
  border-radius: 10px;
}

#m2e_game_main .m2e_game_dev_features .features_box:hover .sprite_icons {
  transform: rotateY(180deg);
  transition: all 0.4s ease-in;
}

#m2e_game_main .m2e_game_dev_platform {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #dae9ff;
}

#m2e_game_main .m2e_game_dev_platform .title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_platform .contents {
  text-align: center;
  font-size: 16px;
}

#m2e_game_main .m2e_game_dev_platform .osc_timeline {
  display: grid;
  justify-content: center;
  margin-top: 35px;
}

#m2e_game_main .m2e_game_dev_platform .osc_timeline .timeline_card {
  list-style: none;
  color: #000;
  background-color: #fff;
  padding: 20px;
  box-shadow: 7px 7px 2px 0px #a7bee6;
  border-radius: 0px 30px 0px 30px;
  font-weight: 600;
}

#m2e_game_main .m2e_game_dev_platform .osc_timeline .timeline_card p {
  margin-bottom: 0px;
}

#m2e_game_main .m2e_game_dev_platform .osc_row_align {
  display: flex;
  align-items: center;
}

#m2e_game_main .m2e_game_dev_platform .timeline_margin_right {
  margin-right: 60px;
  position: relative;
}

#m2e_game_main .m2e_game_dev_platform .timeline_margin_left {
  margin-left: 60px;
}

#m2e_game_main .m2e_game_dev_platform .timeline_vertical {
  position: relative;
}

#m2e_game_main .m2e_game_dev_platform .timeline_vertical:after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #0f8bcc;
  position: absolute;
  left: 50%;
}

#m2e_game_main .m2e_game_dev_platform .timeline_counter_before {
  position: relative;
  margin-top: 40px;
  counter-increment: timeline_counter_before;
}

#m2e_game_main .m2e_game_dev_platform .timeline_counter_before:before {
  content: counter(timeline_counter_before);
  width: 45px;
  height: 45px;
  background: linear-gradient(93.54deg, #0789cd 25.11%, #9c68ff 68.78%);
  border-radius: 20px 0px 20px 0px;
  position: absolute;
  left: 50%;
  box-shadow: 4px 4px 0px 0px #a7bee6;
  transform: translate(-50%, 50%);
  color: #fff;
  font-weight: 800;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#m2e_game_main .m2e_game_dev_why {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff;
}

#m2e_game_main .m2e_game_dev_why .title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_why .industries_box {
  display: inline-block;
  border: 1px solid #afafaf;
  transition: 0.3s ease-out;
  position: relative;
  overflow: hidden;
  padding: 20px;
  min-height: 365px;
  margin-top: 20px;
}

#m2e_game_main .m2e_game_dev_why .industries_box:before {
  z-index: 2;
  background: #0789cd33;
  left: inherit;
  bottom: inherit;
  right: -18px;
  width: 37px;
  top: -18px;
  height: 37px !important;
  transform: rotate(45deg);
  content: "";
  position: absolute;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

#m2e_game_main .m2e_game_dev_why .industries_box .sprite_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
}

#m2e_game_main .m2e_game_dev_why .industries_box:hover .sprite_icons {
  transform: rotateY(180deg);
  transition: all 0.4s ease-in;
}

#m2e_game_main .m2e_game_dev_why .industries_box .right_choice_tile {
  opacity: 0;
  padding: 0.3125rem 0;
  visibility: hidden;
  background-color: #0789cd;
  display: block;
  transition: width 0.6s ease-out;
  width: 0;
  padding: 1px 0;
  margin: 10px 0;
  transition: 0.6s ease-out;
}

#m2e_game_main .m2e_game_dev_why .industries_box:hover {
  z-index: 1;
}

#m2e_game_main .m2e_game_dev_why .industries_box:hover:before {
  background: #0789cd;
}

#m2e_game_main .m2e_game_dev_why .industries_box:hover .right_choice_tile {
  display: block;
  opacity: 1;
  visibility: visible;
  width: 7rem;
}

#m2e_game_main .m2e_game_dev_why .industries_box .sub_title {
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 700;
  color: #000;
  font-size: 18px;
}

#m2e_game_main .m2e_game_dev_why .industries_box .sub_contents {
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 16px;
}

#m2e_game_main .m2e_game_dev_mobapp {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #dae9ff;
}

#m2e_game_main .m2e_game_dev_mobapp .title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_mobapp .contents {
  font-size: 16px;
  text-align: center;
}

#m2e_game_main .m2e_game_dev_mobapp .mobile_app_box {
  padding: 20px;
  margin-top: 30px;
  min-height: 410px;
  background: #fff;
  border-radius: 8px;
}

#m2e_game_main .m2e_game_dev_mobapp .mobile_app_box:hover {
  transform: scale(1.08);
  box-shadow: 0px 0px 9px #d3d3d3;
  border-radius: 10px;
}

#m2e_game_main .m2e_game_dev_mobapp .mobile_app_box:hover .sub_title {
  color: #5749dc;
}

#m2e_game_main .m2e_game_dev_mobapp .mobile_app_box .sub_title {
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: 700;
  color: #000;
  font-size: 18px;
}

#m2e_game_main .m2e_game_dev_mobapp .mobile_app_box .sub_contents {
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  min-height: 325px;
}

#m2e_game_main
  .m2e_game_dev_mobapp
  .mobile_app_box
  .m2e_game_dev_mobapp_btn_sec {
  margin-top: 10px;
}

#m2e_game_main .m2e_game_dev_mobapp .mobile_app_box .m2e_game_dev_mobapp_btn {
  padding: 10px 12px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  background: linear-gradient(93.54deg, #0789cd 25.11%, #9c68ff 68.78%);
  border-radius: 15px;
  border: none;
  font-size: 12px;
}

#m2e_game_main .m2e_game_dev_why_choose {
  padding-top: 50px;
  padding-bottom: 50px;
}

#m2e_game_main .m2e_game_dev_why_choose .title {
  text-align: left;
  font-weight: 700;
  font-size: 30px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#m2e_game_main .m2e_game_dev_why_choose .contents {
  font-size: 16px;
  text-align: justify;
}

#m2e_game_main .m2e_game_dev_why_choose .m2e_game_dev_why_choose_btn {
  padding: 15px 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(93.54deg, #0789cd 25.11%, #9c68ff 68.78%);
  border-radius: 15px;
  border: none;
  margin-top: 10px;
  -webkit-animation: glowing 850ms infinite;
  -moz-animation: glowing 850ms infinite;
  -o-animation: glowing 850ms infinite;
  animation: glowing 850ms infinite;
}

#m2e_game_main .m2e_game_dev_why_choose .why_choose_list {
  padding: 20px;
  border: 1px dashed #0789cd;
  font-weight: 700;
  color: #000;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
  min-height: 118px;
  display: flex;
}

#m2e_game_main .m2e_game_dev_why_choose .why_choose_list p {
  margin-bottom: 0px;
}

#m2e_game_main .m2e_game_dev_why_choose .why_choose_list i {
  color: #0789cd;
  font-size: 20px;
  padding-top: 3px;
  padding-right: 8px;
}

@media (min-width: 991px) {
  #m2e_game_main .m2e_game_dev_mobapp .mobile_app_img1 {
    /* background: url('public/images/inner-pages/move-to-earn-game-dev/mob-app-img1.png'); */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
  }

  #m2e_game_main .m2e_game_dev_mobapp .mobile_app_img2 {
    /* background: url('public/images/inner-pages/move-to-earn-game-dev/mob-app-img2.png'); */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
  }

  #m2e_game_main .m2e_game_dev_mobapp .mobile_app_img3 {
    /* background: url('public/images/inner-pages/move-to-earn-game-dev/mob-app-img3.png'); */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
  }

  #m2e_game_main .m2e_game_dev_mobapp .mobile_app_img4 {
    /* /* background: url('public/images/inner-pages/move-to-earn-game-dev/mob-app-img4.png'); */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
  }
}

@media (max-width: 992px) and (min-width: 766px) {
  #m2e_game_main .m2e_game_dev_banner {
    background: linear-gradient(45deg, #64b7e1, #b1c9e9, #a688df);
    padding: 70px 0px;
    display: flex;
    align-items: center;
    text-align: center !important;
    height: 600px;
  }

  #m2e_game_main .m2e_game_dev_banner .title {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
  }

  #m2e_game_main .m2e_game_dev_banner .contents,
  #m2e_game_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  #m2e_game_main .m2e_game_dev_sec,
  #m2e_game_main .m2e_game_dev_features,
  #m2e_game_main .nft_marketplace_whitelabel {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #m2e_game_main .m2e_game_dev_sec .title,
  #m2e_game_main .m2e_game_dev_features .title,
  #m2e_game_main .nft_marketplace_whitelabel .title {
    font-size: 25px;
    text-align: center;
  }

  #m2e_game_main .m2e_game_dev_sec .nft_marketplace_about_btn_sec {
    text-align: center;
  }

  #m2e_game_main .m2e_game_dev_features .features_box {
    min-height: 0px;
  }
}

@media (max-width: 767px) {
  #m2e_game_main .m2e_game_dev_banner {
    background: linear-gradient(45deg, #64b7e1, #b1c9e9, #a688df);
    padding: 70px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 400px;
  }

  #m2e_game_main .m2e_game_dev_banner .title {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
  }

  #m2e_game_main .m2e_game_dev_banner .contents,
  #m2e_game_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  #m2e_game_main .m2e_game_dev_sec,
  #m2e_game_main .m2e_game_dev_comp_sec,
  #m2e_game_main .m2e_game_dev_features,
  #m2e_game_main .m2e_game_dev_platform,
  #m2e_game_main .m2e_game_dev_why,
  #m2e_game_main .m2e_game_dev_mobapp,
  #m2e_game_main .m2e_game_dev_why_choose {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #m2e_game_main .m2e_game_dev_sec .title,
  #m2e_game_main .m2e_game_dev_comp_sec .title,
  #m2e_game_main .m2e_game_dev_features .title,
  #m2e_game_main .m2e_game_dev_platform .title,
  #m2e_game_main .m2e_game_dev_why .title,
  #m2e_game_main .m2e_game_dev_mobapp .title,
  #m2e_game_main .m2e_game_dev_why_choose .title {
    font-size: 25px;
    text-align: center;
  }

  #m2e_game_main .m2e_game_dev_sec .nft_marketplace_about_btn_sec {
    text-align: center;
  }

  #m2e_game_main .m2e_game_dev_features .features_box,
  #m2e_game_main .m2e_game_dev_why .industries_box,
  #m2e_game_main .m2e_game_dev_mobapp .mobile_app_box,
  #m2e_game_main .m2e_game_dev_why_choose .why_choose_list {
    min-height: 0px;
    margin-top: 10px;
  }

  #m2e_game_main .m2e_game_dev_mobapp .mobile_app_box .sub_contents {
    min-height: 0px;
  }

  #m2e_game_main .m2e_game_dev_platform .timeline_margin_right,
  #m2e_game_main .m2e_game_dev_platform .timeline_margin_left {
    margin-left: 0px;
    margin-right: 0px;
  }

  #m2e_game_main .m2e_game_dev_platform .timeline_vertical:after {
    top: 20px;
    left: 8%;
  }

  #m2e_game_main .m2e_game_dev_platform .timeline_counter_before:before {
    left: 8%;
  }

  #m2e_game_main .m2e_game_dev_platform .osc_timeline .timeline_card {
    margin-left: 60px;
  }

  #m2e_game_main .m2e_game_dev_sec .m2e_game_dev_btn_sec,
  #m2e_game_main .m2e_game_dev_why_choose .m2e_game_dev_why_choose_btn_sec {
    text-align: center;
  }

  #m2e_game_main .m2e_game_dev_banner .m2e_game_dev_bnr_btn,
  #m2e_game_main .m2e_game_dev_sec .m2e_game_dev_btn,
  #m2e_game_main .m2e_game_dev_comp_sec .m2e_game_dev_comp_about_btn,
  #m2e_game_main .m2e_game_dev_comp_sec .m2e_game_dev_comp_about_btn1,
  #m2e_game_main .m2e_game_dev_why_choose .m2e_game_dev_why_choose_btn {
    padding: 10px 15px;
    font-weight: 700;
    font-size: 12px;
  }
}

/*======Move to Earn game development company END======*/

/*========Blockchain Crowdfunding Development Company START=======*/

.blockchain_crowd_main .bc_row_flex {
  display: flex;
  justify-content: center;
}

@media (min-width: 991px) {
  .blockchain_crowd_main .banner_sec {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
    align-items: center !important;
    height: 100vh;
    display: flex;
    /* background: url('public/images/inner-pages/blo-crowdfunding/banner.png'); */
  }
}

.blockchain_crowd_main .banner_sec .title {
  font-size: 40px;
  color: #361baa;
  font-weight: 800;
  line-height: 50px;
}

.blockchain_crowd_main .banner_sec .contents {
  color: #000;
  padding-top: 10px;
  font-size: 18px;
  line-height: 32px;
}

.blockchain_crowd_main .banner_sec .banner_btn_sec {
  margin-top: 30px;
}

.blockchain_crowd_main .banner_sec .banner_btn {
  padding: 15px 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(93.54deg, #11b6d0 25.11%, #5e75f6 68.78%);
  border-radius: 30px;
  border: none;
  -webkit-animation: glowing 950ms infinite;
  -moz-animation: glowing 950ms infinite;
  -o-animation: glowing 950ms infinite;
  animation: glowing 950ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #701abb;
    box-shadow: 0 0 0px #701abb;
  }

  50% {
    background-color: #701abb;
    box-shadow: 0 0 10px #701abb;
  }

  100% {
    background-color: #701abb;
    box-shadow: 0 0 0px #7e48e4;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #701abb;
    box-shadow: 0 0 0px #701abb;
  }

  50% {
    background-color: #701abb;
    box-shadow: 0 0 10px #701abb;
  }

  100% {
    background-color: #701abb;
    box-shadow: 0 0 0px #7e48e4;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #701abb;
    box-shadow: 0 0 0px #701abb;
  }

  50% {
    background-color: #701abb;
    box-shadow: 0 0 10px #701abb;
  }

  100% {
    background-color: #701abb;
    box-shadow: 0 0 0px #7e48e4;
  }
}

@keyframes glowing {
  0% {
    background-color: #701abb;
    box-shadow: 0 0 0px #701abb;
  }

  50% {
    background-color: #701abb;
    box-shadow: 0 0 10px #701abb;
  }

  100% {
    background-color: #701abb;
    box-shadow: 0 0 0px #7e48e4;
  }
}

.blockchain_crowd_main .platform_dev {
  padding: 50px 0px;
}

@media (min-width: 991px) {
  .blockchain_crowd_main .platform_dev_bg {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
    align-items: center !important;
    padding: 50px 65px 50px 90px;
    border-radius: 30px;
    display: flex;
    /* background: url('public/images/inner-pages/blo-crowdfunding/sec-2-img1.png'); */
  }
}

.blockchain_crowd_main .platform_dev .title {
  font-size: 35px;
  font-weight: 800;
  text-align: left;
  color: #000;
}

.blockchain_crowd_main .platform_dev .contents {
  color: #000;
  line-height: 1.8;
  text-align: left;
  font-size: 17px;
  margin-bottom: 0px;
  margin-top: 15px;
}

.blockchain_crowd_main .platform_dev .platform_dev_about_btn_sec {
  margin-top: 20px;
  text-align: center;
}

.blockchain_crowd_main .platform_dev .platform_dev_about_btn {
  text-transform: uppercase;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  background: #4dd30d;
  color: #fff !important;
}

.blockchain_crowd_main .platform_dev .platform_dev_about_btn1 {
  text-transform: uppercase;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  background: #00aff0;
  color: #fff !important;
}

.blockchain_crowd_main .how_does {
  padding: 50px 0px;
}

.blockchain_crowd_main .how_does .title {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
}

.blockchain_crowd_main .how_does .contents {
  line-height: 1.8;
  text-align: center;
  font-size: 17px;
  margin-bottom: 0px;
  margin-top: 15px;
}

.blockchain_crowd_main .how_does_list {
  background: #3f89ec;
  padding: 15px;
  margin-top: 30px;
  border-radius: 10px;
  min-height: 325px;
}

.blockchain_crowd_main .how_does_list .sprite_icons {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.blockchain_crowd_main .how_does_list .sub_title {
  color: #fff;
  font-size: 20px;
  margin-top: 0px;
  padding-top: 10px;
}

.blockchain_crowd_main .how_does_list .sub_content {
  color: #fff;
  margin-bottom: 0px;
  line-height: 1.8;
}

.blockchain_crowd_main .features_sec {
  padding: 50px 0px;
  background: #e5e9ff;
}

.blockchain_crowd_main .features_sec .title {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
  color: #000;
}

.blockchain_crowd_main .features_list {
  padding: 10px;
  margin-top: 20px;
  min-height: 160px;
  border: 1px dashed #3f89ec;
  text-align: center;
  border-radius: 5px;
}

.blockchain_crowd_main .features_list:hover {
  box-shadow: 0px 0px 7px 0px #c3c7da;
  transform: scale(1.08);
  border: none;
}

.blockchain_crowd_main .features_list:hover .sub_title {
  color: #3f89ec;
}

.blockchain_crowd_main .features_list .sub_title {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.blockchain_crowd_main .features_list .sub_content {
  color: #000;
  line-height: 1.8;
  margin-bottom: 0px;
}

.blockchain_crowd_main .advantage_sec {
  padding: 50px 0px;
}

.blockchain_crowd_main .advantage_sec .title {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
}

.blockchain_crowd_main .advantage_list {
  background: #3f89ec;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.blockchain_crowd_main .advantage_list .sub_title {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.blockchain_crowd_main .advantage_list .sub_content {
  color: #fff;
  line-height: 1.8;
  margin-bottom: 0px;
}

.blockchain_crowd_main .advantage_list_top {
  margin-top: 60px;
}

.blockchain_crowd_main .advantage_sec .sprite_icons {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.blockchain_crowd_main .create_blockchain {
  padding: 50px 0px;
  background: #e5e9ff;
}

.blockchain_crowd_main .create_blockchain .title {
  color: #000;
  font-size: 35px;
  font-weight: 800;
  text-align: center;
}

.blockchain_crowd_main .create_blockchain_list {
  padding: 5px;
  text-align: center;
  margin-top: 30px;
}

.blockchain_crowd_main .create_blockchain_list:hover {
  transform: scale(1.08);
  box-shadow: 0px 0px 9px #a96ef396;
  border-radius: 10px;
}

.blockchain_crowd_main
  .create_blockchain
  .create_blockchain_list:hover
  .sprite_icons {
  transform: rotateY(180deg);
  transition: all 0.4s ease-in;
}

.blockchain_crowd_main .create_blockchain_list .sub_title {
  color: #000;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
}

.blockchain_crowd_main .create_blockchain_list .sub_content {
  color: #000;
  line-height: 1.8;
  margin-bottom: 0px;
  margin-top: 10px;
}

.blockchain_crowd_main .create_blockchain .sprite_icons {
  width: 60px;
  height: 60px;
  object-fit: none;
}

.blockchain_crowd_main .create_blck_desig {
  width: 75px;
  height: 75px;
  background: linear-gradient(
    39.01deg,
    #4485ef -27.39%,
    #5277f7 17.7%,
    #5b76f7 23.67%,
    #7374f6 33.36%,
    #9a70f4 45.49%,
    #bb6df3 54.4%
  );
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockchain_crowd_main .create_after {
  position: relative;
}

/*.blockchain_crowd_main .create_after:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: linear-gradient(93.54deg, #11b6d0 25.11%, #5e75f6 68.78%);
    top: 50px;
    left: 0px;
  }*/

.blockchain_crowd_main .blockchain_solutions {
  padding: 50px 0px;
}

.blockchain_crowd_main .blockchain_solutions .title {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
}

.blockchain_crowd_main .features_sec .solution_btn_sec {
  margin-top: 40px;
  text-align: center;
}

.blockchain_crowd_main .features_sec .solution_btn {
  padding: 15px 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(93.54deg, #11b6d0 25.11%, #5e75f6 68.78%);
  border-radius: 30px;
  border: none;
  -webkit-animation: glowing 950ms infinite;
  -moz-animation: glowing 950ms infinite;
  -o-animation: glowing 950ms infinite;
  animation: glowing 950ms infinite;
}

.blockchain_crowd_main .blockchain_solutions .solutions_list {
  padding: 15px;
  background: #3f89ec;
  margin-top: 20px;
  text-align: center;
  border-radius: 10px;
  min-height: 230px;
}

.blockchain_crowd_main .blockchain_solutions .solutions_list .sub_title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.blockchain_crowd_main .blockchain_solutions .solutions_list .sub_content {
  color: #fff;
  line-height: 1.8;
  margin-bottom: 0px;
}

.blockchain_crowd_main .blockchain_solutions .solutions_list .sprite_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
}

.blockchain_crowd_main .why_MetaBlock {
  padding: 50px 0px;
  background: #e5e9ff;
}

.blockchain_crowd_main .why_MetaBlock .title {
  font-size: 35px;
  font-weight: 800;
  color: #000;
  text-align: center;
}

.blockchain_crowd_main .why_MetaBlock .contents {
  color: #000;
  text-align: center;
  font-size: 17px;
  line-height: 1.8;
  margin-top: 15px;
  margin-bottom: 0px;
}

.blockchain_crowd_main .why_MetaBlock .why_btn_sec {
  margin-top: 40px;
  text-align: center;
}

.blockchain_crowd_main .why_MetaBlock .why_btn {
  padding: 15px 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: linear-gradient(93.54deg, #11b6d0 25.11%, #5e75f6 68.78%);
  border-radius: 30px;
  border: none;
  -webkit-animation: glowing 950ms infinite;
  -moz-animation: glowing 950ms infinite;
  -o-animation: glowing 950ms infinite;
  animation: glowing 950ms infinite;
}

@media (max-width: 992px) and (min-width: 766px) {
  .blockchain_crowd_main .banner_sec {
    background: linear-gradient(45deg, #64b7e1, #b1c9e9, #a688df);
    padding: 70px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 400px;
  }

  .blockchain_crowd_main .banner_sec .title {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
  }

  .blockchain_crowd_main .banner_sec .contents,
  .blockchain_crowd_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  .blockchain_crowd_main .platform_dev,
  .blockchain_crowd_main .how_does,
  .blockchain_crowd_main .features_sec,
  .blockchain_crowd_main .advantage_sec,
  .blockchain_crowd_main .create_blockchain,
  .blockchain_crowd_main .blockchain_solutions,
  .blockchain_crowd_main .why_MetaBlock {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blockchain_crowd_main .platform_dev .title,
  .blockchain_crowd_main .how_does .title,
  .blockchain_crowd_main .features_sec .title,
  .blockchain_crowd_main .advantage_sec .title,
  .blockchain_crowd_main .create_blockchain .title,
  .blockchain_crowd_main .blockchain_solutions .title,
  .blockchain_crowd_main .why_MetaBlock .title {
    font-size: 25px;
    text-align: center;
  }

  .blockchain_crowd_main .m2e_game_dev_sec .nft_marketplace_about_btn_sec {
    text-align: center;
  }

  .blockchain_crowd_main .m2e_game_dev_features .features_box {
    min-height: 0px;
  }

  .blockchain_crowd_main .banner_sec .banner_btn,
  .blockchain_crowd_main .features_sec .solution_btn,
  .blockchain_crowd_main .why_MetaBlock .why_btn {
    padding: 10px 15px;
    font-weight: 700;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .blockchain_crowd_main .banner_sec {
    background: linear-gradient(45deg, #64b7e1, #b1c9e9, #a688df);
    padding: 70px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 500px;
  }

  .blockchain_crowd_main .banner_sec .title {
    font-size: 25px;
    text-align: center;
    line-height: 35px;
  }

  .blockchain_crowd_main .banner_sec .contents,
  .blockchain_crowd_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  .blockchain_crowd_main .platform_dev,
  .blockchain_crowd_main .how_does,
  .blockchain_crowd_main .features_sec,
  .blockchain_crowd_main .advantage_sec,
  .blockchain_crowd_main .create_blockchain,
  .blockchain_crowd_main .blockchain_solutions,
  .blockchain_crowd_main .why_MetaBlock {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blockchain_crowd_main .platform_dev .title,
  .blockchain_crowd_main .how_does .title,
  .blockchain_crowd_main .features_sec .title,
  .blockchain_crowd_main .advantage_sec .title,
  .blockchain_crowd_main .create_blockchain .title,
  .blockchain_crowd_main .blockchain_solutions .title,
  .blockchain_crowd_main .why_MetaBlock .title {
    font-size: 25px;
    text-align: center;
  }

  .blockchain_crowd_main .platform_dev .contents {
    text-align: center;
  }

  .blockchain_crowd_main .advantage_list_top {
    margin-top: 20px;
  }

  .blockchain_crowd_main .how_does_list,
  .blockchain_crowd_main .features_list,
  .blockchain_crowd_main .blockchain_solutions .solutions_list {
    min-height: 0px;
    margin-top: 10px;
  }

  .blockchain_crowd_main .banner_sec .banner_btn,
  .blockchain_crowd_main .features_sec .solution_btn,
  .blockchain_crowd_main .why_MetaBlock .why_btn,
  .blockchain_crowd_main .platform_dev .platform_dev_about_btn,
  .blockchain_crowd_main .platform_dev .platform_dev_about_btn1 {
    padding: 10px 15px;
    font-weight: 700;
    font-size: 12px;
  }
}

/*========Blockchain Crowdfunding Development Company END=======*/

/*========Smart Contract MLM software development START=======*/

@media (min-width: 991px) {
  #smart_contract_mlm_main .smart_contract_mlm_banner {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top left;
    align-items: center !important;
    height: 100vh;
    display: flex;
    /* background: url('public/images/inner-pages/smart-contract-mlm-software/banner.png'); */
  }
}

#smart_contract_mlm_main .smart_contract_mlm_banner .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  color: #fff;
  margin-top: 50px;
  text-transform: uppercase;
}

#smart_contract_mlm_main .smart_contract_mlm_banner .cus_title {
  color: #54ffff;
}

#smart_contract_mlm_main .smart_contract_mlm_banner .contents {
  color: #fff;
  padding-top: 10px;
  font-size: 18px;
  line-height: 32px;
}

#smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn_sec {
  margin-top: 30px;
}

#smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn {
  padding: 10px 15px;
  text-transform: uppercase;
  background: #00ffff;
  border-radius: 0px 15px;
  font-weight: 700;
  border: none;
  color: #00063e;
  font-size: 15px;
  position: relative;
}

#smart_contract_mlm_main
  .smart_contract_mlm_banner
  .clone_script_bnr_btn:hover {
  background: unset;
  border: 2px solid #00ffff;
  color: #fff;
}

#smart_contract_mlm_main
  .smart_contract_mlm_banner
  .clone_script_bnr_btn::before {
  position: absolute;
  content: "";
  top: -8px;
  left: -8px;
  background: rgba(0, 255, 255, 0.2);
  width: 215px;
  height: 58px;
  border-radius: 0px 20px;
}

#smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn1 {
  padding: 10px 15px;
  text-transform: uppercase;
  border: 2px solid #00ffff;
  border-radius: 0px 15px;
  font-weight: 700;
  color: #fff;
  font-size: 15px;
  margin-left: 35px;
  position: relative;
}

#smart_contract_mlm_main
  .smart_contract_mlm_banner
  .clone_script_bnr_btn1:hover {
  background: #00ffff;
  border: none;
  color: #00063e;
}

#smart_contract_mlm_main
  .smart_contract_mlm_banner
  .clone_script_bnr_btn1::before {
  position: absolute;
  content: "";
  top: -9px;
  left: -10px;
  background: rgba(0, 255, 255, 0.2);
  width: 205px;
  height: 60px;
  border-radius: 0px 20px;
}

#smart_contract_mlm_main .smart_contract_mlm_sec {
  padding: 70px 0px;
}

#smart_contract_mlm_main .smart_contract_mlm_sec .title {
  font-weight: 700;
  font-size: 35px;
  color: #00095e;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#smart_contract_mlm_main .smart_contract_mlm_sec .contents {
  text-align: justify;
  font-size: 16px;
}

#smart_contract_mlm_main
  .smart_contract_mlm_sec
  .smart_contract_mlm_about_btn_sec {
  margin-top: 20px;
}

#smart_contract_mlm_main .smart_contract_mlm_sec .smart_contract_mlm_about_btn {
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 15px;
  background: #4dd30d;
  border-radius: 0px 20px;
  color: #fff !important;
}

#smart_contract_mlm_main
  .smart_contract_mlm_sec
  .smart_contract_mlm_about_btn1 {
  text-transform: uppercase;
  padding: 10px 40px;
  font-weight: 600;
  font-size: 15px;
  background: #00aff0;
  border-radius: 0px 20px;
  color: #fff !important;
}

#smart_contract_mlm_main .smart_contract_mlm_services {
  padding: 70px 0px;
}

#smart_contract_mlm_main .smart_contract_mlm_services .title {
  font-weight: 700;
  font-size: 35px;
  color: #00095e;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#smart_contract_mlm_main .smart_contract_mlm_services .service_box_row {
  padding: 20px;
  margin-top: 30px;
}

#smart_contract_mlm_main .smart_contract_mlm_services .sub_title {
  font-weight: 700;
  font-size: 20px;
  color: #00095e;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
}

#smart_contract_mlm_main .smart_contract_mlm_services .sub_contents {
  font-size: 16px;
  padding-top: 10px;
  margin-bottom: 0px;
  color: #000;
}

#smart_contract_mlm_main .smart_contract_mlm_features {
  padding: 70px 0px;
  background: rgba(0, 49, 179, 0.12);
}

#smart_contract_mlm_main .smart_contract_mlm_features .title {
  font-weight: 700;
  font-size: 35px;
  color: #00095e;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#smart_contract_mlm_main .smart_contract_mlm_features .features_box {
  padding: 20px;
  margin-top: 30px;
  background: #fff;
  border-radius: 0px 25px;
  text-align: center;
  min-height: 240px;
}

#smart_contract_mlm_main .smart_contract_mlm_features .feature_box1:hover {
  border: 3px solid #b500d2;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

#smart_contract_mlm_main .smart_contract_mlm_features .feature_box2:hover {
  border: 3px solid #00ffa3;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

#smart_contract_mlm_main .smart_contract_mlm_features .feature_box3:hover {
  border: 3px solid #8f00ff;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

#smart_contract_mlm_main .smart_contract_mlm_features .feature_box4:hover {
  border: 3px solid #ff7a00;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

#smart_contract_mlm_main .smart_contract_mlm_features .feature_box5:hover {
  border: 3px solid #ff00e5;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

#smart_contract_mlm_main .smart_contract_mlm_features .feature_box6:hover {
  border: 3px solid #d20000;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
}

#smart_contract_mlm_main .smart_contract_mlm_features .sub_title {
  font-weight: 700;
  font-size: 18px;
  color: #00095e;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
}

#smart_contract_mlm_main .smart_contract_mlm_features .sub_contents {
  font-size: 16px;
  padding-top: 10px;
  margin-bottom: 0px;
  color: #000;
}

#smart_contract_mlm_main .smart_contract_mlm_features .sprite_icons {
  width: 50px;
  height: 50px;
  object-fit: none;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits {
  padding: 70px 0px;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .title {
  font-weight: 700;
  font-size: 35px;
  color: #00095e;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .benefits_box {
  padding: 20px;
  margin-top: 30px;
  background: linear-gradient(180deg, rgba(224, 230, 245, 0) 0%, #e0e6f5 100%);
  border-radius: 15px;
  min-height: 270px;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .benefits_box:hover {
  background: linear-gradient(
    180deg,
    #e0e6f5 0%,
    rgba(224, 230, 245, 0) 86.98%
  );
  border: 1px solid #00095e;
  border-radius: 15px;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .title_sec {
  display: flex;
  align-items: center;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .sub_title {
  font-weight: 700;
  font-size: 18px;
  color: #00095e;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 10px;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .sub_contents {
  font-size: 16px;
  padding-top: 10px;
  margin-bottom: 0px;
  color: #000;
}

#smart_contract_mlm_main .smart_contract_mlm_benefits .sprite_icons {
  width: 46px;
  height: 46px;
  object-fit: none;
}

@media (min-width: 991px) {
  #smart_contract_mlm_main .smart_contract_mlm_why {
    background-size: cover;
    background-repeat: no-repeat !important;
    background-position: top left;
    align-items: center;
    display: flex;
    /* background: url('public/images/inner-pages/smart-contract-mlm-software/why_bg.png'); */
  }
}

#smart_contract_mlm_main .smart_contract_mlm_why .title {
  font-weight: 700;
  font-size: 35px;
  color: #00095e;
  line-height: 50px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#smart_contract_mlm_main .smart_contract_mlm_why .contents {
  text-align: justify;
  font-size: 16px;
}

#smart_contract_mlm_main .smart_contract_mlm_why .why_list {
  display: flex;
  padding: 10px 0px;
}

#smart_contract_mlm_main .smart_contract_mlm_why .why_list img {
  width: 20px;
}

#smart_contract_mlm_main .smart_contract_mlm_why .why_list .sub_title {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 8px;
}

#smart_contract_mlm_main .smart_contract_mlm_why .clone_script_why_btn_sec {
  margin-top: 30px;
  text-align: center;
}

#smart_contract_mlm_main .smart_contract_mlm_why .clone_script_why_btn {
  padding: 10px 15px;
  text-transform: uppercase;
  background: #00063e;
  border-radius: 0px 15px;
  font-weight: 700;
  border: none;
  color: #fff;
  font-size: 15px;
  position: relative;
}

#smart_contract_mlm_main .smart_contract_mlm_why .clone_script_why_btn:hover {
  background: unset;
  border: 2px solid #00063e;
  color: #00063e;
}

#smart_contract_mlm_main .smart_contract_mlm_why .clone_script_why_btn::before {
  position: absolute;
  content: "";
  top: -8px;
  left: -8px;
  background: rgb(0 9 94 / 18%);
  width: 270px;
  height: 58px;
  border-radius: 0px 20px;
}

@media (max-width: 992px) and (min-width: 766px) {
  #smart_contract_mlm_main .smart_contract_mlm_banner {
    background: linear-gradient(45deg, #001c59, #002762, #063071);
    display: flex;
    align-items: center;
    text-align: center;
    height: 450px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .title {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .contents,
  #smart_contract_mlm_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_sec,
  #smart_contract_mlm_main .smart_contract_mlm_services,
  #smart_contract_mlm_main .smart_contract_mlm_features,
  #smart_contract_mlm_main .smart_contract_mlm_benefits {
    padding: 50px 0px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_sec .title,
  #smart_contract_mlm_main .smart_contract_mlm_services .title,
  #smart_contract_mlm_main .smart_contract_mlm_features .title,
  #smart_contract_mlm_main .smart_contract_mlm_benefits .title {
    font-size: 26px;
    text-align: center;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_sec
    .smart_contract_mlm_about_btn_sec,
  #smart_contract_mlm_main .smart_contract_mlm_sec .contents {
    text-align: center;
  }

  #smart_contract_mlm_main .smart_contract_mlm_services .service_box_row {
    margin-top: 0px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn1 {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
    margin-left: 20px;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_banner
    .clone_script_bnr_btn::before {
    top: -6px;
    left: -6px;
    width: 202px;
    height: 52px;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_banner
    .clone_script_bnr_btn1::before {
    top: -8px;
    left: -8px;
    width: 192px;
    height: 55px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_why {
    background: #e0e6f5;
    padding: 70px 0px;
  }
}

@media (max-width: 767px) {
  #smart_contract_mlm_main .smart_contract_mlm_banner {
    background: linear-gradient(45deg, #001c59, #002762, #063071);
    display: flex;
    align-items: center;
    text-align: center;
    height: 550px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .title {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .contents,
  #smart_contract_mlm_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_sec,
  #smart_contract_mlm_main .smart_contract_mlm_services,
  #smart_contract_mlm_main .smart_contract_mlm_features,
  #smart_contract_mlm_main
    .smart_contract_mlm_benefits#smart_contract_mlm_main
    .smart_contract_mlm_why {
    padding: 50px 0px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_sec .title,
  #smart_contract_mlm_main .smart_contract_mlm_services .title,
  #smart_contract_mlm_main .smart_contract_mlm_features .title,
  #smart_contract_mlm_main .smart_contract_mlm_benefits .title,
  #smart_contract_mlm_main .smart_contract_mlm_why .title {
    font-size: 26px;
    text-align: center;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_sec
    .smart_contract_mlm_about_btn_sec,
  #smart_contract_mlm_main .smart_contract_mlm_sec .contents {
    text-align: center;
  }

  #smart_contract_mlm_main .smart_contract_mlm_services .service_box_row {
    margin-top: 0px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_features .features_box,
  #smart_contract_mlm_main .smart_contract_mlm_benefits .benefits_box {
    margin-top: 20px;
    min-height: 0px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn,
  #smart_contract_mlm_main .smart_contract_mlm_why .clone_script_why_btn,
  #smart_contract_mlm_main
    .smart_contract_mlm_sec
    .smart_contract_mlm_about_btn {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn1 {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
    margin-left: 20px;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_banner
    .clone_script_bnr_btn::before {
    top: -6px;
    left: -6px;
    width: 202px;
    height: 52px;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_banner
    .clone_script_bnr_btn1::before {
    top: -8px;
    left: -8px;
    width: 192px;
    height: 55px;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_sec
    .smart_contract_mlm_about_btn1 {
    padding: 10px 35px;
    font-weight: 800;
    font-size: 14px;
  }

  #smart_contract_mlm_main
    .smart_contract_mlm_why
    .clone_script_why_btn::before {
    top: -6px;
    left: -6px;
    width: 255px;
    height: 52px;
  }

  #smart_contract_mlm_main .smart_contract_mlm_why {
    background: #e0e6f5;
    padding: 50px 0px;
  }
}

@media (max-width: 424px) {
  #smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn1 {
    margin-top: 20px !important;
  }

  #smart_contract_mlm_main .smart_contract_mlm_banner .clone_script_bnr_btn {
    margin-left: 20px;
  }
}

/*======Smart Contract MLM software development END======*/

/*========Metamask Wallet Clone Script START=======*/

#metamask_clone_main .cus_row {
  justify-content: center;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_banner {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top right;
    align-items: center !important;
    height: 100vh;
    display: flex;
    /* background: url('public/images/inner-pages/metamask-clone/banner.webp'); */
  }
}

#metamask_clone_main .metamask_clone_banner .title {
  font-weight: 800;
  font-size: 35px;
  line-height: 55px;
  color: #000;
  margin-top: 50px;
  text-transform: uppercase;
}

#metamask_clone_main .metamask_clone_banner .cus_title {
  color: #000;
  font-size: 55px;
  font-weight: 900 !important;
}

#metamask_clone_main .metamask_clone_banner .contents {
  color: #000;
  padding-top: 10px;
  font-size: 18px;
  line-height: 32px;
}

#metamask_clone_main .metamask_clone_banner .metamask_bnr_btn_sec {
  margin-top: 30px;
}

#metamask_clone_main .metamask_clone_banner .metamask_bnr_btn {
  padding: 10px 25px;
  background: linear-gradient(98.19deg, #4542cf -0.41%, #d6518b 83.06%);
  box-shadow: 0px 0px 25px rgba(213, 81, 139, 0.5);
  border-radius: 35px;
  font-weight: 700;
  border: none;
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_banner .metamask_bnr_btn1 {
  padding: 10px 20px;
  border: 2px solid #d6518b;
  border-radius: 35px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_sec {
  padding-top: 70px;
  padding-bottom: 180px;
  background: #f0f0fc;
  position: relative;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_sec:after {
    position: absolute;
    content: "";
    top: 5%;
    right: 5%;
    height: 96px;
    width: 128px;
    background-repeat: no-repeat !important;
    /* background: url(public/images/inner-pages/metamask-clone/cloud.webp); */
  }
}

#metamask_clone_main .metamask_clone_sec .title {
  font-weight: 800;
  font-size: 35px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_sec .contents {
  text-align: justify;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_card_sec .metamask_about_btn_sec {
  margin-top: 10px;
}

#metamask_clone_main .metamask_clone_card_sec .metamask_about_btn {
  text-transform: uppercase;
  padding: 8px 18px;
  font-weight: 600;
  font-size: 13px;
  background: #4dd30d;
  border-radius: 35px;
  color: #fff !important;
}

#metamask_clone_main .metamask_clone_card_sec .metamask_about_btn1 {
  text-transform: uppercase;
  padding: 8px 35px;
  font-weight: 600;
  font-size: 13px;
  background: #00aff0;
  border-radius: 35px;
  color: #fff !important;
  margin-left: 5px;
}

#metamask_clone_main .metamask_clone_bg {
  background: linear-gradient(180deg, #3b1479 0%, #af54db 100%);
}

#metamask_clone_main .metamask_clone_card_sec {
  padding: 0px;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_card_sec #metamask_clone_card {
    background-repeat: no-repeat !important;
    background-position: top left;
    align-items: center;
    display: flex;
    /* background: url('public/images/inner-pages/metamask-clone/metamask-card.webp'); */
    border-radius: 35px;
    padding: 30px;
    margin-top: -120px;
  }
}

#metamask_clone_main .metamask_clone_card_sec .title {
  font-weight: 700;
  font-size: 35px;
  color: #000;
  line-height: 40px;
  margin-bottom: 15px;
  text-transform: capitalize;
  margin-top: 0px;
}

#metamask_clone_main .metamask_clone_card_sec .contents {
  text-align: justify;
  font-size: 16px;
  margin-bottom: 10px;
}

#metamask_clone_main .metamask_clone_features {
  padding-top: 70px;
  padding-bottom: 100px;
  position: relative;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_features:after {
    position: absolute;
    content: "";
    top: 12%;
    left: 5%;
    height: 96px;
    width: 128px;
    background-repeat: no-repeat !important;
    /* background: url(public/images/inner-pages/metamask-clone/cloud.webp); */
  }
}

#metamask_clone_main .metamask_clone_features .contents {
  text-align: center;
  color: #fff;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_features .title {
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#metamask_clone_main .metamask_clone_features .features_cus_col {
  margin: 0px;
  padding: 0px;
}

#metamask_clone_main .metamask_clone_features .feat_cus_row {
  margin-left: -85px;
  margin-right: -85px;
  justify-content: center;
  position: relative;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_features .feat_cus_row:before {
    position: absolute;
    content: "";
    top: -15%;
    left: 12%;
    height: 400px;
    width: 100%;
    background-repeat: no-repeat !important;
    /* background: url(public/images/inner-pages/metamask-clone/map.webp); */
  }
}

#metamask_clone_main .metamask_clone_features .features_box {
  padding: 30px 15px;
  background: #fff;
  border-radius: 15px;
  min-height: 380px;
  margin-right: 10px;
  position: relative;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_features .features_box:before {
    position: absolute;
    content: "";
    left: 0px;
    bottom: -98px;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 15.02%,
      rgba(255, 255, 255, 0.33) 100%
    );
    filter: blur(1px);
    border-radius: 15px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

#metamask_clone_main .metamask_clone_features .features_box_eve {
  padding: 30px 15px;
  margin-top: 100px;
  background: #fff;
  border-radius: 15px;
  min-height: 355px;
  margin-right: 10px;
  position: relative;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_features .features_box_eve:before {
    position: absolute;
    content: "";
    left: 0px;
    bottom: -98px;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 15.02%,
      rgba(255, 255, 255, 0.33) 100%
    );
    filter: blur(1px);
    border-radius: 15px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

#metamask_clone_main .metamask_clone_features .sub_title {
  font-weight: 700;
  font-size: 17px;
  color: #000;
  margin-top: 15px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_features .sub_contents {
  font-size: 15px;
  padding-top: 10px;
  margin-bottom: 0px;
  color: #000;
}

#metamask_clone_main .metamask_clone_features .sprite_icons {
  width: 54px;
  height: 54px;
  object-fit: none;
}

#metamask_clone_main .metamask_clone_benefits {
  padding: 50px 0px;
}

#metamask_clone_main .metamask_clone_benefits .title {
  font-weight: 700;
  font-size: 35px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#metamask_clone_main .metamask_clone_benefits .contents {
  text-align: center;
  color: #000;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box {
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box1 {
  background: #e8dbf6;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box2 {
  background: #f6f0db;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box3 {
  background: #dff6db;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box4 {
  background: #dbf4f6;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box5 {
  background: #f5dbf6;
}

#metamask_clone_main .metamask_clone_benefits .benefits_box6 {
  background: #dbe9f6;
}

#metamask_clone_main .metamask_clone_benefits .sub_title {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 5px;
}

#metamask_clone_main .metamask_clone_crypto {
  padding: 50px 0px;
  background: #f0f0fc;
}

#metamask_clone_main .metamask_clone_crypto .title {
  font-weight: 700;
  font-size: 35px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#metamask_clone_main .metamask_clone_crypto .contents {
  text-align: center;
  color: #000;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_crypto .crypto_cus_col {
  margin: 0px;
  padding: 0px;
}

#metamask_clone_main .metamask_clone_crypto .crypto_box {
  padding: 20px 12px;
  background: #ffffff;
  border-radius: 15px;
  text-align: center;
  margin-right: 20px;
  margin-top: 20px;
}

#metamask_clone_main .metamask_clone_crypto .sub_title {
  font-weight: 600;
  font-size: 17px;
  color: #000;
  line-height: 1.4;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_crypto .sprite_icons {
  height: 78px;
  width: 82px;
  object-fit: none;
}

@media (min-width: 991px) {
  #metamask_clone_main .metamask_clone_types {
    padding-top: 30px;
    background-repeat: no-repeat !important;
    background-position: center;
    /* background: url('public/images/inner-pages/metamask-clone/types-bg.svg'); */
  }
}

#metamask_clone_main .metamask_clone_types .title {
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_types .contents {
  text-align: justify;
  color: #fff;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_types .types_box {
  padding: 15px;
  margin-top: -40px;
  background: linear-gradient(180deg, #f0f0fc 0%, #ffd8fd 100%);
  box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  min-height: 395px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#metamask_clone_main .metamask_clone_types .sub_title {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_types .sub_contents {
  font-size: 16px;
  padding-top: 8px;
  color: #000;
  margin-bottom: 10px;
}

#metamask_clone_main .metamask_clone_types .sprite_icons {
  width: 60px;
  height: 70px;
  object-fit: none;
}

#metamask_clone_main .metamask_clone_techstack {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: -220px;
}

#metamask_clone_main .metamask_clone_techstack .title {
  font-weight: 700;
  font-size: 35px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_techstack .contents {
  text-align: justify;
  color: #000;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_techstack .metamask_tech_btn {
  padding: 10px 25px;
  background: linear-gradient(98.19deg, #4542cf -0.41%, #d6518b 83.06%);
  box-shadow: 0px 0px 25px rgba(213, 81, 139, 0.5);
  border-radius: 35px;
  font-weight: 700;
  border: none;
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_why {
  padding: 50px 0px;
  background: #f0f0fc;
}

#metamask_clone_main .metamask_clone_why .title {
  font-weight: 700;
  font-size: 35px;
  color: #000;
  line-height: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}

#metamask_clone_main .metamask_clone_why .contents {
  text-align: center;
  color: #000;
  font-size: 16px;
}

#metamask_clone_main .metamask_clone_why .why_box {
  padding: 10px;
  text-align: center;
  margin-top: 20px;
}

#metamask_clone_main .metamask_clone_why .sub_title {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-bottom: 0px;
  line-height: 25px;
  text-transform: capitalize;
}

#metamask_clone_main .metamask_clone_why .sprite_icons {
  width: 90px;
  height: 90px;
  object-fit: none;
}

@media (max-width: 992px) and (min-width: 766px) {
  #metamask_clone_main .metamask_clone_banner {
    background: linear-gradient(
      101.83deg,
      #b7b7e2 10.6%,
      #f5ddf7 43.45%,
      #ffcce3 77.7%
    );
    display: flex;
    align-items: center;
    text-align: center;
    height: 500px;
  }

  #metamask_clone_main .metamask_clone_banner .title {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
  }

  #metamask_clone_main .metamask_clone_banner .contents,
  #metamask_clone_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  #metamask_clone_main .metamask_clone_sec,
  #metamask_clone_main .metamask_clone_card_sec,
  #metamask_clone_main .metamask_clone_features,
  #metamask_clone_main .smart_contract_mlm_benefits {
    padding: 30px 0px;
  }

  #metamask_clone_main .metamask_clone_sec .title,
  #metamask_clone_main .metamask_clone_card_sec .title,
  #metamask_clone_main .metamask_clone_features .title,
  #metamask_clone_main .smart_contract_mlm_benefits .title {
    font-size: 26px;
    text-align: center;
  }

  #metamask_clone_main .metamask_clone_sec .smart_contract_mlm_about_btn_sec,
  #metamask_clone_main .metamask_clone_sec .contents {
    text-align: center;
  }

  #metamask_clone_main .metamask_clone_card_sec .service_box_row {
    margin-top: 0px;
  }

  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
  }

  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn1 {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
    margin-left: 20px;
  }

  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn::before {
    top: -6px;
    left: -6px;
    width: 202px;
    height: 52px;
  }

  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn1::before {
    top: -8px;
    left: -8px;
    width: 192px;
    height: 55px;
  }

  #metamask_clone_main .smart_contract_mlm_why {
    background: #e0e6f5;
    padding: 70px 0px;
  }
}

@media (max-width: 767px) {
  #metamask_clone_main .metamask_clone_banner {
    background: linear-gradient(
      101.83deg,
      #b7b7e2 10.6%,
      #f5ddf7 43.45%,
      #ffcce3 77.7%
    );
    display: flex;
    align-items: center;
    text-align: center;
    height: 550px;
  }

  #metamask_clone_main .metamask_clone_banner .title {
    font-size: 25px;
    text-align: center;
    line-height: 40px;
  }

  #metamask_clone_main .metamask_clone_banner .cus_title {
    font-size: 40px;
  }

  #metamask_clone_main .metamask_clone_banner .contents,
  #metamask_clone_main .met_event_plt_sec .contents {
    font-size: 16px;
    line-height: 27px;
  }

  #metamask_clone_main .metamask_clone_sec,
  #metamask_clone_main .metamask_clone_card_sec,
  #metamask_clone_main .metamask_clone_features,
  #metamask_clone_main .metamask_clone_benefits,
  #metamask_clone_main .metamask_clone_crypto,
  #metamask_clone_main .metamask_clone_types,
  #metamask_clone_main .metamask_clone_why {
    padding: 30px 0px;
  }

  #metamask_clone_main .metamask_clone_card_sec .metamask_about_btn1 {
    padding: 8px 25px;
    font-size: 12px;
    margin-left: 0px;
  }

  #metamask_clone_main .metamask_clone_card_sec .metamask_about_btn {
    padding: 8px 8px;
    font-size: 12px;
    border-radius: 35px;
  }

  #metamask_clone_main .metamask_clone_sec .title,
  #metamask_clone_main .metamask_clone_card_sec .title,
  #metamask_clone_main .metamask_clone_features .title,
  #metamask_clone_main .metamask_clone_benefits .title,
  #metamask_clone_main .metamask_clone_crypto .title,
  #metamask_clone_main .metamask_clone_types .title,
  #metamask_clone_main .metamask_clone_why .title,
  #metamask_clone_main .metamask_clone_techstack .title {
    font-size: 26px;
    text-align: center;
  }

  #metamask_clone_main #metamask_clone_card {
    background: linear-gradient(
      101.83deg,
      #b7b7e2 10.6%,
      #f5ddf7 43.45%,
      #ffcce3 77.7%
    );
    border-radius: 35px;
    margin: 0px 10px;
    padding: 20px 15px;
  }

  #metamask_clone_main .metamask_clone_features .feat_cus_row {
    margin-left: 0px;
    margin-right: 0px;
  }

  #metamask_clone_main .metamask_clone_features .features_box_eve {
    margin-top: 20px;
    min-height: 0px;
  }

  #metamask_clone_main .metamask_clone_sec .metamask_about_btn_sec,
  #metamask_clone_main .metamask_clone_sec .contents,
  #metamask_clone_main .metamask_clone_types .contents,
  #metamask_clone_main .metamask_clone_techstack .contents,
  .metamask_tech_btn_sec {
    text-align: center;
  }

  #metamask_clone_main .metamask_clone_crypto .crypto_box {
    margin: 20px;
  }

  #metamask_clone_main .metamask_clone_features .features_box,
  #metamask_clone_main .smart_contract_mlm_benefits .benefits_box,
  #metamask_clone_main .metamask_clone_benefits .benefits_box,
  #metamask_clone_main .metamask_clone_types .types_box {
    margin-top: 20px;
    min-height: 0px;
  }

  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn {
    padding: 10px 15px;
    font-weight: 800;
    font-size: 14px;
  }

  #metamask_clone_main .metamask_clone_banner .metamask_bnr_btn {
    padding: 10px 15px;
    font-size: 13px;
  }

  #metamask_clone_main .metamask_clone_banner .metamask_bnr_btn1 {
    padding: 9px 10px;
    font-size: 13px;
    margin-left: 0px;
  }

  #metamask_clone_main .metamask_clone_types {
    background: linear-gradient(180deg, #3b1479 0%, #af54db 106.72%);
  }

  #metamask_clone_main .metamask_clone_techstack {
    margin-top: 0px;
    padding: 50px 0px;
  }
}

@media (max-width: 424px) {
  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn1 {
    margin-top: 20px !important;
  }

  #metamask_clone_main .metamask_clone_banner .clone_script_bnr_btn {
    margin-left: 20px;
  }
}

/*======Metamask Wallet Clone Script END======*/
