#blk_game_dev_comp_main {
    background: #090909;
}

#blk_game_dev_comp_main .blk_game_dev_comp_banner {
    background: url('/public/images/banner\ \(2\).webp');
    /* D:\projects\blockchain\blockchain\public\banner (2).webp */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
}

@media (max-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_banner {
        position: relative;
        z-index: 1;
        text-align: center;
        background-position: 100% !important;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_banner:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #090909cf;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_banner .title {
    width: 600px;
    font-weight: 200;
    font-size: 40px !important;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    color: #fff;
    letter-spacing: 6px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_banner .bnr_clr_title {
    font-size: 60px;
    font-weight: 900;
    background: linear-gradient(160deg, #E92626 0%, #E022AB 34.95%, #B9E800 63.21%, #0FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    line-height: 1;
}

#blk_game_dev_comp_main .blk_game_dev_comp_banner .contents {
    font-size: 16px !important;
    line-height: 1.9;
    margin-bottom: 10px;
    font-weight: 300;
    padding-top: 10px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_banner .banner_btn_sec {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_banner .banner_btn {
    position: relative;
    background: linear-gradient(138deg, #8FED39 0%, #07FEF5 100%);
    color: #090909;
    padding: 10px 20px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 92% 100%, 8% 100%, 0% 70%);
    text-transform: capitalize;
    border-radius: 0px;
    border: none;
    font-size: 16px;
    font-weight: 600;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec {
    padding: 50px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .big_title {
    font-size: 130px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: rgba(143, 237, 57, 0.05);
    letter-spacing: 2px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn_sec {
    margin-top: 30px;
    display: flex;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn {
    text-transform: uppercase;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 15px;
    color: #fff !important;
    border-radius: 11px;
    border: 1px solid #52FF00;
    background: rgba(82, 255, 0, 0.15);
    margin-right: 10px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn.mobile {
    display: none;
}

#blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn1 {
    text-transform: uppercase;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 15px;
    border-radius: 11px;
    border: 1px solid #00F0FF;
    background: rgba(0, 240, 255, 0.15);
    color: #fff !important;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whysec {
    padding: 50px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whysec .why_blockchain_contsec {
    margin-top: -15%;
    text-align: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whysec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whysec .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whysec .blk_game_btn_sec {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whysec .blk_game_btn {
    position: relative;
    background: linear-gradient(138deg, #8FED39 0%, #07FEF5 100%);
    color: #090909;
    padding: 10px 20px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 92% 100%, 8% 100%, 0% 70%);
    text-transform: capitalize;
    border-radius: 0px;
    border: none;
    font-size: 16px;
    font-weight: 600;
}

@media (min-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_services {
        /* background: url('./asset/inner-pages-new/blockchain-game-development-company/service-bg.webp?v1'); */
        padding: 60px 0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 100vh;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .cus_title {
    font-size: 50px;
    color: #fff;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .service_tab_sec {
    border-radius: 18px;
    border: 1px solid #E022AB;
    margin-top: 30px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .service_tab_sec #left_line {
    position: relative;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .service_tab_sec #left_line:after {
    position: absolute;
    content: '';
    width: 2px;
    right: 0%;
    top: 0%;
    height: 100%;
    background: linear-gradient(180deg, #E12298 0%, #BBDC0A 100%);
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs {
    padding: 40px;
    border-bottom: unset;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs .nav-link {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    padding: 20px 0px;
    position: relative;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs .nav-link.active,
#blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs .nav-link:hover {
    background-color: unset;
    border: none !important;
    background: linear-gradient(160deg, #E92626 0%, #E022AB 34.95%, #B9E800 60.21%, #0FF 75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs .nav-link.active:after {
    position: absolute;
    content: '';
    left: 0%;
    bottom: 12%;
    background: linear-gradient(63deg, rgba(9, 11, 12, 0.00) 0%, #E022AB 50.48%, rgba(9, 9, 9, 0.00) 100%);
    width: 200px;
    height: 2px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .tab-content {
    padding: 40px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .sub_title {
    background: linear-gradient(48deg, #E92626 0%, #E022AB 10.95%, #B9E800 20.21%, #0FF 36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-top: 20px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .sub_contents {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1.8;
}

#blk_game_dev_comp_main .blk_game_dev_comp_services .tab-content .service_img {
    width: 500px;
}

@media (min-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_techstack {
        background: url('/public/images/techstack-sliderbg.webp');
        padding-top: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 916px;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .carousel_sec {
    position: absolute;
    top: 32%;
    left: 74%;
    transform: translate(-40%, -50%);
    width: 100%;
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .carousel_container {
    position: relative;
    max-width: 680px;
    width: 100%;
    min-height: 916px;
}

@media(max-width: 1300px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_techstack .carousel_container {
        max-width: 600px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_techstack .carousel_sec {
        position: absolute;
        top: 34%;
        left: 84%;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .sub_title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .techstack_list {
    text-align: center;
    margin: 20px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .slick-slide .sprite_icons {
    display: initial;
    width: 101px;
    height: 100px;
    object-fit: none;
}

#blk_game_dev_comp_main .blk_game_dev_comp_techstack .slick-prev:before,
#blk_game_dev_comp_main .blk_game_dev_comp_techstack .slick-next:before {
    display: none;
}

@media (min-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_benefits {
        background: url('/public/images/benefits-bg.webp');
        /* D:\projects\blockchain\blockchain\public\benefits-bg.webp */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top right;
        min-height: 100vh;
        padding: 50px 0px;
    }
}

@media (min-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_benefits .cus_container {
        max-width: 1200px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_genres .cus_container {
        max-width: 1320px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_approaches .cus_container {
        max-width: 1400px;
    }
}

@media (max-width: 1400px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_approaches .cus_container {
        max-width: 1140px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock {
        background-position: 20% !important;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_benefits .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_benefits .sprite_icons {
    width: 70px;
    height: 70px;
    object-fit: none;
    -webkit-animation: change_bg 3s infinite;
    animation: change_bg 3s linear infinite alternate;
}

#blk_game_dev_comp_main .blk_game_dev_comp_benefits .benefits_box-2 {
    display: flex;
    padding: 20px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_benefits .contents_sec {
    margin-left: 20px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_benefits .sub_title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_benefits .sub_contents {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1.8;
}

@-webkit-keyframes change_bg {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(360deg);
    }
}

@keyframes change_bg {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(360deg);
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres {
    padding: 50px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .genres_box {
    border-radius: 7px;
    background: #000;
    padding: 15px;
    margin-top: 30px;
    transition: all ease-in-out 0.8s;
    margin-right: 12px;
    min-height: 475px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .genres_box:hover {
    transition: all ease-in-out 0.8s;
    background: radial-gradient(590px at 8.2% 13.8%, rgb(0, 0, 0) 0%, rgb(68, 66, 66) 90%);
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .sub_title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-top: 10px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .sub_contents {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1.8;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec2,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec3,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec4,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec5,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec6,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec7,
#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec8 {
    position: relative;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ff9900ab;
    mix-blend-mode: lighten;
    filter: blur(50px);
    width: 199px;
    height: 119px;
    opacity: 0;
    transition: all ease-in-out 0.8s;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec2:after {
    background: #4200FF;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec3:after {
    background: #ec48f48f;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec4:after {
    background: #e02409;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec5:after {
    background: #1200ff;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec6:after {
    background: #89ff00db;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec7:after {
    background: #0500FF;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec8:after {
    background: #0010ff;
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .img_sec img {
    max-width: 100%;
    width: 100%;
    min-height: 220px;
    transition: all ease-in-out 0.8s;
    filter: saturate(0.5);
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .genres_box:hover .img_sec img {
    transition: all ease-in-out 0.8s;
    filter: saturate(1.5);
}

#blk_game_dev_comp_main .blk_game_dev_comp_genres .genres_box:hover .img_sec::after {
    transition: all ease-in-out 0.8s;
    opacity: 1;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones {
    padding: 50px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_box {
    padding: 20px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.8s ease-in-out;
    position: relative;
    background: rgba(164, 162, 162, 0.1);
    box-shadow: 2.3034431934px -2.3034431934px 2.3034431934px 0px rgba(182, 182, 182, 0.4) inset;
    -webkit-backdrop-filter: blur(11.5172157288px);
    backdrop-filter: blur(11.5172157288px);
    min-height: 265px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_box:hover .contents_sec {
    display: block;
    transition: all 0.8s ease-in-out;
    z-index: 1;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_box .contents_sec {
    display: none;
    position: absolute;
    left: 0%;
    top: 8%;
    transition: all 0.8s ease-in-out;
    padding: 20px 15px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_box:hover .sub_title {
    display: none;
    transition: all 2s ease-in-out;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .sub_title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    transition: all 2s ease-in-out;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .sub_title1 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    transition: all 0.8s ease-in-out;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .sub_contents {
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0px;
    line-height: 1.8;
    transition: all 0.8s ease-in-out;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_img {
    transition: all 0.8s ease-in-out;
    margin-bottom: 20px;
    position: relative;
    filter: blur(0px);
    transition: all 0.8s ease-in-out;
    max-width: 100%;
    height: auto;
}

#blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_box:hover .clone_img {
    filter: blur(15px);
    transition: all 0.8s ease-in-out;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches {
    padding: 50px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_box {
    padding: 20px 0px 20px 20px;
    border-radius: 30px 0px 0px 30px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(121, 121, 121, 0.08) 54.57%, rgba(9, 9, 9, 0.00) 100%);
    margin-top: 20px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_box1 {
    padding: 20px 20px 20px 0px;
    border-radius: 0px 30px 30px 0px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.05) 0%, rgba(121, 121, 121, 0.08) 54.57%, rgba(9, 9, 9, 0.00) 100%);
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_box2 {
    padding: 20px;
    text-align: center;
    border-radius: 30px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.01) 49.54%, rgba(255, 255, 255, 0.03) 100%);
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .head_sec {
    display: flex;
    align-items: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .sub_title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-left: 10px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_box2 .sub_title1 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-left: 10px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .sub_contents {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1.8;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .sprite_icons {
    width: 37px;
    height: 36px;
    object-fit: none;
    -webkit-animation: change_bg 3s infinite;
    animation: change_bg 3s linear infinite alternate;
}

#blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_box2 .sprite_icons1 {
    width: 36px;
    height: 36px;
    object-fit: none;
    margin-top: -13%;
    -webkit-animation: change_bg 3s infinite;
    animation: change_bg 3s linear infinite alternate;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best {
    padding-bottom: 50px;
    margin-top: -10%;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab {
    margin-top: 50px;
    border: none;
    justify-content: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    border-radius: 7px 7px 0px 0px;
    padding: 10px 30px;
    position: relative;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link:hover {
    border-color: transparent;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link.active:after {
    position: absolute;
    content: '';
    left: 5%;
    bottom: 0%;
    background: linear-gradient(63deg, rgba(9, 11, 12, 0.00) 0%, #E022AB 50.48%, rgba(9, 9, 9, 0.00) 100%);
    width: 150px;
    height: 2px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link.active {
    background: linear-gradient(160deg, #E92626 0%, #E022AB 34.95%, #B9E800 63.21%, #0FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: none;
    font-weight: 500 !important;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best .tab-content .img_sec {
    text-align: center;
    margin-top: 50px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_best .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
}

#blk_game_dev_comp_main .blk_game_dev_comp_top {
    padding: 50px 0px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_top .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    margin-top: -10%;
}

#blk_game_dev_comp_main .blk_game_dev_comp_top .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    padding-top: 5px;
}

@media(min-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_top #top_blockchain_card {
        /* background: url('asset/inner-pages-new/blockchain-game-development-company/top-blockchain.webp?v1'); */
        background-repeat: no-repeat;
        min-height: 727px;
        display: flex;
        align-items: center;
        padding: 30px;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_top .blk_game_btn_sec {
    margin-top: 30px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_top .blk_game_btn {
    position: relative;
    background: linear-gradient(138deg, #8FED39 0%, #07FEF5 100%);
    color: #090909;
    padding: 10px 20px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 92% 100%, 8% 100%, 0% 70%);
    text-transform: capitalize;
    border-radius: 0px;
    border: none;
    font-size: 16px;
    font-weight: 600;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock {
    padding: 50px 0px;
}

@media(min-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock {
        /* background: url('asset/inner-pages-new/blockchain-game-development-company/why-MetaBlock.webp?v1'); */
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        align-items: center;
    }
}

#blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock .title {
    font-size: 35px;
    color: #fff;
    font-weight: 700;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    margin-bottom: 20px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock .why_list {
    display: flex;
    margin-top: 20px;
}

#blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock .sub_title {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 1.8;
    padding-left: 10px;
}

@media(max-width: 991px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_banner {
        display: flex;
        align-items: center;
        padding: 50px 0px;
        text-align: center;
        min-height: 700px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_banner .banner_sec {
        margin-top: 10%;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_banner .banner_btn_sec {
        justify-content: center;
        display: flex;
        margin-top: 30px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .big_title {
        font-size: 55px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec {
        text-align: center;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn_sec {
        justify-content: center;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn.mobile {
        display: block !important;
        width: fit-content
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn.desktop,
    #blk_game_dev_comp_main .blk_game_dev_comp_services .service_tab_sec #left_line:after {
        display: none;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs {
        padding: 20px;
        display: flex;
        white-space: nowrap;
        flex-wrap: unset;
        overflow-y: hidden;
        overflow-x: scroll;
        flex-direction: row !important;
        margin: 0px 10px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs li {
        padding-right: 20px;
    }

    /** Scrollbar Styles START **/
    #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs {
        scrollbar-width: thin;
        scrollbar-color: #00F0FF transparent;
        overflow-x: auto;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs::-webkit-scrollbar {
        width: 10px;
        height: 5px;
        border-radius: 6px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs::-webkit-scrollbar-track {
        border-radius: 6px;
        background: #1e1e1e;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: linear-gradient(160deg, #E92626 0%, #E022AB 34.95%, #B9E800 60.21%, #0FF 75%);
        height: 500px;
    }

    @-moz-document url-prefix() {
        #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs {
            scrollbar-width: thin;
            scrollbar-color: #00F0FF transparent;
            overflow-y: auto;
        }

        #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs::scrollbar {
            width: 10px;
            height: 5px;
            border-radius: 6px;
        }

        #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs::-webkit-scrollbar-track {
            border-radius: 6px;
            background: #1e1e1e;
        }

        #blk_game_dev_comp_main .blk_game_dev_comp_services .nav-tabs::-webkit-scrollbar-thumb {
            border-radius: 30px;
            background: linear-gradient(160deg, #E92626 0%, #E022AB 34.95%, #B9E800 60.21%, #0FF 75%);
            height: 500px;
        }
    }

    /** Scrollbar Styles END **/
    #blk_game_dev_comp_main .blk_game_dev_comp_techstack {
        min-height: unset;
        padding: 50px 0px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_techstack .carousel_container {
        max-width: unset;
        min-height: unset;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_techstack .carousel_sec {
        transform: unset;
        position: unset;
        margin-top: 30px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_top #top_blockchain_card {
        border-radius: 26px;
        background: #000;
        padding: 20px;
        margin: 0px 10px;
        text-align: center;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_benefits,
    #blk_game_dev_comp_main .blk_game_dev_comp_techstack,
    #blk_game_dev_comp_main .blk_game_dev_comp_services {
        padding: 50px 0px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-item {
        width: 100%;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link {
        margin-bottom: 7px;
        font-size: 18px;
        padding: 10px 20px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link.active {
        background: linear-gradient(47deg, #E12298 0%, #BBDC0A 100%);
        border: none;
        color: #fff;
        border-radius: 0px;
        -webkit-text-fill-color: unset;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab .nav-link.active:after {
        display: none;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab {
        background: #ffffff08;
        border-radius: 10px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best {
        margin-top: 0%;
        padding-top: 20px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_img_mbl {
        margin-top: 20px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_approaches .approach_box {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_top .title {
        margin-top: 0%;
    }
}

@media(max-width: 767px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_banner {
        display: flex;
        align-items: center;
        min-height: 600px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_banner .title {
        width: 349px;
        font-size: 30px !important;
        line-height: 1.4;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_banner .bnr_clr_title {
        font-size: 35px !important;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .big_title {
        font-size: 45px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_banner .banner_btn,
    #blk_game_dev_comp_main .blk_game_dev_comp_top .blk_game_btn,
    #blk_game_dev_comp_main .blk_game_dev_comp_whysec .blk_game_btn {
        font-size: 14px;
        padding: 10px 18px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec {
        padding-top: 0px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_whysec .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_services .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_techstack .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_benefits .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_genres .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_clones .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_approaches .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_top .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_best .title,
    #blk_game_dev_comp_main .blk_game_dev_comp_whyMetaBlock .title {
        font-size: 25px !important;
        line-height: 1.5 !important;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_techstack .title {
        text-align: center;
    }

    #blk_game_dev_comp_main .cus_title {
        font-size: 30px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn,
    #blk_game_dev_comp_main .blk_game_dev_comp_sec .blk_game_dev_about_btn1 {
        font-size: 14px !important;
        padding: 8px 18px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_whysec .why_blockchain_contsec {
        margin-top: 0;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_best #techstack_tab {
        margin-top: 20px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_services .tab-content {
        padding: 20px;
    }

    #blk_game_dev_comp_main .blk_game_dev_comp_genres .genres_box,
    #blk_game_dev_comp_main .blk_game_dev_comp_clones .clone_box {
        min-height: 0px;
    }
}

@media(max-width: 476px) {
    #blk_game_dev_comp_main .blk_game_dev_comp_top .title {
        font-size: 22px !important;
    }
}





.custom-drp.show .dropdown-menu {
    display: flex;
}

.navMnuMn li:hover>.sub-menu {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
    border-radius: 5px;
    box-shadow: 0 12px 28px 1px rgb(0 0 0 / 47%), 0 2px 4px 0 rgb(8 8 8 / 37%);
}

.navMnuMn .nav-item {
    position: relative;
}

.navMnuMn .sub-menu {
    visibility: hidden;
    opacity: 0;
    margin: 0;
    padding: 10px 10px;
    position: absolute;
    /*left: -100px;*/
    background: #090909;
    backdrop-filter: blur(23px);
    z-index: 99;
    transform: translate(0, 20px);
    transition: all 0.2s ease-out;
    width: max-content;
    top: 60px;
    border: 1px solid #ffffff12;
    padding: 20px 30px;
}

.navMnuMn .sub-menu ul li {
    margin-top: 10px;
}

.navMnuMn .sub-menu li a {
    text-decoration: none !important;
    font-weight: 400;
    font-size: 14px;
}

.navMnuMn .sub-menu li a:before {
    display: none;
}

.navMnuMn .sub-menu li a:after {
    display: none;
}

.tbsMenu .nav-tabs {
    display: none;
}

.cmpy_Overflow {
    background: #090909;
}

.card-body {
    background: #090909;
}

/*Social Icons Animation Style Start*/

.HddrLnks li.hasNewMenu a::before,
.HddrLnks li.hasNewMenu a::after {
    display: none !important;
}

.HddrLnks .hasNewMenu {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    background: #090909;
    place-items: center;
    border-radius: 50%;
    transition: .2s ease-in-out;
    position: relative;
    border: 1px solid #fff3;
    z-index: 0;
    cursor: pointer;
    margin-top: 5px;
}

.HddrLnks .hasNewMenu:hover {
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
    border-color: transparent;
    border: none;
}

.HddrLnks .hasNewMenu::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
    border-radius: 50%;
    will-change: box-shadow;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    z-index: -1;
    animation: 1s ease-out infinite ripple
}

.HddrLnks .hasNewMenu .nav-link .contactIconTop {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 6px;
    top: 0px;
}

.mr-40 {
    margin-right: 40px
}

@keyframes ripple {

    100%,
    25% {
        box-shadow: 0 0 0 0 rgb(165 115 201 / 30%), 0 0 0 .2em rgb(165 115 201 / 10%)
    }

    50% {
        box-shadow: 0 0 0 0.5em rgb(165 115 201 / 30%), 0 0 0 1em rgb(165 115 201 / 30%)
    }

    75% {
        box-shadow: 0 0 0 2em rgb(165 115 201 / 30%), 0 0 0 2.5em rgb(165 115 201 / 10%)
    }

    from {
        opacity: 1;
        transform: scale(1)
    }

    to {
        opacity: 0;
        transform: scale(2)
    }
}

.HddrLnks .hasNewMenu .social_icon_sub_menu {
    position: absolute;
    border: 1px solid #ffffff17 !important;
    border-radius: 6px;
    top: 55px !important;
    min-width: 280px !important;
    width: 280px !important;
    transition: .1s ease-in-out !important;
    background: #1c1f21 !important;
    right: 0px !important;
    left: auto !important;
    padding: 0px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 10px solid #8650ff;
    border-left: 10px solid transparent;
    top: -10px;
    right: 12px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact {
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: flex-start;
    border-radius: 6px 6px 0 0;
    background: #8650ff;
    padding: 5px 10px;
    border-bottom: 1px solid #efefef21;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu>p {
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 1px solid #ffffff38;
    margin-bottom: 0px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li {
    margin-top: 0px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li a {
    padding-left: 5px;
    margin-right: 0px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li a {
    font-size: 14px;
    margin-bottom: 0px;
    padding-left: 10px;
    margin-right: 0px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li a:hover {
    background: unset;
    color: #fff;
    -webkit-text-fill-color: unset;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .topNewContact ul li {
    display: flex;
    margin-top: 0px;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li.lastWhatsapp {
    display: flex;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li.lastWhatsapp a {
    line-height: 15px !important;
}

.HddrLnks .hasNewMenu .social_icon_sub_menu .middlePartMenu ul li .middlePartMenu_img {
    width: 18px;
}

.HddrLnks .HddrBtns .menu_btn {
    padding: 10px 20px;
    /*width: 160px;*/
    font-size: 15px;
    min-height: 40px;
}

.HddrLnks .HddrBtns .menu_btn:focus {
    box-shadow: unset;
}

.HddrLnks .HddrBtns .menu_btn.button_christ .raindeer_img {
    width: 25px;
    padding-left: 3px;
}

.HddrLnks .HddrBtns .menu_btn.button_christ {
    position: relative;
    display: flex;
    align-items: center;
    background: #8650ff;
    padding: 10PX 15PX;
    line-height: 26px;
    text-decoration: none;
    color: #FFF;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    border: 1px solid #8650ff;
    box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25), inset 0 0 6px #be48ff, inset 0 80px 80px -40px #a54cff, 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.HddrLnks .HddrBtns .menu_btn.button_christ::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: -7px;
    left: -3px;
    right: 0;
    height: 23px;
    background: url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow1.webp) no-repeat 0 0, url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow2.webp) no-repeat 50% 0, url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow3.webp) no-repeat 100% 0;
}

.HddrLnks li a.cus_title {
    color: #00fff5;
    animation: zoom-in-zoom-out1 3s ease-out infinite;
}

.HddrLnks li a.cus_title:before,
.HddrLnks li a.cus_title:after {
    background: unset;
}

.HddrLnks li a.cus_title:hover {
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background-clip: unset;
    text-fill-color: unset;
    color: #00fff5;
}

@keyframes zoom-in-zoom-out1 {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

/*Social Icons Animation Style Start*/

@media (min-width: 991px) {
    .tbsMenu .nav-tabs {
        display: flex;
        flex-flow: column nowrap;
    }

    .tbsMenu .nav-tabs {
        border-bottom: none;
        border-right: 1px solid #ddd;
        display: flex;
    }

    .tbsMenu .nav-tabs {
        margin: 0 15px;
    }

    .tbsMenu .nav-tabs .nav-item+.nav-item {
        margin-top: 0.25rem;
    }

    .tbsMenu .nav-tabs .nav-link {
        transition: border-color 0.125s ease-in;
        white-space: nowrap;
    }

    .tbsMenu .nav-tabs .nav-link:hover {
        background-color: #f7f7f7;
        border-color: transparent;
    }

    .tbsMenu .nav-tabs .nav-link.active {
        background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
        border-radius: 5px;
        border: none;
        color: #fff !important;
    }

    .tbsMenu .nav-tabs .nav-link.active:hover {
        background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
        color: #fff !important;
        -webkit-text-fill-color: unset;
    }

    .tbsMenu .card {
        border: none;
    }

    .tbsMenu .card .card-header {
        display: none;
    }

    .tbsMenu .card .collapse {
        display: block;
    }
}

@media (max-width: 991px) {
    .tbsMenu .tab-pane {
        display: block !important;
        opacity: 1;
    }

    .HddrLnks .HddrBtns .menu_btn {
        margin: 12px 0px;
    }
}

.cmpy_Overflow {
    height: 350px;
    overflow-x: hidden;
}

.tbsMenu ::-webkit-scrollbar {
    width: 3px;
}

/* Track */

.tbsMenu ::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 50px;
}

/* Handle */

.tbsMenu ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

/* Handle on hover */

.tbsMenu ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    border-radius: 50px;
}

.serSubMnu {
    left: -300px !important;
}

.serSubMnu .tab-content {
    width: 700px !important;
}

.serTabsOver {
    height: 450px;
    overflow-x: hidden;
}

.sub-menu ul li {
    position: relative;
}

.sub-menu ul li:after {
    content: "\25B6";
    position: absolute;
    top: 7px;
    left: -16px;
    color: #000000;
    font-size: 1px;
}

.cmpyAfter:after {
    display: none;
}

@media(min-width: 992px) {
    .menu_res {
        display: none;
    }
}

@media(max-width: 991px) {
    .menu_desk {
        display: none;
    }

    .menu_res .dropdown-menu {
        padding: 15px;
        background-color: #090909;
        border: 1px solid rgb(122 122 122 / 15%);
        color: #fff;
        height: 260px;
        overflow-y: scroll;
    }

    .menu_res .dropdown-toggle:after {
        right: -16px;
        top: 22px;
        background: #090909;
        width: 0px !important;
    }

    .menu_res .dropdown-toggle:before {
        display: none;
    }

    .compyMenuResHight {
        height: 400px;
        overflow-x: hidden;
    }

    .serSubMnu {
        position: initial !important;
        height: 350px;
        overflow-x: hidden;
        visibility: initial !important;
        opacity: 1 !important;
        width: initial !important;
    }

    .cmpy_Overflow {
        height: 200px !important;
    }

    .card {
        margin-top: 20px;
    }

    .navMnuMn .sub-menu {
        margin-bottom: 30px;
    }
}

#navbarNavAltMarkup .dropdown_sub_menu {
    margin-top: 10px;
}

#navbarNavAltMarkup .dropdown_sub_menu .drop_right_menu {
    width: max-content;
    margin-left: 2.1rem;
    padding: 20px 30px;
    background: #090909;
    backdrop-filter: blur(23px);
    border: 1px solid #ffffff12;
    border-radius: 5px;
    box-shadow: 0 12px 28px 1px rgb(0 0 0 / 47%), 0 2px 4px 0 rgb(8 8 8 / 37%);
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    margin-right: 5px;
    position: relative;
    display: inline-block;
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link::before {
    transition: unset;
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link:hover::before,
#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link:hover::after {
    background: unset;
    transition: unset;
}

#navbarNavAltMarkup .dropdown_sub_menu .dropdown_nav_link::after {
    right: auto;
    background: unset;
    margin-left: 10px;
}

@media (max-width: 991px) {
    .HddrBg .navbar #navbarNavAltMarkup .HdrSroll {
        height: 703px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

/*Side Conatct Form Style Start*/

.HddrBg .web_menu_btn_sec {
    position: relative;
    z-index: 999;
    cursor: pointer;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec {
    position: absolute;
    border: 1px solid #ffffff17 !important;
    border-radius: 6px;
    top: 55px !important;
    min-width: 300px !important;
    width: 300px !important;
    transition: .1s ease-in-out !important;
    background: #1c1f21 !important;
    right: 0% !important;
    left: auto !important;
    display: none;
    padding: 0px !important;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 10px solid #8650ff;
    border-left: 10px solid transparent;
    top: -10px;
    right: 20px;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec .topNewContact {
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: flex-start;
    border-radius: 6px 6px 0 0;
    background: #8650ff;
    padding: 10px 10px;
    border-bottom: 1px solid #efefef21;
}

.HddrBg .web_menu_btn_sec .contact_form_mainsec .topNewContact .sub_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.HddrBg .contact_form_mainsec .contact_form_sec {
    padding: 20px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .side_contact_submit_btn {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 8px;
    background: #8650ff !important;
    margin-top: 0px;
    border: none;
    padding: 8px 20px;
    padding-left: 20px !important;
    width: fit-content;
    color: #fff;
    text-align: center;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input {
    background: rgb(255 255 255 / 6%) !important;
    backdrop-filter: blur(2px);
    border: 1px solid #ffffff36;
    border-radius: 5px;
    color: #fff !important;
    height: 40px;
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 200;
    width: 100%;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_country select {
    background: rgb(255 255 255 / 6%) !important;
    backdrop-filter: blur(2px);
    border: 1px solid #ffffff36;
    border-radius: 5px;
    color: #fff !important;
    height: 40px;
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 200;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_country select option {
    color: #000 !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div {
    display: flex;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list {
    background: rgb(255 255 255 / 6%) !important;
    border: 1px solid #ffffff36;
    border-radius: 5px;
    color: #fff !important;
    height: 40px;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 200;
    margin-right: 10px;
    width: 30%;
    align-items: center;
    display: grid;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .btn {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none !important;
    color: #fff;
    padding: 5px;
    margin-top: 0px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .btn i {
    font-size: 16px;
    padding-left: 10px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg {
    background: rgb(255 255 255 / 6%) !important;
    backdrop-filter: blur(2px);
    border: 1px solid #ffffff36;
    border-radius: 5px;
    color: #fff !important;
    padding-left: 10px;
    margin-top: 0px;
    height: 90px;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 200;
    padding-top: 15px;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-ms-input-placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input::-ms-input-placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select::placeholder,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #header_proposal_msg::placeholder {
    /* Microsoft Edge */
    color: #fff;
}

#popup_form_modal .fitoutform .form-group {
    margin-bottom: 1em !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form select:-webkit-autofill,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form textarea:-webkit-autofill,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill:focus,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px #292c2e inset !important;
    background-image: none;
    -webkit-text-fill-color: #fff !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .select2-selection__rendered {
    color: #929292;
    margin: 0px;
    padding: 6px;
    background: #fff;
    padding-left: 15px
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .popup_select {
    padding-left: 15px;
    font-size: 17px;
    color: #929292 !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: unset;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form label.error {
    border-radius: 5px;
    background: red;
    color: #fff !important;
    padding: 2px;
    position: absolute;
    top: -12px;
    right: 0;
    width: auto;
    font-size: 10px;
    font-weight: 400;
    z-index: 1;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form label.error:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a {
    background: #8650ff;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    height: 40px;
    text-decoration: none;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li a {
    height: 40px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    height: 40px;
    color: #000;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a::before,
.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a::after {
    background: unset !important;
    -webkit-text-fill-color: unset !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form li a:hover {
    -webkit-text-fill-color: unset !important;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .selecpicker_input_group_div .select_icon_list .dropdown-menu ul li.selected a:hover {
    background: #8650ff !important;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    height: 40px;
    text-decoration: none;
}

.HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form #side_contact_form_error_msg {
    font-size: 14px !important;
}

/*Side Conatct Form Style End*/

/*Metaverse Blink Button Style*/

.single_trending_btn>p>a:hover {
    -webkit-text-fill-color: unset !important;
    -webkit-background-clip: unset;
    background: #bd48ff;
}

.single_trending_btn>p>a:hover:before,
.single_trending_btn>p>a::before,
.single_trending_btn>p>a::after {
    background: unset;
}

.single_trending_btn {
    position: absolute;
    right: 5px;
    bottom: -34px;
}

.single_trending_btn>p {
    margin-bottom: 0;
}

.single_trending_btn>p>a {
    color: #fff;
    background: #bd48ff;
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 25px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-decoration: none;
    margin-right: 0px;
    line-height: unset;
}

.single_trending_btn>p>a img {
    filter: invert(1);
    width: 20px;
}

.single_trending_btn .text_blink_animate {
    display: block;
    animation: zoom-in-zoom-out1 3s ease-out infinite;
}

@keyframes zoom-in-zoom-out1 {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

/*Metaverse Blink Button Style*/

@media(max-width: 991px) {
    .HddrBg .web_menu_btn_sec .side_menu_btn.button_christ {
        position: relative;
        display: flex;
        align-items: center;
        background: #8650ff;
        padding: 10PX 15PX;
        line-height: 23px;
        text-decoration: none;
        color: #FFF;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
        border-radius: 12px;
        border: 1px solid #8650ff;
        box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25), inset 0 0 6px #be48ff, inset 0 80px 80px -40px #a54cff, 1px 1px 3px rgba(0, 0, 0, 0.75);
    }

    .HddrBg .web_menu_btn_sec .side_menu_btn.button_christ::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: -7px;
        left: -3px;
        right: 0;
        height: 23px;
        background: url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow1.webp) no-repeat 0 0, url(https://www.MetaBlocktechnologies.com/asset/inner-pages-new/christmas/snow3.webp) no-repeat 100% 0;
    }
}

@media(max-width: 767px) {
    .HddrBg .navbar .logo_header .mbl_res_logo {
        width: 210px;
    }

    .HddrBg .web_menu_btn_sec .side_menu_btn {
        padding: 8px 10px !important;
        font-size: 13px !important;
    }

    .HddrBg .web_menu_btn_sec .contact_form_mainsec::after {
        right: 50px !important;
    }

    .HddrBg .web_menu_btn_sec .contact_form_mainsec {
        right: -30% !important;
    }

    .HddrBg .contact_form_mainsec .contact_form_sec #side_contact_form .side_contact_submit_btn {
        font-size: 14px !important;
    }
}

@media(max-width: 467px) {
    .HddrBg .web_menu_btn_sec .side_menu_btn {
        font-size: 12px !important;
        padding: 6px 8px !important;
        font-weight: 400 !important;
    }

    .HddrBg .logo_header {
        width: 55% !important;
    }

    .HddrBg .navbar {
        padding: 0.25rem 0.50rem;
    }
}

/*On Hover Hide Metaverse button*/

.HddrLnks .hasNewMenu:hover~.single_trending_sec {
    opacity: 0 !important;
}

.HddrBg .web_menu_btn_sec .side_menu_btn {
    padding: 8px 20px;
    font-size: 16px;
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
    color: #fff;
    font-weight: 500;
    border: none;
}

.HddrBg .web_menu_btn_sec .side_menu_btn:focus {
    box-shadow: unset;
}

/*Social Media Icons Style*/

.menu_social_icon_main {
    padding: 20px 0px;
}

.menu_social_icon_main .contents {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    font-style: italic;
}

.menu_social_icon_main .menu_social_listsec {
    width: fit-content;
}

.menu_social_icon_main .menu_social_listsec .menu_social_list {
    list-style: none;
    display: inline-block;
    position: relative;
    margin: 0 3px;
    transition: 0.3s all ease-in-out;
}

.menu_social_icon_main .menu_social_listsec .menu_social_list img {
    width: 35px;
}

.menu_social_icon_main .menu_social_listsec:hover>li {
    opacity: 0.4;
    transition: 0.3s all ease-in-out;
}

.menu_social_icon_main .menu_social_listsec>li:hover {
    opacity: 1;
    transition: 0.3s all ease-in-out;
    transform: scale(1.3);
}

/*Social Media Icons Style*/

.skiptranslate.goog-te-gadget,
#google_translate_element {
    visibility: hidden;
}

.skiptranslate.goog-te-gadget div:nth-child(1) {
    visibility: visible;
}

.skiptranslate iframe {
    display: none !important;
}

.fixed_social_icons {
    bottom: 29%;
    right: 1.2%;
    position: fixed;
    z-index: 10;
}

/*.fixed_social_icons ul {
        padding-left: 0px;
        padding: 10px 3px;
        text-align: center;
        background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
        border-radius: 5px;
    }*/

.fixed_social_icons li {
    float: none;
    list-style: none;
    margin-bottom: 10px;
}

.fixed_social_icons li a img {
    max-width: 25px;
}

a.whatsapp {
    position: relative;
}

.fixed_social_icons a.mobile {
    align-items: center;
    border-radius: 50%;
    color: #ffd;
    display: flex !important;
    font-size: 23px !important;
    height: 35px !important;
    justify-content: center;
    width: 35px !important;
    float: initial;
}

.fixed_social_icons a.desktop {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: flex !important;
    font-size: 23px !important;
    height: 35px !important;
    justify-content: center;
    width: 35px !important;
}

.fixed_social_icons a.skype_new {
    align-items: center;
    border-radius: 50%;
    color: #ffd;
    display: flex !important;
    font-size: 23px !important;
    height: 35px !important;
    justify-content: center;
    width: 35px !important;
    position: relative;
}

.fixed_social_icons a.telegram_new {
    align-items: center;
    border-radius: 50%;
    color: #ffd;
    display: flex !important;
    font-size: 23px !important;
    height: 35px !important;
    justify-content: center;
    width: 35px !important;
    position: relative;
}

.fixed_social_icons a.whatsapp:after {
    border: 5px solid #17c258;
}

@media screen and (max-width: 991px) {
    .fixed_social_icons .desktop {
        display: initial !important;
    }

    .fixed_social_icons {
        right: 2% !important;
        bottom: 20%;
    }

    #back_to_top_button {
        bottom: 14% !important;
    }
}

@media (max-width: 767px) {
    #back_to_top_button {
        width: 40px !important;
        right: 15px !important;
        bottom: 14% !important;
    }

    .fixed_social_icons li a img {
        max-width: 22px !important;
    }

    .fixed_social_icons li {
        margin-bottom: 10px;
    }

    #back_to_top_button i {
        font-size: 20px;
    }

    .fixed_social_icons {
        bottom: 20%;
        right: 1.5%;
    }
}

@media (max-width: 567px) {
    #back_to_top_button {
        right: 8px !important;
    }
}

/*Start Back to Top Section*/

#back_to_top_button {
    display: inline-block;
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    position: fixed;
    bottom: 21%;
    right: 17px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    cursor: pointer;
}

#back_to_top_button i {
    font-size: 22px;
    color: #fff;
    position: absolute;
    top: 20%;
    left: 33%;
}

#back_to_top_button.show {
    opacity: 1;
    visibility: visible;
}

/*End Back to Top Section*/

/*Fixed Button Start */

.designer_pops .demo-icon {
    font-size: 16px;
    position: fixed;
    white-space: nowrap;
    left: 100%;
    top: 50%;
    z-index: 10;
    color: #fff;
    padding: 4px 15px;
    text-transform: capitalize;
    font-weight: 500;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
    cursor: pointer;
    transform: translate(-59%, -50%) rotate(-90deg);
    text-align: center;
    border-radius: 0px;
    border: none;
    -webkit-animation: change_bg 5s infinite;
    animation: change_bg 5s linear infinite alternate;
}

@-webkit-keyframes change_bg {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(180deg);
    }
}

@keyframes change_bg {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(180deg);
    }
}

/*Fixed Button End*/

.home_blog_box img {
    display: block;
    max-width: 100% !important;
    height: auto;
}

.inr_cont h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.inr_cont .normal_text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400px;
    color: #fff;
    line-height: 25px;
}

.blog-sec .page_why h3 {
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    font-family: "Urbanist", sans-serif;
    text-transform: uppercase;
}

.blog_box.home_blog_box .inr_cont {
    padding: 10px !important;
}

.readmore {
    color: #E442FF;
}

.OurSrvcHdd .rel_title {
    text-transform: uppercase;
    background: linear-gradient(92.12deg, #E442FF 2.22%, #3C5BFF 51.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.blog_box.home_blog_box {
    padding: 20px;
    border: 1px solid #C446FF;
    border-radius: 10px;
}

.home_blog_box img {
    border-radius: 10px;
}

.inr_cont .sub_title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.inr_cont .normal_text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 100px;
}

.home_blog_box .readmore {
    border-radius: 8px;
    background: linear-gradient(136deg, #C746FF 0%, #405AFF 100%);
    color: #fff;
    padding: 6px 10px;
}

.BnrCnt .main_title {
    font-size: 35px !important;
    line-height: 52px;
    font-weight: 600;
    font-family: "Urbanist", sans-serif;
    text-transform: uppercase;
}

@media(max-width: 1300px) {
    .BnrCnt .main_title {
        font-size: 35px !important;
        line-height: 52px;
    }
}

@media(max-width: 767px) {
    .BnrCnt .main_title {
        font-size: 25px !important;
        line-height: 52px;
    }
}

.OurBlgBg {
    background: #000;
}

.blockchaincasino-main-slider-div {
    /* width: 800px; */
    width: 100% !important;
}