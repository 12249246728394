 /* .devanshu {
    display: flex;

}

@media(max-width:768px) {
    .devanshu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .award-1 div {
        width: 80%;
        height: 200px !important;
    }

}

@media(max-width:768px) {
    .why-choose-us-right-slider {
        margin-bottom: 40px !important;
    }

    .award-same-1 {
        display: none;
    }
} 
  */


   /* Updated CSS */
.devanshu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

@media (max-width: 768px) {
    .devanshu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .award-1 div {
        width: 100%;
        height: auto !important;
    }

    .why-choose-us-right-slider {
        margin-bottom: 20px !important;
    }

    .award-same-1 {
        display: none;
    }
}
