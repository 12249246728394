/*   
  .devanshu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

@media (max-width: 768px) {
    .devanshu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .award-1 div {
        width: 100%;
        height: auto !important;
    }

    .why-choose-us-right-slider {
        margin-bottom: 20px !important;
    }

    .award-same-1 {
        display: none;
    }
} */


/* General styles for the section */
.main-container {
  display: flex !important;
  flex-direction: column !important;
}

/* Row styles */
.row {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 1rem !important; /* Space between rows */
}

/* Column styles */
.col {
  flex: 1 !important; /* Allow columns to grow equally */
  max-width: 48% !important; /* Set max width for two columns */
}

/* Styles for the award items */
.award-1 {
  max-width: 100% !important; /* Ensure they don't overflow */
}

/* Text styles */
.text-white {
  color: white !important;
}

.text-warning {
  color: #ffc107 !important; /* Bootstrap warning color */
}

.p-award-1 {
  border-radius: 10px !important;
  padding: 1rem !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .row {
    flex-direction: column !important; /* Stack rows vertically on small screens */
  }

  .col {
    max-width: 100% !important; /* Full width for columns on small screens */
  }
}