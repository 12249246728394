.main-aboutus-div {
    width: 100%;
    height: auto;
    padding-top: 60px;
    background: url(../../../public/images/who-we-are-bg.webp) no-repeat;
   
    background-position: cover;
    background-size: cover;
    padding-bottom: 40px;
}

.container-div {
  
    margin: auto;
    display: flex;
    justify-content: center;

}

.heading-about-us {
    height: auto;
    border-radius: 11px;
    background: linear-gradient(134deg, #C846FF 0%, #445AFF 100%);
    box-shadow: 15px 13px 24px 0px rgba(230, 226, 226, 0.56);
    text-align: center;
    width: 17%;
    
}

.heading-about-us h1 {
    font-size: 4vw;
    display: flex;
    margin-top: 50px;
   
    font-weight: 700;
    line-height: 1.4;
}

.about-us-p {
    display: flex;
    justify-content: center;
    flex-direction: column;
   
    border-radius: 12px;
    
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));

    backdrop-filter: blur(4.5px);
    padding: 10px 20px 0px 20px;
    
    width: 70%;
  
}

.about-us-p p {
    line-height: 1.5;
    font-weight: 300;
    font-size: 1.3vw;
   
}

.Who-we-are-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 25px;
}

.button-who {

    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%) !important;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -6px lightpink;
    color: white;
    font-size: 13px;
    width: 140px;
}

.Who-we-are-buttons .button-who {
    
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%) !important;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px black !important;
    color: white;
    font-size: 11px;
    width: 140px;
}

.button-who {
   
    background-image: linear-gradient(-45deg, rgb(252, 86, 202), rgb(10, 199, 247));
    border-radius: 10px;
    box-shadow: 0px 0px 20px -6px lightpink;
    color: white !important;
    font-size: 13px;
    width: 140px;
}

.Our-service-button {
 
    background-image: linear-gradient(-45deg, rgb(252, 86, 202), rgb(10, 199, 247));
    border-radius: 10px;
    box-shadow: 0px 0px 20px -6px lightpink;
    color: white !important;
    font-size: 13px;
    width: 160px;
}


@media (max-width: 768px) {

    .container-div {
        flex-direction: column;

    }

    .heading-about-us {
        border-radius: 15px 15px 0px 0px;
        width: 300px;
        margin: auto;

    }

    .heading-about-us h1 {
        margin: 10px 0px 0px 30px;
        font-size: 30px;
        padding-top: 10px;
       
    }

    .about-us-p {
        width: auto;
        padding: 20px;
        
    }

    .about-us-p p {
        font-size: 16px;
        
    }
}


