#about_us_main {
    background: #090909;
}

#about_us_main .about_us_banner {
    background: url('/public/images/about-us-banner1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    height: 100vh;
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    #about_us_main .about_us_banner {
        position: relative;
        z-index: 1;
        text-align: center;
        background-position: 30% !important;
        height: 650px;
    }
}

#about_us_main .about_us_banner .title {
    font-weight: 300;
    font-size: 40px !important;
    text-transform: capitalize;
    line-height: 1.3;
    color: #fff;
    text-align: center;
}

#about_us_main .about_us_banner .cus_title {
    background: linear-gradient(94.84deg, #C139FF 1.15%, #546CFF 101.13%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 60px !important;
    font-weight: 600;
}

#about_us_main .about_us_banner .contents {
    font-size: 16px !important;
    color: #FFFFFF;
    line-height: 1.8;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: 300;
}

#about_us_main .about_us_banner .banner_btn_sec {
    margin-top: 30px;
}

#about_us_main .about_us_banner .banner_btn {
    padding: 8px 20px;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px !important;
    color: #fff !important;
    border: none;
}

@media (min-width:991px) {
    #about_us_main .who_we_are_sec {
        background: url(/public/images/who-we-are-bg.webp) no-repeat;
        background-size: cover;
        position: relative;
        padding: 80px 50px;
        background-position: 60%;
    }
}

#about_us_main .who_we_are_sec .title {
    font-size: 70px;
    text-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.38);
    color: #fff;
    font-weight: 800;
    line-height: 1.6;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-family: "Urbanist", sans-serif;
}

#about_us_main .who_we_are_sec .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
}

#about_us_main .who_we_are_sec .cus_contents {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-style: italic;
}

#about_us_main .who_we_are_sec .we_are_box {
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.51);
    backdrop-filter: blur(4.5px);
    padding: 40px 20px 30px 70px;
}

#about_us_main .who_we_are_sec .title_sec {
    border-radius: 11px;
    background: linear-gradient(134deg, #C846FF 0%, #445AFF 100%);
    box-shadow: 15px 13px 24px 0px rgba(0, 0, 0, 0.56);
    padding: 30px;
    margin-right: -15%;
    z-index: 1;
    position: relative;
    margin-left: 22%;
}

#about_us_main .our_mission_vision_sec {
    padding: 70px 0px;
}

#about_us_main .our_mission_vision_sec .vision_box {
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.03);
    padding-bottom: 20px;
    min-height: 288px;
}

#about_us_main .our_mission_vision_sec .vision_box .sub_title {
    color: #fff !important;
    font-size: 25px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(176deg, #C846FF 0%, #445AFF 100%);
    padding: 12px;
    text-align: center;
}

#about_us_main .our_mission_vision_sec .vision_box .about-title {
    color: #fff !important;
    font-size: 25px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(176deg, #C846FF 0%, #445AFF 100%);
    padding: 12px;
    text-align: center;
}

#about_us_main .our_mission_vision_sec .vision_box .sub_contents {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 1.7;
    padding: 20px;
    text-align: center;
}

#about_us_main .our_mission_vision_sec .sub_title1 {
    color: #fff !important;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 10px;
}

#about_us_main .our_mission_vision_sec .contents {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 1.7;
    text-align: center;
    padding-bottom: 20px;
}

#about_us_main .our_mission_vision_sec .values_box {
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.03);
    padding: 20px;
    text-align: center;
    margin-top: 60px;
    position: relative;
    min-height: 225px;
}

#about_us_main .our_mission_vision_sec .values_box .sub_title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-top: 10px;
}

#about_us_main .our_mission_vision_sec .values_box .sub_contents {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 0px;
    padding-top: 6px;
    line-height: 1.7;
}

#about_us_main .our_mission_vision_sec .sprite_icons {
    width: 65px;
    height: 65px;
    object-fit: none;
    position: absolute;
    top: -15%;
    left: 6%;
}

#about_us_main .whado_wedo_sec {
    padding: 50px 0px;
}

#about_us_main .whado_wedo_sec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#about_us_main .whado_wedo_sec .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    padding-top: 10px;
}

#about_us_main .blockchain_network {
    padding: 50px 0px;
}

#about_us_main .blockchain_network .title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
}

#about_us_main .blockchain_network .img_sec {
    margin-top: 30px;
}

#about_us_main .industries_work_sec {
    padding: 50px 0px;
}

#about_us_main .industries_work_sec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
}

#about_us_main .industries_work_sec .industries_list {
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#about_us_main .industries_work_sec .sub_title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-left: 10px;
}

#about_us_main .industries_work_sec .industries_list_sec {
    margin-top: 30px;
    display: grid;
    justify-content: center;
}

#about_us_main .industries_work_sec .industries_list_sec1 {
    margin-top: 30px;
}

#about_us_main .specialization_dev_sec {
    padding: 50px 0px;
}

#about_us_main .specialization_dev_sec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#about_us_main .specialization_dev_sec .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    padding-top: 10px;
}

#about_us_main .specialization_dev_sec .specialization_list_box {
    padding: 20px;
    border-radius: 15px 0px 0px 15px;
    border: 1px solid #FB46FF;
    background: #000;
    box-shadow: -3px 0px 10px 0px #fb46ff7a;
    height: 360px;
    overflow-y: scroll;
    border-right: none;
}

#about_us_main .specialization_dev_sec .specialization_list {
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#about_us_main .specialization_dev_sec .sub_title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    padding-left: 10px;
}

/** Scrollbar Styles START **/

#about_us_main .specialization_dev_sec .specialization_list_box {
    scrollbar-width: thin;
    scrollbar-color: #db9d00 transparent;
    overflow-y: auto;
}

#about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

#about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-track {
    background-color: #ffffff0f;
}

#about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-thumb {
    background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
}

#about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-track,
#about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-thumb {
    border-radius: 50px;
}

@-moz-document url-prefix() {
    #about_us_main .specialization_dev_sec .specialization_list_box {
        scrollbar-width: thin;
        scrollbar-color: #db9d00 #ffffff0f;
        overflow-y: auto;
    }

    #about_us_main .specialization_dev_sec .specialization_list_box::scrollbar {
        width: 5px;
        height: 3px;
    }

    #about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-track {
        background-color: #ffffff0f;
    }

    #about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-thumb {
        background: linear-gradient(93.79deg, #E442FF -13.43%, #3C5BFF 100.49%);
    }

    #about_us_main .specialization_dev_sec .specialization_list_box::-scrollbar-track,
    #about_us_main .specialization_dev_sec .specialization_list_box::-webkit-scrollbar-thumb {
        border-radius: 50px;
    }
}

/** Scrollbar Styles END **/

#about_us_main .development_process_sec {
    padding: 50px 0px;
}

#about_us_main .development_process_sec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
    margin-bottom: 20px;
}

#about_us_main .development_process_sec .contents {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
}

#about_us_main .development_process_sec .process_box {
    margin: 80px 0px;
}

#about_us_main .development_process_sec .sub_title {
    color: #C632FF;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
}

#about_us_main .development_process_sec .sub_contents {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 1.7;
}

#about_us_main .development_process_sec .sprite_icons {
    width: 100px;
    height: 100px;
    object-fit: none;
}

#about_us_main .development_process_sec .img_sec {
    text-align: center;
    background: #000;
    box-shadow: inset 0px 0px 16px #ffffff26;
    padding: 30px 50px;
    border-radius: 10px;
    width: fit-content;
}

#about_us_main .list_of_certificat_sec {
    padding: 50px 0px;
}

#about_us_main .list_of_certificat_sec .title {
    font-size: 35px;
    color: #fff;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
    font-family: "Urbanist", sans-serif;
    text-align: center;
}

#about_us_main .list_of_certificat_sec .img_sec {
    margin-top: 30px;
}

#about_us_main .contact_us_achive {
    padding: 50px 0px;
}

#about_us_main .contact_us_achive .title {
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    line-height: 1.4;
    text-transform: capitalize;
    text-align: center;
    font-family: "Urbanist", sans-serif;
}

#about_us_main .contact_us_achive .achive_box {
    padding: 10px;
    text-align: center;
    margin-top: 30px;
}

#about_us_main .contact_us_achive .sub_title {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    padding-top: 10px;
}

#about_us_main .contact_us_achive .cus_title {
    color: #B43EFF;
    font-weight: 500;
}

#about_us_main .contact_us_achive .sprite_icons {
    width: 102px;
    height: 100px;
    object-fit: none;
}

@media(max-width: 991px) {
    #about_us_main .about_us_banner {
        display: flex;
        align-items: center;
        padding: 50px 0px;
        text-align: center;
    }

    #about_us_main .about_us_banner .banner_btn_sec {
        justify-content: center;
        display: flex;
    }

    #about_us_main .who_we_are_sec {
        padding: 50px 0px;
    }

    #about_us_main .who_we_are_sec .title_sec {
        border-radius: 10px 10px 0px 0px;
        margin-right: 0%;
        margin-left: 0%;
        padding: 10px;
    }

    #about_us_main .who_we_are_sec .we_are_box {
        padding: 40px 20px 30px 30px;
    }

    #about_us_main .who_we_are_sec .title {
        font-size: 50px;
        text-align: center;
        margin-bottom: 0px;
    }

    #about_us_main .whado_wedo_sec {
        text-align: center;
    }
}

@media(max-width: 767px) {
    #about_us_main .about_us_banner {
        display: flex;
        align-items: center;
        height: 550px;
        background-size: cover;
        padding: 50px 0px;
    }

    #about_us_main .about_us_banner .banner_btn,
    #about_us_main .smart_contract_dev_why .smart_contract_btn {
        font-size: 15px !important;
        padding: 10px 20px;
        margin: 0 auto;
        width: 165px;
    }

    #about_us_main .who_we_are_sec .title,
    #about_us_main .whado_wedo_sec .title,
    #about_us_main .blockchain_network .title,
    #about_us_main .industries_work_sec .title,
    #about_us_main .specialization_dev_sec .title,
    #about_us_main .development_process_sec .title,
    #about_us_main .list_of_certificat_sec .title,
    #about_us_main .contact_us_achive .title {
        font-size: 25px !important;
        line-height: 1.6 !important;
        text-align: center;
    }

    #about_us_main .our_mission_vision_sec {
        padding: 50px 0px;
    }

    #about_us_main .our_mission_vision_sec .vision_box {
        min-height: 0px;
        margin-bottom: 30px;
    }

    #about_us_main .our_mission_vision_sec .values_box {
        min-height: 0px;
    }

    #about_us_main .our_mission_vision_sec .sub_title1 {
        padding-top: 20px;
        padding-bottom: 0px;
    }

    #about_us_main .industries_work_sec .industries_list_sec {
        margin-left: 20%;
        display: table;
    }

    #about_us_main .industries_work_sec .industries_list_sec1 {
        margin-left: 20%;
    }

    #about_us_main .specialization_dev_sec .contents {
        text-align: center;
    }

    #about_us_main .development_process_sec .process_box {
        margin: 30px 0px;
        text-align: center;
    }

    #about_us_main .development_process_sec .img_sec {
        margin: 0 auto;
    }

    #about_us_main .development_process_sec .sub_title {
        padding-top: 10px;
    }

    #about_us_main .specialization_dev_sec .specialization_list_box {
        margin-top: 20px;
    }
}


.sub_title-1 {
    color: #C632FF;
}